import { useState, createContext, useEffect, useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from "../firebase";
import { doc, getDoc } from 'firebase/firestore'; // Import getDoc

export const ActiveUidContext = createContext();

export const ActiveUidProvider = ({ children }) => {
    const [user] = useAuthState(auth);
    const [uid, setUid] = useState(null);
    const [email, setEmail] = useState(null);
    const [userType, setUserType] = useState(null)

    useEffect(() => {
        if (user) {
            // Ensure user doc matching id in clientDashboardAdmin exists
            const userDocRef = doc(firestore, "clientDashboardAdmin", user.uid);
            getDoc(userDocRef).then((docSnap) => { // Use getDoc to fetch document snapshot
                if (docSnap.exists()) {
                    setUid(user.uid);
                    setEmail(docSnap.data().email);
                    setUserType("DashboardAdmin")
                } else {
                    // If not in clientDashboardAdmin, check additionalSeats
                    const additionalSeatsRef = doc(firestore, "additionalSeats", user.email);
                    getDoc(additionalSeatsRef).then((docSnap) => {
                        if (docSnap.exists()) {
                            setUid(docSnap.data().adminID);
                            setEmail(user.email);
                            setUserType("AdditionalSeats")
                        } else {
                            setUid(null);
                        }
                    }).catch((error) => {
                        console.error("Error fetching additionalSeatsRef:", error);
                        setUid(null);
                    });
                }
            }).catch((error) => {
                console.error("Error fetching document:", error);
                setUid(null);
            });
        } else {
            setUid(null); // Reset uid if no user is logged in
        }
    }, [user]);

    return (
        <ActiveUidContext.Provider value={{ uid, setUid, email, userType }}>
            {children}
        </ActiveUidContext.Provider>
    );
};
