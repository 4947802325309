import React, { Dispatch, SetStateAction, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,

} from "@stripe/react-stripe-js";
import { Box, Button, FormControl, FormLabel, Input, Spinner, Text, useToast, VStack, Flex } from "@chakra-ui/react";
import { addPaymentMethodToCustomer } from '../../../../services/flaskService'

interface StripePaymentMethodFormProps {
  onClose: () => void;
  setLoadPaymentMethod: Dispatch<SetStateAction<boolean>>;
  email: string
}

const StripePaymentMethodForm: React.FC<StripePaymentMethodFormProps> = ({ onClose, email, setLoadPaymentMethod }) => {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [cardHolderName, setCardHolderName] = useState<string | null>("");
  const [loadingPaymentMethodProcessing, setLoadingPaymentMethodProcessing] =
    useState<boolean>(false);

  /**
   * @info process for add payment method for the customer 
   * check and validation card elements and if error then show error if not then first add payment method to stripe and then attatch card to customer while api in backend
   * In the backend just pass paymentMethodId and email of customer.
   * @param event 
   * @returns 
   */
  const processPayment = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoadingPaymentMethodProcessing(true);
    setErrorMessage(null)

    try {
      // check if stripe element is there or not
      if (!stripe || !elements) {
        setErrorMessage("Stripe has not loaded yet.");
        return;
      }

      // check if card holder name is there or not.
      if (!cardHolderName) {
        setErrorMessage("Card holder name should not be empty.");
        setLoadingPaymentMethodProcessing(false);
        return;
      }
      // get card element and if card element is there or not
      const cardElement = elements.getElement(CardNumberElement);
      if (!cardElement) {
        setErrorMessage("Card details are not provided.");
        setLoadingPaymentMethodProcessing(false);
        return;
      }

      // create payment method to stripe
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardHolderName,
        },
      })


      if (error) {
        setErrorMessage("Invalid card detail.");
        setLoadingPaymentMethodProcessing(false);
        return;
      }

      // add payment method to backend
      const addPayment = await addPaymentMethodToCustomer(paymentMethod.id, email)
      if (!addPayment) {
        throw new Error("Error occurred while add payment method.")
      }

      toast({
        title: "Payment method added sucessfully.",
        status: "success",
        duration: 1250,
        isClosable: false,
      });
      // disable loader
      setLoadingPaymentMethodProcessing(false);
      setLoadPaymentMethod(true)
      // close the model on successfully adding of card
      onClose()
    } catch (error: any) {
      const errorObj = error.toString()
      toast({
        title: 'Validation Errors',
        description: errorObj?.message || errorObj,
        status: 'error',
      });
      setLoadingPaymentMethodProcessing(false);
      return;
    }

  };

  // style for card element
  const cardStyleOptions = {
    style: {
      base: {
        fontSize: '15px',
        padding: "8px",
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  return (
    <Box p={0} bg="white" width="100%">
      <VStack spacing={4} width="100%">
        <Text fontSize="xl" fontWeight={'bold'}>Card Detail</Text>
        <form onSubmit={processPayment} style={{ width: "100%", textAlign: "center" }}>

          <FormControl mb="16px">
            <FormLabel variant={'chakra_label_light'} fontSize={'chakra_sm'} display={'flex'} gap={'1'}>Card Holder Name<Text color="red">*</Text></FormLabel>
            <Input height={8} type={"text"} name="cardHolderName" placeholder="Card Holder Name"
              value={cardHolderName} onChange={(e) => {
                setCardHolderName(e?.target?.value)
              }}
            />
          </FormControl>

          <FormControl mb="16px">
            <FormLabel variant={'chakra_label_light'} fontSize={'chakra_sm'} display={'flex'} gap={'1'}> Card Number<Text color="red">*</Text>
            </FormLabel>

            <Box border='1px' borderColor='gray.200' padding="5px" height={8} alignItems='center' borderRadius='md'>
              <CardNumberElement
                options={cardStyleOptions}
              />
            </Box>
          </FormControl>

          <Flex gap={6}>
            <FormControl>

              <FormLabel variant={'chakra_label_light'} fontSize={'chakra_sm'} display={'flex'} gap={'1'}> Expiry Date<Text color="red">*</Text>
              </FormLabel>
              <Box border='1px' borderColor='gray.200' padding="5px" height={8} alignItems='center' borderRadius='md'>
                <CardExpiryElement
                  options={cardStyleOptions}
                />
              </Box>
            </FormControl>
            <FormControl>

              <FormLabel variant={'chakra_label_light'} fontSize={'chakra_sm'} display={'flex'} gap={'1'} >CVC<Text color="red">*</Text>
              </FormLabel>
              <Box border='1px' borderColor='gray.200' padding="5px" height={8} alignItems='center' borderRadius='md'>
                <CardCvcElement
                  options={cardStyleOptions}
                />
              </Box>
            </FormControl>
          </Flex>
          <Button
            type="submit"
            isDisabled={!stripe || !elements}
            backgroundColor="black"
            height="50px"
            width="150px"
            mt="20px"
            borderRadius="10px"
            transition="transform 0.2s, box-shadow 0.2s"
            transform="translateY(0)"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _focus={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _active={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Text
              fontWeight="500"
              fontSize="15px"
              textAlign="center"
              color="white"
            >
              {
                loadingPaymentMethodProcessing ? <Spinner size="md" /> : "Add Card"
              }
            </Text>
          </Button>
        </form>

        {errorMessage && (
          <Text mt={4} color="red.500">
            {errorMessage}
          </Text>
        )}
      </VStack>
    </Box >
  );
};

export default StripePaymentMethodForm;
