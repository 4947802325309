import React from "react";
import { Text } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Spinner
} from "@chakra-ui/react";

interface DeleteConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  handleDelete: () => void;
  deletedName: string;
  isDeleting: boolean;
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
  isOpen,
  onClose,
  handleDelete,
  deletedName,
  isDeleting,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Are you sure you want to delete {deletedName}?</p>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" onClick={() => handleDelete()}>
            {isDeleting ? <Spinner size="md" /> : <Text>Delete</Text>}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteConfirmation;
