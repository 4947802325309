import { mode } from '@chakra-ui/theme-tools';

export const radioStyles = {
  components: {
    Radio: {
      baseStyle: {
        control: {

        },
      },
      variants: {
        chakra_radio_light: (props: any) => ({
          control: {
            borderRadius: '50%',
            transition: 'all 0.4s ease-in-out 0s',
            bg: mode('#fff', '#fff')(props),
            borderColor: mode('rgba(111, 118, 126, 0.4)', 'rgba(111, 118, 126, 0.4)')(props),
            color: mode('#33383F', '#33383F')(props),
            _active: {
              bg: mode('#2A85FF', '#2A85FF')(props),
              borderColor: mode('#2A85FF', '#2A85FF')(props),
            },
          },
        }),
        buttonRadio: {
          control: {
            borderRadius: "12px",
            bg: "#fff",
            color: "#344054",
            border: "1px solid",
            borderColor: "#fff",
            _checked: {
              bg: "#24282C",
              color: "#fff",
              borderColor: "#24282C",
            },
            _hover: {
              bg: "#24282C",
              color: "#fff",
            },
          },
        },
      },
      sizes: {
        radio_lg: {
          control: {
            width: '29px',
            height: '29px',
          },
        },
        radio_button: {
          control: {
            width: 'auto',
            height: 'auto',
            fontSize: '17px',
            lineHeight: '24px',
            padding: '12px 60px',
          },
        },
      },
    },
  },
};
