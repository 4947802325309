import {
  Box,
  Flex,
  Text,
  Button,
  Spinner,
  Avatar,
  background,
  Icon,
  SimpleGrid,
} from "@chakra-ui/react";

import BackButton from "../../../../components/buttons/BackButton";
import DropdownMenuCommunity from "../components/DropdownMenuCommunity";
import { useState, useEffect } from "react";
import Card from "components/card/Card";
import CustomerStatTiles from "../components/CustomerStatTiles";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { CustomersDataContext } from "contexts/customersDataContext";
import { BsTelephone } from "react-icons/bs";
import { CopyIcon } from "@chakra-ui/icons";
import { FaInstagram, FaTiktok } from "react-icons/fa";
import { MdOutlineEmojiPeople } from "react-icons/md";
import { LuClipboardList } from "react-icons/lu";

import {
  MdDiscount,
  MdLocationPin,
  MdOutlineBarChart,
  MdSend,
  MdImage,
  MdCheck,
} from "react-icons/md";
import StatsRow from "../../../../components/stats/StatsRow";
import UGCImage from "../../ugcLibrary/components/UGCImage";
import { useToast } from "@chakra-ui/react";

function ViewCustomerProfile() {
  function formatPhoneNumber(phoneNumber: string) {
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

    const formattedPhoneNumber = `(${numericPhoneNumber.slice(
      0,
      3
    )}) - ${numericPhoneNumber.slice(3, 6)} - ${numericPhoneNumber.slice(6)}`;

    return formattedPhoneNumber;
  }

  const [isLoading, setIsLoading] = useState(true);
  const [imageLinks, setImageLinks] = useState<string[]>([]);

  function getCustomerUGCLinks(rawAcceptedOffers: Array<any>) {
    setIsLoading(true);

    const listOfUGCLinks: Array<string> = [];

    for (let i = 0; i < rawAcceptedOffers.length; i++) {
      if (
        rawAcceptedOffers[i].ugcURL !== undefined &&
        rawAcceptedOffers[i].ugcURL !== ""
      ) {
        listOfUGCLinks.push(rawAcceptedOffers[i].ugcURL);
      } else if (
        rawAcceptedOffers[i].uploadedUGC !== undefined &&
        rawAcceptedOffers[i].uploadedUGC !== ""
      ) {
        listOfUGCLinks.push(rawAcceptedOffers[i].uploadedUGC);
      }
    }

    setImageLinks(listOfUGCLinks);
    setIsLoading(false);
  }

  const [isBackButtonHovered, setIsBackButtonHovered] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const customerPhoneNumber = "+" + queryParams.get("phone").trim();
  const clientID = queryParams.get("accountId");

  const { data: customersData } = useContext(CustomersDataContext);

  let customers: { [phoneNumber: string]: any } = {}; // Defines an object with string keys and 'any' type values

  Object.keys(customersData).forEach((clientIDKey) => {
    const clientCustomerData = customersData[clientIDKey];

    // Assume clientCustomerData is an object where keys are phoneNumbers and values are customer details
    Object.keys(clientCustomerData).forEach((phoneNumber) => {
      customers[phoneNumber] = clientCustomerData[phoneNumber];
    });
  });

  const customer = customers[customerPhoneNumber];

  useEffect(() => {
    getCustomerUGCLinks(customer?.rawAcceptedOffers);
  }, [customer.rawAcceptedOffers]);

  let rawAcceptances = customer?.rawAcceptedOffers ?? {};

  let acceptances = customer?.rawAcceptedOffers ?? {};
  let posts = customer?.postedOffers ?? {};
  let redemptions = customer?.redeemedOffers ?? {};

  console.log(acceptances);

  if (clientID.length > 1) {
    if (rawAcceptances.length > 0) {
      acceptances = rawAcceptances.filter(
        (acceptance) => acceptance.clientID === clientID
      );
      posts = rawAcceptances.filter(
        (acceptance) =>
          acceptance.clientID === clientID && acceptance.finalValidation
      );
      redemptions = rawAcceptances.filter(
        (acceptance) =>
          acceptance.clientID === clientID && acceptance.isRedeemed
      );
    }
  }

  const statsList = [
    {
      title: "Estimated Reach",
      value: String(Math.floor((customer?.instagramFollowerCount || 0) / 3)),
      icon: MdOutlineBarChart,
    },
    {
      title: "Offers Accepted",
      value: String(acceptances.length),
      icon: MdCheck,
    },
    {
      title: "Completed Posts",
      value: String(posts.length),
      icon: MdImage,
    },
    {
      title: "Discounts Redeemed",
      value: String(redemptions.length),
      icon: MdDiscount,
    },
  ];

  const toast = useToast();

  const columnsLocal = 2;

  const handleCopy = async (text: string, mediaType: string) => {
    navigator.clipboard.writeText(text);

    toast({
      title: mediaType + " copied to clipboard",
      status: "success",
      duration: 1500,
      isClosable: false,
    });
  };

  return (
    <Box
      backgroundColor="white"
      height="1100px"
      borderRadius="10px"
      overflow="hidden"
    >
      <Flex justifyContent="space-between" alignItems="center" pr="10px">
        <Flex alignItems="center" pt="25px" pb="25px">
          <BackButton
            isHovered={isBackButtonHovered}
            setIsHovered={setIsBackButtonHovered}
          />
          <Text ml="10px" fontWeight="bold" fontSize="30px">
            View customer profile
          </Text>
        </Flex>

        {/* 
        <Button
          onClick={() => {}}
          backgroundColor="black"
          borderRadius="10px"
          mr="2%"
          transition="transform 0.2s, box-shadow 0.2s"
          transform="translateY(0)"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          _hover={{
            transform: "scale(1.05)",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          _focus={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          _active={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Text
            fontWeight="500"
            fontSize="16px"
            textAlign="center"
            color="white"
          >
            Send Offer
          </Text>
        </Button>
         */}
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <Box borderTop="1.3px solid #E2E8F0" width="96%" />
      </Flex>
      <Flex p="1.5%" flexDirection="column">
        <Box>
          <StatsRow stats={statsList}></StatsRow>
        </Box>

        <Flex flexDirection="row" alignItems="center" justify="space-evenly">
          <Box
            backgroundColor="white"
            width="49%"
            height="830px"
            borderRadius="lg"
            boxShadow="md"
          >
            <Flex flexDirection="column" p="5%">
              <Flex align="center">
                <Box
                  display="inline-block"
                  width="130px"
                  height="130px"
                  borderRadius="50%"
                  backgroundImage={customer.profilePicURL}
                  backgroundSize="cover"
                  boxShadow="md"
                />

                <Text fontSize="32.5px" fontWeight="600" pl="6%">
                  {customer.instagramDisplayName}
                </Text>
              </Flex>
              <Box
                backgroundColor="white"
                mt="4%"
                width="100%"
                boxShadow="md"
                p="4%"
                borderRadius="lg"
              >
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Flex justifyContent="start" alignItems="center" width="70%">
                    <BsTelephone size="35px" />

                    <Text ml="4%" fontWeight="500" fontSize="18px" width="110%">
                      {"+1"} {formatPhoneNumber(customerPhoneNumber.slice(2))}
                    </Text>
                  </Flex>
                  <CopyIcon
                    boxSize={5}
                    onClick={() => {
                      handleCopy(
                        formatPhoneNumber(customerPhoneNumber.slice(2)),
                        "Phone number"
                      );
                    }}
                    _hover={{ cursor: "pointer" }}
                  />
                </Flex>
              </Box>
              <Box
                backgroundColor="white"
                mt="4%"
                width="100%"
                boxShadow="md"
                p="5%"
                borderRadius="lg"
              >
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Flex justifyContent="start" alignItems="center" width="70%">
                    <FaInstagram size="35px" />

                    <Text ml="4%" fontWeight="500" fontSize="18px" width="110%">
                      {customer.instagramHandle}
                    </Text>
                  </Flex>
                  <CopyIcon
                    boxSize={5}
                    onClick={() => {
                      handleCopy(customer.instagramHandle, "Instagram");
                    }}
                    _hover={{ cursor: "pointer" }}
                  />
                </Flex>
                <Flex justifyContent="start" alignItems="center" mt="1.5">
                  <Icon
                    as={MdOutlineEmojiPeople}
                    boxSize={5}
                    color="black"
                    ml="10%"
                  />
                  <Text fontSize="15px" ml="2%">
                    {customer.instagramFollowerCount}
                  </Text>
                </Flex>
                <Flex justifyContent="start" alignItems="center" mt="1.5">
                  <Icon
                    as={LuClipboardList}
                    boxSize={5}
                    color="black"
                    ml="10%"
                  />
                  <Text fontSize="15px" ml="2%">
                    {customer.instagramBio}
                  </Text>
                </Flex>

                {customer.tiktokHandle !== "" &&
                  customer.tiktokHandle !== undefined && (
                    <Flex
                      mt="5%"
                      flexDirection="row"
                      alignItems="center"
                      justify="space-between"
                    >
                      <Flex
                        justifyContent="start"
                        alignItems="center"
                        width="70%"
                      >
                        <FaTiktok size="30px" />

                        <Text
                          ml="4%"
                          fontWeight="500"
                          fontSize="18px"
                          width="110%"
                        >
                          {customer.tiktokHandle}
                        </Text>
                      </Flex>
                      <CopyIcon
                        boxSize={5}
                        onClick={() => {
                          handleCopy(customer.tiktokHandle, "TikTok");
                        }}
                        _hover={{ cursor: "pointer" }}
                      />
                    </Flex>
                  )}
              </Box>
            </Flex>
          </Box>

          <Box
            backgroundColor="white"
            width="49%"
            height="830px"
            borderRadius="lg"
            boxShadow="md"
          >
            <Text fontSize="32.5px" fontWeight="600" p="5%">
              UGC
            </Text>

            <Box
              backgroundColor="transparent"
              maxHeight="85%"
              mx="5%"
              overflowY="auto"
            >
              {isLoading && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  height="200px"
                >
                  <Spinner size="xl" />
                </Flex>
              )}
              {!isLoading && (
                <>
                  {imageLinks.length > 0 ? (
                    <SimpleGrid columns={columnsLocal} spacing="4%">
                      {imageLinks.map((link, index) => (
                        <UGCImage
                          link={link}
                          idx={index}
                          key={index}
                          hoverPop={false}
                        />
                      ))}
                    </SimpleGrid>
                  ) : (
                    <Flex justifyContent="center" mt="5%">
                      <Text justifyContent="center" fontSize="20px">
                        User has no UGC yet.
                      </Text>
                    </Flex>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

export default ViewCustomerProfile;
