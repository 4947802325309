import React, { useEffect, useState } from "react";
import { Box, Image, BoxProps, Text } from "@chakra-ui/react";
import UGCPopup from "./UGCPopup";
import { getContentMetadata } from "services/firebaseService";

interface UGCImageProps extends BoxProps {
  link: string;
  idx: number;
  hoverPop: boolean;
}

const UGCImage: React.FC<UGCImageProps> = ({ link, idx, hoverPop }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [linkData, setLinkData] = useState<any>();

  const handleClick = () => {
    setIsOpen(true); // Set isOpen to true when the box is clicked
  };

  const onClose = () => {
    setIsOpen(false); // Set isOpen to false when the popup is closed
  };

  // fetch the metadata for the link from firestore
  useEffect(() => {
    getContentMetadata(link).then((data) => {
      setLinkData(data);
    });
  }, [link]);

  console.log(linkData?.likes);

  return (
    <Box
      w="100%"
      overflow="hidden"
      borderRadius="md"
      shadow="md"
      p={2}
      transition="transform 0.2s"
      _hover={{
        transform: hoverPop ? "scale(1.05)" : "scale(1.00)",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <Box backgroundColor={'#000'} position={'relative'} pt="100%" borderRadius="md" overflow={"hidden"}>
        {link?.includes(".mp4") ? (
          <video controls width="100%" style={
            {
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center"
            }
          }>
            <source src={link} type="video/mp4" />
            An error has occurred loading the video.
          </video>
        ) : (
          <Image
            src={link}
            alt={`Image ${idx + 1}`}
            objectFit="cover"
            borderRadius="md"
            position={'absolute'}
            top={'0px'}
            left={'0px'}
            width={"100%"}
            height={"100%"}
          />
        )}
      </Box>
      {(linkData?.likes ||
        linkData?.comments ||
        linkData?.views ||
        linkData?.caption) && (
          <Text fontSize={linkData?.caption && "8px"}>
            <br />
            {linkData?.likes ? (
              <>
                Likes: {linkData.likes}
                <br />
              </>
            ) : (
              ""
            )}
            {linkData?.comments ? (
              <>
                Comments: {linkData.comments}
                <br />
              </>
            ) : (
              ""
            )}
            {linkData?.views ? (
              <>
                Views: {linkData.views}
                <br />
              </>
            ) : (
              ""
            )}
            {linkData?.caption ? (
              <>
                {linkData.caption}
                <br />
              </>
            ) : (
              ""
            )}
          </Text>
        )}{" "}
      {linkData?.likes !== undefined && <Box w="100px" color="black"></Box>}
      <UGCPopup
        link={link}
        idx={idx}
        isOpen={isOpen}
        onClose={onClose}
        linkData={linkData}
      />
    </Box>
  );
};

export default UGCImage;
