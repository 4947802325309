/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import {
    Text,
    Flex,
    Box,
    FormControl,
    Button,
    FormLabel,
    Input,
    useToast,
    Spinner,
} from "@chakra-ui/react";
import {
    createNewOfferFlowDoc,
    deleteOfferDoc,
    generateID,
    getCurrentUTCTimestamp,
    updateClientOfferIds,
    updateLocationOfferIds,
    updateNewOfferFlowDoc,
    uploadImageExampleUGC,
    uploadOfferImage,
    uploadVideoExampleUGC,
} from "services/firebaseService";
import {
    createShopifyPriceRule,
    sendPrivateOffer,
} from "services/flaskService";
import { useHistory } from "react-router-dom";
import { cashBackSummary, decimalFraction, strToFloat, valueToNumberConvert } from "utils";
import { removeData } from "utils/localStorage.service";

interface CashbackAmountProps {
    inputValues: {
        offerName: string;
        date?: string;
        integration?: string;
        integrations?: any;
        isPrivate: boolean;
        isInfluencerOffer: boolean;
        isCustomerOffer: boolean;
        privateOfferPhoneList?: any[];
        chosenCustomerGroups?: any[];
        typeOfOffer: string;
        isCash: boolean;
        locations?: any[];
        existingLocations?: any[]
        influencerPhoneNumbers?: any[];
        contentType: string;
        quantity: number;
        exampleUGC: any;
        OfferImage?: any;
        specificProduct?: string;
        hashtag?: string;
        compensation?: any;
        percentOrCashOff?: string;
        isPrerequisite?: boolean;
        prerequisiteName?: string;
        prerequisiteQuantity?: number;
        prerequisiteCollectionID?: string;
        isFreeReward?: boolean;
        discountItem?: string;
        discountItemID?: string;
        discountCollectionID?: string;
        minimumFollowerCount?: number;
        minPurchaseAmount?: number;
        expirationLimit?: number;
        rewardClientIDs?: any[];
        rewardClientNames?: any[];
        offerImage?: any;
        offerID?: string;
        instagramStory?: any;
        isCashBack: boolean;
        discountType: string;
        // new fields added in offer flow on 03/06/2024
        ecommerceDiscountCode?: string;
        productId?: string;
        collectionID?: string;
        exampleUgcFile?: any
    };
    onInputChange: (
        fieldName: keyof CashbackAmountProps["inputValues"],
        value: any
    ) => void;
    data: any;
}

const CashbackAmount: React.FC<CashbackAmountProps> = ({
    inputValues,
    onInputChange,
    data: additionalData,
}) => {
    const toast = useToast();
    const history = useHistory();
    const [isOfferSubmitPending, setIsOfferSubmitPending] = useState(false);
    const { accountData, clientID, isEditing, editedOfferId, offerDetail } = additionalData;
    const { instagramHandle, offers = [], accountName } = accountData;
    const clientOfferList = offers;
    const minPurchaseAmount = 0;
    const minimumFollowerCount = Number(inputValues?.minimumFollowerCount); // minimum follower count
    const handlePostOffer = async () => {
        setIsOfferSubmitPending(true);
        if (isEditing && editedOfferId) {
            try {
                let productId = inputValues.productId ?? "";
                let collectionID = inputValues.collectionID ?? "";
                let exampleUGCUrl = inputValues?.exampleUGC;
                let offerImageUrl = inputValues?.offerImage ?? "";
                if (inputValues?.offerImage) {
                    offerImageUrl = await uploadOfferImage(
                        inputValues.offerImage,
                        clientID,
                        editedOfferId
                    );
                }

                let integrations = inputValues?.integration
                    ? { [inputValues?.integration]: {} }
                    : null;
                if (inputValues?.integration) {
                    if (inputValues?.integration === "shopify") {
                        try {
                            const priceRuleID = await createShopifyPriceRule(
                                clientID,
                                inputValues.offerName?.trim(),
                                strToFloat(inputValues.compensation),
                                inputValues.percentOrCashOff?.toLowerCase(),
                                inputValues.discountItemID, // optional
                                inputValues.discountCollectionID, // optional
                                minPurchaseAmount, // defaults to 0
                                inputValues.prerequisiteCollectionID, // optional : default to ""
                                inputValues.prerequisiteQuantity // optional : default to 0
                            );

                            console.log("priceRuleId", priceRuleID);

                            let shopifyIntegration: any = integrations["shopify"];
                            shopifyIntegration.priceRuleID = priceRuleID?.toString();
                            productId = priceRuleID?.toString() ?? "";
                            collectionID = "";
                            integrations = {
                                shopify: shopifyIntegration,
                            };
                        } catch (error) {
                            console.error("Error setting priceRuleID:", error);
                        }
                    } else if (integrations["eventbrite"]) {
                        // TODO: Test this
                        let eventbriteIntegration: any = integrations["eventbrite"];
                        eventbriteIntegration.eventID = inputValues?.discountItemID;
                        integrations = {
                            eventbrite: eventbriteIntegration,
                        };
                    }
                }
                // Prepare offer data
                const offerDataMap: Map<string, any> = new Map();
                offerDataMap.set("clientID", clientID);
                offerDataMap.set("productId", productId);
                offerDataMap.set("collectionID", collectionID);

                offerDataMap.set("compensation", strToFloat(inputValues.compensation));
                offerDataMap.set("contentType", inputValues.contentType);
                offerDataMap.set("discountItem", inputValues.discountItem?.trim());
                offerDataMap.set("discountItemID", inputValues.discountItemID);
                offerDataMap.set("discountCollectionID", "");
                offerDataMap.set("isInfluencerOffer", inputValues.isInfluencerOffer);
                offerDataMap.set("isCustomerOffer", inputValues?.isCustomerOffer);
                offerDataMap.set("exampleUGC", inputValues.exampleUGC);
                offerDataMap.set("offerID", editedOfferId);
                offerDataMap.set("integrations", integrations);
                offerDataMap.set("isCashBack", true);
                offerDataMap.set("isCash", false);
                offerDataMap.set("minimumFollowerCount", minimumFollowerCount);
                // empty for cashback
                offerDataMap.set(
                    "minPurchaseAmount", 0
                );
                offerDataMap.set("percentOrCashOff", "Percent");
                offerDataMap.set(
                    "privateOfferPhoneList",
                    inputValues.privateOfferPhoneList
                );

                offerDataMap.set("rewardClientIDs", [clientID]);
                offerDataMap.set("rewardClientNames", [accountName]);
                offerDataMap.set("specificProduct", inputValues?.specificProduct?.trim());
                offerDataMap.set("totalCost", 0);
                offerDataMap.set("typeOfOffer", inputValues.typeOfOffer);
                offerDataMap.set("offerName", inputValues.offerName?.trim());
                offerDataMap.set("isPrivate", inputValues.isPrivate);
                offerDataMap.set(
                    "chosenCustomerGroups",
                    inputValues.chosenCustomerGroups ?? []
                );
                offerDataMap.set("locations", inputValues.locations);
                offerDataMap.set("expirationLimit", Number(inputValues?.expirationLimit));
                offerDataMap.set(
                    "influencerPhoneNumbers",
                    inputValues.influencerPhoneNumbers ?? []
                );
                offerDataMap.set(
                    "privateOfferPhoneList",
                    inputValues.privateOfferPhoneList ?? []
                );
                offerDataMap.set("isFreeReward", true);
                offerDataMap.set("isPrerequisite", false)
                offerDataMap.set("prerequisiteCollectionID", "");
                offerDataMap.set("prerequisiteName", "");
                offerDataMap.set("prerequisiteQuantity", 0);
                offerDataMap.set("discountType", inputValues?.discountType)
                offerDataMap.set("ecommerceDiscountCode", inputValues?.ecommerceDiscountCode);
                offerDataMap.set("isEvergreenCode", false);
                offerDataMap.set("isUploadCodes", false);
                offerDataMap.set("isAutopilotOffer", false);
                if (inputValues.exampleUGC !== null) {
                    // this means they edited the example UGC

                    // checking this condition because we dont need to upload content if already uploaded 

                    if (inputValues?.contentType === "instagramReel" || inputValues?.contentType === "tiktokVideo" || inputValues?.contentType === 'ugcVideo') {
                        // if (inputValues?.exampleUGC && typeof inputValues?.exampleUGC !== "string") {
                        //check if video uploading or not
                        if (inputValues?.exampleUgcFile && typeof inputValues?.exampleUgcFile !== "string") {
                            console.log("new file uploaded---", inputValues.exampleUgcFile);
                            exampleUGCUrl = await uploadVideoExampleUGC(
                                inputValues.exampleUgcFile,
                                clientID,
                                editedOfferId
                            );
                        } else {
                            console.log("same file uploaded---", inputValues.exampleUgcFile);
                            exampleUGCUrl = inputValues.exampleUGC
                        }
                        // } 
                    } else {
                        if (inputValues?.exampleUGC && typeof inputValues?.exampleUGC !== "string") {
                            exampleUGCUrl = await uploadImageExampleUGC(
                                inputValues.exampleUGC,
                                clientID,
                                editedOfferId
                            );

                        } else {
                            exampleUGCUrl = inputValues.exampleUGC
                        }
                    }
                }

                // add offer image in edit as well
                if (inputValues?.offerImage) {
                    // checking this condition because we dont need to upload image if image already uploaded 
                    if (typeof inputValues?.offerImage !== "string") {
                        offerImageUrl = await uploadOfferImage(
                            inputValues?.offerImage,
                            clientID,
                            editedOfferId
                        );
                    } else {
                        offerImageUrl = inputValues?.offerImage
                    }
                }

                if (inputValues.locations?.length || inputValues?.existingLocations?.length) {
                    updateLocationOfferIds(
                        inputValues.locations,
                        editedOfferId,
                        inputValues?.existingLocations);
                }

                /**
                 * @todo
                 * delete and create offer 
                 * 
                 */

                offerDataMap.set("quantity", inputValues?.quantity);
                // need to confirm about cashback offer 
                offerDataMap.set(
                    "numberOffersLeft", offerDetail?.quantity === inputValues.quantity ? offerDetail?.numberOffersLeft : inputValues?.quantity
                );
                const deleteOffer = await deleteOfferDoc(editedOfferId);
                let date = getCurrentUTCTimestamp();
                await createNewOfferFlowDoc(
                    editedOfferId,
                    clientID,
                    offerImageUrl,
                    exampleUGCUrl,
                    offerDataMap,
                    date,
                    inputValues.specificProduct?.trim(),
                    inputValues.hashtag?.trim(),
                    minimumFollowerCount, // minimum follower count
                    [clientID], // apend default client
                    [accountName], // apend default client name
                    inputValues?.influencerPhoneNumbers,
                    inputValues?.privateOfferPhoneList
                );
                // check and send offer to private customers
                if (inputValues.isPrivate) {
                    const allCustomers = [...new Set(inputValues?.privateOfferPhoneList)];
                    const newlyAddedCustomers = allCustomers?.filter(phoneNumber => !offerDetail?.privateOfferPhoneList?.includes(phoneNumber))
                    if (newlyAddedCustomers?.length) {
                        for (let phoneNumber of newlyAddedCustomers) {
                            try {
                                console.log("Sending private offer to newly added customer: " + phoneNumber);
                                await sendPrivateOffer(phoneNumber, editedOfferId);
                            } catch (error) {
                                setIsOfferSubmitPending(false);
                                toast({
                                    title: "Validation Errors",
                                    description: "Error occured while create offer",
                                    status: "error",
                                });
                                console.error("Error sending private offer to newly added customer:", error);
                            }
                        }
                    }

                }

                // check and send offer to influencers
                if (inputValues.isInfluencerOffer) {
                    const allInfluencers = [...new Set(inputValues.influencerPhoneNumbers)];
                    const newlyAddedInfluencers = allInfluencers?.filter(phoneNumber => !offerDetail?.influencerPhoneNumbers?.includes(phoneNumber))
                    if (newlyAddedInfluencers?.length) {
                        for (let phoneNumber of newlyAddedInfluencers) {
                            try {
                                console.log("Sending private offer to newly added influencer: " + phoneNumber);
                                await sendPrivateOffer(phoneNumber, editedOfferId);
                            } catch (error) {
                                setIsOfferSubmitPending(false);
                                toast({
                                    title: "Validation Errors",
                                    description: "Error occured while create offer",
                                    status: "error",
                                });
                                console.error("Error sending private offer to newly added influencer:", error);
                            }
                        }
                    }
                }

                toast({
                    title: "Offer updated sucessfully.",
                    status: "success",
                    duration: 1250,
                    isClosable: false,
                });
                // update offer with fields (pass in exampleUGC as a param, along with )
                removeData(`${inputValues?.offerName}-offer-image`);
                removeData(`${inputValues?.offerName}-image`)
                setIsOfferSubmitPending(false);
                history.goBack();
            } catch (error) {
                // Handle any errors
                setIsOfferSubmitPending(false);
                removeData(`${inputValues?.offerName}-offer-image`);
                removeData(`${inputValues?.offerName}-image`)
                toast({
                    title: "Validation Errors",
                    description: "Error occured while update offer",
                    status: "error",
                });
                console.error(error);
                return;
            }
        } else {
            const offerID = generateID();
            let productId = inputValues.productId ?? ""
            let collectionID = inputValues.collectionID ?? "";
            try {
                let exampleUGCURL = "";
                let offerImageUrl = "";
                if (
                    inputValues.contentType === "instagramReel" ||
                    inputValues.contentType === "tiktokVideo" || inputValues.contentType === 'ugcVideo'
                ) {
                    exampleUGCURL = await uploadVideoExampleUGC(
                        inputValues.exampleUgcFile,
                        clientID,
                        offerID
                    );
                } else {
                    exampleUGCURL = await uploadImageExampleUGC(
                        inputValues.exampleUGC,
                        clientID,
                        offerID
                    );
                }

                if (inputValues?.offerImage) {
                    offerImageUrl = await uploadOfferImage(
                        inputValues.offerImage,
                        clientID,
                        offerID
                    );
                }
                let date = getCurrentUTCTimestamp();
                let integrations = inputValues?.integration
                    ? { [inputValues.integration]: {} }
                    : null;
                if (inputValues?.integration) {
                    if (inputValues.integration === "shopify") {
                        try {
                            const priceRuleID = await createShopifyPriceRule(
                                clientID,
                                inputValues.offerName?.trim(),
                                strToFloat(inputValues.compensation),
                                inputValues.percentOrCashOff?.toLowerCase(),
                                inputValues.discountItemID, // optional
                                inputValues.discountCollectionID, // optional
                                minPurchaseAmount, // defaults to 0
                                inputValues.prerequisiteCollectionID, // optional : default to ""
                                inputValues.prerequisiteQuantity // optional : default to 0
                            );

                            let shopifyIntegration: any = integrations["shopify"];
                            shopifyIntegration.priceRuleID = priceRuleID?.toString();
                            productId = priceRuleID?.toString() ?? "";
                            collectionID = ""
                            integrations = {
                                shopify: shopifyIntegration,
                            };
                        } catch (error) {
                            console.error("Error setting priceRuleID:", error);
                        }
                    } else if (integrations["eventbrite"]) {
                        // TODO: Test this
                        let eventbriteIntegration: any = integrations["eventbrite"];
                        eventbriteIntegration.eventID = inputValues?.discountItemID;
                        integrations = {
                            eventbrite: eventbriteIntegration,
                        };
                    } else if (integrations["bigCommerce"]) {
                        console.log("this is a big commerce inetgration");
                        let bigCommerceIntegration: any = integrations["bigCommerce"];
                        integrations = {
                            bigCommerce: bigCommerceIntegration,
                        };
                        // Create call to the bigCommerce endpoint --> do we even have to do this here?
                        // implement this
                    }
                }

                // Prepare offer data
                const offerDataMap: Map<string, any> = new Map();
                offerDataMap.set("clientID", clientID);
                offerDataMap.set("productId", productId);
                offerDataMap.set("collectionID", collectionID);
                offerDataMap.set("compensation", strToFloat(inputValues?.compensation));
                offerDataMap.set("contentType", inputValues.contentType);
                offerDataMap.set("discountItem", inputValues.discountItem?.trim());
                offerDataMap.set("discountItemID", inputValues.discountItemID);
                offerDataMap.set("discountCollectionID", "");
                offerDataMap.set("isInfluencerOffer", inputValues.isInfluencerOffer);
                offerDataMap.set("isCustomerOffer", inputValues.isCustomerOffer);
                offerDataMap.set("exampleUGC", inputValues.exampleUGC);
                offerDataMap.set("offerID", offerID);
                offerDataMap.set("integrations", integrations);
                offerDataMap.set("isCash", false);
                offerDataMap.set("isCashBack", inputValues.isCashBack);
                offerDataMap.set("minimumFollowerCount", minimumFollowerCount);
                offerDataMap.set(
                    "minPurchaseAmount", 0);
                // for cashback this is empty
                offerDataMap.set("percentOrCashOff", "Percent");
                offerDataMap.set(
                    "privateOfferPhoneList",
                    inputValues.privateOfferPhoneList
                );
                offerDataMap.set("quantity", inputValues.quantity);
                offerDataMap.set(
                    "numberOffersLeft", inputValues.quantity ?? 0
                );
                offerDataMap.set("rewardClientIDs", [clientID]);
                offerDataMap.set("rewardClientNames", [accountName]);
                offerDataMap.set(
                    "specificProduct",
                    inputValues.specificProduct?.trim()
                );
                offerDataMap.set("totalCost", 0);
                offerDataMap.set("typeOfOffer", inputValues.typeOfOffer);
                offerDataMap.set("offerName", inputValues.offerName?.trim());
                offerDataMap.set("isPrivate", inputValues.isPrivate);
                offerDataMap.set(
                    "chosenCustomerGroups",
                    inputValues.chosenCustomerGroups ?? []
                );
                offerDataMap.set("locations", inputValues.locations);
                offerDataMap.set(
                    "expirationLimit",
                    Number(inputValues.expirationLimit)
                );
                offerDataMap.set("isFreeReward", false);
                offerDataMap.set("isPrerequisite", false)
                offerDataMap.set("prerequisiteCollectionID", "");
                offerDataMap.set("prerequisiteName", "");
                offerDataMap.set("prerequisiteQuantity", 0);
                offerDataMap.set("discountType", inputValues.discountType)
                offerDataMap.set("discountType", inputValues.discountType);
                // new changes added for batchcodes.
                offerDataMap.set("ecommerceDiscountCode", inputValues.ecommerceDiscountCode);
                offerDataMap.set("isEvergreenCode", false);
                offerDataMap.set("isUploadCodes", false);
                offerDataMap.set("isAutopilotOffer", false);

                await createNewOfferFlowDoc(
                    offerID,
                    clientID,
                    offerImageUrl,
                    exampleUGCURL,
                    offerDataMap,
                    date,
                    inputValues.specificProduct?.trim(),
                    inputValues.hashtag?.trim(),
                    minimumFollowerCount, // minimum follower count
                    [clientID], // apend default client
                    [accountName], // apend default client name
                    inputValues.influencerPhoneNumbers,
                    inputValues.privateOfferPhoneList
                );

                if (!accountData["locationless"]) {
                    updateLocationOfferIds(inputValues.locations, offerID);
                }
                const updateOfferIds = [...clientOfferList, offerID];
                await updateClientOfferIds(clientID, updateOfferIds);

                if (inputValues.isPrivate) {
                    for (let phoneNumber of inputValues?.privateOfferPhoneList) {
                        try {
                            console.log("Sending private offer to: " + phoneNumber);
                            await sendPrivateOffer(phoneNumber, offerID);
                        } catch (error) {
                            setIsOfferSubmitPending(false);
                            toast({
                                title: "Validation Errors",
                                description: "Error occured while create offer",
                                status: "error",
                            });
                            console.error("Error sending private offer:", error);
                        }
                    }
                }

                // added text message to influencers previously skipped.
                if (inputValues.isInfluencerOffer) {
                    for (let phoneNumber of inputValues?.influencerPhoneNumbers) {
                        try {
                            console.log("Sending influencer offer to: " + phoneNumber);
                            await sendPrivateOffer(phoneNumber, offerID);
                        } catch (error) {
                            setIsOfferSubmitPending(false);
                            toast({
                                title: "Validation Errors",
                                description:
                                    "Error occured while sending text to " + phoneNumber,
                                status: "error",
                            });
                            console.error("Error sending influencer offer:", error);
                        }
                    }
                }

                toast({
                    title: "Offer created sucessfully.",
                    status: "success",
                    duration: 1250,
                    isClosable: false,
                });
                setIsOfferSubmitPending(false);

                removeData(`${inputValues.offerName}-offer-image`);
                removeData(`${inputValues.offerName}-image`)
                history.goBack();
            } catch (error) {
                // Handle any errors
                setIsOfferSubmitPending(false);
                removeData(`${inputValues?.offerName}-offer-image`);
                removeData(`${inputValues?.offerName}-image`)
                toast({
                    title: "Validation Errors",
                    description: "Error occured while create offer",
                    status: "error",
                });
                console.error(error);
                return;
            }
        }
    };

    return (
        <>
            <Box py="60px" mb="auto">
                <Flex flexFlow={"column"} gap={"60px"}>
                    <FormControl display="flex" alignItems="center" gap="20px">
                        <FormLabel m="0" variant={"chakra_label_default"}>
                            Cashback Reward (%)
                        </FormLabel>
                        <Box w={"100%"}>
                            <Input
                                width="100%"
                                variant="chakra_input_default"
                                size="chakra_md_medium"
                                fontSize={"chakra_sm"}
                                fontWeight={"500"}
                                readOnly={isOfferSubmitPending || isEditing}
                                value={
                                    inputValues?.compensation === 0 ? "" : inputValues?.compensation
                                }
                                type="text" // Change type to text
                                onChange={(e) => {
                                    let value = e?.target?.value;
                                    // Update state with the cleaned value
                                    onInputChange("compensation", decimalFraction(value));
                                    onInputChange("percentOrCashOff", "Percent");
                                }}
                            />
                        </Box>
                    </FormControl>
                    {inputValues.isCustomerOffer && ["ugcPicture", "ugcVideo"]?.indexOf(inputValues?.contentType) === -1 && (
                        <FormControl display="flex" alignItems="center" gap="20px">
                            <FormLabel m="0" variant={"chakra_label_default"}>
                                Minimum Follower Count
                            </FormLabel>
                            <Box w={"100%"}>
                                <Input
                                    width="100%"
                                    variant="chakra_input_default"
                                    size="chakra_md_medium"
                                    fontSize={"chakra_sm"}
                                    fontWeight={"500"}
                                    type="text"
                                    maxLength={6}
                                    value={inputValues?.minimumFollowerCount}
                                    onChange={(e) => {
                                        let value: any = valueToNumberConvert(e?.target?.value);
                                        if (!isNaN(value)) {
                                            onInputChange("minimumFollowerCount", value);
                                        } else {
                                            onInputChange("minimumFollowerCount", 0);
                                        }
                                    }}
                                />
                            </Box>
                        </FormControl>
                    )}
                </Flex>
                <Box
                    maxW={"514px"}
                    mx="auto"
                    mt="81px"
                    py={"42px"}
                    px={"14px"}
                    bg={"#D4FEAE"}
                    borderRadius={"8px"}
                >
                    <Flex alignItems={"center"} textAlign={"center"}>
                        <Text fontSize={"15px"} fontWeight={"600"}>
                            {cashBackSummary({
                                quantity: inputValues.quantity,
                                minimumFollowerCount: inputValues?.minimumFollowerCount,
                                specificProduct: inputValues?.specificProduct,
                                accountName,
                                instagramHandle,
                                contentType: inputValues?.contentType,
                                compensation: inputValues?.compensation,
                                compensationType: "cashback",
                            })}
                        </Text>
                    </Flex>
                </Box>
            </Box>
            <Flex
                justifyContent={"flex-end"}
                borderTop={"1px solid #EAECF0"}
                paddingTop={"24px"}
            >
                <Button
                    // onClick={handleNext}
                    type="submit"
                    variant="chakra_btn_secondary"
                    size="chakra_xs"
                    disabled={!inputValues.compensation || isOfferSubmitPending || (inputValues.isCustomerOffer && ["ugcPicture", "ugcVideo"]?.indexOf(inputValues?.contentType) === -1 && !inputValues.minimumFollowerCount)}
                    onClick={handlePostOffer}
                >
                    {isOfferSubmitPending ? (
                        <Spinner color="black" height={"20px"} width={"20px"} />
                    ) : isEditing ? (
                        "Update Offer"
                    ) : (
                        "Publish"
                    )}
                </Button>
            </Flex>
        </>
    );
};

export default CashbackAmount;
