/* eslint-disable react-hooks/rules-of-hooks */
import {
  Text,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  useRadioGroup,
  HStack,
} from "@chakra-ui/react";
import {
  checkForEmojiInString,
  contentTypeText,
  valueToNumberConvert,
} from "utils";
import RadioCard from "../../offers/components/Radiocard";

interface PrerequisiteOfferProps {
  inputValues: {
    isPrerequisite?: boolean;
    prerequisiteName?: string;
    prerequisiteQuantity?: number;
    prerequisiteCollectionID?: string;
    contentType?: string;
    specificProduct: string;
  };
  onInputChange: (
    fieldName: keyof PrerequisiteOfferProps["inputValues"],
    value: any
  ) => void;
  data: any;
}

const PrerequisiteOffer: React.FC<PrerequisiteOfferProps> = ({
  inputValues,
  onInputChange,
  data: additionalDetails,
}) => {
  const options = ["Yes", "No"];
  const { accountData } = additionalDetails;
  const handleChange = (value: string) => {
    // Handle change here
    // setSelectedOption(value);
    if (value === "No") {
      onInputChange("prerequisiteName", "");
      onInputChange("prerequisiteQuantity", 0);
      onInputChange("prerequisiteCollectionID", "");
    }
    onInputChange("isPrerequisite", value === "Yes" ? true : false);
  };

  const { instagramHandle, offers = [], accountName } = accountData;

  return (
    <Box py="60px">
      <Flex flexFlow={"column"} gap={"60px"}>
        <FormControl display="flex" alignItems="center" gap="20px">
          <FormLabel m="0" variant={"chakra_label_default"}>
            Prerequisite Offer
          </FormLabel>
          <HStack gap={"24px"}>
            {options.map((value) => {
              return (
                <RadioCard
                  key={value}
                  onChange={() => handleChange(value)}
                  isChecked={inputValues?.isPrerequisite === (value !== "No")} // Set checked state based on inputValues.isPrerequisite
                >
                  {value}
                </RadioCard>
              );
            })}
          </HStack>
        </FormControl>
        {inputValues.isPrerequisite && (
          <>
            <FormControl display="flex" alignItems="center" gap="20px">
              <FormLabel m="0" variant={"chakra_label_default"}>
                Prerequisite Name
              </FormLabel>
              <Box w={"100%"}>
                <Input
                  width="100%"
                  variant="chakra_input_default"
                  size="chakra_md_medium"
                  fontSize={"chakra_sm"}
                  fontWeight={"500"}
                  type="text"
                  value={inputValues.prerequisiteName}
                  maxLength={30}
                  onChange={(e) =>
                    onInputChange("prerequisiteName", e?.target?.value)
                  }
                />
              </Box>
            </FormControl>
            <FormControl display="flex" alignItems="center" gap="20px">
              <FormLabel m="0" variant={"chakra_label_default"}>
                Prerequisite Quantity
              </FormLabel>
              <Box w={"100%"}>
                <Input
                  width="100%"
                  variant="chakra_input_default"
                  size="chakra_md_medium"
                  fontSize={"chakra_sm"}
                  fontWeight={"500"}
                  value={
                    inputValues.prerequisiteQuantity === 0
                      ? ""
                      : inputValues.prerequisiteQuantity
                  }
                  type="text"
                  onChange={(e) => {
                    let value: any = valueToNumberConvert(e.target.value);
                    if (!isNaN(value)) {
                      onInputChange("prerequisiteQuantity", value);
                    } else {
                      onInputChange("prerequisiteQuantity", 0);
                    }
                  }}
                />
              </Box>
            </FormControl>
            <FormControl display="flex" alignItems="center" gap="20px">
              <FormLabel m="0" variant={"chakra_label_default"}>
                Prerequisite Collection ID
              </FormLabel>
              <Box w={"100%"}>
                <Input
                  width="100%"
                  variant="chakra_input_default"
                  size="chakra_md_medium"
                  fontSize={"chakra_sm"}
                  fontWeight={"500"}
                  type="text"
                  value={inputValues.prerequisiteCollectionID}
                  onChange={(e) => {
                    let val = e.target.value?.trim();
                    if (checkForEmojiInString(val)) {
                      onInputChange("prerequisiteCollectionID", val?.trim());
                    }
                  }}
                />
              </Box>
            </FormControl>
          </>
        )}
      </Flex>

      {inputValues.isPrerequisite && (
        <Box
          maxW={"514px"}
          mx="auto"
          mt="60px"
          py={"15px"}
          px={"14px"}
          bg={"#D4FEAE"}
          borderRadius={"8px"}
        >
          <Flex alignItems={"center"} textAlign={"center"}>
            <Text fontSize={"15px"} fontWeight={"600"}>
              {`Post an ${contentTypeText(inputValues.contentType)} of ${inputValues.specificProduct?.trim()?.length
                ? inputValues.specificProduct?.trim()
                : accountName
                } tagging @${instagramHandle} in order to get (next steps) with a purchase of ${inputValues?.prerequisiteQuantity
                } ${inputValues?.prerequisiteName?.trim()}`}
            </Text>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default PrerequisiteOffer;
