import React, { useContext } from "react";
import { Box, Avatar, Text, Flex, SimpleGrid, Spinner } from "@chakra-ui/react";
import { CustomersDataContext } from "contexts/customersDataContext";
import CustomerCheckTile from "./CustomerCheckTile";

interface CustomerCheckListProps {
  handleCheckboxChange: Function;
  customersNumbersSelected: string[];
  clientID: string;
  isInfluencer: boolean
  allInfluencers: any;
}

const CustomerCheckList: React.FC<CustomerCheckListProps> = ({
  handleCheckboxChange,
  customersNumbersSelected,
  clientID,
  isInfluencer,
  allInfluencers
}) => {
  const { data: customersData, isLoading } = useContext(CustomersDataContext);

  const filteredCustomersData = customersData[clientID] ?? [];

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="200px">
        <Spinner size="xl" />
      </Flex>
    );
  }


  if (Object.keys(filteredCustomersData).length <= 1) {
    return <Text>No Data Available</Text>;
  }

  const customerMap = new Map();

  Object.keys(filteredCustomersData)
  .filter((key) => key !== "id")
  .forEach((phoneNumber) => {

    const customer = filteredCustomersData[phoneNumber];

    if (isInfluencer && !customer.isInfluencer) {
      return; // Skip this customer if not an influencer when in influencer mode
    }

    if (customerMap.has(phoneNumber)) {
      const existingCustomer = customerMap.get(phoneNumber);
      existingCustomer.acceptedOffers = Array.from(
        new Set([
          ...(existingCustomer?.acceptedOffers ?? []),
          ...(customer?.acceptedOffers ?? []),
        ])
      );
      existingCustomer.postedOffers = Array.from(
        new Set([
          ...(existingCustomer?.postedOffers ?? []),
          ...(customer?.postedOffers ?? []),
        ])
      );
      existingCustomer.redeemedOffers = Array.from(
        new Set([
          ...(existingCustomer?.redeemedOffers ?? []),
          ...(customer?.redeemedOffers ?? []),
        ])
      );
    } else {
      customerMap.set(phoneNumber, { ...customer, "phoneNumber": phoneNumber });
      allInfluencers.push(phoneNumber);
    }
  });

  // Sort by number of posts first, then by number of followers
  const customers = Array.from(customerMap.values())
    .sort((a, b) => {
      const redeemedOffersDiff =
        b.redeemedOffers.length - a.redeemedOffers.length;
      if (redeemedOffersDiff !== 0) return redeemedOffersDiff;

      const postedOffersDiff = b.postedOffers.length - a.postedOffers.length;
      if (postedOffersDiff !== 0) return postedOffersDiff;

      const acceptedOffersDiff =
        b.acceptedOffers.length - a.acceptedOffers.length;
      if (acceptedOffersDiff !== 0) return acceptedOffersDiff;

      return b.instagramFollowerCount - a.instagramFollowerCount;
    });
  
    


  return (
    <SimpleGrid spacing={4}>
      {customers.map((customer, index) => (
        <CustomerCheckTile
          customer={customer}
          key={index}
          handleCheckboxChange={handleCheckboxChange}
          isChecked={customersNumbersSelected.includes(customer.phoneNumber)}
        />
      ))}
    </SimpleGrid>
  );
};

export default CustomerCheckList;
