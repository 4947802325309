import {
  Box,
  Text,
  Flex,
  IconButton,
  Spinner,
  Center,
  ButtonGroup,
} from "@chakra-ui/react";
import { AccountsDataContext } from "contexts/accountsDataContext";
import { useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CreateOfferButton from "./components/createOfferButton";
import BackButton from "components/buttons/BackButton";
import OfferCard from "../accountOverview/components/OfferCard";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import useOffersData from "services/offerDataService";

const AccountOffers = () => {
  const history = useHistory();
  const accountsData = useContext(AccountsDataContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const clientID = queryParams.get("accountId");
  const locationID = queryParams.get("locationId") ?? ""

  /**
   * @info changes made by nirmal on date :- 16/05/2024
   * Added custome hook to get offerData
   */
  const isInfluencerMode = queryParams.get("isInfluencerMode") ?? false;
  const {
    isLoading: offerLoading = false,
    offersData,
    totalOffers,
    showPrevious,
    showNext,
    fetchedRecordCount,
  } = useOffersData({ clientID, perPage: 10, isInfluencerOffer: isInfluencerMode === 'true' ? true : false, locationId: locationID ?? "" });
  const clientData = accountsData[clientID];
  const [isBackButtonHovered, setIsBackButtonHovered] = useState(false);
  const filteredOffersData = Object.values(offersData).filter((offer: any) => {
    const clientMatch = clientID ? offer.clientID === clientID : true;
    const isInfluencerModeBool = isInfluencerMode === "true";
    const influencerMatch = isInfluencerModeBool
      ? offer.isInfluencerOffer === true
      : true;

    return clientMatch && influencerMatch;
  });


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (offerLoading || !accountsData || (accountsData && !accountsData?.[clientID])) {
    return (
      <Center height="90vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box backgroundColor="white" borderRadius="10px">
      <Flex alignItems="center" justifyContent="space-between" pr="10px">
        <Flex alignItems="center" pt="25px" pb="25px">
          <BackButton
            isHovered={isBackButtonHovered}
            setIsHovered={setIsBackButtonHovered}
          />
          <Text ml="10px" fontWeight="bold" fontSize="30px">
            {isInfluencerMode === "true" ? "Influencer" : ""} Offers{" "}
            {clientID === "" ? "" : "for " + clientData?.accountName}
          </Text>
        </Flex>
        <Flex mr="1.5%">
          <CreateOfferButton clientID={clientID} />
          {/* <Button
            onClick={() => {
              // @todo
              // later need to change this
              history.push(`/admin/create-new-offer-flow/?accountId=${clientID}`)
            }}
          >Create Offer for new flow</Button> */}
        </Flex>
      </Flex>

      <Flex alignItems="center" justifyContent="center">
        <Box borderTop="1.3px solid #E2E8F0" mb="10px" width="96%" />
      </Flex>

      <Box mt="20px" width="100%" px="40px" minHeight="300px" height="calc(100vh - 361px)" overflowY="scroll">
        <Flex flexDirection="column" alignItems="center">
          {filteredOffersData
            // .sort((a: any, b: any) => b.date - a.date) // Sorts in descending order by date
            .map((offer: any, index: number) => (
              <OfferCard
                key={index}
                clientProfilePic={clientData?.instagramProfilePic}
                offer={offer}
                justifyContent="center"
                borderRadius="10px"
                backgroundColor="#F5F5F5"
                width="90%"
                p="2%"
                mb="1%"
              />
            ))}
        </Flex>

        {filteredOffersData.length === 0 && (
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            w="100%"
            h="100%" // or use a specific value depending on your needs
            pe="20px"
            pt="5px"
            mb="15px"
            pb="3%"
          >
            <Text pb="1%" fontWeight="500" fontSize="20px">
              Create your first offer!
            </Text>
            <CreateOfferButton clientID={clientID} />
          </Flex>
        )}
      </Box>

      {/**
       * @info changes made by nirmal on date :- 16/05/2024
       * Added Pagination
       */}
      {filteredOffersData && filteredOffersData.length > 0 && (
        <Flex
          alignItems="center"
          padding="15px"
          justifyContent="right"
          mb="30px"
          px="40px"
        >
          {/* <Text as={'b'} mr={2}>Page : {currentPage}</Text> */}
          <ButtonGroup variant="outline" spacing="2">
            <IconButton
              icon={<ChevronLeftIcon />}
              fontSize="30px"
              aria-label="Previous Page"
              title="Previous Page"
              disabled={
                !filteredOffersData.length ||
                fetchedRecordCount <= filteredOffersData?.length
              }
              onClick={() => {
                showPrevious(filteredOffersData[0], filteredOffersData?.length);
              }}
            />
            <IconButton
              icon={<ChevronRightIcon />}
              aria-label="Next Page"
              title="Next Page"
              fontSize="30px"
              // disabled={perPageLimit !== Object.keys(offersData)?.length}
              disabled={
                !filteredOffersData.length || fetchedRecordCount >= totalOffers
              }
              onClick={() => {

                showNext(

                  filteredOffersData[filteredOffersData.length - 1],
                  fetchedRecordCount
                );
              }}
            />
          </ButtonGroup>
          <Text as={"b"} ml={2}>
            Total Offers : {totalOffers}
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default AccountOffers;
