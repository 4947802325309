/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from "react";
import { Text, Flex, Box, Checkbox, CheckboxGroup, Spinner, Button, useToast } from "@chakra-ui/react";
import { CustomerGroupsContext } from "contexts/customerGroupsDataContext";
import { CustomersDataContext } from "contexts/customersDataContext";
import AddCustomerPopup from "../models/AddCustomerModel";


interface customerGroupOfferProps {
    inputValues: {
        chosenCustomerGroups: any[];
        privateOfferPhoneList: any[];
        quantity?: number;
        minimumFollowerCount: number

    };
    onInputChange: (fieldName: keyof customerGroupOfferProps["inputValues"], value: any) => void;
    data: any
}

const CustomerGroups: React.FC<customerGroupOfferProps> = ({ inputValues, onInputChange, data: additionalData }) => {
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const { clientID, isEditing, offerDetail } = additionalData;
    const customerGroupsData = useContext(CustomerGroupsContext);
    const customerGroupsDataList = Object?.values(
        Object?.values(customerGroupsData)[1]
    );


    const { data: customersData, isLoading: isCustomersLoading } = useContext(CustomersDataContext);

    const filteredCustomersData = customersData[clientID] ?? [];
    const customerMap = new Map();

    Object.keys(filteredCustomersData)
        .filter((key) => key !== "id")
        .forEach((phoneNumber) => {

            const customer = filteredCustomersData[phoneNumber];


            if (customerMap.has(phoneNumber)) {
                const existingCustomer = customerMap.get(phoneNumber);
                existingCustomer.acceptedOffers = Array?.from(
                    new Set([
                        ...(existingCustomer?.acceptedOffers ?? []),
                        ...(customer?.acceptedOffers ?? []),
                    ])
                );
                existingCustomer.postedOffers = Array.from(
                    new Set([
                        ...(existingCustomer?.postedOffers ?? []),
                        ...(customer?.postedOffers ?? []),
                    ])
                );
                existingCustomer.redeemedOffers = Array.from(
                    new Set([
                        ...(existingCustomer?.redeemedOffers ?? []),
                        ...(customer?.redeemedOffers ?? []),
                    ])
                );
            } else {
                customerMap.set(phoneNumber, { ...customer, "phoneNumber": phoneNumber });
                // allInfluencers.push(phoneNumber);
            }
        });

    // Sort by number of posts first, then by number of followers
    const customers = Array.from(customerMap?.values())
        .sort((a, b) => {
            const redeemedOffersDiff =
                b?.redeemedOffers.length - a?.redeemedOffers.length;
            if (redeemedOffersDiff !== 0) return redeemedOffersDiff;

            const postedOffersDiff = b?.postedOffers.length - a?.postedOffers.length;
            if (postedOffersDiff !== 0) return postedOffersDiff;

            const acceptedOffersDiff =
                b?.acceptedOffers.length - a?.acceptedOffers.length;
            if (acceptedOffersDiff !== 0) return acceptedOffersDiff;

            return b?.instagramFollowerCount - a?.instagramFollowerCount;
        });

    let customerGroupsDataListCleaned = Object?.values(
        customerGroupsDataList
    ).filter((customerGroup: any) => {
        return customerGroup?.clientID === clientID;
    });

    const isCustomerInfluencer = (phoneNumber) => {
        const customer = customers?.find(cust => cust?.phoneNumber === phoneNumber);
        return customer;
        /**
         * As of now commented this code we need need both customer and influencer users
         */
        //return customer && !customer.isInfluencer;
    };

    // Remove influencers from each customer group's user array
    const updatedCustomerGroupsDataList = customerGroupsDataListCleaned?.map(customerGroup => {
        // Filter out influencers from this group's users array
        const nonInfluencerUsers = customerGroup?.users?.filter(user => {
            return isCustomerInfluencer(user)
        });
        return { ...customerGroup, users: nonInfluencerUsers };
    });

    customerGroupsDataListCleaned = updatedCustomerGroupsDataList;

    customerGroupsDataListCleaned = updatedCustomerGroupsDataList;

    // Effect to check if influencer selected
    useEffect(() => {
        onInputChange("quantity", inputValues?.privateOfferPhoneList.length > 0 ? inputValues?.privateOfferPhoneList.length : inputValues?.chosenCustomerGroups?.length);
    }, [inputValues?.chosenCustomerGroups, inputValues?.privateOfferPhoneList]);
    const handleCustomerGroupCheckBoxChange = (
        users: string[],
        customerGroupID: string,
        isChecked: boolean
    ) => {
        const updatedChosenCustomerGroups = new Set(inputValues?.chosenCustomerGroups);
        const updatedPrivateOfferPhoneList = new Set(inputValues?.privateOfferPhoneList);
        if (isChecked) {
            // Add group ID to chosen customer groups
            updatedChosenCustomerGroups?.add(customerGroupID);
            // Add users to private offer phone list
            users.forEach(user => {
                if (!updatedPrivateOfferPhoneList?.has(user)) {
                    updatedPrivateOfferPhoneList?.add(user);
                }
            });
        } else {
            // Remove group ID from chosen customer groups
            updatedChosenCustomerGroups?.delete(customerGroupID);
            // Remove users from private offer phone list
            users.forEach(user => {
                const isUserInOtherSelectedGroup = customerGroupsDataListCleaned?.some(group =>
                    updatedChosenCustomerGroups?.has(group?.id) && group?.users?.includes(user)
                );
                if (!isUserInOtherSelectedGroup) {
                    if (isEditing) {
                        if (!offerDetail.privateOfferPhoneList?.includes(user)) {
                            updatedPrivateOfferPhoneList?.delete(user);
                        }
                    } else {
                        updatedPrivateOfferPhoneList?.delete(user);
                    }
                }
            });
        }
        // Update state with updated sets

        onInputChange("chosenCustomerGroups", [...updatedChosenCustomerGroups]);
        onInputChange("privateOfferPhoneList", [...updatedPrivateOfferPhoneList]);
    };

    const handleAddCustomer = (customerList: any[], isAllCustomerSelected) => {
        setShowConfirmPopup(false);
        const updatedChosenCustomerGroups = new Set(inputValues.chosenCustomerGroups);
        customerGroupsDataListCleaned?.forEach((cGroup) => {

            if (cGroup?.users.length && cGroup?.users?.every(user => customerList?.includes(user))) {
                updatedChosenCustomerGroups?.add(cGroup?.id);
            } else {
                // not available
                if (isEditing) {
                    if (!offerDetail.customerGroup?.includes(cGroup?.id)) {
                        updatedChosenCustomerGroups?.delete(cGroup?.id);
                    }
                } else {
                    console.log("else condition--")
                    updatedChosenCustomerGroups?.delete(cGroup?.id);
                }
            }
        });
        const updatedPrivateOfferPhoneList = new Set(customerList);
        // Remove customer's phone number only if they are not part of any other selected group
        // customerList.forEach((customer) => {
        //     const isCustomerInSelectedGroup = customerGroupsDataListCleaned.some(group => {
        //         return updatedChosenCustomerGroups.has(group.id) && group.users.includes(customer)
        //     });
        //     if (!isCustomerInSelectedGroup) {
        //         updatedPrivateOfferPhoneList.add(customer);
        //     }
        // });
        let allCustomers = isAllCustomerSelected ? customerList : [];

        if (!customerList?.length) {
            if (isEditing) {
                if (!offerDetail?.customerGroup?.length) {
                    onInputChange("chosenCustomerGroups", []);
                }
                onInputChange("privateOfferPhoneList", []);
            } else {
                onInputChange("chosenCustomerGroups", []);
                onInputChange("privateOfferPhoneList", []);
            }
            return;
        }

        const phoneList = [...updatedPrivateOfferPhoneList, ...allCustomers];
        console.log("updatedChosenCustomerGroups...", updatedChosenCustomerGroups)
        onInputChange("chosenCustomerGroups", [...updatedChosenCustomerGroups]);
        onInputChange("privateOfferPhoneList", phoneList?.length ? [...new Set(phoneList)] : []);
    }


    const onClose = () => {
        setShowConfirmPopup(false);
    };

    const toast = useToast();

    // Function to handle showing the toast
    const showToast = () => {
        toast({
            title: "Uh oh, you do not have any customers opted in!",
            status: "error",
            duration: 2000,
            isClosable: false,
        });
    };



    // check if data is loading or not
    if (customerGroupsData?.isLoading) {
        return (
            <Box>
                <Flex justifyContent="center" alignItems="center" height="200px">
                    <Spinner size="xl" />
                </Flex>
            </Box>
        );
    }

    return (
        <Box py="60px">

            <Button
                variant="chakra_btn_white"
                size="chakra_md"
                border={'1px solid #EFEFEF'}
                position="absolute"
                top="35px"
                right="40px"
                onClick={() => {
                    setShowConfirmPopup(true);
                }} // Add this line
            >
                Add Customers
            </Button>
            <CheckboxGroup >
                <Flex maxW={'100%'} rowGap={'60px'} columnGap={'20px'} flexWrap={'wrap'}>
                    {

                        customerGroupsDataListCleaned?.map((customerGroup: any, idx: number) => (
                            <Box width="calc(33.33% - 15px)" key={`idx-${customerGroup?.id}`}>
                                <Checkbox key={`idx-${idx}`} variant="chakra_checkbox_dark" size="checkbox_lg" value=''
                                    isChecked={inputValues?.chosenCustomerGroups?.includes(customerGroup?.id)}
                                    onChange={() =>
                                        handleCustomerGroupCheckBoxChange(
                                            customerGroup?.users,
                                            customerGroup?.id,
                                            !inputValues?.chosenCustomerGroups?.includes(customerGroup?.id)
                                        )
                                    }
                                    disabled={isEditing && offerDetail.customerGroup?.includes(customerGroup?.id)}
                                >
                                    <Text fontSize={'chakra_lg'} fontWeight='400' color={'#1A1D1F'} ml="8px">{customerGroup?.customerGroupName ?? "N/A"}</Text>

                                </Checkbox>
                            </Box>
                        ))
                    }
                </Flex>
            </CheckboxGroup>

            {
                showConfirmPopup && (

                    <AddCustomerPopup
                        isOpen={showConfirmPopup}
                        privateOfferPhoneList={inputValues?.privateOfferPhoneList}
                        onClose={onClose}
                        handleAddCustomer={handleAddCustomer}
                        clientID={clientID}
                        showToast={showToast} // Pass the showToast function to AddCustomerPopup
                        isEditing={isEditing}
                        offerDetail={offerDetail}
                    />
                )
            }
        </Box>
    );
}

export default CustomerGroups;
