import React, { useState, useEffect, useContext } from "react";
import {
  Heading,
  Text,
  Input,
  Button,
  FormControl,
  FormLabel,
  useToast,
  Spinner,
  TableContainer,
  Table,
  Thead,
  Tr,
  Tbody,
  Th,
  Td,
  Center,
  InputGroup,
  InputRightElement,
  Icon,
  Grid,
  GridItem,
  Box,
  Flex,
  Link,
} from "@chakra-ui/react";
import { AdminDataContext } from "contexts/adminDataContext";
import {
  addAdditionalSeat,
  deleteAdditionalSheet,
} from "services/flaskService";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { CloseIcon } from "components/icons/Icons";

import DeleteConfirmPopup from "./DeleteConfirmModel";
import { ActiveUidContext } from "contexts/activeUidContext";

interface SeatSettingsProps {}

/**
 *  Seat setting page
 * @returns
 */
const SeatSettings: React.FC<SeatSettingsProps> = () => {
  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const activeUser = useContext(ActiveUidContext);

  const [isFullNameValid, setIsFullNameValid] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(null);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [seatData, setSeatData] = useState([]);
  const [loadingSeats, setLoadingSeats] = useState(false);
  // columns for the seat table add more column as per requirement
  const seatColumns: String[] = ["Username", "Email", "Action"];
  const toast = useToast();
  const textColorSecondary = "gray.500";
  const { adminData, isLoading } = useContext(AdminDataContext);

  let { additionalSeatsData = [] } = adminData;
  const [isAddingSeat, setIsAddingSeat] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = React.useState(null);
  const [show, setShow] = React.useState(false);
  // function for handle click on eye icon
  const handleClick = () => setShow(!show);
  const [deleteSheetModelOpen, setDeleteSheetModelOpen] = useState(false);
  const [additionalSeatEmail, setAdditionalSeatEmail] = useState("");
  //

  const handleConfirmationPopupClose = () => {
    setDeleteSheetModelOpen(false);
    setAdditionalSeatEmail("");
  };
  const handleDeleteSheet = async () => {
    setDeleteSheetModelOpen(false);
    setLoadingSeats(true);
    try {
      const deleteMethod: any = await deleteAdditionalSheet(
        additionalSeatEmail
      );
      if (deleteMethod?.success) {
        // setLoadingSeats(true);
        toast({
          title: "Seat deleted successfully.",
          status: "success",
          duration: 1250,
          isClosable: false,
        });
        setSeatData([]);
        setAdditionalSeatEmail("");
      } else {
        setLoadingSeats(false);
        toast({
          title:
            "Failed to delete seat. Please contact support for assistance.",
          status: "error",
          duration: 1250,
          isClosable: false,
        });
      }
    } catch (error) {
      setLoadingSeats(false);
      toast({
        title: "Failed to delete seat. Please contact support for assistance.",
        status: "error",
        duration: 1250,
        isClosable: false,
      });
    }
  };

  useEffect(() => {
    if (!("additionalSeatsData" in adminData)) {
      setLoadingSeats(true);
      return; // Exit early if additionalSeatsData is not present
    }
    setSeatData(additionalSeatsData);
    setLoadingSeats(false);
  }, [adminData, seatData]);

  /**
   * @info it will check the password and it's validation
   * @param e
   * @returns
   */
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emojiRegex =
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
    const inputPassword = e.target.value?.trim();
    setPassword(inputPassword);
    if (emojiRegex.test(inputPassword)) {
      setIsPasswordValid(false);
      setPasswordErrorMessage("Emoji's are not allowed in password");
      return;
    } else {
      setPasswordErrorMessage(
        inputPassword.length <= 7 ? "Minimum Password length required" : ""
      );
    }
    setIsPasswordValid(inputPassword.length > 7);
  };

  /**
   * reset all the component state as initial after adding seat
   */
  const resetComponentState = () => {
    // reset state variable
    setEmail("");
    setFullName("");
    setPassword("");
    setIsFullNameValid(null);
    setIsEmailValid(null);
    setIsAddingSeat(null);
    setIsPasswordValid(null);
  };

  /**
   * check and handle email validation and update state accordingly
   * @param e
   * @returns
   */
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emojiRegex =
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
    // const inputPassword = e.target.value?.trim();
    const inputEmail = e.target.value?.trim();
    setEmail(inputEmail);
    if (emojiRegex.test(inputEmail)) {
      setIsEmailValid(false);
      return;
    }
    const emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

    setIsEmailValid(emailRegex.test(inputEmail));
  };

  /**
   * check and validation all the input fields on click of save and add seat accordingly in db if no error in inputs.
   * @param email
   * @param password
   * @param fullName
   */
  async function handleSeatAdd(
    email: string,
    password: string,
    fullName: string
  ) {
    setIsAddingSeat(true);
    try {
      // add data in db
      let data = await addAdditionalSeat(
        email,
        password,
        fullName,
        adminData.userID
      );
      if (data !== null && data.success) {
        resetComponentState();
        toast({
          title: "Seat Added Successfully.",
          status: "success",
          duration: 1250,
          isClosable: false,
        });
        setLoadingSeats(true);
        setSeatData([]);
      } else {
        toast({
          title: "Failed to add seat. Please contact support for assistance.",
          status: "error",
          duration: 1250,
          isClosable: false,
        });
      }
    } catch (error) {
      console.error("Error failed while adding seat", error);
      toast({
        title: "Failed seat. Please contact support for assistance.",
        status: "error",
        duration: 1250,
        isClosable: false,
      });
    }
    setIsAddingSeat(false);
  }

  return (
    <>
      <Heading as={"h1"} size="chakra_5xl" mb={"30px"}>
        Current Seats
      </Heading>
      {loadingSeats ? (
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          <Box
            border={"1px solid #DFDFDF"}
            borderRight={"none"}
            borderRadius={"12px"}
            overflow={"hidden"}
            bgColor={"#FCFCFC"}
            position={"relative"}
            mb={"20px"}
            width={"fit-content"}
          >
            <Box
              height={"29px"}
              overflow={"hidden"}
              bgColor={"#FCFCFC"}
              position={"relative"}
              width={"fit-content"}
            >
              <TableContainer width={"fit-content"}>
                <Table
                  variant="simple"
                  style={{ borderCollapse: "separate", borderSpacing: "0" }}
                >
                  <Thead position={"static"} top={"0"}>
                    <Tr>
                      {seatColumns.map((column, idx) => (
                        <Th
                          borderRight={"1px solid #DFDFDF"}
                          borderBottom={"1px solid #DFDFDF !important"}
                          textAlign={"center"}
                          fontSize={"chakra_xs"}
                          lineHeight={1.5}
                          color={"#000000"}
                          p={"5px 15px"}
                          key={`th-${idx}`}
                        >
                          {" "}
                          {column}
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody height={"200px"} overflow={"auto"}>
                    {!isLoading && activeUser.email && !!seatData?.length ? (
                      seatData.map((seat: any, seatIdx: number) => (
                        <Tr key={`tr-${seatIdx}`}>
                          <Td
                            borderRight={"1px solid #DFDFDF"}
                            borderTop={"1px solid #DFDFDF !important"}
                            textAlign={"center"}
                            fontSize={"chakra_xs"}
                            lineHeight={1.5}
                            color={"#000000"}
                            p={"5px 15px"}
                          >
                            {seat.fullName}
                          </Td>
                          <Td
                            borderRight={"1px solid #DFDFDF"}
                            borderTop={"1px solid #DFDFDF !important"}
                            fontSize={"chakra_xs"}
                            lineHeight={1.5}
                            color={"#000000"}
                            p={"5px 15px"}
                          >
                            {seat.email}
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr key={`tr`}>
                        <Td
                          borderRight={"1px solid #DFDFDF"}
                          borderTop={"1px solid #DFDFDF !important"}
                          textAlign={"center"}
                          fontSize={"chakra_xs"}
                          lineHeight={1.5}
                          color={"#000000"}
                          p={"5px 15px"}
                          colSpan={2}
                        >
                          No seats available.
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
            <Box
              maxHeight={"291px"}
              overflow={"auto"}
              mt={"-31px"}
              width={"fit-content"}
            >
              <TableContainer width={"fit-content"}>
                <Table
                  variant="simple"
                  style={{ borderCollapse: "separate", borderSpacing: "0" }}
                >
                  <Thead position={"static"} top={"0"}>
                    <Tr>
                      {seatColumns.map((column, idx) => (
                        <Th
                          borderRight={"1px solid #DFDFDF"}
                          borderTop={"1px solid #DFDFDF !important"}
                          textAlign={"center"}
                          fontSize={"chakra_xs"}
                          lineHeight={1.5}
                          color={"#000000"}
                          p={"5px 15px"}
                          key={`th-${idx}`}
                        >
                          {" "}
                          {column}
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody max-height={"200px"} overflow={"auto"}>
                    {!isLoading && !!additionalSeatsData?.length ? (
                      additionalSeatsData
                        .filter((seat: any) => seat.email !== activeUser.email)
                        .map((seat: any, seatIdx: number) => (
                          <Tr key={`tr-${seatIdx}`}>
                            <Td
                              borderRight={"1px solid #DFDFDF"}
                              borderTop={"1px solid #DFDFDF !important"}
                              textAlign={"center"}
                              fontSize={"chakra_xs"}
                              lineHeight={1.5}
                              color={"#000000"}
                              p={"5px 15px"}
                            >
                              {seat.fullName}
                            </Td>
                            <Td
                              borderRight={"1px solid #DFDFDF"}
                              borderTop={"1px solid #DFDFDF !important"}
                              fontSize={"chakra_xs"}
                              lineHeight={1.5}
                              color={"#000000"}
                              p={"5px 15px"}
                            >
                              {seat.email}
                            </Td>
                            <Td
                              borderRight={"1px solid #DFDFDF"}
                              borderTop={"1px solid #DFDFDF !important"}
                              fontSize={"chakra_xs"}
                              lineHeight={1.5}
                              color={"#000000"}
                              p={"5px 15px"}
                            >
                              <Center>
                                <Link
                                  p={"0px"}
                                  onClick={() => {
                                    setAdditionalSeatEmail(seat.email);
                                    setDeleteSheetModelOpen(true);
                                  }}
                                >
                                  <CloseIcon h="16px" w="16px" />
                                </Link>
                              </Center>
                            </Td>
                          </Tr>
                        ))
                    ) : (
                      <Tr key={`tr`}>
                        <Td
                          borderRight={"1px solid #DFDFDF"}
                          borderTop={"1px solid #DFDFDF !important"}
                          textAlign={"center"}
                          fontSize={"chakra_xs"}
                          lineHeight={1.5}
                          color={"#000000"}
                          p={"5px 15px"}
                          colSpan={3}
                        >
                          No seats available.
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </>
      )}

      <Heading as={"h1"} size="chakra_5xl" mb={"20px"}>
        Add Seat
      </Heading>
      <Box width={"592px"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSeatAdd(email, password, fullName);
          }}
        >
          <FormControl>
            <FormLabel
              variant={"chakra_label_default"}
              display={"flex"}
              gap={"1"}
            >
              Full Name<Text color="red">*</Text>
            </FormLabel>
            <Input
              style={{
                borderColor:
                  isFullNameValid === null
                    ? ""
                    : isFullNameValid
                    ? "green"
                    : "red",
              }}
              isRequired={true}
              value={fullName}
              fontWeight={"normal"}
              placeholder="Min. 3 characters"
              variant="chakra_input_light"
              size="chakra_md"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setIsFullNameValid(e.target.value?.length >= 3);
                setFullName(e.target.value);
              }}
            />
            {fullName && !!fullName?.length && fullName.length < 3 && (
              <Text textColor={"red"} fontSize={"12px"}>
                {"Invalid fullname as minimum length required."}
              </Text>
            )}
          </FormControl>
          <Grid templateColumns="repeat(4, 1fr)" gap={2} my={"28px"}>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel
                  variant={"chakra_label_default"}
                  display={"flex"}
                  gap={"1"}
                >
                  Email<Text color="red">*</Text>
                </FormLabel>
                <Input
                  placeholder="abc@gmail.com"
                  fontWeight={"normal"}
                  value={email}
                  style={{
                    borderColor:
                      isEmailValid === null
                        ? ""
                        : isEmailValid
                        ? "green"
                        : "red",
                  }}
                  isRequired={true}
                  variant="chakra_input_light"
                  size="chakra_md"
                  onChange={handleEmailChange}
                />
              </FormControl>
              {!!email?.length && !!!isEmailValid && (
                <Text textColor={"red"} fontSize={"12px"}>
                  {"Invalid email address."}
                </Text>
              )}
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel
                  variant={"chakra_label_default"}
                  display={"flex"}
                  gap={"1"}
                >
                  Password<Text color="red">*</Text>
                </FormLabel>
                <InputGroup size="md" mb="5px">
                  <Input
                    style={{
                      borderColor:
                        isPasswordValid === null
                          ? ""
                          : isPasswordValid
                          ? "green"
                          : "red",
                    }}
                    isRequired={true}
                    variant="chakra_input_light"
                    size="chakra_md"
                    fontWeight={"normal"}
                    placeholder="Min. 8 characters"
                    type={show ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    autoComplete="new-password"
                    maxLength={20}
                  />
                  <InputRightElement
                    width={"40px"}
                    height={"48px"}
                    display="flex"
                    alignItems="center"
                  >
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>
                {!!password?.length && !!passwordErrorMessage?.length && (
                  <Text textColor={"red"} fontSize={"12px"}>
                    {passwordErrorMessage}
                  </Text>
                )}
              </FormControl>
            </GridItem>
          </Grid>
          <Button
            cursor={"pointer"}
            type="submit"
            isDisabled={
              !fullName ||
              (fullName && fullName.length < 3) ||
              !isEmailValid ||
              !isPasswordValid
            }
            variant="chakra_btn_secondary"
            width={"183px"}
            size="chakra_lg"
          >
            {isAddingSeat ? <Spinner color="white" mt="5px" /> : "Save"}
          </Button>
        </form>
      </Box>
      <DeleteConfirmPopup
        isOpen={deleteSheetModelOpen}
        modelTitle="Delete Addtional Seat"
        modelContent="Are you sure want to delete seat?"
        onClose={handleConfirmationPopupClose}
        handleDeleteModel={handleDeleteSheet}
      />
    </>
  );
};

export default SeatSettings;
