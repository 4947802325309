import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Box, Button, Flex, Spinner, Text, VStack } from "@chakra-ui/react";
import { createPaymentIntent } from "../../../../../../services/flaskService";


interface StripeCheckoutFormProps {
  amount: number;
  clientName: string;
  email: string;
  onPaymentSuccessful: Function;
  setIsPaymentSuccess: Function
  isPaymentSuccess: boolean
  setIsLoading: Function;
  isLoading: boolean
  actualAmount: number
}

const StripeCheckoutForm: React.FC<StripeCheckoutFormProps> = ({
  amount,
  clientName,
  email,
  onPaymentSuccessful,
  setIsLoading,
  isLoading,
  actualAmount
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loadingPaymentProcessing, setLoadingPaymentProcessing] =
    useState<boolean>(false);


  const processPayment = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoadingPaymentProcessing(true);
    setErrorMessage("");

    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements?.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      setLoadingPaymentProcessing(false);
      return;
    }

    const clientPaymentSecret = await createPaymentIntent(amount, email, clientName);

    const result = await stripe?.confirmPayment({
      elements: elements,
      clientSecret: clientPaymentSecret,
      confirmParams: {
        return_url: window.location.href, // Use the current URL as the return URL
      },
      redirect: "if_required",
    });

    if (result.error) {
      setErrorMessage(
        result.error.message + "\n Please contact jason@storyit.us for help."
      );
    } else {
      setIsLoading(true)
      onPaymentSuccessful();
    }
    setLoadingPaymentProcessing(false);

  };

  return (
    <Box p={0} width="316px">
      <VStack spacing={4}>
        {/* <Button variant="chakra_btn_dark" borderRadius={'3px'} py="3px" w={'100%'} h={'36px'} border={'0'} boxShadow={'0 1px 1px 0 rgba(0, 0, 0, 0.08)'}>
          <Image
            width='44px'
            height='18px'
            objectFit='cover'
            src='/apple-pay.png'
            alt='Apple Pay'
          />
        </Button> */}

        <Box width={'100%'} position='relative' fontWeight={"bold"} fontSize="chakra_md" paddingBottom={'14px'} borderBottom={'1px solid #EAECF0'} margin='0px 0 8px !important' w={'100%'} textAlign={'center'} color={'#1A202C'}>
          {/* <Divider orientation='horizontal' bg={'rgba(60, 66, 87, 0.12)'} h={'1px'} /> */}
          Secure Checkout
        </Box>
        <form onSubmit={processPayment}>
          <PaymentElement
          />
          <Button
            type="submit"
            isDisabled={!stripe || !elements}
            w={'100%'}
            mt="24px"
            variant="chakra_btn_secondary"
            size="chakra_xs"
            //|| !inputValues[currentStepId as keyof InputValues] --> Need to add this condition
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _focus={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _active={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            {
              isLoading ?
                <Spinner size="md" /> : `Pay $${actualAmount}`
            }
          </Button>
        </form>

        {errorMessage && (
          <Text mt={4} color="red.500">
            {errorMessage}
          </Text>
        )}

        {loadingPaymentProcessing && (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mb="20px"
          >
            <Spinner size="xl" />
            <Text mt={4} color="black">
              {" "}
              Processing payment... Please do not refresh the page.{" "}
            </Text>
          </Flex>
        )}
      </VStack>
    </Box>
  );
};

export default StripeCheckoutForm;
