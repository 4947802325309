import React, { useState } from "react";
import { Text, Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { AccountsDataContext } from "contexts/accountsDataContext";
import ChooseAccount from "./pages/ChooseAccount";
import ContentType from "./pages/ContentType";
import ContentParameters from "./pages/ContentParameters";
import ExampleUgc from "./pages/ExampleUGC";
import SetPriority from "./pages/Priority";
import Summary from "./pages/Summary";
import { AutopilotDataContext } from "../../../contexts/autopilotDataContext";


function CreateAutoPilotOffer() {
  // Global Offer vars
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const clientID = queryParams.get("accountId");
  const isEditing = queryParams.get("isEditing") === "true";
  const contentType = queryParams.get("contentType") ?? "";

  const accountsData = useContext(AccountsDataContext);
  const [step, setStep] = useState(1);

  const integrations = accountsData[clientID]?.integrations
    ? Object.keys(accountsData[clientID].integrations)
    : [];

  const [autopilotDataMap, setAutopilotDataMap] = useState(
    new Map([
      ["clientID", clientID],
      ["clientProfilePic", accountsData[clientID].instagramProfilePicture],
      ["clientInstagramHandle", accountsData[clientID].instagramHandle],
      ["integrations", integrations],
      ["isEditing", isEditing],
    ])
  );
  const [instagramStoryExampleUGC, setInstagramStoryExampleUGC] =
    useState<Uint8Array | null>(null);
  const [instagramPostExampleUGC, setInstagramPostExampleUGC] =
    useState<Uint8Array | null>(null);
  const [instagramReelExampleUGC, setInstagramReelExampleUGC] =
    useState<Uint8Array | null>(null);
  const [tiktokVideoExampleUGC, setTiktokVideoExampleUGC] =
    useState<Uint8Array | null>(null);

  const [ugcExampleUGC, setUgcExampleUGC] = useState<Uint8Array | null>(null);

  // setAutopilotDataMap(() => {
  //   const tempMap = new Map(autopilotDataMap);
  //   tempMap.set("clientID", clientID);
  //   return tempMap;
  // });

  return (
    <Box
      backgroundColor="white"
      height="1100px"
      p={10}
      borderRadius="10px" //width="100%"
    >
      {/* {step === 1 && (
        <ChooseAccount
          setStep={setStep}
          setAutopilotDataMap={setAutopilotDataMap}
          autopilotDataMap={autopilotDataMap}
        />
      )}
 */}
      {step === 1 && (
        <ContentType
          setStep={setStep}
          setAutopilotDataMap={setAutopilotDataMap}
          autopilotDataMap={autopilotDataMap}
          editingContentType={contentType}
        />
      )}
      {step === 2 && (
        <ExampleUgc
          setStep={setStep}
          setAutopilotDataMap={setAutopilotDataMap}
          autopilotDataMap={autopilotDataMap}
          setInstagramStoryExampleUGC={setInstagramStoryExampleUGC}
          setInstagramPostExampleUGC={setInstagramPostExampleUGC}
          setInstagramReelExampleUGC={setInstagramReelExampleUGC}
          setTiktokVideoExampleUGC={setTiktokVideoExampleUGC}
          setUgcExampleUGC={setUgcExampleUGC}
          instagramStoryExampleUGC={instagramStoryExampleUGC}
          instagramPostExampleUGC={instagramPostExampleUGC}
          instagramReelExampleUGC={instagramReelExampleUGC}
          tiktokVideoExampleUGC={tiktokVideoExampleUGC}
          ugcExampleUGC={ugcExampleUGC}
        />
      )}

      {step === 3 && (
        <SetPriority
          setStep={setStep}
          setAutopilotDataMap={setAutopilotDataMap}
          autopilotDataMap={autopilotDataMap}
        />
      )}
      {step === 4 && (
        <Summary
          isEditing={isEditing}
          setStep={setStep}
          setAutopilotDataMap={setAutopilotDataMap}
          autopilotDataMap={autopilotDataMap}
        />
      )}
    </Box>
  );
}

export default CreateAutoPilotOffer;
