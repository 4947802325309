import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import { lineChartDataTotalSpent } from "variables/charts";
import CommunityLineChart from "./CommunityLineChart";

interface CommunityMetricChartCardProps {
  chartData: any[];
}

const CommunityMetricChartCard = ({
  chartData,
}: CommunityMetricChartCardProps) => {
  const overlayBg = useColorModeValue(
    "rgba(255, 255, 255, 0.8)",
    "rgba(0, 0, 0, 0.8)"
  );
  const overlayColor = useColorModeValue("black", "white");
  const DAYS_REQUIRED_TO_SHOW_CHART = 7;

  const chartOptions = {
    chart: {
      toolbar: {
        show: true,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "#90F831",
      },
    },
    colors: ["#90F831"],
    markers: {
      size: 0,
      colors: "white",
      strokeColors: "#7551FF",
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [] as never[],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      type: "line",
    },
    xaxis: {
      type: "datetime",
      tickAmount: 6,
      labels: {
        formatter: function (value: any, timestamp: any) {
          const date = new Date(value);
          if (isNaN(date.getTime())) {
            // Check if date is invalid
            return "Date unavailable";
          }
          return date.toLocaleDateString();
        },
        style: {
          colors: "#A3AED0",
          fontSize: "11px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      type: "numeric",
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "10px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
  };

  const textColor = useColorModeValue("black", "white");

  const hasSufficientData =
    chartData &&
    chartData[0] &&
    chartData[0].data &&
    chartData[0].data.length >= DAYS_REQUIRED_TO_SHOW_CHART;

  console.log("hasSufficientData===", hasSufficientData);
  console.log("lineChartDataTotalSpent", lineChartDataTotalSpent);
  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mb="0px"
      h="45vh"
      pt="50px"
      p="30px"
    >
      <>
        <Flex
          align="center"
          justify="space-between"
          w="100%"
          pe="20px"
          pt="5px"
        >
          <Text
            color={textColor}
            fontSize="24px"
            textAlign="start"
            fontWeight="700"
            lineHeight="100%"
          >
            {chartData[0] && chartData[0].name ? chartData[0].name : "Chart"}
          </Text>
        </Flex>
        <Box minH="40vh" minW="100%" mt="auto">
          {
            /**
             * @info
             * Changes by nirmal on date:- 22/03/2024
             * fixed date related issue(showing 1970 date) on page load
             * I have added key in line chart to identify dom element when switching between default and custom charts
             */
            !hasSufficientData ? (
              // Render default LineChart with total spent data
              <CommunityLineChart
                key="default" // Set a key to force re-render when switching between default and custom charts
                chartData={lineChartDataTotalSpent}
                chartOptions={chartOptions}
              />
            ) : (
              // Render custom LineChart with chartData
              <CommunityLineChart
                key="custom" // Set a key to force re-render when switching between default and custom charts
                chartData={chartData || []}
                chartOptions={chartOptions || {}}
              />
            )
          }
        </Box>
        {!hasSufficientData && (
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg={overlayBg}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="3vh"
          >
            <Text fontSize="xl" color={overlayColor} fontWeight="bold">
              Accumulating data... Check back soon!
            </Text>
          </Box>
        )}
      </>
    </Card>
  );
};

export default CommunityMetricChartCard;
