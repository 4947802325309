import React, { useState, useEffect } from "react";
import { Box, Input, Flex, Text, Button } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { UploadIcon } from "components/icons/Icons";

interface onInputChangeProps {
  inputValues: {
    contentType: string;
    exampleUGC: string;
    offerImage?: string;
    specificProduct?: string;
    offerName?: string;
    hashtag?: string;
    exampleUgcFile?: any
  };
}

interface VideoUploaderProps {
  setExampleUGC: Function;
  setIsVideoValid: Function;
  isButton: boolean;
  exampleUgcUrl: any;
  exampleUgcFile: any;
  onInputChange: (
    fieldName: keyof onInputChangeProps['inputValues'],
    value: any
  ) => void;
}

const VideoUploader: React.FC<VideoUploaderProps> = ({ setExampleUGC, onInputChange, setIsVideoValid, isButton, exampleUgcUrl }) => {
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [inputId, setInputId] = useState<string>("");
  useEffect(() => {
    // Generate a unique ID for the input element
    setInputId(`video-input-${Math.random().toString(36).substr(2, 9)}`);
    if (exampleUgcUrl) {
      setSelectedVideo(exampleUgcUrl);
      setExampleUGC(exampleUgcUrl)
      setIsVideoValid(true);
    }
  }, []);

  // useEffect(() => {
  //   console.log("exampleUgcUrl===>", exampleUgcUrl);
  //   console.log("selectedVideo===", selectedVideo);
  // }, [selectedVideo, exampleUgcUrl]);

  const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const dataURL = reader.result as string;
        setSelectedVideo(dataURL);
        onInputChange("exampleUgcFile", file);
        setExampleUGC(dataURL);
        const element: any = document.getElementById("ugcVideo");
        element.src = dataURL;
        setIsVideoValid(true);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <Flex direction="column" align="start" justify="flex-start">
      {!selectedVideo ? (
        <Box
          backgroundColor="#F4F4F4"
          width="514px"
          borderStyle="dashed"
          borderRadius="lg"
          textAlign="center"
          cursor="pointer"
          onClick={() => {
            const input = document.getElementById(inputId);
            input?.click();
          }}
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            height="100%"
            justifyContent="center"
            py="30px"
            px="24px"
          >
            <UploadIcon h='40px' w='40px' mb='12px' />
            <Text fontSize="md" fontWeight="600" mb="0px" color="black">
              Click to Upload
            </Text>
            <Text fontSize="md" fontWeight="400" color="#667085">
              Accepted formats include MP4, AVI, or MOV
            </Text>
          </Flex>
        </Box>
      ) : (
        <Flex direction="column" align="center" justify="center" position="relative">
          <Box w="514px" h="200px" position="relative" overflow="hidden" borderRadius="10px" boxShadow="lg" bg="#F4F4F4">
            {/* Display a video player here */}
            <video id="ugcVideo" controls style={{ width: '100%', height: '100%' }}>
              <source id="ugcVideo" src={selectedVideo || exampleUgcUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
          <Button
            position="absolute"
            top="0"
            left="0"
            variant="chakra_btn_theme"
            borderRadius="10px 10px 10px 10px"
            onClick={() => {
              const input = document.getElementById(inputId);
              input?.click();
            }}
            width="40px"
            height="40px"
          >
            <EditIcon color="white" />
          </Button>
        </Flex>
      )}

      <Input
        type="file"
        accept="video/*"
        onChange={handleVideoUpload}
        id={inputId}
        display="none"
      />
    </Flex>
  );
};

export default VideoUploader;
