import { Icon } from "@chakra-ui/react";
import { MdAirplanemodeActive, MdPerson, MdQrCode2 } from "react-icons/md";
import { BiHistory } from "react-icons/bi";
import { IoBook, IoFastFood } from "react-icons/io5";
import { RiCommunityFill } from "react-icons/ri";
import { IoMdSettings } from "react-icons/io";
import { BsGraphUp } from "react-icons/bs";
// Admin Imports
import DashboardOverview from "views/admin/overview";
import Accounts from "views/admin/accounts";
import UgcLibrary from "views/admin/ugcLibrary";
import Community from "views/admin/community";
import OfferHistoryDataBridge from "views/admin/offerHistory/index";
import Touchpoints from "views/admin/touchpoints";
import FoodFluence from "views/admin/foodfluence";
import Settings from "views/admin/settings";
import Autopilot from "./views/admin/autopilot/index";

import {
  OverviewIcon,
  AccountIcon,
  LibraryIcon,
  CommunityIcon,
  HistoryIcon,
  SettingIcon,
  AutoEngagementIcon,
} from "components/icons/Icons";
import AutoEngagement from "views/admin/autoengagement";

const routes = [
  {
    name: "Overview",
    layout: "/admin",
    path: "/overview",
    icon: <Icon as={BsGraphUp} width="30px" height="30px" color="inherit" />,
    component: DashboardOverview,
  },
  {
    name: "Accounts",
    layout: "/admin",
    path: "/accounts",
    icon: <Icon as={MdPerson} width="30px" height="30px" color="inherit" />,
    component: Accounts,
  },
  {
    name: "UGC Library",
    layout: "/admin",
    path: "/ugc-library",
    icon: <Icon as={IoBook} width="30px" height="30px" color="inherit" />,
    component: UgcLibrary,
  },

  {
    name: "Community",
    layout: "/admin",
    icon: (
      <Icon as={RiCommunityFill} width="30px" height="30px" color="inherit" />
    ),
    path: "/community",
    component: Community,
  },
  {
    name: "History",
    layout: "/admin",
    path: "/history",
    icon: <Icon as={BiHistory} width="30px" height="30px" color="inherit" />,
    component: OfferHistoryDataBridge,
  },
  {
    name: "FoodFluence",
    layout: "/admin",
    path: "/foodfluence",
    icon: <Icon as={IoFastFood} width="30px" height="30px" color="inherit" />,
    component: FoodFluence,
  },
  {
    name: "AutoPilot",
    layout: "/admin",
    path: "/autopilot",
    icon: (
      <Icon
        as={MdAirplanemodeActive}
        width="30px"
        height="30px"
        color="inherit"
        transform="rotate(45deg)"
      />
    ),
    component: Autopilot,
  },
  {
    name: "Touchpoints",
    layout: "/admin",
    path: "/touchpoints",
    icon: <Icon as={MdQrCode2} width="30px" height="30px" color="inherit" />,
    component: Touchpoints,
  },
  {
    name: "AutoEngagement",
    layout: "/admin",
    path: "/autoengagement",
    icon: (
      <Icon
        as={AutoEngagementIcon}
        width="30px"
        height="30px"
        color="inherit"
      />
    ),
    component: AutoEngagement,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "/settings",
    icon: <Icon as={IoMdSettings} width="30px" height="30px" color="inherit" />,
    component: Settings,
  },
];

export default routes;
