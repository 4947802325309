import React from "react";
import { Text } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Spinner
} from "@chakra-ui/react";

interface ConfirmEndContestProps {
  isOpen: boolean;
  onClose: () => void;
  handleEndContest: () => void;
  contestName: string;
  isEnding: boolean;
}

const ConfirmEndContest: React.FC<ConfirmEndContestProps> = ({
  isOpen,
  onClose,
  handleEndContest,
  contestName,
  isEnding,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>End Contest Confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Make sure that you have approved all reviews on the manual verification dashboard for this contest before ending</p>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" onClick={() => handleEndContest()}>
            {isEnding ? <Spinner size="md" /> : <Text>End</Text>}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmEndContest;
