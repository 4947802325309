/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Flex, Box, FormControl, FormLabel, Input } from "@chakra-ui/react";
import ImageUploader from "./ImageUploader";
import VideoUploader from "./VideoUploader";

interface ExampleOfUgcProps {
  inputValues: {
    contentType: string;
    exampleUGC: string;
    offerImage?: string;
    specificProduct?: string;
    offerName?: string;
    hashtag?: string;
    exampleUgcFile?: any
  };
  onInputChange: (
    fieldName: keyof ExampleOfUgcProps["inputValues"],
    value: any
  ) => void;
  data: any;
}

const ExampleUGC: React.FC<ExampleOfUgcProps> = ({
  inputValues,
  onInputChange,
  data: additionalData,
}) => {
  const { clientID, isEditing } = additionalData;
  const [exampleUGC, setExampleUGC] = useState<Uint8Array | null | any>(
    isEditing && inputValues?.exampleUGC ? inputValues?.exampleUGC : null
  );
  const [isExampleUGCValid, setIsExampleUGCValid] = useState(false);
  const [offerImage, setOfferImage] = useState<Uint8Array | null | any>(
    isEditing && inputValues?.offerImage ? inputValues?.offerImage : null
  );
  const [isOfferImageValid, setIsOfferImageValid] = useState(false);
  useEffect(() => {
    if (isExampleUGCValid) {
      onInputChange("exampleUGC", exampleUGC);
    }
  }, [isExampleUGCValid, exampleUGC]);

  useEffect(() => {
    if (isOfferImageValid) {
      onInputChange("offerImage", offerImage);
    } else {
      // onInputChange("offerImage", isEditing && inputValues.offerImage ? inputValues.offerImage : null)
    }
  }, [isOfferImageValid, offerImage]);

  const handleSpecificProductChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e?.target?.value;
    if (value.length <= 75) {
      onInputChange("specificProduct", e?.target?.value);
    }
  };

  const handleHashtagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value?.replaceAll("#", "");
    if (value?.length <= 20) {
      onInputChange("hashtag", value);
    }
  };

  return (
    <Box py="60px">
      <Flex flexFlow={"column"} gap={"60px"}>
        <FormControl display="flex" alignItems="center" gap="20px">
          <FormLabel m="0" variant={"chakra_label_default"}>
            Example Submission{" "}
          </FormLabel>
          <Box w="100%">
            {inputValues.contentType !== "instagramReel" &&
              inputValues.contentType !== "tiktokVideo" &&
              inputValues.contentType !== "ugcVideo" && (
                <ImageUploader
                  isButton={false}
                  setExampleUGC={setExampleUGC}
                  setIsImageValid={setIsExampleUGCValid}
                  exampleUgcUrl={inputValues.exampleUGC ?? ""}
                  offerName={inputValues.offerName}
                />
              )}
            {(inputValues.contentType === "instagramReel" ||
              inputValues.contentType === "tiktokVideo" ||
              inputValues.contentType === "ugcVideo") && (
                <VideoUploader
                  isButton={false}
                  setExampleUGC={setExampleUGC}
                  setIsVideoValid={setIsExampleUGCValid}
                  exampleUgcUrl={inputValues.exampleUGC ?? ""}
                  exampleUgcFile={inputValues.exampleUgcFile ?? ""}
                  onInputChange={onInputChange}
                />
              )}
          </Box>
        </FormControl>
        <FormControl display="flex" alignItems="center" gap="20px">
          <FormLabel m="0" variant={"chakra_label_default"}>
            Offer Image(Optional){" "}
          </FormLabel>
          <Box w="100%">
            <ImageUploader
              isButton={false}
              setExampleUGC={setOfferImage}
              setIsImageValid={setIsOfferImageValid}
              exampleUgcUrl={inputValues.offerImage ?? ""}
              fieldName={"offerImage"}
              offerName={inputValues.offerName}
            />
          </Box>
        </FormControl>
        <FormControl display="flex" alignItems="center" gap="20px">
          <FormLabel m="0" variant={"chakra_label_default"}>
            Specific Product (Optional)
          </FormLabel>
          <Box w={"100%"}>
            <Input
              width="100%"
              variant="chakra_input_default"
              placeholder="Spring Collection shirts"
              size="chakra_md_medium"
              fontSize={"chakra_sm"}
              fontWeight={"500"}
              type="text"
              value={inputValues?.specificProduct}
              onChange={handleSpecificProductChange}
            />
          </Box>
        </FormControl>

        {inputValues.contentType !== "ugcVideo" &&
          inputValues.contentType !== "ugcPicture" && (
            <FormControl display="flex" alignItems="center" gap="20px">
              <FormLabel m="0" variant={"chakra_label_default"}>
                Hashtag Requirement (Optional)
              </FormLabel>
              <Box w={"100%"}>
                <Input
                  width="100%"
                  variant="chakra_input_default"
                  placeholder="shopZara, commanders2win, skoBruins..."
                  size="chakra_md_medium"
                  fontSize={"chakra_sm"}
                  fontWeight={"500"}
                  type="text"
                  value={inputValues?.hashtag}
                  onChange={handleHashtagChange}
                />
              </Box>
            </FormControl>
          )}
      </Flex>
    </Box>
  );
};

export default ExampleUGC;
