// Chakra imports
import {
  Box,
  Grid,
  SimpleGrid,
  Text,
  Flex,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { AdminDataContext } from "contexts/adminDataContext";
import { useContext, useEffect, useState } from "react";

// Custom components
import Upload from "views/admin/ugcLibrary/components/Upload";
import ImageGallery from "./components/UGCGallery";
import DropdownMenu from "./components/DropdownMenu";
import { AccountsDataContext } from "contexts/accountsDataContext";

interface AccountData {
  accountName: string;
  // Add other properties if they exist
}

export default function UgcLibrary() {
  const { adminData, isLoading } = useContext(AdminDataContext);

  const [filteredClientID, setfilteredClientID] = useState("");

  if (!adminData || !adminData.clientIDs) {
    return (
      <Flex justifyContent="center" alignItems="center" height="200px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box>
      <Box
        backgroundColor="white"
        width="100%"
        height="100%"
        // overflow="hidden" // Hides the scrollbar of this widget
        borderRadius="10px"
        position="relative"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text ml="20px" mt="25px" fontWeight="bold" fontSize="25px">
            User Generated Content (UGC)
          </Text>

          <Flex mt="25px" mr="15px">
            <DropdownMenu
              filteredClientID={""}
              isCommunity={false}
              isUgc={true}
              clientIDs={adminData.clientIDs}
              setfilteredClientID={setfilteredClientID}
            />

          </Flex>
        </Flex>
        <Flex alignItems="center">
          <Box
            borderTop="1.3px solid #E2E8F0"
            mt="15px"
            width="96%"
            ml="20px"
          />
        </Flex>
        {/* <Text fontWeight="bold" fontSize="25px">
          User Generated Content (UGC)
        </Text> */}

        <Grid templateColumns="repeat(8, 1fr)" mt="25px">
          <Box gridColumn="8" justifySelf="start">
            {/* <Upload /> */}
          </Box>
        </Grid>
        <ImageGallery
          clientIDs={[...adminData.clientIDs, "All accounts"]}
          filteredClientID={filteredClientID}
        />
      </Box>
    </Box>
  );
}
