/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import {
  Text,
  Flex,
  Box,
  Center,
  Button,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import OfferConfirmPopup from "../models/OfferConfirmModel";
import { useHistory } from "react-router-dom";
import {
  addUpdateRemoveOfferBatchCodes,
  createNewOfferFlowDoc,
  createOfferBatchCodes,
  generateID,
  getCurrentUTCTimestamp,
  updateClientOfferIds,
  updateLocationOfferIds,
  updateNewOfferFlowDoc,
  uploadImageExampleUGC,
  uploadOfferImage,
  uploadVideoExampleUGC,
} from "services/firebaseService";
import {
  createShopifyPriceRule,
  sendPrivateOffer,
  sendCustomerOffer,
} from "services/flaskService";
import {
  contentTypeText,
  crossConceptSummary,
  strToFloat,
  toTitleCase,
  validateEditStats,
} from "utils";
import { removeData } from "utils/localStorage.service";

interface OfferSummaryProps {
  inputValues: {
    offerName: string;
    integration?: string;
    integrations?: any;
    isPrivate: boolean;
    isInfluencerOffer: boolean;
    isCustomerOffer: boolean;
    privateOfferPhoneList?: any[];
    chosenCustomerGroups?: any[];
    typeOfOffer: string;
    isCash: boolean;
    locations?: any[];
    influencerPhoneNumbers?: any[];
    contentType: string;
    quantity: number;
    exampleUGC: any;
    OfferImage?: any;
    specificProduct?: string;
    hashtag?: string;
    compensation?: any;
    percentOrCashOff?: string;
    isPrerequisite?: boolean;
    prerequisiteName?: string;
    prerequisiteQuantity?: number;
    prerequisiteCollectionID?: string;
    isFreeReward?: boolean;
    discountItem?: string;
    discountItemID?: string;
    discountCollectionID?: string;
    minimumFollowerCount?: number;
    minPurchaseAmount?: number;
    expirationLimit?: number;
    rewardClientIDs?: any[];
    rewardClientNames?: any[];
    offerImage?: any;
    discountType: boolean;
    isCashBack: boolean;
    existingLocations?: any[];

    // new fields added in offer flow on 03/06/2024
    isUploadCodes?: boolean;
    batchCodes?: string[];
    ecommerceDiscountCode?: string;
    isEvergreenCode?: boolean;
    // newely added key for shopify integration
    productID?: string;
    collectionID?: string;
    exampleUgcFile?: any
  };
  onInputChange: (
    fieldName: keyof OfferSummaryProps["inputValues"],
    value: any
  ) => void;
  data: any;
}

const OfferSummary: React.FC<OfferSummaryProps> = ({
  inputValues,
  data: additionalDetails,
}) => {
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [offerTypeText, setOfferTypeText] = useState("");
  const [isSendingText, setIsSendingText] = useState(false);
  useEffect(() => {
    let offerText = "";
    if (inputValues.isPrivate) {
      offerText = "customer group";
    } else if (inputValues.isInfluencerOffer) {
      offerText = "influencer";
    } else if (inputValues.isCustomerOffer) {
      offerText = "customer";
    }
    setOfferTypeText(offerText);
  }, [
    inputValues.isPrivate,
    inputValues.isInfluencerOffer,
    inputValues.isCustomerOffer,
  ]);

  const toast = useToast();
  const {
    adminData: adminDetail,
    accountData,
    clientID,
    isEditing,
    editedOfferId,
    offerDetail
  } = additionalDetails;
  const { instagramHandle, offers = [] } = accountData;

  const clientOfferList = offers;
  const [isLoading, setIsLoading] = useState(false);
  const [isOfferConfirm, setIsOfferConfirm] = useState(false);
  useEffect(() => {
    if (isEditing) {
      // skip texting model in edit mode
      setIsOfferConfirm(true);
    } else {
      if (inputValues.isPrivate || inputValues.isInfluencerOffer) {
        // skip texting model for private/influencer offers.
        setIsOfferConfirm(true);
      }
    }
  }, []);

  const history = useHistory();
  const handleNext = () => {
    if (inputValues.isCustomerOffer) {
      setShowConfirmPopup(true);
    }
  };

  const onClose = () => {
    setShowConfirmPopup(false);
  };

  const onModelSubmit = () => {
    setShowConfirmPopup(false);
    setIsOfferConfirm(true);
  };

  console.log(
    "venvScriptsactivate", inputValues.quantity
  );

  const submitOffer = async () => {
    setIsLoading(true);
    setShowConfirmPopup(false);
    if (isEditing && editedOfferId) {
      try {
        let productID = inputValues.productID ?? "";
        let collectionID = inputValues.collectionID ?? "";
        let exampleUGCUrl = inputValues.exampleUGC;
        let offerImageUrl = inputValues?.offerImage ?? "";
        if (inputValues?.offerImage) {
          offerImageUrl = await uploadOfferImage(
            inputValues?.offerImage,
            clientID,
            editedOfferId
          );
        }
        let integrations = inputValues?.integration
          ? { [inputValues.integration]: {} }
          : null;
        if (inputValues.integration) {
          if (inputValues.integration === "shopify") {
            try {
              const priceRuleID = await createShopifyPriceRule(
                clientID,
                inputValues.offerName?.trim(),
                strToFloat(inputValues.compensation),
                inputValues.percentOrCashOff?.toLowerCase(),
                inputValues.discountItemID, // optional
                inputValues.discountCollectionID, // optional
                strToFloat(inputValues?.minPurchaseAmount), // defaults to 0
                inputValues.prerequisiteCollectionID, // optional : default to ""
                inputValues.prerequisiteQuantity // optional : default to 0
              );

              let shopifyIntegration: any = integrations["shopify"];
              shopifyIntegration.priceRuleID = priceRuleID?.toString();
              productID = priceRuleID?.toString() ?? "";
              collectionID = "";
              integrations = {
                shopify: shopifyIntegration,
              };
            } catch (error) {
              console.error("Error setting priceRuleID:", error);
            }
          } else if (integrations["bigCommerce"]) {
            console.log("this is a big commerce inetgration");
            let bigCommerceIntegration: any = integrations["bigCommerce"];
            integrations = {
              bigCommerce: bigCommerceIntegration,
            };
            // Create call to the bigCommerce endpoint --> do we even have to do this here?
            // implement this
          } else if (integrations["eventbrite"]) {
            console.log("this is a eventbrite inetgration");
            // TODO: Test this
            // Create call to the eventbrite endpoint --> do we even have to do this here?
            let eventbriteIntegration: any = integrations["eventbrite"];
            eventbriteIntegration.eventID = inputValues.discountItemID;
            integrations = {
              eventbrite: eventbriteIntegration,
            };
          }
        }
        // Prepare offer data
        const offerDataMap: Map<string, any> = new Map();
        offerDataMap.set("clientID", clientID);
        offerDataMap.set("productID", productID);
        offerDataMap.set("collectionID", collectionID);

        offerDataMap.set("compensation", strToFloat(inputValues?.compensation));
        offerDataMap.set("contentType", inputValues.contentType);
        offerDataMap.set("discountItem", inputValues.discountItem?.trim());
        offerDataMap.set("discountItemID", inputValues.discountItemID);
        offerDataMap.set(
          "discountCollectionID",
          inputValues.discountCollectionID
        );
        offerDataMap.set("isFreeReward", inputValues.isFreeReward);
        offerDataMap.set("isInfluencerOffer", inputValues.isInfluencerOffer);
        offerDataMap.set("isCustomerOffer", inputValues.isCustomerOffer);
        offerDataMap.set("exampleUGC", inputValues.exampleUGC);
        offerDataMap.set(
          "minPurchaseAmount",
          inputValues.minPurchaseAmount
            ? strToFloat(inputValues.minPurchaseAmount)
            : 0
        );
        offerDataMap.set("offerID", editedOfferId);
        offerDataMap.set("integrations", integrations);
        offerDataMap.set("isCash", inputValues.isCash);
        offerDataMap.set("isCashBack", inputValues.isCashBack);
        offerDataMap.set(
          "minimumFollowerCount",
          inputValues.minimumFollowerCount ?? 0
        );
        offerDataMap.set("percentOrCashOff", inputValues.percentOrCashOff);
        offerDataMap.set(
          "privateOfferPhoneList",
          inputValues.privateOfferPhoneList
        );

        if (inputValues.integration) {
          offerDataMap.set("rewardClientIDs", [clientID]);
          offerDataMap.set("rewardClientNames", [accountData["accountName"]]);
        } else {
          offerDataMap.set("rewardClientIDs", [
            clientID,
            ...inputValues.rewardClientIDs,
          ]);
          offerDataMap.set("rewardClientNames", [
            accountData["accountName"],
            ...inputValues.rewardClientNames,
          ]);
        }
        offerDataMap.set(
          "specificProduct",
          inputValues.specificProduct?.trim()
        );
        offerDataMap.set("hashtag", inputValues?.hashtag?.trim());
        offerDataMap.set("totalCost", 0);
        offerDataMap.set("typeOfOffer", inputValues.typeOfOffer);
        offerDataMap.set("offerName", inputValues.offerName?.trim());
        offerDataMap.set("isPrivate", inputValues.isPrivate);
        offerDataMap.set(
          "chosenCustomerGroups",
          inputValues.chosenCustomerGroups ?? []
        );

        offerDataMap.set("locations", inputValues.locations);
        offerDataMap.set(
          "expirationLimit",
          Number(inputValues.expirationLimit)
        );
        offerDataMap.set("isPrerequisite", inputValues.isPrerequisite);
        offerDataMap.set(
          "influencerPhoneNumbers",
          inputValues.influencerPhoneNumbers ?? []
        );

        offerDataMap.set(
          "prerequisiteCollectionID",
          inputValues.prerequisiteCollectionID
        );
        offerDataMap.set("prerequisiteName", inputValues.prerequisiteName);
        offerDataMap.set(
          "prerequisiteQuantity",
          Number(inputValues.prerequisiteQuantity) ?? 0
        );
        offerDataMap.set("discountType", inputValues.discountType);
        offerDataMap.set(
          "ecommerceDiscountCode",
          inputValues.ecommerceDiscountCode
        );
        offerDataMap.set("isEvergreenCode", inputValues.isEvergreenCode);
        offerDataMap.set("isUploadCodes", inputValues.isUploadCodes);
        offerDataMap.set("isAutopilotOffer", false);


        const { totalQuantity = 0, numberOfferLeft = 0, addCodes = false, removeCodes = false }: {
          totalQuantity: number, numberOfferLeft: number, addCodes?: boolean, removeCodes?: boolean
        } = validateEditStats(inputValues, offerDetail);

        if (addCodes) {
          // need to add codes
          addUpdateRemoveOfferBatchCodes(editedOfferId, inputValues.batchCodes, addCodes, removeCodes)
        }
        if (removeCodes) {
          addUpdateRemoveOfferBatchCodes(editedOfferId, inputValues.batchCodes, addCodes, removeCodes)
        }

        // assign quantity and number offer left after hadled use case.
        offerDataMap.set("quantity", totalQuantity);
        offerDataMap.set(
          "numberOffersLeft", numberOfferLeft
        );

        if (inputValues.exampleUGC !== null) {
          // this means they edited the example UGC

          // checking this condition because we dont need to upload content if already uploaded 

          if (inputValues?.contentType === "instagramReel" || inputValues?.contentType === "tiktokVideo" || inputValues?.contentType === 'ugcVideo') {
            // if (inputValues?.exampleUGC && typeof inputValues?.exampleUGC !== "string") {
            //check if video uploading or not
            if (inputValues?.exampleUgcFile && typeof inputValues?.exampleUgcFile !== "string") {
              console.log("new file uploaded---", inputValues.exampleUgcFile);
              exampleUGCUrl = await uploadVideoExampleUGC(
                inputValues.exampleUgcFile,
                clientID,
                editedOfferId
              );
            } else {
              console.log("same file uploaded---", inputValues.exampleUgcFile);
              exampleUGCUrl = inputValues.exampleUGC
            }
            // } 
          } else {
            if (inputValues?.exampleUGC && typeof inputValues?.exampleUGC !== "string") {
              exampleUGCUrl = await uploadImageExampleUGC(
                inputValues.exampleUGC,
                clientID,
                editedOfferId
              );

            } else {
              exampleUGCUrl = inputValues.exampleUGC
            }
          }
        }

        // add offer image in edit as well
        if (inputValues?.offerImage) {
          // checking this condition because we dont need to upload image if image already uploaded

          if (typeof inputValues?.offerImage !== "string") {
            offerImageUrl = await uploadOfferImage(
              inputValues?.offerImage,
              clientID,
              editedOfferId
            );
          } else {
            offerImageUrl = inputValues.offerImage;
          }
        }

        if (
          inputValues?.locations?.length ||
          inputValues?.existingLocations?.length
        ) {
          updateLocationOfferIds(
            inputValues.locations,
            editedOfferId,
            inputValues.existingLocations
          );
        }

        await createNewOfferFlowDoc(
          editedOfferId,
          clientID,
          offerImageUrl,
          exampleUGCUrl,
          offerDataMap,
          getCurrentUTCTimestamp(),
          inputValues.specificProduct?.trim(),
          inputValues.hashtag?.trim() ?? "",
          inputValues.minimumFollowerCount ?? 0, // minimum follower count
          [clientID, ...inputValues.rewardClientIDs], //
          [accountData["accountName"], ...inputValues.rewardClientNames],
          inputValues.influencerPhoneNumbers,
          inputValues.privateOfferPhoneList
        );

        // check and send offer to private customers
        if (inputValues.isPrivate) {
          const allCustomers = [...new Set(inputValues.privateOfferPhoneList)];
          const newlyAddedCustomers = allCustomers?.filter(phoneNumber => !offerDetail?.privateOfferPhoneList?.includes(phoneNumber))
          if (newlyAddedCustomers?.length) {
            for (let phoneNumber of newlyAddedCustomers) {
              try {
                console.log("Sending private offer to newly added customer: " + phoneNumber);
                await sendPrivateOffer(phoneNumber, editedOfferId);
              } catch (error) {
                setIsLoading(false);
                toast({
                  title: "Validation Errors",
                  description: "Error occured while create offer",
                  status: "error",
                });
                console.error("Error sending private offer to newly added customer:", error);
              }
            }
          }

        }

        // check and send offer to influencers
        if (inputValues.isInfluencerOffer) {
          const allInfluencers = [...new Set(inputValues.influencerPhoneNumbers)];
          const newlyAddedInfluencers = allInfluencers?.filter(phoneNumber => !offerDetail?.influencerPhoneNumbers?.includes(phoneNumber))
          if (newlyAddedInfluencers?.length) {
            for (let phoneNumber of newlyAddedInfluencers) {
              try {
                console.log("Sending private offer to newly added influencer: " + phoneNumber);
                await sendPrivateOffer(phoneNumber, editedOfferId);
              } catch (error) {
                setIsLoading(false);
                toast({
                  title: "Validation Errors",
                  description: "Error occured while create offer",
                  status: "error",
                });
                console.error("Error sending private offer to newly added influencer:", error);
              }
            }
          }
        }

        toast({
          title: "Offer updated sucessfully.",
          status: "success",
          duration: 1250,
          isClosable: false,
        });
        // update offer with fields (pass in exampleUGC as a param, along with )

        // remove item from localstorage
        removeData(`${inputValues?.offerName}-offer-image`);
        removeData(`${inputValues?.offerName}-image`);
        setIsLoading(false);
        history.goBack();
      } catch (error: any) {
        removeData(`${inputValues.offerName}-offer-image`);
        removeData(`${inputValues.offerName}-image`);
        // Handle any errors
        setIsLoading(false);
        toast({
          title: "Validation Errors",
          description: "Error occured while update offer",
          status: "error",
        });
        console.error(error);
        return;
      }
    } else {
      const offerID = generateID();
      let productID = inputValues.productID ?? "";
      let collectionID = inputValues.collectionID ?? "";
      try {
        let exampleUGCURL = "";
        let offerImageUrl = "";
        if (
          inputValues.contentType === "instagramReel" ||
          inputValues.contentType === "tiktokVideo" || inputValues.contentType === 'ugcVideo'
        ) {
          exampleUGCURL = await uploadVideoExampleUGC(
            inputValues.exampleUgcFile,
            clientID,
            offerID
          );
        } else {
          exampleUGCURL = await uploadImageExampleUGC(
            inputValues.exampleUGC,
            clientID,
            offerID
          );
        }

        if (inputValues?.offerImage) {
          offerImageUrl = await uploadOfferImage(
            inputValues.offerImage,
            clientID,
            offerID
          );
        }
        let date = getCurrentUTCTimestamp();
        let integrations = inputValues.integration
          ? { [inputValues.integration]: {} }
          : null;
        if (inputValues.integration) {
          if (inputValues.integration === "shopify") {
            try {
              const priceRuleID = await createShopifyPriceRule(
                clientID,
                inputValues.offerName?.trim(),
                strToFloat(inputValues.compensation),
                inputValues.percentOrCashOff?.toLowerCase(),
                inputValues.discountItemID, // optional
                inputValues.discountCollectionID, // optional
                strToFloat(inputValues.minPurchaseAmount), // defaults to 0
                inputValues.prerequisiteCollectionID, // optional : default to ""
                inputValues.prerequisiteQuantity // optional : default to 0
              );

              let shopifyIntegration: any = integrations["shopify"];
              productID = priceRuleID?.toString() ?? "";
              collectionID = "";
              integrations = {
                shopify: shopifyIntegration,
              };
            } catch (error) {
              console.error("Error setting priceRuleID:", error);
            }
          } else if (integrations["bigCommerce"]) {
            console.log("this is a big commerce inetgration");
            let bigCommerceIntegration: any = integrations["bigCommerce"];
            integrations = {
              bigCommerce: bigCommerceIntegration,
            };
          } else if (integrations["eventbrite"]) {
            console.log("this is a eventbrite inetgration");
            let eventbriteIntegration: any = integrations["eventbrite"];
            eventbriteIntegration.eventID = inputValues.discountItemID;
            integrations = {
              eventbrite: eventbriteIntegration,
            };
          }
        }

        // Prepare offer data
        const offerDataMap: Map<string, any> = new Map();
        offerDataMap.set("clientID", clientID);
        offerDataMap.set("productID", productID);
        offerDataMap.set("collectionID", collectionID);
        offerDataMap.set("compensation", strToFloat(inputValues.compensation));
        offerDataMap.set("contentType", inputValues.contentType);
        offerDataMap.set("discountItem", inputValues.discountItem?.trim());
        offerDataMap.set("discountItemID", inputValues.discountItemID);
        offerDataMap.set(
          "discountCollectionID",
          inputValues.discountCollectionID
        );

        offerDataMap.set("isInfluencerOffer", inputValues.isInfluencerOffer);
        offerDataMap.set("isCustomerOffer", inputValues.isCustomerOffer);
        offerDataMap.set("exampleUGC", inputValues.exampleUGC);
        offerDataMap.set("offerID", offerID);
        offerDataMap.set("integrations", integrations);
        offerDataMap.set(
          "minPurchaseAmount",
          inputValues.minPurchaseAmount
            ? strToFloat(inputValues.minPurchaseAmount)
            : 0
        );

        offerDataMap.set("isCash", inputValues.isCash);
        offerDataMap.set("isCashBack", inputValues.isCashBack);
        offerDataMap.set(
          "minimumFollowerCount",
          inputValues.minimumFollowerCount ?? 0
        );
        offerDataMap.set("percentOrCashOff", inputValues.percentOrCashOff);
        offerDataMap.set(
          "privateOfferPhoneList",
          inputValues.privateOfferPhoneList
        );
        offerDataMap.set(
          "quantity",
          inputValues.isUploadCodes
            ? inputValues?.batchCodes?.length
            : inputValues.quantity
        );
        offerDataMap.set(
          "numberOffersLeft",
          inputValues.isUploadCodes
            ? inputValues?.batchCodes?.length
            : inputValues.quantity
        );
        // skip reward if client has select any integration
        if (inputValues.integration) {
          offerDataMap.set("rewardClientIDs", [clientID]);
          offerDataMap.set("rewardClientNames", [accountData["accountName"]]);
        } else {
          offerDataMap.set("rewardClientIDs", [
            clientID,
            ...inputValues.rewardClientIDs,
          ]);
          offerDataMap.set("rewardClientNames", [
            accountData["accountName"],
            ...inputValues.rewardClientNames,
          ]);
        }
        offerDataMap.set(
          "specificProduct",
          inputValues.specificProduct?.trim()
        );
        offerDataMap.set("totalCost", 0);
        offerDataMap.set("typeOfOffer", inputValues.typeOfOffer);
        offerDataMap.set("offerName", inputValues.offerName?.trim());
        offerDataMap.set("isPrivate", inputValues.isPrivate);
        offerDataMap.set(
          "chosenCustomerGroups",
          inputValues.chosenCustomerGroups ?? []
        );
        offerDataMap.set("locations", inputValues.locations);
        offerDataMap.set(
          "expirationLimit",
          Number(inputValues.expirationLimit)
        );
        offerDataMap.set("isFreeReward", inputValues.isFreeReward);
        offerDataMap.set("isPrerequisite", inputValues.isPrerequisite);
        offerDataMap.set(
          "prerequisiteCollectionID",
          inputValues?.prerequisiteCollectionID
        );
        offerDataMap.set("prerequisiteName", inputValues?.prerequisiteName);
        offerDataMap.set(
          "prerequisiteQuantity",
          Number(inputValues?.prerequisiteQuantity) ?? 0
        );
        offerDataMap.set("discountType", inputValues.discountType);

        // new changes added for batch codes
        offerDataMap.set(
          "ecommerceDiscountCode",
          inputValues.ecommerceDiscountCode
        );
        offerDataMap.set("isEvergreenCode", inputValues.isEvergreenCode);
        offerDataMap.set("isUploadCodes", inputValues.isUploadCodes);
        offerDataMap.set("isAutopilotOffer", false);

        if (inputValues.isUploadCodes) {
          const batchCodes = {
            offerID,
            codes: inputValues.batchCodes?.map((code) => {
              return {
                code,
                isUsed: false,
              };
            }),
          };
          // add batch codes in firebase.
          createOfferBatchCodes(offerID, batchCodes);
        }

        await createNewOfferFlowDoc(
          offerID,
          clientID,
          offerImageUrl,
          exampleUGCURL,
          offerDataMap,
          date,
          inputValues.specificProduct?.trim(),
          inputValues.hashtag?.trim() ?? "",
          inputValues.minimumFollowerCount ?? 0, // minimum follower count
          [clientID, ...inputValues.rewardClientIDs], //
          [accountData["accountName"], ...inputValues?.rewardClientNames],
          inputValues.influencerPhoneNumbers,
          inputValues.privateOfferPhoneList
        );

        if (!accountData["locationless"]) {
          updateLocationOfferIds(inputValues?.locations, offerID);
        }
        const updateOfferIds = [...clientOfferList, offerID];
        await updateClientOfferIds(clientID, updateOfferIds);

        if (inputValues.isPrivate) {
          for (let phoneNumber of inputValues?.privateOfferPhoneList) {
            try {
              console.log("Sending private offer to: " + phoneNumber);
              await sendPrivateOffer(phoneNumber, offerID);
            } catch (error) {
              setIsLoading(false);
              toast({
                title: "Validation Errors",
                description:
                  "Error occured while sending text to " + phoneNumber,
                status: "error",
              });
              console.error("Error sending private offer:", error);
            }
          }
        } else if (
          inputValues.isInfluencerOffer  // && isSendingText --> need to add a boolean "isSendingText" bc clients can choose not to
        ) {
          for (let phoneNumber of inputValues.influencerPhoneNumbers) {
            try {
              console.log("Sending influencer offer to: " + phoneNumber);
              await sendPrivateOffer(phoneNumber, offerID);
            } catch (error) {
              setIsLoading(false);
              toast({
                title: "Validation Errors",
                description:
                  "Error occured while sending text to " + phoneNumber,
                status: "error",
              });
              console.error("Error sending influencer offer:", error);
            }
          }
        } else if (
          inputValues.isCustomerOffer &&
          isSendingText // && isSendingText --> need to add a boolean "isSendingText" bc clients can choose not to
        ) {
          try {
            console.log("Sending customer offer of client" + clientID);
            await sendCustomerOffer(offerID, clientID);
          } catch (error) {
            setIsLoading(false);
            toast({
              title: "Validation Errors",
              description: "Error occured while sending customer text",
              status: "error",
            });
            console.error("Error sending customer offer:", error);
          }

          // this is where i send the offer to all customers
          // no looping through anything since we do that in the backend
        }
        toast({
          title: "Offer created sucessfully.",
          status: "success",
          duration: 1250,
          isClosable: false,
        });
        removeData(`${inputValues.offerName}-offer-image`);
        removeData(`${inputValues.offerName}-image`);
        setIsLoading(false);
        history.goBack();
      } catch (error) {
        removeData(`${inputValues.offerName}-offer-image`);
        removeData(`${inputValues.offerName}-image`);
        // Handle any errors
        setIsLoading(false);
        toast({
          title: "Validation Errors",
          description: "Error occured while create offer",
          status: "error",
        });
        console.error(error);
        return;
      }
    }
  };

  return (
    <>
      {/*
              // commented this component will call in future if needed
            <OfferSummaryContent
                offerDataMap={mapOfferDataForSummary}
                specificProduct={inputValues.specificProduct}
                accountName={accountData["accountName"]}
                instagramHandle={accountData["instagramHandle"]}
                minimumFollowerCount={inputValues.minimumFollowerCount}
                rewardClientNames={[accountData["accountName"], ...inputValues.rewardClientNames]}
            /> */}

      <>
        <Box
          maxW={"434px"}
          mx="auto"
          mt="60px"
          py={"15px"}
          px={"14px"}
          bg={"#D4FEAE"}
          borderRadius={"8px"}
        >
          <Flex alignItems={"center"} textAlign={"center"}>
            <Text fontSize={"15px"} fontWeight={"600"}>
              {crossConceptSummary({
                isPrerequisite: inputValues.isPrerequisite,
                contentText: contentTypeText(inputValues.contentType),
                specificProduct: inputValues.specificProduct,
                discountType: inputValues.discountType,
                accountName: accountData["accountName"],
                instagramHandle,
                isFreeReward: inputValues.isFreeReward,
                compensation: inputValues.compensation,
                percentOrCashOff: inputValues.percentOrCashOff,
                discountItem: inputValues.discountItem,
                prerequisiteQuantity: inputValues?.prerequisiteQuantity,
                prerequisiteName: inputValues.prerequisiteName,
                minimumFollowerCount: inputValues?.minimumFollowerCount,
                expirationLimit: inputValues.expirationLimit,
                minPurchaseAmount: inputValues.minPurchaseAmount,
                hashtag: inputValues.hashtag,
              })}
              {inputValues.minimumFollowerCount > 0 &&
                ` Redeemers should have at least ${inputValues.minimumFollowerCount} followers on Instagram.`}
              {`This coupon expires ${inputValues.expirationLimit} days after the
                            user receives it.`}
              This offer can be redeemed at
              {!isEditing
                ? " " +
                accountData["accountName"] +
                (inputValues.rewardClientNames.length ? "," : ".")
                : ""}{" "}
              {inputValues.rewardClientNames.length > 0 && (
                <>
                  &nbsp;
                  {inputValues?.rewardClientNames?.map((name, index) => (
                    <React.Fragment key={index}>
                      {name}
                      {index < inputValues.rewardClientNames.length - 1 && ", "}
                    </React.Fragment>
                  ))}
                  .
                </>
              )}
              {inputValues?.isEvergreenCode &&
                ` Code ${inputValues.ecommerceDiscountCode} will be distributed to the people who complete the offer.`}
            </Text>
          </Flex>
        </Box>
      </>

      <Flex
        justifyContent={"flex-end"}
        borderTop={"1px solid #EAECF0"}
        paddingTop={"24px"}
        mt={"auto"}
      >
        <Button
          onClick={isOfferConfirm ? submitOffer : handleNext}
          type="submit"
          variant="chakra_btn_secondary"
          size="chakra_xs"
          disabled={isLoading}
          _hover={{
            transform: "scale(1.05)",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          _focus={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          _active={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          {isLoading ? (
            <Spinner color="black" height={"20px"} width={"20px"} />
          ) : isOfferConfirm ? (
            isEditing ? (
              "Update Offer"
            ) : (
              "Publish"
            )
          ) : (
            "Next"
          )}
        </Button>
      </Flex>

      <OfferConfirmPopup
        isOpen={showConfirmPopup}
        onClose={onClose}
        handleOfferSubmit={onModelSubmit}
        setIsSendingText={setIsSendingText}
        modelTitle={`Send Text to ${toTitleCase(offerTypeText)}`}
        modelContent={`Would you like to notify customers about this offer?`}
      />
    </>
  );
};
export default OfferSummary;
