import { mode } from '@chakra-ui/theme-tools';
export const HeadingStyles = {


	components: {
		Heading: {
			sizes: {
				'chakra_6xl': (props: any) => ({
					fontFamily: '"Poppins", sans- serif',
					fontSize: 'chakra_6xl',
					lineHeight: '48px',
					fontWeight: 600
				}),
				'chakra_5xl': (props: any) => ({
					fontFamily: '"Poppins", sans- serif',
					fontSize: 'chakra_5xl',
					lineHeight: '48px',
					fontWeight: 600
				}),
				'chakra_xl': (props: any) => ({
					fontFamily: '"Poppins", sans- serif',
					fontSize: 'chakra_xl',
					lineHeight: '32px',
					fontWeight: 600
				})
			}
		}
	}
};
