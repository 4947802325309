import { useState } from "react";
import { Box, Flex, Heading, Text, Button, Icon } from "@chakra-ui/react";
import SettingSideBar from "./components/SideBar";

import {
  EditIcon,
  SeatIcon,
  PaymentIcon,
  LogoutIcon,
} from "components/icons/Icons";
import { auth } from "../../../firebase";

import LogoutPopupModel from "./components/LogoutModel";

export default function Settings() {
  const [activeSection, setActiveSection] = useState("general");
  const [showLogoutModel, setShowLogoutModel] = useState(false);

  const sections = [
    {
      title: "General",
      value: "general",
      icon: <EditIcon color={"inherit"} h="18px" w="18px" />,
    },
    {
      title: "Seats",
      value: "seats",
      icon: <Icon as={SeatIcon} width="18px" height="18px" color="inherit" />,
    },
    {
      title: "Payment Settings",
      value: "payment",
      icon: (
        <Icon as={PaymentIcon} width="18px" height="18px" color="inherit" />
      ),
    },
    // Tempory hide this section from the sidebar but later we need to add this

    // { title: 'Integrations', value: 'integrations', icon: <Icon as={IntegrationIcon} width='18px' height='18px' color='inherit' /> },
    // { title: 'AutoPilot', value: 'autoPilot', icon: <Icon as={IntegrationIcon} width='18px' height='18px' color='inherit' /> },
  ];

  const handleSectionClick = (value: any) => {
    setActiveSection(value);
  };

  // on click of logout button from sidebar popup will open
  const handleOnLogoutClick = () => {
    setShowLogoutModel(true);
  };

  // on cancle logout logout popup will close
  const onLogoutCancel = () => {
    setShowLogoutModel(false);
  };

  // on click of logoutbutton signout from the dashboard but first it will ask for confirmation
  const onLogout = () => {
    setShowLogoutModel(false);
    auth.signOut();
  };

  return (
    <Box position="relative" fontSize={"15px"} lineHeight={"24px"}>
      <Text ml="20px" mt="20px" fontWeight="bold" fontSize="25px" mb={"24px"}>
        Settings
      </Text>

      <Flex
        overflow={"auto"}
        flex={"1 1 auto"}
        p={"0"}
        backgroundColor={"#FCFCFC"}
        borderRadius={"8px"}
      >
        <Flex
          position={"sticky"}
          top={"0"}
          w="280px"
          flexDirection={"column"}
          p={"24px"}
        >
          {sections.map((section) => (
            <Button
              key={section.value}
              size="chakra_md"
              justifyContent={"flex-start"}
              gap={"4px"}
              variant={
                activeSection === section.value
                  ? "chakra_btn_light"
                  : "chakra_btn_outline_light"
              }
              onClick={() => handleSectionClick(section.value)}
              mb={"8px"}
            >
              {section.icon}
              {section.title}
            </Button>
          ))}

          <Button
            key={"logout"}
            size="chakra_md"
            justifyContent={"flex-start"}
            gap={"4px"}
            onClick={handleOnLogoutClick}
            variant="chakra_btn_outline_light"
            mb={"8px"}
          >
            <Icon as={LogoutIcon} width="18px" height="18px" color="inherit" />
            <Text>Log Out</Text>
          </Button>
        </Flex>
        <Box flex={"1 1 auto"} p={"24px 24px 24px 8px"} overflow={"auto"}>
          <SettingSideBar activeSection={activeSection} />
        </Box>
      </Flex>

      {
        // open logout Model
        <LogoutPopupModel
          isOpen={showLogoutModel}
          handleSignout={onLogout}
          onClose={onLogoutCancel}
        />
      }
    </Box>
  );
}
