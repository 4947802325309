import React, { createContext, useState, useEffect, useContext } from 'react';
import { firestore } from '../firebase';
import { doc, onSnapshot, getDoc, query, where, getDocs, collection } from 'firebase/firestore';
import { ActiveUidContext } from './activeUidContext';

export const AdminDataContext = createContext();

export const AdminDataProvider = ({ children }) => {
    const adminStatsCollectionName = 'adminStats';
    const adminCollectionName = 'clientDashboardAdmin';
    const [isLoading, setIsLoading] = useState(true);
    // use activeUid context
    const { uid } = useContext(ActiveUidContext);

    const [adminData, setAdminData] = useState({
        adminStats: null,
    });

    useEffect(() => {
        if (!uid) {
            setIsLoading(false); // Set loading to false if uid is null
            return;
        }

        const adminStatsRef = doc(firestore, adminStatsCollectionName, uid);
        const adminDocRef = doc(firestore, adminCollectionName, uid);

        const unsubscribeAdminStats = onSnapshot(adminStatsRef, (docSnap) => {
            if (docSnap.exists()) {
                const data = docSnap.data();
                const id = docSnap.id; // Capture the document's ID
                const keys = Object.keys(data).map(Number).sort((a, b) => b - a);
                const latestTimestamp = keys[0];
                const latestData = data[latestTimestamp];

                setAdminData(prevData => (
                    {
                        ...prevData,
                        adminStats: { ...latestData, timestamps: data, id } // Include the ID in the state
                    }));
            }
            setIsLoading(false); // Data loaded
        });



        const unsubscribeAdmin = onSnapshot(adminDocRef, async (docSnap) => {
            if (docSnap.exists()) {
                const data = docSnap.data();
                const id = docSnap.id; // Capture the document's ID
                if (data && data.additionalSeats && data.additionalSeats.length > 0) {
                    // Prepare promises for each document fetch
                    const promises = data.additionalSeats.map(email => {
                        const additionalSeatRef = doc(firestore, "additionalSeats", email);
                        return getDoc(additionalSeatRef).then(snapshot => snapshot.exists() ? snapshot.data() : null);
                    });
                    // Execute all promises concurrently and filter out any nulls (for documents that don't exist)
                    Promise.all(promises).then(results => {
                        const additionalSeatsData = results.filter(result => result !== null);

                        // Update state with additional seats data, ensure this happens inside the .then()
                        setAdminData(prevData => ({
                            ...prevData,
                            ...data,
                            adminId: id,
                            additionalSeatsData // Include additional seats data in the state
                        }));
                    }).catch(error => {
                        console.log("Error fetching additional seats:", error);
                    }).finally(() => {
                        setIsLoading(false); // Data loaded, or error occurred
                    });
                } else {
                    // Directly update state if there are no additional seats
                    setAdminData(prevData => ({
                        ...prevData,
                        ...data,
                        adminId: id,
                        additionalSeatsData: [] // Ensure this is always an array
                    }));
                    setIsLoading(false); // Data loaded
                }
            } else {
                setIsLoading(false); // Handle case where docSnap does not exist
            }
        });

        // Cleanup function
        return () => {
            unsubscribeAdminStats();
            unsubscribeAdmin();
        };
    }, [uid]);

    return (
        <AdminDataContext.Provider value={{ adminData, isLoading }}>
            {children}
        </AdminDataContext.Provider>
    );
};

export default AdminDataProvider;
