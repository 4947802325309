import React from "react";
import ReactApexChart from "react-apexcharts";

type ChartProps = {
  chartData: any[];
  chartOptions: any;
};

type ChartState = {
  chartData: any[];
  chartOptions: any;
};

class CommunityLineChart extends React.Component<ChartProps, ChartState> {
  constructor(props: ChartProps) {
    super(props);

    this.state = {
      chartData:
        props.chartData && props.chartData.length
          ? props.chartData
          : [{ name: "Default Series", data: [] }],
      chartOptions: props.chartOptions || {},
    };
  }

  componentDidUpdate(prevProps: ChartProps) {
    if (prevProps.chartData !== this.props.chartData) {
      this.setState({
        chartData: this.props.chartData,
      });
    }

    if (prevProps.chartOptions !== this.props.chartOptions) {
      this.setState({
        chartOptions: this.props.chartOptions,
      });
    }
  }

  render() {
    const { chartData, chartOptions } = this.state;

    if (!chartData.length) {
      return <div>Loading...</div>;
    }

    return (
      <ReactApexChart
        options={chartOptions}
        series={chartData}
        type="line"
        width="100%"
        height="100%"
      />
    );
  }
}

export default CommunityLineChart;
