import React from "react";
import { Box, Text, Icon, Flex, background, Divider } from "@chakra-ui/react";
import { BsGraphUp } from "react-icons/bs";
import IconBox from "components/icons/IconBox";
import { MdLocationPin, MdOutlineBarChart, MdSend } from "react-icons/md";
// import { StatsRow } from 'components/stats/StatsRow';

import MiniStatistics from "components/card/MiniStatistics";
interface CustomerStatTilesProps {
  customer: any;
  clientID: string;
}

const CustomerStatTiles: React.FC<CustomerStatTilesProps> = ({
  customer,
  clientID,
}) => {
  let rawAcceptances = customer?.rawAcceptedOffers ?? {};

  let acceptances = customer?.rawAcceptedOffers ?? {};
  let posts = customer?.postedOffers ?? {};
  let redemptions = customer?.redeemedOffers ?? {};


  if (clientID.length > 1) {
    if (rawAcceptances.length > 0) {
      acceptances = rawAcceptances.filter(
        (acceptance) => acceptance.clientID === clientID
      );
      posts = rawAcceptances.filter(
        (acceptance) =>
          acceptance.clientID === clientID && acceptance.finalValidation
      );
      redemptions = rawAcceptances.filter(
        (acceptance) =>
          acceptance.clientID === clientID && acceptance.isRedeemed
      );
    }
  }

  const stats = {
    reach: customer?.instagramFollowerCount ?? 0,
    acceptances: acceptances.length,
    posts: posts.length,
    redemptions: redemptions.length,
  };

  return (
    <Flex alignItems="center" justifyContent="space-evenly">
      <MiniStatistics
        width="15%"
        boxShadow="0"
        name={"Followers"}
        value={stats?.reach ?? 0}
      />

      <MiniStatistics
        name={"Offers Accepted"}
        value={stats?.acceptances ?? 0}
        width="16%"
        boxShadow="0"
      />

      <MiniStatistics
        name={"Completed Posts"}
        value={stats?.posts ?? 0}
        width="16%"
        boxShadow="0"
      />

      <MiniStatistics
        name={"Discounts Redeemed"}
        value={stats?.redemptions ?? 0}
        width="16%"
        boxShadow="0"
      />
    </Flex>
  );
};

export default CustomerStatTiles;
