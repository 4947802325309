import React, { useState,useEffect } from "react";
import {
  Text,
  Box,
  Flex,
  Switch,
  Button,
  VStack,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

import { updateWhiteLabeledMessages } from 'services/firebaseService';

type CustomTextMessagesPopupProps = {
  whiteLabeledKit: any;
  whiteLabelDomain: string | null;
  accountName: string
};

const CustomTextMessagesPopup: React.FC<CustomTextMessagesPopupProps> = ({
  whiteLabeledKit, whiteLabelDomain, accountName
}) => {
 console.log(whiteLabeledKit);
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<any>({});

  const defaultMessages = {
     businessWelcomeText: {name: "Welcome Text", description: "Welcome to the " + accountName + " rewards program! You can earn incredible rewards by sharing your Instagram stories and content. 💰💫 Start sharing and unlock cash, credit, discounts, and more! 📸✨ "},
     businessOfferAcceptedText:{name: "Offer Acceptance Text", description:"Congratulations on accepting the offer from " + accountName + "! 🎉 Your submission is under review. We'll let you know when your reward is ready!"},
     businessContentApprovedText:{name: "Content Approved Text", description: "Your content was verified. Your well-deserved reward is now available in your wallet. Simply click the link below to access it and start enjoying the benefits! 💰✨\n\nhttps://"+ whiteLabelDomain+ "/wallet\n\nThank you for your participation and stay tuned for more exciting opportunities and rewards in the future. Happy redeeming! 😊🛍️"},
     businessContentRejectedText: {name: "Content Rejected Text", description:"Oops! We encountered a little hiccup in verifying your post. Not to worry, though! To receive your well-deserved reward, please fill out the form below and we will contact you shortly.\n\nhttps://forms.gle/ZbZQX7RQ4qG8ZyvG7\n\nIf your account is set to private, kindly ensure that one of our verification accounts is following you. We appreciate your cooperation and look forward to resolving this issue promptly. Thank you! "}
  };
  
  useEffect(() => {
    const updatedMessages = Object.keys(defaultMessages).reduce((acc, key) => {
      acc[key] = whiteLabeledKit?.[key]|| defaultMessages[key].description;
      return acc;
    }, {});
  
    setMessages(updatedMessages);
      

  }, [isOpen]);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleSave = () => {
    const messagesToSave = Object.keys(messages).reduce((acc, key) => {
    if (messages[key] === defaultMessages[key].description) {
      acc[key] = null;
    } else {
      acc[key] = messages[key];
    }
    return acc;
    }, {});
    updateWhiteLabeledMessages(whiteLabelDomain, messagesToSave);
    handleClose()
  };


  const handleInputChange = (key: string, new_description: string) => {
    setMessages(prevMessages => ({ ...prevMessages,[key]: new_description}));
  };
 console.log(messages)
  return (
    <>
      <Box>
        <Flex align="center" justify="space-between">
          <Button style={{backgroundColor:"#1a1a1a", color:"white"}} onClick={handleOpen}>
            Edit Customer Messages
          </Button>
        </Flex>
      </Box>

      <Modal isOpen={isOpen} onClose={handleClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Customer Messages</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              {Object.keys(defaultMessages).map((messageType) => (
                <Box key={messageType}>
                  <Text fontSize="md" fontWeight="bold">
                    {defaultMessages[messageType].name}
                  </Text>
                  <Flex align="center" flexDirection="column">
                    <Textarea
                      value={messages[messageType]}
                      onChange={(e) => handleInputChange(messageType, e.target.value)}
                      placeholder={`Enter ${defaultMessages[messageType].name}`}
                      size="sm"
                      resize="vertical"
                      style={{ width: '100%', height: '125px' }}
                    />
                    {
                    messages[messageType] !== defaultMessages[messageType].description ? (
                    <Button variant="link" colorScheme="blue" ml={2} onClick={() => handleInputChange(messageType,defaultMessages[messageType].description)}>
                      Restore Default
                    </Button>
                        ):(<></>)

                    }
                  </Flex>
                </Box>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSave}>
              Save
            </Button>
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};


export default CustomTextMessagesPopup;