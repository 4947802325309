import React, {useEffect} from "react";
import { Box, Text, Grid } from "@chakra-ui/react";
import { loadImageViaProxy } from "services/flaskService";

interface MemoryImageProps {
  imageData: Uint8Array;
  mimeType: string;
}

const MemoryImage: React.FC<MemoryImageProps> = ({ imageData, mimeType }) => {
  if (imageData) {
    const base64String = btoa(
      new Uint8Array(imageData).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );

    const dataUrl = `data:${mimeType};base64,${base64String}`;

    return <img src={dataUrl} alt="Loaded from Uint8Array" />;
  } else {
    return null; // Return null if imageData is not available
  }
};

interface InstagramProfilePicProps {
  imageURL: string;
  imageSize: string;
}

const InstagramProfilePic: React.FC<InstagramProfilePicProps> = ({
  imageURL, imageSize
}) => {
  
  const [imageData, setImageData] = React.useState<Uint8Array | null>(null);

  useEffect(() => {
    let isMounted = true;  // Flag to track component mount status

    loadImageViaProxy(imageURL)
      .then((data) => {
        if (isMounted) {
          setImageData(data);
        }
      })
      .catch((error) => console.error(error));

    return () => {
      isMounted = false;  // Cleanup function to set flag to false on unmount
    };
  }, [imageURL]);

  const mimeType = "image/jpeg"; // Adjust this according to your image type

  return (
    <Box
      overflow="auto" 
      //mt="30px"
      borderRadius="360px"
      width={imageSize}
      height={imageSize}
      backgroundColor={imageData === null && "white"}
      // backgroundImage={imageURL !== "" && `url(${imageURL})`}
    >
      {imageData !== null ? (
        <MemoryImage imageData={imageData} mimeType={mimeType} />
      ) : null}
    </Box>
  );
};

export default InstagramProfilePic;
