import React, { createContext, useState, useEffect } from 'react';
import { firestore } from '../firebase';
import { collection, onSnapshot, doc } from 'firebase/firestore';

export const SuperUserDataContext = createContext();

export const SuperUserDataProvider = ({ children }) => {
    const adminStatsCollectionName = 'adminStats';
    const adminCollectionName = 'clientDashboardAdmin';

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        const unsubscribeList = [];
        const tempUserData = [];

        const adminCollectionRef = collection(firestore, adminCollectionName);
        const unsubscribeAdmins = onSnapshot(adminCollectionRef, (adminDocsSnapshot) => {
            adminDocsSnapshot.docs.forEach((adminDoc) => {
                const adminData = adminDoc.data();
                const adminStatsRef = doc(firestore, adminStatsCollectionName, adminDoc.id);

                const unsubscribeStats = onSnapshot(adminStatsRef, (adminStatsSnapshot) => {
                    let adminStatsData = {};

                    if (adminStatsSnapshot.exists()) {
                        const data = adminStatsSnapshot.data();
                        const keys = Object.keys(data).map(Number).sort((a, b) => b - a);
                        const latestTimestamp = keys[0];
                        adminStatsData = data[latestTimestamp];
                    }

                    const adminCompleteData = {
                        id: adminDoc.id,
                        ...adminData,
                        adminStats: { ...adminStatsData, timestamps: adminStatsSnapshot.exists() ? adminStatsSnapshot.data() : {} }
                    };

                    // Update or add data in temp array
                    const index = tempUserData.findIndex(item => item.id === adminDoc.id);
                    if (index !== -1) {
                        tempUserData[index] = adminCompleteData;
                    } else {
                        tempUserData.push(adminCompleteData);
                    }

                    if (tempUserData.length === adminDocsSnapshot.docs.length) {
                        setUserData(tempUserData);
                    }
                });

                unsubscribeList.push(unsubscribeStats);
            });
        });

        unsubscribeList.push(unsubscribeAdmins);

        return () => {
            unsubscribeList.forEach(unsubscribe => unsubscribe());
        };
    }, []);

    return (
        <SuperUserDataContext.Provider value={userData}>
            {children}
        </SuperUserDataContext.Provider>
    );
};

export default SuperUserDataProvider;
