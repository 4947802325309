import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex, SimpleGrid, Spinner, Text, useColorModeValue
} from "@chakra-ui/react";
import Card from "components/card/Card";
import MetricChartCard from "components/charts/MetricChartCard";
import IconBox from "components/icons/IconBox";
import StatsRow from "components/stats/StatsRow";
import { AccountsDataContext } from "contexts/accountsDataContext";
import { AdminDataContext } from "contexts/adminDataContext";
import { useContext, useEffect, useState } from "react";
import {
  MdCheck,
  MdImage, MdLocationPin,
  MdOutlineBarChart
} from "react-icons/md";
import { useHistory } from "react-router-dom";
import AccountTile from "../accounts/AccountTile";
import UgcPreviewCard from "./components/UgcPreviewCard";

export default function DashboardOverview() {
  const textColor = useColorModeValue("black", "white");
  const history = useHistory();

  const { adminData, isLoading } = useContext(AdminDataContext);
  const accountsData = useContext(AccountsDataContext);

  const clientIds = adminData?.clientIDs ?? [];
  const adminStats = adminData?.adminStats;

  const timestampedData = adminStats?.timestamps ?? [];
  const chartData = [
    {
      name: "Estimated Reach",
      // Map the keys to data points after filtering out any elements without a .storyReach property
      data: timestampedData == null || timestampedData.length === 0 || isLoading ? [] :
        Object.keys(timestampedData)
          .filter((timestamp) => timestampedData[timestamp].storyReach !== undefined) // Filter out elements without a .storyReach
          .map((timestamp) => ({
            // Convert timestamp to a number and create a Date object
            x: new Date(parseInt(timestamp)).getTime(),
            y: Math.ceil(timestampedData[timestamp].storyReach / 3)
          }))
          // Sort the data points by the 'x' property, which is the timestamp in milliseconds
          .sort((a, b) => a.x - b.x)
    }
  ];

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  const statsList = [
    {
      title: "Locations",
      value: String(adminData?.adminStats?.locationQty ?? 0),
      icon: MdLocationPin,
    },
    {
      title: "Offers Accepted",
      value: String(adminData?.adminStats?.acceptances ?? 0),
      icon: MdCheck,
    },
    {
      title: "Number of Posts",
      value: String(adminData?.adminStats?.posts ?? 0),
      icon: MdImage,
    },
    {
      title: "Estimated Reach",
      value: String(Math.floor((adminData?.adminStats?.storyReach || 0) / 3)),
      icon: MdOutlineBarChart,
    },
  ];

  function handleClick(clientID: string) {
    history.push("/admin/accounts/view-account/?accountId=" + clientID);
  }

  return (
    <Box>
      <Flex alignItems="center">
        <Text
          ml="10px"
          mt="-17.5px"
          mb="15px"
          fontWeight="bold"
          fontSize="25px"
        >
          Overview
        </Text>
      </Flex>
      <StatsRow stats={statsList} />
      <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mb="20px">
        <Flex
          gridColumn={{ md: "span 2" }}
          flexDirection="column"
          w="100%"
          mb="0px"
          justifyContent={"space-around"}
        >
          <MetricChartCard chartData={chartData} />
          <UgcPreviewCard clientIDs={clientIds} maxRows={1} mt="20px" />
        </Flex>
        <Card
          justifyContent="start"
          alignItems="center"
          flexDirection="column"
          w="100%"
          h="100%"
        >
          <Flex justifyContent="space-between" w="100%" pe="20px" pt="5px">
            <Text
              color={textColor}
              fontSize="24px"
              textAlign="start"
              fontWeight="700"
              lineHeight="100%"
            >
              Accounts
            </Text>

            <IconBox
              _hover={{
                cursor: "pointer",
                transform: "scale(1.05)",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _focus={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _active={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              onClick={() => {
                history.push("/admin/accounts");
              }}
              w="40px"
              h="40px"
              bg="#598FE727"
              icon={<ChevronRightIcon w="20px" h="20px" color="#1877F2" />}
            />
          </Flex>
          <Flex
            flexDirection={"column"}
            mt="10%"
            width="100%"
            justifyContent="center"
            ml="10%"
          >
            {Object.values(accountsData).map(
              (account: any, index) =>
                index < 3 && (
                  <AccountTile
                    handleClick={handleClick}
                    key={account.clientID}
                    role="button"
                    size="100px"
                    imageURL={account.instagramProfilePicture}
                    accountName={account.accountName}
                    clientID={account.clientID}
                    style={index < 2 ? { marginBottom: "10%" } : {}}
                  />
                )
            )}
          </Flex>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
