/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import {
  Text,
  Flex,
  Box,
  Image,
  Checkbox,
  Center,
  Button,
} from "@chakra-ui/react";
import { contentTypeText, crossConceptSummary } from "utils";
interface CrossConceptRewardsOfferingProps {
  inputValues: {
    rewardClientIDs: any[];
    rewardClientNames: any[];
    expirationLimit?: number;
    isCustomerOffer?: Boolean;
    prerequisiteQuantity?: number;
    minimumFollowerCount?: number;
    discountItem?: string;
    prerequisiteName?: string;
    isPrerequisite: Boolean;
    compensation: number;
    percentOrCashOff: string;
    minPurchaseAmount: string;
    contentType: string;
    specificProduct: string;
    isFreeReward: string;
    discountType: boolean;
    hashtag: string;
    isEvergreenCode: boolean;
    ecommerceDiscountCode: string
  };
  onInputChange: (
    fieldName: keyof CrossConceptRewardsOfferingProps["inputValues"],
    value: any
  ) => void;
  data: any;
}

const CrossConceptRewardsOffering: React.FC<
  CrossConceptRewardsOfferingProps
> = ({ inputValues, onInputChange, data: additionalDetails }) => {
  const { clientID, accountData, allAccounts, isEditing } = additionalDetails;
  const { instagramHandle, offers = [], accountName } = accountData;
  const accountsArrayRaw = Object?.values(allAccounts);
  const accountsArray = accountsArrayRaw?.filter(
    // make the condition here only need to show those account who have not integration
    (client: any) => !client?.integrations && client?.clientID !== clientID
  );
  const [allRewardConcepts, setAllRewardConcepts] = useState(false);

  const selectedRewardClientIDsFromMap = inputValues?.rewardClientIDs ?? [
    clientID,
  ];
  const [rewardClientIDs, setRewardClientIDs] = useState(
    selectedRewardClientIDsFromMap
  );

  const selectedRewardsClientNamesFromMap = inputValues?.rewardClientNames ?? [
    accountData.accountName,
  ];

  const [rewardClientNames, setRewardClientNames] = useState(
    selectedRewardsClientNamesFromMap
  );

  const handleCheckboxChange = (
    newclientID: string,
    clientName: string,
    isChecked: boolean
  ) => {
    const prevClientIds = inputValues?.rewardClientIDs;
    const prevClientNames = inputValues?.rewardClientNames;
    if (isChecked) {
      onInputChange("rewardClientIDs", [...(prevClientIds || []), newclientID]);
      onInputChange("rewardClientNames", [...(prevClientNames || []), clientName]); // Fixed here
    } else {
      const updatedIDList = prevClientIds?.filter(
        (item: string) => item !== newclientID
      );
      onInputChange("rewardClientIDs", updatedIDList);
      const updatedNamesList = prevClientNames?.filter(
        (item: string) => item !== clientName
      );
      onInputChange("rewardClientNames", updatedNamesList);
    }
  };

  const toggleSelectAll = () => {
    if (allRewardConcepts) {
      onInputChange("rewardClientIDs", []);
      onInputChange("rewardClientNames", []);
    } else {
      const allIds = accountsArray?.map((client: any) => client?.id);
      const allAccountName = accountsArray?.map(
        (client: any) => client.accountName
      );
      onInputChange("rewardClientIDs", allIds);
      onInputChange("rewardClientNames", allAccountName);
    }
  };

  // Effect to check if all locations are selected
  useEffect(() => {
    if (
      accountsArray &&
      inputValues.rewardClientIDs?.length === accountsArray?.length
    ) {
      setAllRewardConcepts(true);
    } else {
      setAllRewardConcepts(false);
    }
  }, [
    accountsArray,
    inputValues?.rewardClientNames,
    inputValues?.rewardClientIDs,
  ]);

  return (
    <Box py="60px">
      <Flex flexWrap={"wrap"} rowGap={"60px"}>
        {accountsArray?.length > 0 && (
          <Button
            variant="chakra_btn_white"
            size="chakra_md"
            border={"1px solid #EFEFEF"}
            position="absolute"
            top="35px"
            right="40px"
            onClick={toggleSelectAll} // Add this line
          >
            {allRewardConcepts ? "Deselect All" : "Select All"}
          </Button>
        )}
        {accountsArray?.length > 0 &&
          accountsArray?.map((client: any, index: any) => (
            <Box w={"25%"} px={"40px"} key={index}>
              <Flex gap={"36px"}>
                <Checkbox
                  variant={"chakra_checkbox_dark"}
                  size={"checkbox_lg"}
                  isChecked={inputValues?.rewardClientIDs?.includes(client?.id)}
                  value={inputValues?.rewardClientIDs?.includes(client.id)?.toString()}
                  onChange={() =>
                    handleCheckboxChange(
                      client.id,
                      client.accountName,
                      !inputValues.rewardClientIDs?.includes(client?.id)
                    )
                  }
                ></Checkbox>
                {/* <Text ml="2">{client.accountName}</Text> */}
                {
                  <Box
                    width={"90px"}
                    height={"90px"}
                    borderRadius={"9px"}
                    border={"1px"}
                    borderColor={"#9A9A9A"}
                    bg={"#fff"}
                    overflow={"hidden"}
                  >
                    <Image
                      boxSize="full"
                      objectFit="contain"
                      objectPosition="Center"
                      src={client.instagramProfilePicture}
                      alt="Reward"
                    />
                  </Box>
                }
              </Flex>
            </Box>
          ))}
      </Flex>

      <>
        <Box
          maxW={"434px"}
          mx="auto"
          mt="60px"
          py={"15px"}
          px={"14px"}
          bg={"#D4FEAE"}
          borderRadius={"8px"}
        >
          <Flex alignItems={"center"} textAlign={"center"}>
            <Text fontSize={"15px"} fontWeight={"600"}>
              {crossConceptSummary({
                isPrerequisite: inputValues?.isPrerequisite,
                contentText: contentTypeText(inputValues?.contentType),
                specificProduct: inputValues.specificProduct,
                discountType: inputValues.discountType,
                accountName,
                instagramHandle,
                isFreeReward: inputValues.isFreeReward,
                compensation: inputValues.compensation,
                percentOrCashOff: inputValues.percentOrCashOff,
                discountItem: inputValues.discountItem,
                prerequisiteQuantity: inputValues.prerequisiteQuantity,
                prerequisiteName: inputValues.prerequisiteName,
                minimumFollowerCount: inputValues.minimumFollowerCount,
                expirationLimit: inputValues.expirationLimit,
                minPurchaseAmount: inputValues.minPurchaseAmount,
                hashtag: inputValues?.hashtag
              })}
              {inputValues.minimumFollowerCount > 0 &&
                ` Redeemers should have at least ${inputValues?.minimumFollowerCount} followers on Instagram.`}{" "}
              {`This coupon expires ${inputValues.expirationLimit} days after the
                            user receives it.`}
              This offer can be redeemed at
              {!isEditing
                ? " " +
                accountData["accountName"] +
                (inputValues?.rewardClientNames?.length ? "," : ".")
                : ""}{" "}
              {inputValues?.rewardClientNames?.length > 0 && (
                <>
                  &nbsp;
                  {inputValues?.rewardClientNames?.map((name, index) => (
                    <React.Fragment key={index}>
                      {name}
                      {index < inputValues?.rewardClientNames?.length - 1 && ", "}
                    </React.Fragment>
                  ))}
                  .
                </>
              )}
              {
                inputValues?.isEvergreenCode &&
                ` Code ${inputValues?.ecommerceDiscountCode} will be distributed to the people who complete the offer.`
              }

            </Text>
          </Flex>
        </Box>
      </>
    </Box>
  );
};

export default CrossConceptRewardsOffering;
