import {
  Box,
  Flex,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Heading,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import useFacebookMetaData from "services/facebookMetaService";

// Define the type for a single DM log entry
interface DMLog {
  id: number;
  user: string;
  message: string;
  timestamp: string;
}

interface DMLogsProps {
  userId: string;
  metaData: any;
  // saveDmTemplate: Function;
}

// Sample data for DM logs

const DMLogs: React.FC<DMLogsProps> = ({ userId, metaData }) => {
  const { isLoading = true, fbMetaData = {} } = useFacebookMetaData({
    userId: userId || "",
  });
  const [dmLogs, setDMLogs] = useState<DMLog[]>([]);

  useEffect(() => {
    /**
     * @info changes made by nirmal on date :- 26/04/2024
     * Added a filter for the task below
     *  #`Adding a filter into the History tab in the dashboard to allow customers to filter offers by brand`
     */
    if (
      fbMetaData &&
      typeof fbMetaData === "object" &&
      Object.keys(fbMetaData)?.length
    ) {
      const userData = fbMetaData[userId];
      if (userData && Array.isArray(userData?.dmLogs)) {
        const sortedLogs = userData.dmLogs
          .slice()
          .sort((a: DMLog, b: DMLog) => {
            return (
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
            );
          });

        setDMLogs(sortedLogs);
      } else {
        setDMLogs([]);
      }
    }
  }, [fbMetaData, isLoading]); // Ru

  if (isLoading) {
    return (
      <Box>
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="md" />
        </Flex>
      </Box>
    );
  }

  return (
    <Box className="dm-logs">
      <Heading as="h2" size="sm" marginBottom={"10px"} fontWeight={"600"}>
        DM Logs
      </Heading>
      <Box
        border="0px solid #F6F6F6"
        width="100%"
        borderRadius={"10px"}
        padding={"20px"}
      >
        <Table variant="simple" color="gray.500" size="md">
          <Thead>
            <Tr>
              <Th>User</Th>
              <Th>Message</Th>
              <Th>Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {dmLogs?.length > 0 ? (
              dmLogs.map((log) => (
                <Tr key={log.id}>
                  <Td>{log.user}</Td>
                  <Td>{log.message}</Td>
                  <Td>{log.timestamp}</Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={3}>
                  <Text align={"center"}>No data available.</Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default DMLogs;
