import { useEffect, useState } from "react";
import {
  collection,
  doc,
  endBefore,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
  count,
  and,
  getCountFromServer,
} from "firebase/firestore";
import { firestore } from "../firebase";

interface Offer {
  offerID: string;
  // Add other offer properties here
}

interface OffersData {
  [offerId: string]: any; // Map offer data by offerID
}

interface UseOffersDataResult {
  isLoading: boolean;
  data: OffersData;
  totalOffers: number;
  fetchedRecordCount: number;
  showNext: (item: any, currentCount?: number) => void;
  showPrevious: (item: any, currentCount?: number) => void;
}

const useOffersData = (
  params
) => {
  const { clientID = "", userId = "", perPage = 10, offerId = "", isInfluencerOffer = false, locationId = "" } = params;
  /**
   * @todo need to add isDeleted == false condition with everywhere.
   */
  const offersCollectionRef = collection(firestore, "offers");
  const [offersData, setOfferData] = useState<OffersData>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [totalOffers, setTotalOffers] = useState<number>(0);
  const [fetchedRecordCount, setFetchedRecordCount] = useState(0);
  const [lastVisible, setLastVisible] = useState({});
  const [firstVisible, setFirstVisible] = useState({});

  let unsubscribeOffers: any; // Store unsubscribe function for location offers
  let unsubscribeSingleOffer: any; // Store unsubscribe function for single offer

  useEffect(() => {
    if (clientID && locationId) {
      getLocationOffers();
      getTotalLocationOffers();
    } else if (clientID) {
      getOffers();
      getTotalOffers();
    }

    // Cleanup function to unsubscribe on unmount
    return () => {
      unsubscribeOffers?.(); // Call unsubscribe function if it exists
    };
  }, [clientID, locationId]);

  useEffect(() => {
    if (offerId) {
      fetchSingleOffer();
    }

    // Cleanup function to unsubscribe on unmount
    return () => {
      unsubscribeSingleOffer?.(); // Call unsubscribe function if it exists
    };
  }, [offerId]);

  const getTotalOffers = async () => {
    // Find Total Row Count
    if (!clientID) {
      return;
    }
    let queryRef = query(offersCollectionRef, where("clientID", "==", clientID), where("isDeleted", "==", false));
    // Efficiently add the 'isInfluencerOffer' filter if necessary
    if (isInfluencerOffer) {
      queryRef = query(offersCollectionRef, where("isInfluencerOffer", "==", isInfluencerOffer), where("clientID", "==", clientID), where("isDeleted", "==", false));
    }

    const snapshot = await getCountFromServer(queryRef);
    setTotalOffers(snapshot?.data()?.count || 0);
  };

  const getTotalLocationOffers = async () => {
    // Find Total Row Count
    if (!clientID || !locationId) {
      return;
    }
    let queryRef = query(offersCollectionRef, where("clientID", "==", clientID), where("locations", "array-contains-any", [locationId]), where("isDeleted", "==", false));
    const snapshot = await getCountFromServer(queryRef);
    setTotalOffers(snapshot?.data()?.count || 0);
    return;
  };

  const getOfferStatDetails = async (offerId: string) => {
    // Simulated async operation to fetch offer stats
    const statsDocRef = doc(firestore, "offerStats", offerId);
    const statsDocSnapshot = await getDoc(statsDocRef);

    const sortedTimestamps = statsDocSnapshot
      ? Object.keys(statsDocSnapshot.data() || {})
        .map(Number)
        .sort((a, b) => b - a)
      : [];

    const latestTimestamp = sortedTimestamps[0];
    const latestData = statsDocSnapshot?.data()?.[latestTimestamp] || {};

    return { ...latestData, timestamps: statsDocSnapshot?.data() || null };
  };
  const setupOfferAndStateData = async (queryRef: any, recordCount?: number, type?: string) => {
    try {
      const unsubscribe = onSnapshot(queryRef, async (querySnapshot) => {
        const updatedOffersData: OffersData = {};
        const promises: Promise<void>[] = [];
        if (querySnapshot?.docs?.length) {
          let fetchedRecord = querySnapshot?.docs?.length;
          if (type === "next") {
            fetchedRecord = fetchedRecordCount + querySnapshot?.docs?.length;
          } else if (type === "previous") {
            fetchedRecord = fetchedRecordCount - recordCount;
          }
          setFetchedRecordCount(fetchedRecord);
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setFirstVisible(querySnapshot.docs[0]);
          querySnapshot?.docs.forEach((doc) => {
            const offerId = doc.id;
            const offerData = doc.data() as Offer;
            // Push each async operation into the promises array
            promises.push(
              (async () => {
                // Simulate your async operation, e.g., fetching offer stats
                const offerStat = await getOfferStatDetails(offerId);

                // Update the offers data with the retrieved data
                updatedOffersData[offerId] = {
                  ...offerData,
                  stats: offerStat || null,
                };
              })(),
            );
          });
          await Promise.all(promises);
          setOfferData(updatedOffersData);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });

      return unsubscribe;
    } catch (error) {
      console.error("Error while fetch offer data--", error);
      setLoading(false);
      return; // Handle errors appropriately (optional)
    }
  };

  const getLocationOffers = async () => {
    if (!clientID && !locationId) {
      setOfferData({});
      return;
    }
    setLoading(true);

    let queryRef = query(
      offersCollectionRef,
      where("clientID", "==", clientID),
      where("isDeleted", "==", false),
      where("locations", "array-contains-any", [locationId]),
      orderBy("date", "desc"),
      orderBy("offerID", "asc"),
      limit(perPage)
    );

    unsubscribeOffers = await setupOfferAndStateData(queryRef, 0, "");
    return unsubscribeOffers;
  };

  const fetchSingleOffer = async () => {
    if (!offerId) {
      setOfferData({});
      return;
    }
    setLoading(true);

    let queryRef = query(
      offersCollectionRef,
      where("offerID", "==", offerId),
    );
    unsubscribeSingleOffer = await setupOfferAndStateData(queryRef, 0, "");
    return unsubscribeSingleOffer;
  };

  const getOffers = async () => {
    if (!clientID) {
      setOfferData({});
      return;
    }
    setLoading(true);

    let queryRef = query(
      offersCollectionRef,
      where("clientID", "==", clientID),
      where("isDeleted", "==", false),
      orderBy("date", "desc"),
      orderBy("offerID", "asc"),
      limit(perPage)
    );

    if (isInfluencerOffer) {
      queryRef = query(
        offersCollectionRef,
        where("clientID", "==", clientID),
        where("isInfluencerOffer", "==", isInfluencerOffer),
        where("isDeleted", "==", false),
        orderBy("date", "desc"),
        orderBy("offerID", "asc"),
        limit(perPage)
      );
    }

    unsubscribeOffers = await setupOfferAndStateData(queryRef, 0, "");
    return unsubscribeOffers;
  };

  const showNext = async (item: any, currentCount?: number) => {
    if (!clientID) {
      return;
    }

    setLoading(true);
    let queryRef: any;

    if (locationId) {
      queryRef = query(
        offersCollectionRef,
        where("clientID", "==", clientID),
        where("isDeleted", "==", false),
        where("locations", "array-contains-any", [locationId]),
        orderBy("date", "desc"),
        orderBy("offerID", "asc"),
        limit(perPage),
        startAfter(lastVisible),
      );
    } else {
      queryRef = query(
        offersCollectionRef,
        where("clientID", "==", clientID),
        where("isDeleted", "==", false),
        orderBy("date", "desc"),
        orderBy("offerID", "asc"),
        startAfter(lastVisible),
        limit(perPage)
      );

      if (isInfluencerOffer) {
        queryRef = query(
          offersCollectionRef,
          where("clientID", "==", clientID),
          where("isDeleted", "==", false),
          where("isInfluencerOffer", "==", isInfluencerOffer),
          orderBy("date", "desc"),
          orderBy("offerID", "asc"),
          startAfter(lastVisible),
          limit(perPage)
        );
      }
    }

    unsubscribeOffers = await setupOfferAndStateData(queryRef, currentCount ? currentCount : perPage, "next");
  };

  const showPrevious = async (item: any, currentCount?: number) => {
    if (!clientID) {
      return;
    }

    setLoading(true);
    let queryRef: any;

    if (locationId) {
      queryRef = query(
        offersCollectionRef,
        where("clientID", "==", clientID),
        where("locations", "array-contains-any", [locationId]),
        where("isDeleted", "==", false),
        orderBy("date", "desc"),
        orderBy("offerID", "asc"),
        endBefore(firstVisible),
        limitToLast(perPage)
      );
    } else {
      queryRef = query(
        offersCollectionRef,
        where("clientID", "==", clientID),
        where("isDeleted", "==", false),
        orderBy("date", "desc"),
        orderBy("offerID", "asc"),
        endBefore(firstVisible),
        limitToLast(perPage)
      );

      if (isInfluencerOffer) {
        queryRef = query(
          offersCollectionRef,
          where("clientID", "==", clientID),
          where("isInfluencerOffer", "==", isInfluencerOffer),
          where("isDeleted", "==", false),
          orderBy("date", "desc"),
          orderBy("offerID", "asc"),
          endBefore(firstVisible),
          limitToLast(perPage)
        );
      }
    }
    unsubscribeOffers = await setupOfferAndStateData(queryRef, currentCount, "previous");
  };

  return {
    isLoading,
    offersData,
    totalOffers,
    fetchedRecordCount,
    showNext,
    showPrevious,
  };
};

export default useOffersData;
