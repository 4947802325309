import React, { useState } from "react";
import { Text, Flex, Box, Button, Spinner } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { AccountsDataContext } from "contexts/accountsDataContext";
import TitleAndDescription from "../components/TitleAndDescription";
import { IoMdInformationCircle } from "react-icons/io";
import AccountsGrid from "../../accounts/AccountsGrid";
import AccountTile from "../../accounts/AccountTile";
import { useHistory } from "react-router-dom";
import ContentExampleUgc from "../components/ContentExampleUGC";

import ImageUploader from "../../accounts/pages/offers/components/ImageUploader";
import {
  uploadVideoExampleUGC,
  uploadImageExampleUGC,
} from "../../../../services/firebaseService";

interface ExampleUgcProps {
  setStep: Function;
  setAutopilotDataMap: Function;
  autopilotDataMap: Map<string, any>;
  setInstagramStoryExampleUGC: Function;
  setInstagramPostExampleUGC: Function;
  setInstagramReelExampleUGC: Function;
  setTiktokVideoExampleUGC: Function;
  setUgcExampleUGC: Function;
  instagramStoryExampleUGC: Uint8Array;
  instagramPostExampleUGC: Uint8Array;
  instagramReelExampleUGC: Uint8Array;
  tiktokVideoExampleUGC: Uint8Array;
  ugcExampleUGC: Uint8Array;
}

function ExampleUgc({
  setStep,
  setAutopilotDataMap,
  autopilotDataMap,
  instagramStoryExampleUGC,
  instagramPostExampleUGC,
  instagramReelExampleUGC,
  setTiktokVideoExampleUGC,
  ugcExampleUGC,
  setInstagramStoryExampleUGC,
  setInstagramPostExampleUGC,
  setInstagramReelExampleUGC,
  tiktokVideoExampleUGC,
  setUgcExampleUGC,
}: ExampleUgcProps) {
  const contentTypesPreSelected = autopilotDataMap.get(
    "contentTypesPreSelected"
  );

  const contentTypes = autopilotDataMap.get("contentTypes");


  const editingContentType = autopilotDataMap.get("editingContentType");
  let newContentTypesSelected = contentTypes.filter(
    (contentType: string) => !contentTypesPreSelected.includes(contentType) // add editingContentType if its not ""
  );

  const [isInstagramStoryExampleUGCValid, setIsInstagramStoryExampleUGCValid] =
    useState(contentTypesPreSelected.includes("instagramStory"));
  const [isInstagramPostExampleUGCValid, setIsInstagramPostExampleUGCValid] =
    useState(contentTypesPreSelected.includes("instagramPost"));
  const [isInstagramReelExampleUGCValid, setIsInstagramReelExampleUGCValid] =
    useState(contentTypesPreSelected.includes("instagramReel"));
  const [isUgcExampleUGCValid, setIsUgcExampleUGCValid] = useState(
    contentTypesPreSelected.includes("ugc")
  );
  const [isTiktokVideoExampleUGCValid, setIsTiktokVideoExampleUGCValid] = useState(
    contentTypesPreSelected.includes("tiktokVideo")
  );


  const [isUploading, setIsUploading] = useState(false);

  async function handleClick() {
    setIsUploading(true);
    for (let i = 0; i < newContentTypesSelected.length; i++) {
      let exampleUgcUrl = "";

      let exampleUGC =
        contentTypes[i] === "instagramStory"
          ? instagramStoryExampleUGC
          : contentTypes[i] === "instagramPost"
            ? instagramPostExampleUGC
            : contentTypes[i] === "instagramReel"
              ? instagramReelExampleUGC
              : contentTypes[i] === "ugc"
                ? ugcExampleUGC
                : tiktokVideoExampleUGC;

      let randomNumber = Math.random().toString().slice(2);

      if (contentTypes[i] === "instagramReel" || contentTypes[i] === "tiktokVideo") {
        exampleUgcUrl = await uploadVideoExampleUGC(
          exampleUGC,
          autopilotDataMap.get("clientID"),
          "autopilot_" + contentTypes[i] + "_" + randomNumber
        );
      } else {
        exampleUgcUrl = await uploadImageExampleUGC(
          exampleUGC,
          autopilotDataMap.get("clientID"),
          "autopilot_" + contentTypes[i] + "_" + randomNumber
        );
      }

      let contentTypeData = autopilotDataMap.get(newContentTypesSelected[i]);

      contentTypeData.set("exampleUGC", exampleUgcUrl);
      console.log(contentTypeData);

      setAutopilotDataMap(() => {
        const tempMap = new Map(autopilotDataMap);
        tempMap.set(newContentTypesSelected[i], contentTypeData);
        return tempMap;
      });
    }
    setIsUploading(false);

    setStep(3);
  }

  function backFunction() {
    setStep(1);
  }

  const isButtonDisabled = () => {
    for (let i = 0; i < contentTypes.length; i++) {
      if (
        contentTypes[i] === "instagramStory" &&
        !isInstagramStoryExampleUGCValid
      ) {
        return true;
      } else if (
        contentTypes[i] === "instagramPost" &&
        !isInstagramPostExampleUGCValid
      ) {
        return true;
      } else if (
        contentTypes[i] === "instagramReel" &&
        !isInstagramReelExampleUGCValid
      ) {
        return true;
      }
      else if (
        contentTypes[i] === "tiktokVideo" &&
        !isTiktokVideoExampleUGCValid
      ) {
        return true;
      } else if (contentTypes[i] === "ugc" && !isUgcExampleUGCValid) {
        return true;
      }
    }

    return false;
  };

  return (
    <Flex flexDirection="column" alignItems="start">
      <Flex justifyContent="start" alignItems="center" width="100%">
        <TitleAndDescription
          hideBackButton={false}
          title={"Example UGC"}
          description={
            "Choose example content that will be showed to guide your customers"
          }
          backFunction={backFunction}
        />

        <Box
          marginLeft="auto"
          color="white"
          display="inline-block"
          width="50px"
          height="50px"
          borderRadius="50px"
          backgroundImage={autopilotDataMap.get("clientProfilePic")}
          backgroundSize="cover"
        />
      </Flex>

      <Box borderTop="1.3px solid #E2E8F0" width="100%" mt="1.5%" />
      <Box
        height="650px"
        borderRadius="lg"
        boxShadow="lg"
        overflowY="auto"
        width="100%"
        mt="3%"
        p={7}
      >
        <Flex width="100%" alignItems="start" justifyContent="start">
          {((contentTypes.includes("instagramStory") &&
            !contentTypesPreSelected.includes("instagramStory")) ||
            editingContentType === "instagramStory" ||
            autopilotDataMap.get("instagramStory")?.get("exampleUGC") !==
            undefined) && (
              <Flex width="20%">
                <ContentExampleUgc
                  setContentExampleUGC={setInstagramStoryExampleUGC}
                  contentType={"instagramStory"}
                  setIsContentExampleUGCValid={setIsInstagramStoryExampleUGCValid}
                  exampleUgcUrl={
                    autopilotDataMap.get("instagramStory").get("exampleUGC") ?? ""
                  }
                />
              </Flex>
            )}

          {((contentTypes.includes("instagramPost") &&
            !contentTypesPreSelected.includes("instagramPost")) ||
            editingContentType === "instagramPost" ||
            autopilotDataMap.get("instagramPost")?.get("exampleUGC") !==
            undefined) && (
              <Flex width="20%">
                <ContentExampleUgc
                  setContentExampleUGC={setInstagramPostExampleUGC}
                  contentType={"instagramPost"}
                  setIsContentExampleUGCValid={setIsInstagramPostExampleUGCValid}
                  exampleUgcUrl={
                    autopilotDataMap.get("instagramPost").get("exampleUGC") ?? ""
                  }
                />
              </Flex>
            )}
          {((contentTypes.includes("instagramReel") &&
            !contentTypesPreSelected.includes("instagramReel")) ||
            autopilotDataMap.get("instagramReel")?.get("exampleUGC") !==
            undefined ||
            editingContentType === "instagramReel") && (
              <Flex width="20%">
                <ContentExampleUgc
                  setContentExampleUGC={setInstagramReelExampleUGC}
                  contentType={"instagramReel"}
                  setIsContentExampleUGCValid={setIsInstagramReelExampleUGCValid}
                  exampleUgcUrl={
                    autopilotDataMap.get("instagramReel").get("exampleUGC") ?? ""
                  }
                />
              </Flex>
            )}
          {((contentTypes.includes("ugc") &&
            !contentTypesPreSelected.includes("ugc")) ||
            editingContentType === "ugc" ||
            autopilotDataMap.get("ugc")?.get("exampleUGC") !== undefined) && (
              <Flex width="20%">
                <ContentExampleUgc
                  setContentExampleUGC={setUgcExampleUGC}
                  contentType={"ugc"}
                  setIsContentExampleUGCValid={setIsUgcExampleUGCValid}
                  exampleUgcUrl={
                    autopilotDataMap.get("ugc").get("exampleUGC") ?? ""
                  }
                />
              </Flex>
            )}
          {((contentTypes.includes("tiktokVideo") &&
            !contentTypesPreSelected.includes("tiktokVideo")) ||
            autopilotDataMap.get("tiktokVideo")?.get("exampleUGC") !==
            undefined ||
            editingContentType === "tiktokVideo") && (
              <Flex width="20%">
                <ContentExampleUgc
                  setContentExampleUGC={setTiktokVideoExampleUGC}
                  contentType={"tiktokVideo"}
                  setIsContentExampleUGCValid={setIsTiktokVideoExampleUGCValid}
                  exampleUgcUrl={
                    autopilotDataMap.get("tiktokVideo").get("exampleUGC") ?? ""
                  }
                />
              </Flex>
            )}
        </Flex>
      </Box>
      <Box borderTop="1.3px solid #E2E8F0" width="100%" mt="7.5%" />

      <Flex justifyContent="flex-end" width="100%" p={4} mt="1.5%">
        <Button
          isDisabled={isButtonDisabled()}
          onClick={handleClick}
          backgroundColor="black"
          width="150px"
          p="10px 15px"
          borderRadius="10px"
          transition="transform 0.2s, box-shadow 0.2s"
          transform="translateY(0)"
          _hover={{
            transform: "scale(1.05)",
          }}
          _active={{}}
        >
          {!isUploading ? (
            <Text
              fontWeight="500"
              fontSize="15px"
              textAlign="center"
              color="white"
            >
              Next
            </Text>
          ) : (
            <Spinner color="white" size="md" />
          )}
        </Button>
      </Flex>
    </Flex>
  );
}

export default ExampleUgc;
