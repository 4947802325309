// Chakra imports
import { Box, Grid, Flex, Image, Spinner, Button } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import DropdownMenu from "../ugcLibrary/components/DropdownMenu";
import { AdminDataContext } from "contexts/adminDataContext";
import aiImage from "assets/img/layout/AI.png";
import dynamicRewarding from "assets/img/layout/dynamicReward.png";
import reEngaging from "assets/img/layout/reEngaging.png";
import { AutopilotDataContext } from "../../../contexts/autopilotDataContext";
import { useToast, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import ContentTypePublished from "./components/ContentTypePublished";
import { updateAutopilotContentActiveStatus } from "../../../services/firebaseService";
import { AccountsDataContext } from "contexts/accountsDataContext";

export default function Autopilot() {
  const { adminData, isLoading } = useContext(AdminDataContext);
  const autopilotDataContext = useContext(AutopilotDataContext);
  const toast = useToast();
  const history = useHistory();
  const accountsData = useContext(AccountsDataContext);

  const [filteredClientID, setfilteredClientID] = useState(
    adminData?.clientIDs?.[0] ?? ""
  );

  const filteredAutopilotData = autopilotDataContext[filteredClientID] ?? {};

  const toggleSwitch = async (
    clientID: string,
    contentType: string,
    isActive: boolean
  ) => {
    try {
      await updateAutopilotContentActiveStatus(clientID, contentType, isActive);
    } catch (error) {
      console.log("Unabled to update status of autopilot at the moment");
    }
  };

  if (!adminData || !adminData.clientIDs) {
    return (
      <Flex justifyContent="center" alignItems="center" height="200px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (filteredClientID === "") {
    return <Text>Please make an account first</Text>;
  }

  return (
    <Box>
      {Object.keys(filteredAutopilotData).length > 0 &&
        filteredClientID !== "" && (
          <Box
            backgroundColor="white"
            width="100%"
            height="1000px"
            // overflow="hidden" // Hides the scrollbar of this widget
            borderRadius="10px"
            // height="100px"
            position="relative"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center">
                <Text ml="20px" mt="20px" fontWeight="bold" fontSize="25px">
                  AutoPilot Campaigns for{" "}
                  {accountsData[filteredClientID]["accountName"]}
                </Text>
              </Flex>

              <DropdownMenu
                isCommunity={false}
                filteredClientID={filteredClientID}
                isUgc={false}
                clientIDs={adminData.clientIDs}
                setfilteredClientID={setfilteredClientID}
              />
            </Flex>
            <Flex alignItems="center">
              <Box
                borderTop="1.3px solid #E2E8F0"
                mt="15px"
                width="96%"
                ml="20px"
              />
            </Flex>
            <Box
              px="2%"
              py="2%"
              justifyContent="center"
              borderRadius="lg"
              boxShadow="lg"
              overflowY="auto"
              m="3%"
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Flex width="15%" justifyContent="start" alignItems="center">
                  <Text fontSize="20px" fontWeight="400">
                    Content Type
                  </Text>
                </Flex>
                <Flex width="15%" justifyContent="center" alignItems="center">
                  <Text fontSize="20px" fontWeight="400">
                    Active
                  </Text>
                </Flex>
                <Flex width="15%" justifyContent="end" alignItems="center">
                  <Text fontSize="20px" fontWeight="400">
                    Edit
                  </Text>
                </Flex>
              </Flex>
              <Box borderTop="1.3px solid #E2E8F0" width="100%" mt="1.5%" />

              {/* {filteredAutopilotData.tiktokVideo?.exampleUGC && (
                <Flex mt="3%" flexDirection="column">
                  <ContentTypePublished
                    contentType={filteredAutopilotData.tiktokVideo?.contentType}
                    isActive={
                      filteredAutopilotData.tiktokVideo.isActive ?? false
                    }
                    clientID={filteredClientID}
                    toggleSwitch={toggleSwitch}
                  />
                </Flex>
              )} */}
              {filteredAutopilotData.instagramStory?.exampleUGC && (
                <Flex mt="3%" flexDirection="column">
                  <ContentTypePublished
                    contentType={
                      filteredAutopilotData.instagramStory?.contentType
                    }
                    isActive={
                      filteredAutopilotData.instagramStory.isActive ?? false
                    }
                    clientID={filteredClientID}
                    toggleSwitch={toggleSwitch}
                  />
                </Flex>
              )}

              {filteredAutopilotData.instagramPost?.exampleUGC && (
                <Flex mt="3%" flexDirection="column">
                  <ContentTypePublished
                    contentType={
                      filteredAutopilotData.instagramPost?.contentType
                    }
                    isActive={
                      filteredAutopilotData.instagramPost.isActive ?? false
                    }
                    clientID={filteredClientID}
                    toggleSwitch={toggleSwitch}
                  />
                </Flex>
              )}

              {filteredAutopilotData.instagramReel?.exampleUGC && (
                <Flex mt="3%" flexDirection="column">
                  <ContentTypePublished
                    contentType={
                      filteredAutopilotData.instagramReel?.contentType
                    }
                    isActive={
                      filteredAutopilotData.instagramReel.isActive ?? false
                    }
                    clientID={filteredClientID}
                    toggleSwitch={toggleSwitch}
                  />
                </Flex>
              )}
              {filteredAutopilotData.ugc?.exampleUGC && (
                <Flex mt="3%" flexDirection="column">
                  <ContentTypePublished
                    contentType={filteredAutopilotData.ugc?.contentType}
                    isActive={filteredAutopilotData.ugc.isActive ?? false}
                    clientID={filteredClientID}
                    toggleSwitch={toggleSwitch}
                  />
                </Flex>
              )}
              {filteredAutopilotData.tiktokVideo?.exampleUGC && (
                <Flex mt="3%" flexDirection="column">
                  <ContentTypePublished
                    contentType={filteredAutopilotData.tiktokVideo?.contentType}
                    isActive={
                      filteredAutopilotData.tiktokVideo.isActive ?? false
                    }
                    clientID={filteredClientID}
                    toggleSwitch={toggleSwitch}
                  />
                </Flex>
              )}
            </Box>
            {(!filteredAutopilotData.ugc?.exampleUGC ||
              !filteredAutopilotData.instagramReel?.exampleUGC ||
              !filteredAutopilotData.instagramPost?.exampleUGC ||
              !filteredAutopilotData.instagramStory?.exampleUGC ||
              !filteredAutopilotData.tiktokVideo?.exampleUGC) && (
                <Flex alignItems="center" justifyContent="center">
                  <Button
                    onClick={() => {
                      history.push(
                        "/admin/create-autopilot-offer/?accountId=" +
                        filteredClientID +
                        "&isEditing=true"
                      );
                    }}
                    backgroundColor="black"
                    width="150px"
                    p="10px 15px"
                    borderRadius="10px"
                    transition="transform 0.2s, box-shadow 0.2s"
                    transform="translateY(0)"
                    _hover={{
                      transform: "scale(1.05)",
                    }}
                  >
                    <Text
                      fontWeight="500"
                      fontSize="15px"
                      textAlign="center"
                      color="white"
                    >
                      Add Campaign
                    </Text>
                  </Button>
                </Flex>
              )}

            {/* <Text fontWeight="bold" fontSize="25px">
     User Generated Content (UGC)
     </Text> */}
          </Box>
        )}

      {Object.keys(filteredAutopilotData).length === 0 &&
        filteredClientID !== "" && (
          <Box
            backgroundColor="white"
            width="100%"
            height="100%"
            // overflow="hidden" // Hides the scrollbar of this widget
            borderRadius="10px"
            // height="100px"
            position="relative"
            p={5}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center">
                <Text ml="20px" mt="20px" fontWeight="bold" fontSize="25px">
                  Autopilot Dynamic Reengagement
                </Text>
              </Flex>

              <DropdownMenu
                filteredClientID={filteredClientID}
                isCommunity={false}
                isUgc={false}
                clientIDs={adminData.clientIDs}
                setfilteredClientID={setfilteredClientID}
              />
            </Flex>

            <Flex justify="space-evenly" mt="5%">
              <Box
                w="25%"
                h="300px"
                borderRadius="15px"
                border="0.8px solid #9B9B9B"
                boxSizing="border-box"
                p={5}
              >
                <Flex
                  flexDirection="column"
                  justifyContent="space-evenly"
                  alignItems="center"
                  height="260px"
                >
                  <Image
                    src={reEngaging} // Replace with the actual path to your image
                    maxWidth="50%"
                  />

                  <Text
                    color="#9B9B9B"
                    fontSize="15px"
                    align="center"
                    width="80%"
                  >
                    Re-engage customers to continue sharing
                  </Text>
                </Flex>
              </Box>
              <Box
                w="25%"
                h="300px"
                borderRadius="15px"
                border="0.8px solid #9B9B9B"
                boxSizing="border-box"
                p={5}
              >
                <Flex
                  flexDirection="column"
                  justifyContent="space-evenly"
                  alignItems="center"
                  height="260px"
                >
                  <Image
                    src={dynamicRewarding} // Replace with the actual path to your image
                    maxWidth="50%"
                  />

                  <Text
                    color="#9B9B9B"
                    fontSize="15px"
                    align="center"
                    width="100%"
                  >
                    Dynamic reward creation, based on customer’s social
                    presence, we reward accordingly{" "}
                  </Text>
                </Flex>
              </Box>
              <Box
                w="25%"
                h="300px"
                borderRadius="15px"
                border="0.8px solid #9B9B9B"
                boxSizing="border-box"
                p={5}
              >
                <Flex
                  flexDirection="column"
                  justifyContent="space-evenly"
                  alignItems="center"
                  height="260px"
                >
                  <Image
                    src={aiImage} // Replace with the actual path to your image
                    maxWidth="50%"
                  />

                  <Text
                    color="#9B9B9B"
                    fontSize="15px"
                    align="center"
                    width="100%"
                  >
                    Using AI, we learn about your customers and tell you all
                    about them{" "}
                  </Text>
                </Flex>
              </Box>
            </Flex>

            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Text
                width="60%"
                mt="5%"
                color="#9B9B9B"
                fontSize="20px"
                align="center"
              >
                Experience the future of customer reengagement with our new
                Autopilot feature — effortlessly bringing your customers back
                through the door!
              </Text>

              <Box
                borderTop="1.3px solid #E2E8F0"
                mt="5%"
                width="96%"
                ml="20px"
              />

              <Button
                onClick={() => {
                  if (adminData.clientIDs.length === 0) {
                    toast({
                      title: "Please create an account first",
                      status: "error",
                      duration: 1250,
                      isClosable: false,
                    });
                  }

                  // if (adminData.clientIDs.length === 1) {

                  //   // go to contentType with assuming theyre working with their only client ID
                  // }
                  else {
                    history.push(
                      "/admin/create-autopilot-offer/?accountId=" +
                      filteredClientID
                    );
                  }
                }}
                backgroundColor="black"
                mt="4%"
                height="55px"
                width="225px"
                borderRadius="10px"
                transition="transform 0.2s, box-shadow 0.2s"
                transform="translateY(0)"
                boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                _hover={{
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
                _focus={{
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
                _active={{
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Text
                  fontWeight="500"
                  fontSize="18px"
                  textAlign="center"
                  color="white"
                >
                  Get Started
                </Text>
              </Button>
            </Flex>
          </Box>
        )}
    </Box>
  );
}
