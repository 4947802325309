import CustomerGroupsTable from "./CustomerGroups";
import { Text, Flex, Button, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";



interface CustomerGroupsPreviewProps {
  clientID: string;
}

export default function CustomerGroupsPreview({ clientID }: CustomerGroupsPreviewProps) {
  const history = useHistory();
  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      mb="0px"
      position="relative"
      p="30px"
      mt="30px"
    >
      <Flex
        justifyContent="space-between"
        flexDirection={"row"}
        px="20px"
        mb="15px"
        w="100%"
      >
        <Text fontWeight="bold" fontSize="20px">
          Customer Groups
        </Text>
        <Button
          backgroundColor="black"
          borderRadius="10px"
          transition="transform 0.2s, box-shadow 0.2s"
          transform="translateY(0)"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          _hover={{
            transform: "scale(1.05)",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          _focus={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          _active={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
          onClick={() => {
            history.push("/admin/community/view-all-customer-groups?accountId=" + clientID);
          }}
        >
          <Text color="white" fontSize="15px" fontWeight="500">
            View All
          </Text>
        </Button>
      </Flex>

      <Flex
        w="100%"
        h="250px"
        flexDirection={{ base: "column", lg: "row" }}
        position="relative"
      >
        <CustomerGroupsTable isPreview={true} clientID={clientID}/>
      </Flex>
    </Card>
  );
}
