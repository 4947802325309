import React from "react";
import { Box, Avatar, Text, Flex, background } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";

import CustomerStatTiles from "./CustomerStatTiles";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";

interface Customer {
  firstName: string;
  instagramHandle: string;
  profilePicURL: string;
  phoneNumber: string;
  isInfluencer: boolean;
}

interface CustomerCardProps {
  customer: Customer;
  showStats?: boolean;
  [x: string]: any;
  clientID: string;
}

const CustomerCard: React.FC<CustomerCardProps> = ({
  customer,
  showStats,
  clientID,
  ...rest
}) => {
  const history = useHistory();

  return (
    <Box
      width={showStats && "95%"}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      m="1%"
      pt={showStats ? "1%" : "3.5%"}
      pb={showStats ? "1%" : "3.5%"}
      pl={showStats ? "4%" : "5%"}
    >
      <Flex align="center">
        <Avatar
          size="xl"
          name={customer.firstName}
          src={customer.profilePicURL}
        />
        <Box pl="5%" width={showStats && "20%"}>
          <Flex justify="start" alignItems="center">
            <Text fontSize="25px" fontWeight="semibold">
              {customer.firstName !== ""
                ? customer.firstName
                : customer.phoneNumber}
            </Text>
            {customer.isInfluencer && (
              <Box
                ml="10px"
                backgroundColor="#D3FFD2"
                borderRadius="10px"
                width="75px"
                p="1"
              >
                <Flex width="100%" alignItems="center" justifyContent="center">
                  <Text fontSize="11px" color="#117C00">
                    Influencer
                  </Text>
                </Flex>
              </Box>
            )}
          </Flex>
          {customer.instagramHandle !== "" && (
            <Text color="gray.600" fontSize="15px">
              @{customer.instagramHandle}
            </Text>
          )}{" "}
        </Box>
        {showStats && <CustomerStatTiles customer={customer} clientID={clientID} />}
        {showStats && (
          <IconBox
            ml="auto"
            mr="5%"
            _hover={{
              cursor: "pointer",
              transform: "scale(1.05)",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            _focus={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            _active={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
            onClick={() => {
              history.push(
                "/admin/view-customer?accountId=" + clientID + "&phone=" + customer.phoneNumber
              );
            }}
            w="40px"
            h="40px"
            bg="#598FE727"
            icon={<ChevronRightIcon w="20px" h="20px" color="#1877F2" />}
          />
        )}
      </Flex>
    </Box>
  );
};

export default CustomerCard;
