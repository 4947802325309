import React, { createContext, useState, useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";

export const FoodFluenceDataContext = createContext();

export const FoodFluenceDataProvider = ({ children }) => {
    const [foodfluenceData, setFoodFluenceData] = useState({});

    useEffect(() => {
        const foodfluenceCollectionRef = collection(firestore, "influencers");

        const unsubscribe = onSnapshot(foodfluenceCollectionRef, (snapshot) => {
            const fetchedFoodfluenceData = {};
            snapshot.forEach((docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    fetchedFoodfluenceData[docSnap.id] = { id: docSnap.id, ...data };
                }
            });
            setFoodFluenceData(fetchedFoodfluenceData);
        });

        // Cleanup the listener when the component is unmounted
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <FoodFluenceDataContext.Provider value={foodfluenceData}>
            {children}
        </FoodFluenceDataContext.Provider>
    );
};

export default FoodFluenceDataProvider;
