import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, IconButton, Input, VStack, Image, Text,useDisclosure, Modal, ModalOverlay,
  ModalContent,  ModalBody, } from '@chakra-ui/react';
import { RepeatIcon, AttachmentIcon } from '@chakra-ui/icons';
import { uploadTouchpointDesign } from 'services/firebaseService';
import { saveAs } from 'file-saver';
import { MdUpload,MdFindReplace,MdFileDownload } from "react-icons/md";
import { BsBox } from 'react-icons/bs';


interface FileUploaderProps {
  clientID: string;
  touchpointID: string;
  touchpointDesign: string | null;
}

const FileUploader: React.FC<FileUploaderProps> = ({ clientID, touchpointID, touchpointDesign }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    onOpen();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const arrayBuffer = await file.arrayBuffer(); // Convert file to ArrayBuffer
      const uint8Array = new Uint8Array(arrayBuffer); // Convert ArrayBuffer to Uint8Array

      await uploadTouchpointDesign(clientID, touchpointID, uint8Array); // Upload Uint8Array to Firebase
    }
  };

  const handleDownload = () => {
    if (touchpointDesign) {
      saveAs(touchpointDesign, touchpointID+".jpg");
    } else {
      console.error('No file data available to download');
    }
  };

  const handleFileSelect = () => {
    // Trigger file input click programmatically
    inputRef.current?.click();
  };

  return (
    <Box justifyContent={"center"}>
       <Input
            type="file"
            ref={inputRef}
            display="none"
            onChange={handleFileChange}
          />
      {!touchpointDesign  ? (
        <VStack flexDirection={"row"} >
         
          <Button
           variant="ghost"
            onClick={() => inputRef.current?.click()}
            style={{ display: 'flex', alignItems: 'center', padding: 0 }}
            >
             <Text>
            Upload
            </Text>
            <MdUpload />
           
          </Button>
        </VStack>
      ) : (
        
        <VStack flexDirection={"row"}>
        <Box maxW="100px">
        <Image src={touchpointDesign} alt="Uploaded Image" onClick={() => handleImageClick(touchpointDesign)}
          cursor="pointer" />
        <Modal isOpen={isOpen} onClose={onClose} >
        <ModalOverlay/>
        <ModalContent maxW="50%" maxH="50%">
          <ModalBody p={0}>
            {selectedImage && (
              <Image src={selectedImage} alt="Expanded Image" objectFit="contain" />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
        </Box>
        <Button
         variant="ghost"
            onClick={handleDownload}
          >
            <MdFileDownload/>
          </Button>
       
          <Button
           variant="ghost"
           onClick={() => inputRef.current?.click()}
          >
            <MdFindReplace/>

          </Button>
        </VStack>
      )}
    </Box>
  );
};

export default FileUploader;
