import React, { useContext, useState } from "react";
import {
    Text,
    Flex,
    Box,
    Stack,
    Radio,
    RadioGroup,
    Link,
    Spinner,
} from "@chakra-ui/react";
import { CustomersDataContext } from "contexts/customersDataContext";
import { CustomerGroupsContext } from "contexts/customerGroupsDataContext";
import { NavLink } from "react-router-dom";

interface OfferTypesProps {
    inputValues: {
        isPrivate: false;
        isInfluencerOffer: false;
        isCustomerOffer: false;
        privateOfferPhoneList: any[];
        influencerPhoneNumbers: any[];
        chosenCustomerGroups: any[];
        quantity?: number;
        minimumFollowerCount: number
    };
    onInputChange: (
        fieldName: keyof OfferTypesProps["inputValues"],
        value: any
    ) => void;
    data: any;
}

const OfferType: React.FC<OfferTypesProps> = ({
    inputValues,
    onInputChange,
    data: additionalDetails,
}) => {
    const mapDefaultValue = (values: any): string => {
        return values.isPrivate ? "isPrivate" : values.isInfluencerOffer ? "isInfluencerOffer" : values.isCustomerOffer ? "isCustomerOffer" : null

    }
    const [defaultSelectRadio, setDefaultSelectedRadio] = useState<string>(mapDefaultValue(inputValues))
    const { setCustomerGroups, setInfluencers, clientID, isEditing } = additionalDetails;
    const [showInfluencerErrorMessage, setShowInfluencerErrorMessage] =
        useState(false);
    const [showPrivateErrorMessage, setShowPrivateErrorMessage] = useState(false);
    const customerGroupsData = useContext(CustomerGroupsContext);
    const customerGroupsDataList = Object.values(
        Object.values(customerGroupsData)[1]
    );

    const { data: customersData, isLoading } = useContext(CustomersDataContext);

    const customerData = customersData[clientID];

    let hasInfluencers = false;

    if (customerData) {
        hasInfluencers = Object?.keys(customerData)?.filter((key) => key !== "id").some((phoneNumber) => {
            const customer = customerData[phoneNumber];
            return customer.isInfluencer;
        });
    }
    const customerGroupsDataListCleaned = Object?.values(
        customerGroupsDataList
    )?.filter((customerGroup: any) => {
        return customerGroup.clientID === clientID;
    });

    const handleOfferTypeChange = (offerType: string) => {
        onInputChange("minimumFollowerCount", inputValues.minimumFollowerCount)
        switch (offerType) {
            case "isCustomerOffer":
                setDefaultSelectedRadio(offerType)
                onInputChange("isCustomerOffer", true);
                onInputChange("isPrivate", false);
                onInputChange("isInfluencerOffer", false);
                onInputChange("quantity", isEditing ? inputValues.quantity : 0);
                // handled temporary
                onInputChange("chosenCustomerGroups", []);
                onInputChange("privateOfferPhoneList", []);
                onInputChange("minimumFollowerCount", 100);
                setShowInfluencerErrorMessage(false);
                setShowPrivateErrorMessage(false);
                break;
            case "isPrivate":
                if (
                    customerGroupsDataListCleaned?.length
                ) {
                    // setIsPrivateOffer(isChecked);
                    onInputChange("minimumFollowerCount", 0);
                    setDefaultSelectedRadio(offerType)
                    onInputChange("isPrivate", true)
                    onInputChange("isCustomerOffer", false);
                    onInputChange("isInfluencerOffer", false);
                    setShowPrivateErrorMessage(false);
                    onInputChange("influencerPhoneNumbers", []);
                    onInputChange("privateOfferPhoneList", []);
                } else {
                    setShowPrivateErrorMessage(true);
                    onInputChange("minimumFollowerCount", 0);
                    onInputChange("isPrivate", false)
                    onInputChange("isCustomerOffer", false);
                    onInputChange("isInfluencerOffer", false);
                    setDefaultSelectedRadio("")
                }
                setShowInfluencerErrorMessage(false);
                break;
            case "isInfluencerOffer":
                if (hasInfluencers) {
                    setDefaultSelectedRadio(offerType)
                    onInputChange("isInfluencerOffer", true)
                    onInputChange("minimumFollowerCount", 0);
                    onInputChange("isPrivate", false)
                    onInputChange("isCustomerOffer", false);
                    setShowInfluencerErrorMessage(false);
                    onInputChange("chosenCustomerGroups", []);
                    // onInputChange("privateOfferPhoneList", []);

                } else {
                    setShowInfluencerErrorMessage(true);
                    onInputChange("isPrivate", false)
                    onInputChange("minimumFollowerCount", 0);
                    onInputChange("isCustomerOffer", false);
                    onInputChange("isInfluencerOffer", false);
                    setDefaultSelectedRadio("")
                }
                setShowPrivateErrorMessage(false);
                break;

            default:
                setShowInfluencerErrorMessage(false);
                setDefaultSelectedRadio("")
                setShowPrivateErrorMessage(false);
                onInputChange("isPrivate", false)
                onInputChange("isCustomerOffer", false);
                onInputChange("isInfluencerOffer", false);
                break;
        }
    };
    // check if data is loading or not
    if (isLoading || customerGroupsData?.isLoading) {
        return (
            <Box>
                <Flex justifyContent="center" alignItems="center" height="200px">
                    <Spinner size="xl" />
                </Flex>
            </Box>
        );
    }


    return (
        <Box py="60px">
            <RadioGroup value={defaultSelectRadio} defaultValue="" onChange={handleOfferTypeChange}>
                <Stack spacing={"60px"} direction="column">
                    <Box>
                        <Radio
                            variant="chakra_radio_light"
                            size="radio_lg"
                            value="isCustomerOffer"
                        // onChange={(e) => handleOfferTypeChange("isCustomerOffer", e.target.checked)}
                        // isChecked={inputValues.isCustomerOffer}
                        // checked={inputValues.isCustomerOffer}
                        >
                            <Flex flexFlow="column" ml="14px">
                                <Text fontSize="chakra_lg" fontWeight="500" color="#101828">
                                    Customer Offer
                                </Text>
                                <Text fontSize="chakra_sm" fontWeight="400" color="#667085">
                                    Allowed to be accepted and completed by anyone who meets the
                                    minimum follower requirement
                                </Text>
                            </Flex>
                        </Radio>
                    </Box>
                    <Box>
                        <Radio
                            variant="chakra_radio_light"
                            size="radio_lg"
                            value="isPrivate"
                        // onChange={(e) => handleOfferTypeChange("isPrivate", e.target.checked)}
                        // isChecked={inputValues.isPrivate}
                        // checked={inputValues.isPrivate}

                        >
                            <Flex flexFlow="column" ml="14px">
                                <Text fontSize="chakra_lg" fontWeight="500" color="#101828">
                                    Private Offer
                                </Text>
                                <Text fontSize="chakra_sm" fontWeight="400" color="#667085">
                                    Only available to the customer group(s) you select on the next
                                    step
                                </Text>
                            </Flex>
                        </Radio>
                        {showPrivateErrorMessage && (
                            <Text color="red" fontSize="chakra_xs" pl="53px" mt={'5px'}>
                                Unable to make private offer. Must{" "}
                                <Link
                                    as={NavLink}
                                    to="/admin/community/create-customer-group/"
                                    color="red"
                                    textDecoration="underline"
                                >
                                    create a customer group
                                </Link>{" "}
                                first.
                            </Text>
                        )}
                    </Box>
                    <Box>
                        <Radio
                            variant="chakra_radio_light"
                            size="radio_lg"
                            value="isInfluencerOffer"
                            // onChange={(e) => handleOfferTypeChange("isInfluencerOffer", e.target.checked)}
                            // isChecked={inputValues.isInfluencerOffer}
                            checked={inputValues.isInfluencerOffer}
                        >
                            <Flex flexFlow="column" ml="14px">
                                <Text fontSize="chakra_lg" fontWeight="500" color="#101828">
                                    Influencer Offer
                                </Text>
                                <Text fontSize="chakra_sm" fontWeight="400" color="#667085">
                                    Sent privately to the influencers you choose on the next step
                                </Text>
                            </Flex>
                        </Radio>
                        {!hasInfluencers && showInfluencerErrorMessage && (
                            <Text color="red" fontSize="chakra_xs" pl="53px" mt={'5px'}>
                                Unable to make influencer offer. Must{" "}
                                <Link
                                    as={NavLink}
                                    to={`/admin/community`}
                                    color="red"
                                    textDecoration="underline"
                                >
                                    invite influencers
                                </Link>{" "}
                                first.
                            </Text>
                        )}
                    </Box>
                </Stack>
            </RadioGroup>
        </Box >
    );
};

export default OfferType;
