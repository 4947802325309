import { useEffect, useState } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../firebase";

interface UserMetaData {
  [key: string]: any; // Adjust according to your actual data structure
}

const useFacebookMetaData = (params: { userId: string }) => {
  const { userId } = params;
  const metaInfoCollectionRef = collection(firestore, "metaInformation");
  const [fbMetaData, setFbMetaData] = useState<UserMetaData>({});
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!userId) {
      setFbMetaData({});
      setLoading(false);
      return;
    }

    setLoading(true);

    const queryRef = query(
      metaInfoCollectionRef,
      where("userId", "==", userId)
    );

    const unsubscribe = onSnapshot(
      queryRef,
      (querySnapshot) => {
        if (!querySnapshot.empty) {
          const fbMetaData = querySnapshot.docs.reduce((acc, doc) => {
            acc[doc.id] = doc.data();
            return acc;
          }, {} as UserMetaData);

          setFbMetaData(fbMetaData);
        } else {
          setFbMetaData({});
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error while fetching offer data:", error);
        setLoading(false);
      }
    );

    // Clean up the listener on component unmount
    return () => {
      unsubscribe();
    };
  }, [userId]);

  return {
    isLoading,
    fbMetaData,
  };
};

export default useFacebookMetaData;
