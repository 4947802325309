import React, { createContext, useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";

export const AutopilotDataContext = createContext();

export const AutopilotDataProvider = ({ children, clientIds }) => {
    const [autopilotData, setAutopilotData] = useState({});

    useEffect(() => {
        if (!clientIds || clientIds.length === 0) return;

        const fetchedAutopilotData = {};

        const unsubscribes = [];

        // Listen for real-time updates for each autopilot id in clientIds
        clientIds.forEach((autopilotId) => {
            const autopilotDocRef = doc(firestore, "autopilot", autopilotId);
            const autopilotUnsubscribe = onSnapshot(autopilotDocRef, (docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    fetchedAutopilotData[autopilotId] = { id: docSnap.id, ...data };
                    setAutopilotData((prevData) => ({
                        ...prevData,
                        [autopilotId]: fetchedAutopilotData[autopilotId],
                    }));
                }
            });
            unsubscribes.push(autopilotUnsubscribe);

        });

        console.log("fetchedAutopilotData", fetchedAutopilotData);

        // Cleanup the listeners when the component is unmounted
        return () => {
            unsubscribes.forEach((unsubscribe) => unsubscribe());
        };
    }, [clientIds]);

    return (
        <AutopilotDataContext.Provider value={autopilotData}>
            {children}
        </AutopilotDataContext.Provider>
    );
};

export default AutopilotDataProvider;
