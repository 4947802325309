import React, { useContext, useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    Flex,
    Box,
    Spinner,
    Checkbox,
    Image,
    Link,
    Avatar,
} from "@chakra-ui/react";
import { CustomersDataContext } from "contexts/customersDataContext";

interface AddCustomerPopupProps {
    isOpen: boolean;
    onClose: () => void;
    showToast: () => void; // Add showToast prop

    handleAddCustomer?: (customerList: any, handleAddCustomer: boolean) => void;
    // selectedCustomers: any[]
    clientID: string
    privateOfferPhoneList: any[]
    isEditing: boolean,
    offerDetail: any
}

const AddCustomerPopup: React.FC<AddCustomerPopupProps> = ({
    isOpen, clientID, onClose, handleAddCustomer, showToast, privateOfferPhoneList, isEditing, offerDetail
}) => {

    const handleClose = () => {
        onClose();
    };
    const { data: customersData, isLoading: isCustomersLoading } = useContext(CustomersDataContext);

    const filteredCustomersData = customersData[clientID] ?? [];

    const customerMap = new Map();

    Object.keys(filteredCustomersData)
        .filter((key) => key !== "id")
        .forEach((phoneNumber) => {

            const customer = filteredCustomersData[phoneNumber];

            /**
             * As of now comment this condition because we need both influencers and private customer
             */
            // if (customer.isInfluencer) {
            //     return;// Skip this customer if not an influencer when in influencer mode
            // }

            if (customerMap.has(phoneNumber)) {
                const existingCustomer = customerMap?.get(phoneNumber);
                existingCustomer.acceptedOffers = Array.from(
                    new Set([
                        ...(existingCustomer?.acceptedOffers ?? []),
                        ...(customer?.acceptedOffers ?? []),
                    ])
                );
                existingCustomer.postedOffers = Array.from(
                    new Set([
                        ...(existingCustomer?.postedOffers ?? []),
                        ...(customer?.postedOffers ?? []),
                    ])
                );
                existingCustomer.redeemedOffers = Array.from(
                    new Set([
                        ...(existingCustomer?.redeemedOffers ?? []),
                        ...(customer?.redeemedOffers ?? []),
                    ])
                );
            } else {
                customerMap?.set(phoneNumber, { ...customer, "phoneNumber": phoneNumber });
                // allInfluencers.push(phoneNumber);
            }
        });

    // Sort by number of posts first, then by number of followers
    const customers = Array.from(customerMap?.values())
        .sort((a, b) => {
            const redeemedOffersDiff =
                b?.redeemedOffers?.length - a?.redeemedOffers?.length;
            if (redeemedOffersDiff !== 0) return redeemedOffersDiff;

            const postedOffersDiff = b?.postedOffers?.length - a?.postedOffers?.length;
            if (postedOffersDiff !== 0) return postedOffersDiff;

            const acceptedOffersDiff =
                b?.acceptedOffers.length - a?.acceptedOffers.length;
            if (acceptedOffersDiff !== 0) return acceptedOffersDiff;
            return b?.instagramFollowerCount - a?.instagramFollowerCount;
        });

    const [selectedCustomer, SetSelectedCustomer] = useState<any>(privateOfferPhoneList?.length ? privateOfferPhoneList : [])
    const [allCustomerSelected, setAllCustomerSelected] = useState(privateOfferPhoneList?.length === customers?.length);

    const handleCheckboxChange = (phoneNumber: string, isChecked: boolean) => {
        if (isChecked) {
            SetSelectedCustomer((prevList: string[]) => [
                ...prevList,
                phoneNumber,
            ]);
        } else {
            const updatedList = selectedCustomer.filter(
                (item: string) => item !== phoneNumber
            );
            SetSelectedCustomer(updatedList);
        }
    };

    // Effect to check if all customers are selected
    useEffect(() => {
        if (selectedCustomer?.length === customers?.length) {
            setAllCustomerSelected(true);
        } else {

            setAllCustomerSelected(false);
        }
    }, [selectedCustomer]);


    const toggleSelectAll = () => {
        if (allCustomerSelected) {
            SetSelectedCustomer([]);
        } else {
            const allCustomers = customers?.map((customer: any) => customer?.phoneNumber);
            SetSelectedCustomer(allCustomers);
        }
    };
    if (isCustomersLoading) {
        return (
            <Box>
                <Flex justifyContent="center" alignItems="center" height="200px">
                    <Spinner size="xl" />
                </Flex>
            </Box>
        );
    }


    if (!customers.length && !isCustomersLoading) {
        showToast();
        handleClose()
        return null;
    }

    return (
        <Modal isOpen={isOpen} onClose={handleClose} isCentered>
            <ModalOverlay />
            <ModalContent p={'34px 42px 42px 42px'} borderRadius='16px' maxW="700px">
                <ModalHeader p={'0px'} mb={'51px'}>
                    <Flex justifyContent={'space-between'} alignItems={'center'}>
                        <Text fontSize={'chakra_2xl'} fontWeight={'500'}>Add customers to the private offer</Text>
                        {
                            !isEditing && <Button variant="chakra_btn_white"
                                size="chakra_md"
                                border={'1px solid #EFEFEF'} onClick={toggleSelectAll}>{allCustomerSelected ? "Deselect All" : "Select All"}</Button>
                        }
                    </Flex>
                </ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody width={'700px'} marginLeft={'-42px'} p={'0px 42px 0px 20px'} mb="58px" maxH={'484px'} overflow={'auto'}>

                    {customers?.map((customer, index) => (
                        <Box
                            key={`customer-${index}`}
                            width="100"
                            borderBottom='1px' borderColor={'#EFEFEF'} py="24px" pl="36px" pr="0"
                        >
                            <Flex justifyContent={'space-between'} alignItems={'center'} gap={'20px'}>
                                <Flex gap="14px" w="100%" alignItems={'center'}>
                                    <Avatar
                                        boxSize='72px'
                                        borderRadius='full'
                                        objectFit='cover'
                                        name={customer.firstName}
                                        src={customer.profilePicURL}
                                    />

                                    <Box display={'flex'} flexDirection={'column'} gap={'5px'} alignItems={'center'}>
                                        <Text fontSize="22.5px" fontWeight="semibold">
                                            {customer.firstName === ""
                                                ? customer.phoneNumber
                                                : customer.firstName}
                                        </Text>
                                        <Box display={'flex'} alignItems={'flex-start'} gap="11px" w="100%">
                                            <Link>
                                                <Image
                                                    width={'18px'}
                                                    height={'18px'}
                                                    objectFit='cover'
                                                    cursor={"default"}
                                                    src='/instagram.png'
                                                    alt='instagarm'
                                                />
                                            </Link>
                                            <Link>
                                                <Image
                                                    width={'16px'}
                                                    height={'18px'}
                                                    objectFit='cover'
                                                    cursor={"default"}

                                                    src='/tiktok.png'
                                                    alt='tiktok'
                                                />
                                            </Link>
                                            {/* <Text color="gray.600" fontSize="15px">
                                                @{customer.instagramHandle}
                                            </Text> */}
                                        </Box>

                                    </Box>

                                </Flex>
                                <Checkbox
                                    variant="chakra_checkbox_dark_sm" size="checkbox_sm"
                                    // isChecked={true}
                                    disabled={isEditing && offerDetail?.privateOfferPhoneList?.includes(customer?.phoneNumber)}
                                    isChecked={selectedCustomer?.includes(customer?.phoneNumber)}
                                    onChange={() => {
                                        handleCheckboxChange(customer?.phoneNumber, !selectedCustomer?.includes(customer?.phoneNumber))
                                    }}
                                />
                            </Flex>
                        </Box>

                    ))}

                </ModalBody>

                <ModalFooter justifyContent={'center'} pt={'0px'} px={'0'} pb={'0'} >
                    <Button
                        width={'315px'}
                        variant="chakra_btn_secondary"
                        size="chakra_xs"
                        // disabled={!selectedCustomer.length}
                        // cursor={!selectedCustomer.length ? "pointer" : ""}
                        _hover={{
                            transform: "scale(1.05)",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                        }}
                        _focus={{
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                        }}
                        _active={{
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                        onClick={() => {
                            handleAddCustomer(selectedCustomer, allCustomerSelected)
                        }}
                    >Confirm</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddCustomerPopup;
