import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Image,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import getUgcForClient from "../../../../services/flaskService"; // Replace with the path to the function
import UGCImage from "./UGCImage";
import { getContentMetadata } from "services/firebaseService";

interface ImageGalleryProps {
  clientIDs: string[];
  columns?: number;
  filteredClientID?: string;
  filteredLocationID?: string;
  maxRows?: number;
  [x: string]: any; // This allows the component to accept other props
}

const ImageGallery: React.FC<ImageGalleryProps> = ({
  clientIDs,
  filteredClientID,
  filteredLocationID,
  maxRows,
  columns,
}) => {
  const [imageLinks, setImageLinks] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchAllUGC() {
      setIsLoading(true);
      try {
        // Map over clientIDs and create an array of promises
        const linksPromises = clientIDs.map((clientID) =>
          getUgcForClient(clientID).catch((error) => {
            console.error(
              `Error fetching image links for clientID ${clientID}:`,
              error
            );
            return []; // return an empty array on error
          })
        );

        // Use Promise.allSettled to wait for all promises to be settled (either fulfilled or rejected)
        const results = await Promise.allSettled(linksPromises);

        // Extract the image links from the fulfilled promises and ignore the rejected ones
        const links = results
          .filter((result) => result.status === "fulfilled")
          .map((result) => (result as PromiseFulfilledResult<string[]>).value)
          .flat();

        setImageLinks(links);
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      } finally {
        setIsLoading(false);
      }
    }

    async function getClientUGC(clientID: string, locationID?: string) {
      setIsLoading(true);

      let clientIDs = [clientID];

      try {
        // Map over clientIDs and create an array of promises
        const linksPromises = clientIDs.map((clientID) =>
          getUgcForClient(clientID).catch((error) => {
            console.error(
              `Error fetching image links for clientID ${clientID}:`,
              error
            );
            return []; // return an empty array on error
          })
        );

        // Use Promise.allSettled to wait for all promises to be settled (either fulfilled or rejected)
        const results = await Promise.allSettled(linksPromises);

        // Extract the image links from the fulfilled promises and ignore the rejected ones
        let links = results
          .filter((result) => result.status === "fulfilled")
          .map((result) => (result as PromiseFulfilledResult<string[]>).value)
          .flat();

        // filter on locationId if it is passed in
        if (locationID) {
          links = links.filter((link) => link.includes(`/${locationID}/`));
        }

        setImageLinks(links);
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      } finally {
        setIsLoading(false);
      }
    }

    if (!filteredClientID || filteredClientID === "") {
      console.log("Showing UGC for all accounts");
      fetchAllUGC();
    } else {
      console.log("filtered Client ID" + filteredClientID);
      getClientUGC(filteredClientID, filteredLocationID);
      // fetch specific client images
    }
  }, [clientIDs, filteredClientID]);

  const columnsLocal = columns ? columns : 5;
  const maxImages = maxRows ? maxRows * columns : undefined;
  const displayLinks = maxRows ? imageLinks.slice(0, maxImages) : imageLinks;

  console.log(displayLinks);

  if (isLoading) {
    return (
      <Center>
        {" "}
        <Spinner size="xl" mb="75px" mt="50px" />
      </Center>
    );
  }

  if (displayLinks.length > 0) {
    return (
      <SimpleGrid columns={columnsLocal} spacing="20px">
        {displayLinks.map((link, idx) => (
          <UGCImage link={link} idx={idx} key={idx} hoverPop={true} />
        ))}
      </SimpleGrid>
    );
  } else {
    return (
      <Center h="full">
        <Text fontWeight="medium" fontSize="22.5px" mt="50px" mb="100px">
          No UGC available
        </Text>
      </Center>
    );
  }
};

export default ImageGallery;
