import React, { createContext, useState, useEffect } from "react";
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";

export const CustomersDataContext = createContext();

export const CustomersDataProvider = ({ children, clientIds }) => {
  const [customersData, setCustomersData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [snapshotsReceived, setSnapshotsReceived] = useState(0);

  useEffect(() => {
    if (!clientIds || clientIds.length === 0) {
      setIsLoading(false);
      return;
    }

    const unsubscribes = [];

    const fetchUserData = async (clientId, phoneNumber) => {
      const userDocRef = doc(firestore, "users", phoneNumber);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const {
          isInfluencer = false,
          firstName,
          instagramHandle,
          instagramFollowerCount,
          profilePicURL,
          acceptedOffers,
          instagramDisplayName,
          instagramBio,
          tiktokHandle
        } = userDocSnap.data();

        // Filter the acceptedOffers to include only those with the current clientId
        const filteredAcceptedOffers = acceptedOffers.filter(
          (offer) => offer.clientId === clientId
        );

        setCustomersData((prevData) => ({
          ...prevData,
          [clientId]: {
            ...prevData[clientId],
            [phoneNumber]: {
              ...prevData[clientId][phoneNumber],
              isInfluencer: isInfluencer,
              firstName,
              instagramHandle,
              instagramFollowerCount,
              profilePicURL,
              acceptedOffers: filteredAcceptedOffers,
              rawAcceptedOffers: acceptedOffers,
              instagramDisplayName,
              instagramBio,
              tiktokHandle
            },
          },
        }));
      }
    };

    clientIds.forEach((clientId) => {
      const clientCommunityStatsDocRef = doc(
        firestore,
        "clientCommunityStats",
        clientId
      );
      const customerUnsubscribe = onSnapshot(
        clientCommunityStatsDocRef,
        (docSnap) => {
          if (docSnap.exists()) {
            const currentData = docSnap.data();
            setCustomersData((prevData) => ({
              ...prevData,
              [clientId]: { id: docSnap.id, ...currentData },
            }));

            for (const phoneNumber in currentData) {
              fetchUserData(clientId, phoneNumber);
            }
          }
          setSnapshotsReceived((prevCount) => prevCount + 1);
        }
      );
      unsubscribes.push(customerUnsubscribe);
    });

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [clientIds]);

  useEffect(() => {
    if (snapshotsReceived === clientIds?.length) {
      setIsLoading(false);
    }
  }, [snapshotsReceived, clientIds]);

  return (
    <CustomersDataContext.Provider value={{ isLoading, data: customersData }}>
      {children}
    </CustomersDataContext.Provider>
  );
};

export default CustomersDataProvider;
