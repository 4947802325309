import { mode } from '@chakra-ui/theme-tools';

export const globalStyles = {
	colors: {
		brand: {
			100: '#E6FAF5', // Modified to match green shade
			200: '#199025', // Primary brand color (#199025)
			300: '#199025', // Primary brand color (#199025)
			400: '#01B574', // Modified to match green shade
			500: '#199025', // Primary brand color (#199025)
			600: '#148C20', // Modified to match green shade
			700: '#02044A', // Unchanged
			800: '#190793', // Unchanged
			900: '#02044A'  // Unchanged
		},
		brandScheme: {
			100: '#E6FAF5', // Modified to match green shade
			200: '#01B574', // Modified to match green shade
			300: '#01B574', // Modified to match green shade
			400: '#01B574', // Modified to match green shade
			500: '#199025', // Primary brand color (#199025)
			600: '#148C20', // Modified to match green shade
			700: '#02044A', // Unchanged
			800: '#190793', // Unchanged
			900: '#02044A'  // Unchanged
		},
		brandTabs: {
			100: '#E6FAF5', // Modified to match green shade
			200: '#199025', // Primary brand color (#199025)
			300: '#199025', // Primary brand color (#199025)
			400: '#199025', // Primary brand color (#199025)
			500: '#199025', // Primary brand color (#199025)
			600: '#148C20', // Modified to match green shade
			700: '#02044A', // Unchanged
			800: '#190793', // Unchanged
			900: '#02044A'  // Unchanged
		},
		secondaryGray: {
			100: '#E0E5F2', // Unchanged
			200: '#E1E9F8', // Unchanged
			300: '#F4F7FE', // Unchanged
			400: '#E9EDF7', // Unchanged
			500: '#8F9BBA', // Unchanged
			600: '#A3AED0', // Unchanged
			700: '#707EAE', // Unchanged
			800: '#707EAE', // Unchanged
			900: '#1B2559'  // Unchanged
		},
		red: {
			100: '#FEEFEE',
			500: '#EE5D50',
			600: '#E31A1A'
		},
		blue: {
			50: '#EFF4FB',
			500: '#3965FF'
		},
		orange: {
			100: '#FFF6DA',
			500: '#FFB547'
		},
		green: {
			100: '#E6FAF5',
			500: '#01B574'
		},
		navy: {
			50: '#d0dcfb',
			100: '#aac0fe',
			200: '#a3b9f8',
			300: '#728fea',
			400: '#3652ba',
			500: '#1b3bbb',
			600: '#24388a',
			700: '#1B254B',
			800: '#111c44',
			900: '#0b1437'
		},
		gray: {
			100: '#FAFCFE',
			200: '#F4F4F4'
		}
		// Other color definitions can be left unchanged
	},
	fontSizes: {
		'chakra_xs': "12px",
		'chakra_sm': "14px",
		'chakra_md': "16px", // Update the global font size here
		'chakra_lg': "18px",
		'chakra_xl': "20px",
		'chakra_1xl': "22px",
		"chakra_2xl": "24px",
		"chakra_3xl": "30px",
		"chakra_4xl": "32px",
		"chakra_5xl": "36px",
		"chakra_6xl": "40px",
	},
	styles: {
		global: (props: any) => ({
			body: {
				overflowX: 'hidden',
				bg: mode('secondaryGray.300', 'navy.900')(props),
				fontFamily: 'Poppins',
				letterSpacing: '-0.5px',

			},
			input: {
				color: 'gray.700'
			},
			html: {
				fontFamily: 'Poppins'
			}
		})
	}
};
