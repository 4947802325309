/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import {
  Text,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  useRadioGroup,
  HStack,
  Link,
  Center,
  SimpleGrid,
  Spinner,
  useToast,
  Button,
  List,
  ListItem
} from "@chakra-ui/react";
import RadioCard from "../../offers/components/Radiocard";
import MannualAddCodesPopup from "../models/MannualAddCodesModel";
import CSVUploader from "./CsvUploader";
import { Download } from "components/icons/Icons";

interface UploadDiscountCodesProps {
  inputValues: {
    isUploadCodes: boolean;
    batchCodes?: string[];
    ecommerceDiscountCode: string;
    isPrivate: false;
    isInfluencerOffer: false;
    privateOfferPhoneList: any[];
    influencerPhoneNumbers: any[];
    isEvergreenCode: boolean;
    typeOfOffer: string;
    quantity: number;
    isCustomerOffer: boolean;
    isCash: boolean;
    isCashBack: boolean;
    percentOrCashOff: boolean;
    expirationLimit: boolean;
    compensation: boolean
  };
  onInputChange: (
    fieldName: keyof UploadDiscountCodesProps["inputValues"],
    value: any
  ) => void;
  data: any;
}

const UploadDiscountCodes: React.FC<UploadDiscountCodesProps> = ({
  inputValues,
  onInputChange,
  data: additionalDetails,
}) => {
  const toast = useToast();
  const offerType = inputValues?.isCustomerOffer ? "Customer" : inputValues?.isPrivate ? "Private" : inputValues?.isInfluencerOffer ? "Influencers" : ""
  const { accountData, clientIntegrations, isEditing, offerDetail } = additionalDetails;
  const { instagramHandle, offers = [], accountName } = accountData;
  const [isMannualEnterCode, setIsMannualEnterCode] = useState(false);
  const [evergreenCodeError, setEvergreenCodeError] = useState("");
  const [ecommerceDiscountCode, setEcommerceDiscountCode] = useState(
    inputValues.ecommerceDiscountCode || ""
  );

  const [isLoading, setLoading] = useState(false);
  const options = ["Yes", "No"];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: "No",
    onChange: console.log,
  });

  const group = getRootProps();
  const handleBatchCodeChange = (value: string) => {
    // Handle change here

    onInputChange("isUploadCodes", value === "Yes" ? true : false);

    if (value === "No") {
      onInputChange("batchCodes", []);
    } else {
      onInputChange("typeOfOffer", "credit");
    }
  };

  const handleEvergreenCodeChange = (value: string) => {

    onInputChange("isEvergreenCode", value === "Yes" ? true : false);
    if (value === "No") {
      onInputChange("ecommerceDiscountCode", "");
      setEcommerceDiscountCode("");
    }
  };

  // hide for compensation type
  useEffect(() => {
    if (!isEditing && (inputValues?.isEvergreenCode || inputValues?.isUploadCodes)) {
      onInputChange("isCash", false);
      onInputChange("isCashBack", false);
      onInputChange("percentOrCashOff", "");
      onInputChange("expirationLimit", 30);
      onInputChange("compensation", 0);
      onInputChange("typeOfOffer", "credit");
    }

  }, [inputValues.isEvergreenCode, inputValues.isUploadCodes])

  const handleClose = () => {
    setIsMannualEnterCode(false);
  };


  const checkCodesForPrivateAndInfluencer = (codes?: any) => {
    if (inputValues.isCustomerOffer) {
      return false
    }
    const totalCustomers = inputValues?.isPrivate ? inputValues.privateOfferPhoneList?.length : inputValues.influencerPhoneNumbers?.length;
    if (inputValues.batchCodes?.length) {
      return [...codes, ...inputValues.batchCodes]?.length < totalCustomers;
    } else {
      return codes?.length < totalCustomers;
    }
  }

  const handleAddCodes = (codes) => {
    if (!codes.length) {
      setIsMannualEnterCode(false);
      return;
    }
    const data = new Set(codes);
    const alreadyExistsCode = inputValues?.batchCodes?.filter((element) =>
      data?.has(element)
    );
    // const checkCodes = checkCodesForPrivateAndInfluencer([...new Set([
    //   ...(inputValues.batchCodes || []),
    //   ...(codes || []),
    //   ...(offerDetail?.batchCodes || [])
    // ])]);
    // if (checkCodes) {
    //   toast({
    //     title: "Validation Errors",
    //     description: "Number of total codes should be greater then number of total customers/influencers.",
    //     status: "error",
    //     duration: 3000,
    //     isClosable: true,
    //   });
    //   setIsMannualEnterCode(false);
    //   return;
    // }

    if (codes?.length > 0) {
      const codesLength = codes?.length || 0;
      const alreadyExistsCodeLength = alreadyExistsCode?.length || 0;
      //       const privateOfferPhoneListLength = inputValues.privateOfferPhoneList?.length || 0;
      //       const influencerPhoneNumbersLength = inputValues.influencerPhoneNumbers?.length || 0;
      //       const totalCodes = [...new Set([...inputValues?.batchCodes, ...codes, ...(offerDetail?.batchCodes || [])])]?.length || 0;
      //       const title = inputValues.isCustomerOffer ? `${codesLength - alreadyExistsCodeLength} code(s) added` : `${codesLength - alreadyExistsCodeLength} code(s) added.
      // ${privateOfferPhoneListLength ? "Private customer: " + privateOfferPhoneListLength + "\nUploaded code(s): " + totalCodes : ""}
      // ${influencerPhoneNumbersLength ? "Influencer's customer: " + influencerPhoneNumbersLength + "\nUploaded code(s): " + totalCodes : ""}`;

      toast({
        title: `${codesLength - alreadyExistsCodeLength} code(s) added`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    onInputChange("batchCodes", [
      ...new Set([...inputValues?.batchCodes, ...codes]),
    ]);
    setIsMannualEnterCode(false);
  };

  const handleEcommerceDiscountCodeChange = (event: any) => {
    const value = event?.target?.value?.trim();
    // Check if the element contains only numbers and characters
    setEcommerceDiscountCode(value);
    const emojiRegex =
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
    // const inputPassword = e.target.value?.trim()
    let error = "";

    if (value?.length < 3) {
      error = "Invalid evergreen code, minimum 3 characters are allowed.";
    } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
      error =
        "Invalid evergreen code, only characters and numbers are allowed.";
    } else if (emojiRegex?.test(value)) {
      error = "Invalid code, please try with a different one.";
    }

    if (error) {
      setEvergreenCodeError(error);
      onInputChange("ecommerceDiscountCode", ""); // Update global state only if valid
    } else {
      setEvergreenCodeError("");
      // Handle change here

      if (isEditing && offerDetail?.isUploadCodes && inputValues?.isEvergreenCode && inputValues.quantity > 0 && inputValues?.isCustomerOffer) {
        console.log("quantity")
        onInputChange("quantity", 0);
      }
      onInputChange("ecommerceDiscountCode", value); // Update global state only if valid
    }
  };

  // remove batch code
  const handleRemoveCode = (code) => {
    const filterCodes = inputValues?.batchCodes?.filter((c) => c != code);
    onInputChange("batchCodes", filterCodes);
    return;
  };

  const handleDownload = () => {
    const link = document?.createElement("a");
    link.href = "/discountCodes.csv"; // Adjust the path if necessary
    link.download = "discountCodes.csv";
    document.body?.appendChild(link);
    link.click();
    document?.body?.removeChild(link);
  };

  return (
    <Box py="60px">
      <Flex flexFlow={"column"} gap={"60px"}>
        <FormControl display="flex" alignItems="center" gap="20px">
          <FormLabel m="0" variant={"chakra_label_default"}>
            Evergreen Code
          </FormLabel>
          <HStack {...group} gap={"24px"}>
            {options.map((value) => {
              const radio = getRadioProps({ value });
              return (
                <RadioCard
                  key={value}
                  isDisabled={inputValues?.isUploadCodes}
                  onChange={() => handleEvergreenCodeChange(value)}
                  isChecked={inputValues?.isEvergreenCode === (value === "Yes")} // Set checked state based on inputValues.isPrerequisite
                >
                  {value}
                </RadioCard>
              );
            })}
          </HStack>
        </FormControl>
        {inputValues.isEvergreenCode && (
          <FormControl display="flex" alignItems="center" gap="20px">
            <FormLabel m="0" variant={"chakra_label_default"}>
              Evergreen Code
            </FormLabel>
            <Box w={"100%"}>
              <Input
                // width="100%"
                variant="chakra_input_default"
                size="chakra_md_medium"
                fontSize={"chakra_sm"}
                fontWeight={"500"}
                type="text"
                value={ecommerceDiscountCode}
                onChange={handleEcommerceDiscountCodeChange}
                maxLength={35}
              />
              {evergreenCodeError && evergreenCodeError?.length > 0 && (
                <Text textColor={"red"} mt="3px" fontSize={"12px"}>
                  {evergreenCodeError}
                </Text>
              )}
            </Box>
          </FormControl>
        )}
        <FormControl display="flex" alignItems="center" gap="20px">
          <FormLabel m="0" variant={"chakra_label_default"}>
            Upload Batch Codes
          </FormLabel>
          <HStack {...group} gap={"24px"}>
            {options.map((value) => {
              const radio = getRadioProps({ value });
              return (
                <RadioCard
                  key={value}
                  isDisabled={inputValues.isEvergreenCode}
                  onChange={() => handleBatchCodeChange(value)}
                  isChecked={inputValues?.isUploadCodes === (value === "Yes")} // Set checked state based on inputValues.isPrerequisite
                >
                  {value}
                </RadioCard>
              );
            })}
          </HStack>
        </FormControl>


        {inputValues.isUploadCodes && (
          <>
            <FormControl display="flex" alignItems="center" gap="20px">
              <FormLabel m="0" variant={"chakra_label_default"}>
                Upload Codes
              </FormLabel>
              <Box w={"100%"}>
                <CSVUploader
                  onInputChange={onInputChange}
                  inputValues={inputValues}
                  setLoading={setLoading}
                  existingCodes={offerDetail?.batchCodes ?? []}
                  isEdit={isEditing}
                  checkCodesForPrivateAndInfluencer={checkCodesForPrivateAndInfluencer}
                />
                <Center width="514px" mt="11px" position="relative">
                  <Link
                    textDecorationLine={"underline"}
                    fontSize="14px"
                    onClick={() => {
                      setIsMannualEnterCode(true);
                    }}
                  >
                    Manually Enter Codes
                  </Link>
                  <Link onClick={handleDownload} position="absolute" top={'0'} right={'0'} fontSize="14px">
                    <Download h='16px' w='16px' me='6px' /> Template
                  </Link>
                </Center>
                {/* <Button onClick={handleDownload}>Download Template</Button> */}
              </Box>
            </FormControl>

            {
              inputValues.isUploadCodes && (
                <FormControl display="flex" alignItems="center" gap="20px">
                  <FormLabel m="0" variant={"chakra_label_default"}>
                    Summary
                  </FormLabel>
                  <Box w={"100%"} >
                    <Box backgroundColor={"#F4F4F4"}
                      borderRadius="lg"
                      width="514px"
                      py="30px"
                      px="24px">
                      <List spacing={3}>
                        <ListItem>
                          <Text as='b' width={'106px'} display="inline-block">Offer Type :</Text> {offerType}
                        </ListItem>
                        <ListItem>
                          <Text as='b' width={'106px'} display="inline-block">Total Codes:</Text> {inputValues.batchCodes?.length + (offerDetail?.batchCodes?.length ?? 0)}
                        </ListItem>
                        <ListItem>
                          {
                            inputValues.isPrivate && inputValues?.privateOfferPhoneList?.length > 0 && (
                              <>
                                <Text as='b' width={'106px'} display="inline-block">Customers:</Text>{inputValues?.privateOfferPhoneList?.length}
                              </>
                            )
                          }
                          {
                            inputValues.isInfluencerOffer && inputValues?.influencerPhoneNumbers?.length > 0 && (
                              <>
                                <Text as='b' width={'106px'} display="inline-block">Influencers:</Text>{inputValues?.influencerPhoneNumbers?.length}
                              </>
                            )
                          }
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                </FormControl>
              )
            }


            {
              isEditing && offerDetail?.batchCodes?.length > 0 && (
                <FormControl display="flex" alignItems="start" gap="20px">
                  <FormLabel m="0" variant={"chakra_label_default"}>
                    Existing Codes
                  </FormLabel>
                  <Box w={"100%"} position={"relative"}>

                    <Box mx="-12px">
                      <Box maxHeight="140px" overflowY={"auto"} px="12px">
                        <SimpleGrid columns={5} spacing={15}>
                          {offerDetail.batchCodes?.length > 0 &&
                            offerDetail?.isUploadCodes &&
                            offerDetail?.batchCodes?.map((code, index) => (
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                key={`idx-${index}`}
                                fontSize="14px"
                                fontWeight="500"
                                lineHeight={"24px"}
                                color={"#1A1D1F"}
                                ml="0"
                                bg={"#F4F4F4"}
                                borderRadius="5px"
                                px="23px"
                                py="3px"
                              >
                                <Text
                                  width={"calc(100% - 12px)"}
                                  textOverflow={"ellipsis"}
                                  overflow={"hidden"}
                                  whiteSpace="nowrap"
                                >
                                  {code}
                                </Text>
                              </Box>
                            ))}
                        </SimpleGrid>
                      </Box>
                    </Box>
                  </Box>
                </FormControl>
              )
            }


            {isLoading ? (
              <Flex justifyContent="center" alignItems="center" height="200px">
                <Spinner size="sm" />
              </Flex>
            ) : (
              inputValues?.batchCodes?.length > 0 && (
                <FormControl display="flex" alignItems="start" gap="20px">
                  <FormLabel m="0" variant={"chakra_label_default"}>
                    Uploaded Codes
                  </FormLabel>
                  <Box w={"100%"} position={"relative"}>
                    <Link
                      textDecorationLine={"underline"}
                      fontSize="14px"
                      position={"absolute"}
                      top={"-30px"}
                      right={"0px"}
                      onClick={() => {
                        onInputChange("batchCodes", []);
                      }}
                    >
                      Clear Codes
                    </Link>
                    <Box mx="-12px">
                      <Box maxHeight="140px" overflowY={"auto"} px="12px">
                        <SimpleGrid columns={5} spacing={15}>
                          {inputValues.batchCodes?.length > 0 &&
                            inputValues.isUploadCodes &&
                            inputValues.batchCodes?.map((code, index) => (
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                key={`idx-${index}`}
                                fontSize="14px"
                                fontWeight="500"
                                lineHeight={"24px"}
                                color={"#1A1D1F"}
                                ml="0"
                                bg={"#F4F4F4"}
                                borderRadius="5px"
                                px="23px"
                                py="3px"
                              >
                                <Text
                                  width={"calc(100% - 12px)"}
                                  textOverflow={"ellipsis"}
                                  overflow={"hidden"}
                                  whiteSpace="nowrap"
                                >
                                  {code}
                                </Text>
                                <Text
                                  cursor={"pointer"}
                                  fontSize="14px"
                                  color={"#8C8E95"}
                                  onClick={() => {
                                    handleRemoveCode(code);
                                  }}
                                >
                                  X
                                </Text>
                              </Box>
                            ))}
                        </SimpleGrid>
                      </Box>
                    </Box>
                  </Box>
                </FormControl>
              )
            )}
          </>
        )}
      </Flex>
      {isMannualEnterCode && (
        <MannualAddCodesPopup
          isOpen={isMannualEnterCode}
          onClose={handleClose}
          handleAddCodes={handleAddCodes}
          modelTitle="Add Codes"
          batchCodes={inputValues.batchCodes}
          existingCodes={offerDetail?.batchCodes ?? []}
        />
      )}
    </Box>
  );
};

export default UploadDiscountCodes;
