import {
  Box,
  Flex,
  Text,
  Heading,
  useToast,
  Button,
  Textarea,
  Spinner,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { saveDmTemplate } from "services/firebaseService";

interface DMTemplateProps {
  userId: string;
  metaData: any;
  // saveDmTemplate: Function;
}

const DMTemplateEditor: React.FC<DMTemplateProps> = ({ userId, metaData }) => {
  const toast = useToast();

  const [template, setTemplate] = useState<string>(metaData?.dmTemplate || "");

  // later we need to use this state
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTemplate(e.target.value);
  };

  const saveTemplate = async () => {
    try {
      setIsLoading(true);
      const saveContent = await saveDmTemplate(userId, template?.trim());
      if (saveContent) {
        toast({
          title: "Content Saved Successfully.",
          status: "success",
          duration: 1250,
          isClosable: false,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast({
        title: "Validation Errors",
        description: "Error occured while adding meta information.",
        status: "error",
      });
      setIsLoading(false);
      console.error("An error occurred while saving the template", error);
    }
  };

  return (
    <Flex marginBottom={"30px"}>
      <Box width={"40%"} flex={"0 0 auto"}>
        <Heading as="h2" size="sm" marginBottom={"10px"} fontWeight={"600"}>
          DM Template
        </Heading>
        <Textarea
          appearance="none"
          border="1px solid #D1D1D1 !important"
          outline="none !important"
          boxShadow="none !important"
          value={template}
          onChange={handleChange}
          placeholder="Enter your DM template here..."
          rows={6}
          cols={50}
          marginBottom="20px"
          bg="#fff"
          color="#33383F"
          borderRadius="12px"
          transition="all 0.4s ease-in-out 0s"
          _focus={{
            borderColor: "#000",
            bg: "#f4f4f4",
          }}
          _active={{
            borderColor: "#000",
            bg: "#f4f4f4",
          }}
        />
        <Button
          variant="chakra_btn_secondary"
          size="chakra_lg"
          borderRadius="50rem"
          transition="transform 0.2s, box-shadow 0.2s"
          transform="translateY(0)"
          boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
          tabIndex={-1}
          onClick={saveTemplate}
          disabled={template?.trim()?.length === 0 || isLoading}
          width={"100%"}
        >
          {isLoading ? (
            <Spinner color="" height={"20px"} width={"20px"} />
          ) : (
            "Save"
          )}
        </Button>
      </Box>
    </Flex>
  );
};

export default DMTemplateEditor;
