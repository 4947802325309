import {
  Box,
  Text,
  Input,
  Flex,
  Button,
  Spinner,
  Spacer,
  Grid,
} from "@chakra-ui/react";

import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { CustomerGroupsContext } from "contexts/customerGroupsDataContext";
import React, { useState, useContext } from "react";

import {
  createNewCustomerGroupDoc,
  generateID,
  addCustomerGroupToClientDoc,
  updateCustomerGroupDoc,
} from "services/firebaseService";
import { AdminDataContext } from "contexts/adminDataContext";
import BackButton from "components/buttons/BackButton";
import CustomerCheckList from "../components/CustomerCheckList";
import AddCustomersByPhoneNumber from "../components/AddCustomersByPhoneNumber";
import InputtedPhoneNumberTile from "../components/InputtedPhoneNumberTile";
import DropdownMenuCommunity from "../components/DropdownMenuCommunity";
import { CustomersDataContext } from "../../../../contexts/customersDataContext";

function AddOrEditCustomerGroup() {
  // This is for handling editing a customer group

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const customerGroupId = queryParams.get("customerGroupId");

  let isEditing = false;

  let isLoadingData = false;

  const allNumbersOfCustomerGroup: string[] = [];

  const editingCustomerNumbersSelected: string[] = [];

  const editingCustomerNumbersInputted: string[] = [];

  const customerGroupDataRaw = useContext(CustomerGroupsContext);
  const customerGroupsData = customerGroupDataRaw?.data;

  const customersDataRaw = useContext(CustomersDataContext);
  const customerData = customersDataRaw?.data;

  let editingClientId: string = "";

  if (customerGroupId != null) {
    isEditing = true;

    isLoadingData = true;

    const customerGroupData = customerGroupsData[customerGroupId];

    const customerDataOfClient = customerData[customerGroupData.clientID] ?? {};

    editingClientId = customerGroupData.clientID.toString();

    allNumbersOfCustomerGroup.push(...customerGroupData.users); // get all users in the customer group

    const clientCustomers = Object.keys(customerDataOfClient).slice(1) ?? []; // get list of clientCustomers


    for (let i = 0; i < allNumbersOfCustomerGroup.length; i++) {
      const trimmedNumber = String(allNumbersOfCustomerGroup[i]).trim(); // trim whitespace

      if (clientCustomers.includes(trimmedNumber)) {
        console.log("In client numbers" + trimmedNumber)
        editingCustomerNumbersSelected.push(trimmedNumber);
      } else {
        console.log("Not in client numbers" + trimmedNumber)

        editingCustomerNumbersInputted.push(trimmedNumber);
      }
    }
    isLoadingData = false;
  }

  const [customersNumbersSelected, setCustomersNumbersSelected] = useState(
    isEditing ? editingCustomerNumbersSelected : []
  );

  const [customerNumbersInputted, setCustomerNumbersInputted] = useState(
    isEditing ? editingCustomerNumbersInputted : []
  );

  // these are variables for both editing and adding customer group

  const [isBackButtonHovered, setIsBackButtonHovered] = useState(false);
  const [isCustomerGroupBeingAdded, setIsCustomerGroupBeingAdded] =
    useState(false);

  const { adminData, isLoading } = useContext(AdminDataContext);

  const [filteredClientID, setfilteredClientID] = useState(
    adminData.clientIDs[0]
  );

  const [isOpen, setIsOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [customerGroupName, setCustomerGroupName] = useState("");

  const history = useHistory();

  const handleCheckboxChange = (phoneNumber: string, isChecked: boolean) => {
    if (isChecked) {
      setCustomersNumbersSelected((prevList: string[]) => [
        ...prevList,
        phoneNumber,
      ]);
    } else {
      const updatedList = customersNumbersSelected.filter(
        (item: string) => item !== phoneNumber
      );
      setCustomersNumbersSelected(updatedList);
    }
  };

  const handleNumbersInputted = (phoneNumber: string, isAdding: boolean) => {
    if (isAdding) {
      setCustomerNumbersInputted((prevList: string[]) => [
        ...prevList,
        phoneNumber,
      ]);
    } else {
      const updatedList = customerNumbersInputted.filter(
        (item: string) => item !== phoneNumber
      );
      setCustomerNumbersInputted(updatedList);
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const formatPhoneNumber = (input: string) => {
    const numericInput = input.replace(/\D/g, "");

    if (numericInput.length >= 10) {
      return `(${numericInput.slice(0, 3)})-${numericInput.slice(
        3,
        6
      )}-${numericInput.slice(6, 10)}`;
    }

    return numericInput;
  };

  const unformatPhoneNumber = (formattedNumber: string) => {
    return formattedNumber.replace(/[()-]/g, "");
  };

  return !isLoadingData ? (
    <Box
      backgroundColor="white"
      height="1350px"
      borderRadius="10px" //width="100%"
      overflow="hidden"
    >
      {/* <Text>{customerGroupId}</Text> */}
      <Flex alignItems="center" pt="25px">
        <BackButton
          isHovered={isBackButtonHovered}
          setIsHovered={setIsBackButtonHovered}
        />
        <Text ml="10px" fontWeight="bold" fontSize="25px">
          {isEditing ? "Edit customer group" : "Add customer group"}
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <Box borderTop="1.3px solid #E2E8F0" mt="15px" width="96%" />
      </Flex>

      {!isEditing && (
        <Flex p="1%" justifyContent="start" alignItems="center">
          <Text
            display="flex"
            ml="30px"
            fontSize="lg"
            fontWeight="500"
            color="black"
            mt="1.5%"
            mr="3%"
          >
            <Text color="red">*</Text>Account:
          </Text>
          <DropdownMenuCommunity
            clientIDs={adminData.clientIDs}
            setfilteredClientID={setfilteredClientID}
          />
        </Flex>
      )}

      {!isEditing && (
        <Flex p="1%" justifyContent="start" alignItems="center">
          <Text
            display="flex"
            ml="30px"
            fontSize="lg"
            fontWeight="500"
            color="black"
            mr="1%"
          >
            <Text color="red">*</Text>Group name:
          </Text>
          <Input
            isRequired={true}
            variant="auth"
            value={customerGroupName} // This is where the preset value is set
            fontSize="17px"
            width="35%"
            type="text"
            borderRadius="10px"
            placeholder="Ex. Top Posters, Influencers, Coffee lovers"
            fontWeight="500"
            size="md"
            onChange={(e) => {
              setCustomerGroupName(e.target.value);
            }}
          />
        </Flex>
      )}

      <Flex height="72%">
        <Box
          justifyContent="start"
          alignItems="start"
          width="45%"
          height="90%"
          mt="2%"
          mx="2%"
          px="2%"
          py="1.5%"
          borderRadius="lg"
          shadow="md"
          overflow="auto"
        >
          <Text fontWeight="medium" fontSize="20px" mb="3%">
            Customers
          </Text>
          <CustomerCheckList
            isInfluencer={false}
            clientID={isEditing ? editingClientId : filteredClientID}
            handleCheckboxChange={handleCheckboxChange}
            customersNumbersSelected={customersNumbersSelected}
            allInfluencers={[]}
          />
        </Box>
        <Flex
          // direction="column"
          direction="column"
          justifyContent="start"
          alignItems="center"
          width="45%"
          height="90%"
          mt="2%"
          mx="2%"
          px="2%"
          py="1.5%"
          borderRadius="lg"
          shadow="md"
          overflow="auto"
        >
          <Text fontWeight="medium" fontSize="20px" mb="3%" align="center">
            Don't see customers but want to add them?
          </Text>
          <Button
            mb="5%"
            backgroundColor="black"
            height="45px"
            mt="1%"
            width="225px"
            borderRadius="10px"
            transition="transform 0.2s, box-shadow 0.2s"
            transform="translateY(0)"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _focus={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _active={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
            mr="2.5%"
            onClick={openModal}
          >
            <Text
              fontWeight="500"
              fontSize="15px"
              textAlign="center"
              color="white"
            >
              + Add by phone number
            </Text>
          </Button>
          <AddCustomersByPhoneNumber
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            handleNumbersInputted={handleNumbersInputted}
            customerNumbersInputted={customerNumbersInputted}
            isOpen={isOpen}
            onClose={closeModal}
            formatPhoneNumber={formatPhoneNumber}
            formattedPhoneNumber={formattedPhoneNumber}
            setFormattedPhoneNumber={setFormattedPhoneNumber}
            unformatPhoneNumber={unformatPhoneNumber}
            setIsOpen={setIsOpen}
          />
          <Box width="100%" height="70%" overflow="auto">
            <Grid templateColumns="repeat(3, 1fr)" gap="5%">
              {customerNumbersInputted.map((number, index) => (
                <InputtedPhoneNumberTile
                  phoneNumber={number}
                  handleNumbersInputted={handleNumbersInputted}
                />
              ))}
            </Grid>
          </Box>
        </Flex>
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <Box borderTop="1.3px solid #E2E8F0" mt="0px" width="96%" />
      </Flex>
      <Flex height="8%" alignItems="center">
        <Spacer />
        <Button
          isDisabled={
            (customersNumbersSelected.length === 0 &&
              customerNumbersInputted.length === 0) || (!isEditing && customerGroupName?.trim()?.length === 0)
            // (isEditing ? customerGroupName === "" : true)
          }
          backgroundColor="black"
          height="45px"
          width="150px"
          borderRadius="10px"
          transition="transform 0.2s, box-shadow 0.2s"
          transform="translateY(0)"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          _hover={{
            transform: "scale(1.05)",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          _focus={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          _active={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
          mr="2.5%"
          // onClick={async () => {
          //   setIsCustomerGroupBeingAdded(true);
          //   const combinedListOfNumbers = Array.from(
          //     new Set([...customersNumbersSelected, ...customerNumbersInputted])
          //   );
          //   const customerGroupID = generateID();
          //   await createNewCustomerGroupDoc(
          //     customerGroupID,
          //     combinedListOfNumbers
          //   );
          //   if (!doesDocExist("communities", filteredClientID)) {
          //     await createNewCommunitytDoc(filteredClientID);
          //   }
          //   await updateCommunityDoc(
          //     filteredClientID,
          //     customerGroupID,
          //     combinedListOfNumbers
          //   );
          //   await createCustomerGroupStatsDoc(customerGroupID);
          //   setIsCustomerGroupBeingAdded(false);
          // }}
          onClick={async () => {
            setIsCustomerGroupBeingAdded(true);
            const combinedListOfNumbers = Array.from(
              new Set([...customersNumbersSelected, ...customerNumbersInputted])
            );
            if (isEditing) {
              await updateCustomerGroupDoc(
                customerGroupId,
                combinedListOfNumbers
              );
            } else {
              const id = generateID();
              await createNewCustomerGroupDoc(
                id,
                filteredClientID,
                customerGroupName?.trim(),
                combinedListOfNumbers
              );
              await addCustomerGroupToClientDoc(filteredClientID, id);
            }
            setIsCustomerGroupBeingAdded(false);
            history.goBack();

            // window.location.reload();
          }}
        >
          <Text
            fontWeight="500"
            fontSize="15px"
            textAlign="center"
            color="white"
          >
            {isCustomerGroupBeingAdded ? (
              <Spinner color="white" mt="5px" />
            ) : isEditing ? (
              "Update"
            ) : (
              "Create"
            )}
          </Text>
        </Button>
      </Flex>
    </Box>
  ) : (
    <Box>
      <Spinner color="black" mt="5px" />
    </Box>
  );
}

export default AddOrEditCustomerGroup;
