/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Text, Flex, Box, useToast, Spinner, Button } from "@chakra-ui/react";
import Environment from "environment";
import { useHistory } from "react-router-dom";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  createNewOfferFlowDoc,
  createOfferBatchCodes,
  deleteOfferDoc,
  generateID,
  getCurrentUTCTimestamp,
  updateClientOfferIds,
  updateLocationOfferIds,
  updateNewOfferFlowDoc,
  uploadImageExampleUGC,
  uploadOfferImage,
  uploadVideoExampleUGC,
} from "services/firebaseService";
import StripeCheckoutForm from "./StripeCheckoutForm";

import {
  createShopifyPriceRule,
  sendPrivateOffer,
} from "services/flaskService";
import { cashSummary, contentTypeText, strToFloat } from "utils";
import { removeData } from "utils/localStorage.service";
interface PayAndCheckoutProps {
  inputValues: {
    offerName: string;
    integration?: string;
    integrations?: any;
    isPrivate: boolean;
    isInfluencerOffer: boolean;
    isCustomerOffer: boolean;
    privateOfferPhoneList?: any[];
    chosenCustomerGroups?: any[];
    typeOfOffer: string;
    isCash: boolean;
    locations?: any[];
    influencerPhoneNumbers?: any[];
    contentType: string;
    quantity: number;
    exampleUGC: any;
    OfferImage?: any;
    specificProduct?: string;
    hashtag?: string;
    compensation?: any;
    percentOrCashOff?: string;
    isPrerequisite?: boolean;
    prerequisiteName?: string;
    prerequisiteQuantity?: number;
    prerequisiteCollectionID?: string;
    isFreeReward?: boolean;
    discountItem?: string;
    discountItemID?: string;
    discountCollectionID?: string;
    minimumFollowerCount?: number;
    minPurchaseAmount?: number;
    expirationLimit?: number;
    rewardClientIDs?: any[];
    rewardClientNames?: any[];
    offerImage?: any;
    discountType: string;
    existingLocations?: any[];
    isCashBack: boolean;
    // new fields added in offer flow on 03/06/2024
    isUploadCodes?: boolean;
    batchCodes?: string[];
    ecommerceDiscountCode?: string;
    isEvergreenCode?: boolean;
    productID?: string;
    collectionID?: string;
    exampleUgcFile?: any
  };
  onInputChange: (
    fieldName: keyof PayAndCheckoutProps["inputValues"],
    value: any
  ) => void;
  data: any;
}

const PayAndCheckout: React.FC<PayAndCheckoutProps> = ({
  inputValues,
  onInputChange,
  data: additionalData,
}) => {
  const toast = useToast();
  const {
    adminData: adminDetail,
    accountData,
    clientID,
    isEditing,
    offerDetail,
    editedOfferId,
  } = additionalData;
  const { instagramHandle, offers = [], accountName } = accountData;
  const clientOfferList = offers;
  const minPurchaseAmount = 0;
  const minimumFollowerCount = Number(inputValues.minimumFollowerCount); // minimum follower count
  const stripePromise = loadStripe(Environment.STRIPE_SECRET_KEY); // TODO: use different key for production
  const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const calculateCost = () => {
    // cost in cents
    if (inputValues?.quantity && inputValues?.compensation) {
      return inputValues.quantity * inputValues.compensation * 100;
    } else if (inputValues?.quantity) {
      return inputValues?.quantity * 100;
    } else if (inputValues?.compensation) {
      return inputValues.compensation * 100;
    } else {
      return 1; // Return default value if neither quantity nor compensation is present
    }
  };
  const calculateActualAmount = () => {
    // cost in cents
    if (inputValues?.quantity && inputValues?.compensation) {
      return inputValues.quantity * inputValues.compensation;
    } else if (inputValues?.quantity) {
      return inputValues.quantity;
    } else if (inputValues?.compensation) {
      return inputValues.compensation;
    } else {
      return 1; // Return default value if neither quantity nor compensation is present
    }
  };

  let netEditPayableAmount: any = "0.0"
  let centsEditPayableCost = 0;

  if (isEditing && inputValues?.quantity > offerDetail?.quantity) {
    const quantity = inputValues.quantity - offerDetail?.quantity;
    netEditPayableAmount = (quantity * inputValues?.compensation).toFixed(2);
    centsEditPayableCost = Number((quantity * inputValues?.compensation * 100)?.toFixed(0));

  }

  // check in edit mode  

  const costIncents = Number(calculateCost()?.toFixed(0));
  const actualAmount = calculateActualAmount()?.toFixed(2);



  const stripeOptions: StripeElementsOptions = {
    mode: "payment",
    amount: isEditing ? centsEditPayableCost : costIncents,
    currency: "usd",
    appearance: {},
  };

  const onPaymentSuccessful = async () => {
    if (isEditing && editedOfferId) {
      try {
        let productID = inputValues.productID ?? "";
        let collectionID = inputValues.collectionID ?? "";
        let exampleUGCUrl = inputValues.exampleUGC;
        let offerImageUrl = inputValues?.offerImage ?? "";
        if (inputValues?.offerImage) {
          offerImageUrl = await uploadOfferImage(
            inputValues.offerImage,
            clientID,
            editedOfferId
          );
        }
        let integrations = inputValues.integration
          ? { [inputValues.integration]: {} }
          : null;
        if (inputValues.integration) {
          if (inputValues.integration === "shopify") {
            try {
              const priceRuleID = await createShopifyPriceRule(
                clientID,
                inputValues.offerName?.trim(),
                strToFloat(inputValues.compensation),
                inputValues.percentOrCashOff?.toLowerCase(),
                inputValues.discountItemID, // optional
                inputValues.discountCollectionID, // optional
                minPurchaseAmount, // defaults to 0
                inputValues.prerequisiteCollectionID, // optional : default to ""
                inputValues.prerequisiteQuantity // optional : default to 0
              );

              let shopifyIntegration: any = integrations["shopify"];
              shopifyIntegration.priceRuleID = priceRuleID?.toString();
              productID = priceRuleID?.toString() ?? "";
              collectionID = "";
              integrations = {
                shopify: shopifyIntegration,
              };
            } catch (error) {
              console.error("Error setting priceRuleID:", error);
            }
          } else if (integrations["eventbrite"]) {
            // TODO: Test this
            let eventbriteIntegration: any = integrations["eventbrite"];
            eventbriteIntegration.eventID = inputValues.discountItemID;
            integrations = {
              eventbrite: eventbriteIntegration,
            };
          }
        }
        // Prepare offer data
        const offerDataMap: Map<string, any> = new Map();
        offerDataMap.set("clientID", clientID);
        offerDataMap.set("productID", productID);
        offerDataMap.set("collectionID", collectionID);

        offerDataMap.set("compensation", strToFloat(inputValues.compensation));
        offerDataMap.set("contentType", inputValues.contentType);
        offerDataMap.set("discountItem", inputValues.discountItem?.trim());
        offerDataMap.set("discountItemID", inputValues.discountItemID);
        offerDataMap.set("discountCollectionID", "");
        offerDataMap.set("isInfluencerOffer", inputValues.isInfluencerOffer);
        offerDataMap.set("isCustomerOffer", inputValues.isCustomerOffer);
        offerDataMap.set("exampleUGC", inputValues.exampleUGC);
        offerDataMap.set("offerID", editedOfferId);
        offerDataMap.set("integrations", integrations);
        offerDataMap.set("isCash", inputValues.isCash);
        offerDataMap.set("isCashBack", inputValues.isCashBack);

        offerDataMap.set("minimumFollowerCount", minimumFollowerCount);
        // for cash this is empty
        offerDataMap.set("percentOrCashOff", "Cash");
        offerDataMap.set(
          "privateOfferPhoneList",
          inputValues.privateOfferPhoneList
        );
        offerDataMap.set("quantity", inputValues.quantity);
        offerDataMap.set(
          "numberOffersLeft", offerDetail?.quantity === inputValues.quantity ? offerDetail?.numberOffersLeft : inputValues.quantity
        );
        offerDataMap.set("rewardClientIDs", [clientID]);
        offerDataMap.set("rewardClientNames", [accountName]);
        offerDataMap.set(
          "specificProduct",
          inputValues.specificProduct?.trim()
        );
        offerDataMap.set("totalCost", actualAmount);
        offerDataMap.set("typeOfOffer", inputValues?.typeOfOffer);
        offerDataMap.set("offerName", inputValues.offerName?.trim());
        offerDataMap.set("isPrivate", inputValues.isPrivate);
        offerDataMap.set(
          "chosenCustomerGroups",
          inputValues.chosenCustomerGroups ?? []
        );
        offerDataMap.set("minPurchaseAmount", 0);
        offerDataMap.set("locations", inputValues.locations);
        offerDataMap.set(
          "expirationLimit",
          Number(inputValues.expirationLimit)
        );
        offerDataMap.set(
          "influencerPhoneNumbers",
          inputValues.influencerPhoneNumbers ?? []
        );
        offerDataMap.set(
          "privateOfferPhoneList",
          inputValues.privateOfferPhoneList ?? []
        );
        offerDataMap.set("isFreeReward", false);
        offerDataMap.set("isPrerequisite", false);
        offerDataMap.set("prerequisiteCollectionID", "");
        offerDataMap.set("prerequisiteName", "");
        offerDataMap.set("prerequisiteQuantity", 0);
        offerDataMap.set("discountType", inputValues.discountType);
        offerDataMap.set(
          "ecommerceDiscountCode",
          inputValues.ecommerceDiscountCode
        );
        offerDataMap.set("isEvergreenCode", inputValues.isEvergreenCode);
        offerDataMap.set("isUploadCodes", inputValues.isUploadCodes);
        offerDataMap.set("isAutopilotOffer", false);


        if (inputValues.exampleUGC !== null) {
          // this means they edited the example UGC

          // checking this condition because we dont need to upload content if already uploaded 

          if (inputValues?.contentType === "instagramReel" || inputValues?.contentType === "tiktokVideo" || inputValues?.contentType === 'ugcVideo') {
            // if (inputValues?.exampleUGC && typeof inputValues?.exampleUGC !== "string") {
            //check if video uploading or not
            if (inputValues?.exampleUgcFile && typeof inputValues?.exampleUgcFile !== "string") {
              console.log("new file uploaded---", inputValues.exampleUgcFile);
              exampleUGCUrl = await uploadVideoExampleUGC(
                inputValues.exampleUgcFile,
                clientID,
                editedOfferId
              );
            } else {
              console.log("same file uploaded---", inputValues.exampleUgcFile);
              exampleUGCUrl = inputValues.exampleUGC
            }
            // } 
          } else {
            if (inputValues?.exampleUGC && typeof inputValues?.exampleUGC !== "string") {
              exampleUGCUrl = await uploadImageExampleUGC(
                inputValues.exampleUGC,
                clientID,
                editedOfferId
              );

            } else {
              exampleUGCUrl = inputValues.exampleUGC
            }
          }
        }

        // add offer image in edit as well
        if (inputValues?.offerImage) {
          // checking this condition because we dont need to upload image if image already uploaded
          if (
            inputValues?.exampleUGC &&
            typeof inputValues?.offerImage !== "string"
          ) {
            offerImageUrl = await uploadOfferImage(
              inputValues.offerImage,
              clientID,
              editedOfferId
            );
          } else {
            offerImageUrl = inputValues?.offerImage;
          }
        }

        if (
          inputValues.locations?.length ||
          inputValues?.existingLocations?.length
        ) {
          updateLocationOfferIds(
            inputValues.locations,
            editedOfferId,
            inputValues.existingLocations
          );
        }


        await deleteOfferDoc(editedOfferId);
        await createNewOfferFlowDoc(
          editedOfferId,
          clientID,
          offerImageUrl,
          exampleUGCUrl,
          offerDataMap,
          getCurrentUTCTimestamp(),
          inputValues.specificProduct?.trim(),
          inputValues.hashtag?.trim(),
          minimumFollowerCount, // minimum follower count
          [clientID], // default account id
          [accountName], // default account name
          inputValues.influencerPhoneNumbers,
          inputValues.privateOfferPhoneList
        );

        // check and send offer to private customers
        if (inputValues.isPrivate) {
          const allCustomers = [...new Set(inputValues.privateOfferPhoneList)];
          const newlyAddedCustomers = allCustomers?.filter(phoneNumber => !offerDetail?.privateOfferPhoneList?.includes(phoneNumber))
          if (newlyAddedCustomers?.length) {
            for (let phoneNumber of newlyAddedCustomers) {
              try {
                console.log("Sending private offer to newly added customer: " + phoneNumber);
                await sendPrivateOffer(phoneNumber, editedOfferId);
              } catch (error) {
                setIsLoading(false);
                toast({
                  title: "Validation Errors",
                  description: "Error occured while create offer",
                  status: "error",
                });
                console.error("Error sending private offer to newly added customer:", error);
              }
            }
          }

        }

        // check and send offer to influencers
        if (inputValues.isInfluencerOffer) {
          const allInfluencers = [...new Set(inputValues.influencerPhoneNumbers)];
          const newlyAddedInfluencers = allInfluencers?.filter(phoneNumber => !offerDetail?.influencerPhoneNumbers?.includes(phoneNumber))
          if (newlyAddedInfluencers?.length) {
            for (let phoneNumber of newlyAddedInfluencers) {
              try {
                console.log("Sending private offer to newly added influencer: " + phoneNumber);
                await sendPrivateOffer(phoneNumber, editedOfferId);
              } catch (error) {
                setIsLoading(false);
                toast({
                  title: "Validation Errors",
                  description: "Error occured while create offer",
                  status: "error",
                });
                console.error("Error sending private offer to newly added influencer:", error);
              }
            }
          }
        }

        toast({
          title: "Offer updated sucessfully.",
          status: "success",
          duration: 1250,
          isClosable: false,
        });
        // update offer with fields (pass in exampleUGC as a param, along with )
        setIsLoading(false);

        // removeitem from localstorage
        removeData(`${inputValues.offerName}-offer-image`);
        removeData(`${inputValues.offerName}-image`);

        history.goBack();
      } catch (error: any) {
        // Handle any errors
        setIsLoading(false);
        toast({
          title: "Validation Errors",
          description: "Error occured while update offer",
          status: "error",
        });
        // removeitem from localstorage
        removeData(`${inputValues.offerName}-offer-image`);
        removeData(`${inputValues.offerName}-image`);
        console.error(error);
        return;
      }
    } else {
      const offerID = generateID();
      let productID = inputValues.productID ?? "";
      let collectionID = inputValues.collectionID ?? "";

      try {
        let exampleUGCURL = "";
        let offerImageUrl = "";
        if (
          inputValues.contentType === "instagramReel" ||
          inputValues.contentType === "tiktokVideo" || inputValues.contentType === 'ugcVideo'
        ) {
          exampleUGCURL = await uploadVideoExampleUGC(
            inputValues.exampleUgcFile,
            clientID,
            offerID
          );
        } else {
          exampleUGCURL = await uploadImageExampleUGC(
            inputValues.exampleUGC,
            clientID,
            offerID
          );
        }
        if (inputValues?.offerImage) {
          offerImageUrl = await uploadOfferImage(
            inputValues.offerImage,
            clientID,
            offerID
          );
        }
        let date = getCurrentUTCTimestamp();
        let integrations = inputValues.integration
          ? { [inputValues.integration]: {} }
          : null;
        if (inputValues.integration) {
          if (inputValues.integration === "shopify") {
            try {
              const priceRuleID = await createShopifyPriceRule(
                clientID,
                inputValues.offerName?.trim(),
                strToFloat(inputValues.compensation),
                inputValues.percentOrCashOff?.toLowerCase(),
                inputValues.discountItemID, // optional
                inputValues.discountCollectionID, // optional
                minPurchaseAmount, // defaults to 0
                inputValues.prerequisiteCollectionID, // optional : default to ""
                inputValues.prerequisiteQuantity // optional : default to 0
              );
              productID = priceRuleID?.toString() ?? "";
              collectionID = "";

              let shopifyIntegration: any = integrations["shopify"];
              shopifyIntegration.priceRuleID = priceRuleID?.toString();
              integrations = {
                shopify: shopifyIntegration,
              };
            } catch (error) {
              console.error("Error setting priceRuleID:", error);
            }
          } else if (integrations["eventbrite"]) {
            // TODO: Test this
            let eventbriteIntegration: any = integrations["eventbrite"];
            eventbriteIntegration.eventID = inputValues.discountItemID;
            integrations = {
              eventbrite: eventbriteIntegration,
            };
          } else if (integrations["bigCommerce"]) {
            console.log("this is a big commerce inetgration");
            let bigCommerceIntegration: any = integrations["bigCommerce"];
            integrations = {
              bigCommerce: bigCommerceIntegration,
            };
            // Create call to the bigCommerce endpoint --> do we even have to do this here?
            // implement this
          }
          // @todo add more integration in future
        }

        // Prepare offer data
        const offerDataMap: Map<string, any> = new Map();
        offerDataMap.set("clientID", clientID);
        offerDataMap.set("productID", productID);
        offerDataMap.set("collectionID", collectionID);
        offerDataMap.set("compensation", strToFloat(inputValues.compensation));
        offerDataMap.set("contentType", inputValues.contentType);
        offerDataMap.set("discountItem", inputValues.discountItem?.trim());
        offerDataMap.set("discountItemID", inputValues.discountItemID);
        offerDataMap.set("discountCollectionID", "");
        offerDataMap.set("isInfluencerOffer", inputValues.isInfluencerOffer);
        offerDataMap.set("isCustomerOffer", inputValues.isCustomerOffer);

        offerDataMap.set("exampleUGC", inputValues.exampleUGC);
        offerDataMap.set("offerID", offerID);
        offerDataMap.set("integrations", integrations);
        offerDataMap.set("isCash", inputValues.isCash);
        offerDataMap.set("isCashBack", inputValues.isCashBack);

        offerDataMap.set("minimumFollowerCount", minimumFollowerCount);
        offerDataMap.set("percentOrCashOff", "Cash");
        offerDataMap.set(
          "privateOfferPhoneList",
          inputValues.privateOfferPhoneList
        );
        offerDataMap.set("quantity", inputValues.quantity);
        offerDataMap.set(
          "numberOffersLeft", inputValues.quantity ?? 0
        );
        offerDataMap.set("minPurchaseAmount", 0);
        offerDataMap.set("rewardClientIDs", [clientID]);
        offerDataMap.set("rewardClientNames", [accountName]);
        offerDataMap.set(
          "specificProduct",
          inputValues.specificProduct?.trim()
        );
        offerDataMap.set("totalCost", actualAmount);
        offerDataMap.set("typeOfOffer", inputValues.typeOfOffer);
        offerDataMap.set("offerName", inputValues.offerName?.trim());
        offerDataMap.set("isPrivate", inputValues.isPrivate);
        offerDataMap.set(
          "chosenCustomerGroups",
          inputValues.chosenCustomerGroups ?? []
        );
        offerDataMap.set("locations", inputValues.locations);
        offerDataMap.set(
          "expirationLimit",
          Number(inputValues.expirationLimit)
        );
        offerDataMap.set("isFreeReward", false);
        offerDataMap.set("isPrerequisite", false);
        offerDataMap.set("prerequisiteCollectionID", "");
        offerDataMap.set("prerequisiteName", "");
        offerDataMap.set("prerequisiteQuantity", 0);
        offerDataMap.set("discountType", inputValues.discountType);

        // new changes added for batch codes
        offerDataMap.set(
          "ecommerceDiscountCode",
          inputValues.ecommerceDiscountCode
        );
        offerDataMap.set("isEvergreenCode", inputValues.isEvergreenCode);
        offerDataMap.set("isUploadCodes", inputValues.isUploadCodes);
        offerDataMap.set("isAutopilotOffer", false);

        await createNewOfferFlowDoc(
          offerID,
          clientID,
          offerImageUrl,
          exampleUGCURL,
          offerDataMap,
          date,
          inputValues.specificProduct?.trim(),
          inputValues.hashtag?.trim(),
          minimumFollowerCount, // minimum follower count
          [clientID], // default account id
          [accountName], // default account name
          inputValues.influencerPhoneNumbers,
          inputValues.privateOfferPhoneList
        );

        if (!accountData["locationless"]) {
          updateLocationOfferIds(inputValues.locations, offerID);
        }
        const updateOfferIds = [...clientOfferList, offerID];
        await updateClientOfferIds(clientID, updateOfferIds);

        if (inputValues.isPrivate) {
          for (let phoneNumber of inputValues.privateOfferPhoneList) {
            try {
              console.log("Sending private offer to: " + phoneNumber);
              await sendPrivateOffer(phoneNumber, offerID);
            } catch (error) {
              setIsLoading(false);
              toast({
                title: "Validation Errors",
                description: "Error occured while create offer",
                status: "error",
              });
              console.error("Error sending private offer:", error);
            }
          }
        }

        // added text message to influencers previously skipped.
        if (inputValues.isInfluencerOffer) {
          for (let phoneNumber of inputValues.influencerPhoneNumbers) {
            try {
              console.log("Sending influencer offer to: " + phoneNumber);
              await sendPrivateOffer(phoneNumber, offerID);
            } catch (error) {
              setIsLoading(false);
              toast({
                title: "Validation Errors",
                description:
                  "Error occured while sending text to " + phoneNumber,
                status: "error",
              });
              console.error("Error sending influencer offer:", error);
            }
          }
        }
        toast({
          title: "Offer created sucessfully.",
          status: "success",
          duration: 1250,
          isClosable: false,
        });
        setIsLoading(false);
        // removeitem from localstorage
        removeData(`${inputValues.offerName}-offer-image`);
        removeData(`${inputValues.offerName}-image`);
        history.goBack();
      } catch (error) {
        // Handle any errors
        setIsLoading(false);
        toast({
          title: "Validation Errors",
          description: "Error occured while create offer",
          status: "error",
        });
        // removeitem from localstorage
        removeData(`${inputValues.offerName}-offer-image`);
        removeData(`${inputValues.offerName}-image`);
        console.error(error);
        return;
      }
    }
  };


  return (
    offerDetail?.quantity === inputValues.quantity ?
      (
        <>
          <Box
            maxW={"434px"}
            mx="auto"
            mt="auto"
            mb="auto"
            py={"15px"}
            px={"14px"}
            bg={"#D4FEAE"}

            borderRadius={"8px"}
          >
            <Flex alignItems={"center"} textAlign={"center"}>
              <Text fontSize={"15px"} fontWeight={"600"}>
                {cashSummary({
                  quantity: inputValues.quantity,
                  minimumFollowerCount: inputValues?.minimumFollowerCount,
                  compensationType: "cash",
                  specificProduct: inputValues?.specificProduct,
                  accountName,
                  instagramHandle,
                  contentType: inputValues.contentType,
                  compensation: inputValues.compensation,
                })}
              </Text>
            </Flex>
          </Box>
          <Flex
            justifyContent={"flex-end"}
            borderTop={"1px solid #EAECF0"}
            paddingTop={"24px"}
          >
            <Button
              onClick={() => {
                setIsLoading(true);
                onPaymentSuccessful();
              }}
              type="submit"
              variant="chakra_btn_secondary"
              size="chakra_xs"
              disabled={isLoading}
              _hover={{
                transform: "scale(1.05)",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _focus={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _active={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              {isLoading ? (
                <Spinner color="black" height={"20px"} width={"20px"} />
              ) : "Update Offer"
              }
            </Button>
          </Flex>
        </>
      )

      :
      (
        <>
          <Box py="60px" mb={"auto"}>
            <Flex gap={"47px"} w={"100%"}>
              <Flex w={"514px"} justify={"start"} align={"end"} direction="column">
                <Flex
                  w={"385px"}
                  py={"23px"}
                  px={"22px"}
                  border={"1px solid #EAECF0"}
                  direction="column"
                  borderRadius={"7px"}
                >
                  <Box
                    w={"100%"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems="center"
                    pb={"12px"}
                    mb={"12px"}
                    borderBottom="1px solid rgba(17, 19, 21, 0.12)"
                  >
                    <Text color={"#33383F"} fontSize={"15px"}>
                      You’re paying
                    </Text>
                    <Text color={"#000"} fontSize={"chakra_xl"} fontWeight={"600"}>
                      ${isEditing ? netEditPayableAmount : actualAmount}
                    </Text>
                  </Box>
                  <Box
                    w={"100%"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems="center"
                    py={"12px"}
                  >
                    <Text color={"#6F767E"} fontSize={"15px"}>
                      {inputValues?.quantity} customers earning $
                      {inputValues?.compensation}
                    </Text>

                    <Text color={"#1A1D1F"} fontSize={"15px"} fontWeight={"600"}>
                      ${actualAmount}
                    </Text>
                  </Box>
                  {
                    isEditing && offerDetail && (
                      <Box
                        w={"100%"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems="center"
                        py={"12px"}
                      >
                        <Text color={"#6F767E"} fontSize={"15px"}>
                          Already paid amount
                        </Text>

                        <Text color={"#1A1D1F"} fontSize={"15px"} fontWeight={"600"}>
                          ${offerDetail?.totalCost}
                        </Text>
                      </Box>
                    )
                  }

                  <Box
                    w={"100%"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems="center"
                    pt={"12px"}
                    pb={"7px"}
                    borderTop="1px solid rgba(17, 19, 21, 0.12)"
                  >
                    <Text color={"#33383F"} fontSize={"15px"}>
                      Total
                    </Text>
                    <Text color={"#000"} fontSize={"15px"} fontWeight={"600"}>
                      ${actualAmount}
                    </Text>
                  </Box>
                </Flex>
                <Box
                  maxW={"100%"}
                  mx="auto"
                  mt="81px"
                  py={"42px"}
                  px={"14px"}
                  bg={"#D4FEAE"}
                  borderRadius={"8px"}
                >
                  <Text textAlign={"center"} fontSize={"15px"} fontWeight={"600"}>
                    {cashSummary({
                      quantity: inputValues.quantity,
                      minimumFollowerCount: inputValues?.minimumFollowerCount,
                      compensationType: "cash",
                      specificProduct: inputValues?.specificProduct,
                      accountName,
                      instagramHandle,
                      contentType: inputValues?.contentType,
                      compensation: inputValues?.compensation,
                    })}
                  </Text>
                </Box>
              </Flex>
              <Box w={"50%"}>
                {
                  <Elements stripe={stripePromise} options={stripeOptions}>
                    <StripeCheckoutForm
                      amount={isEditing ? centsEditPayableCost : costIncents}
                      clientName={accountData?.accountName}
                      email={adminDetail.adminData.email}
                      onPaymentSuccessful={onPaymentSuccessful}
                      setIsPaymentSuccess={setIsPaymentSuccess}
                      isPaymentSuccess={isPaymentSuccess}
                      setIsLoading={setIsLoading}
                      isLoading={isLoading}
                      actualAmount={isEditing ? netEditPayableAmount : actualAmount}
                    />
                  </Elements>
                }
              </Box>
            </Flex>
          </Box>
        </>
      )
  );
};

export default PayAndCheckout;
