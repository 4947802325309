/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Text, Flex, Box, Stack, Radio, RadioGroup } from "@chakra-ui/react";
import { removeData } from "utils/localStorage.service";

interface TypeOfContentProps {
  inputValues: {
    contentType: string;
    exampleUGC: string;
    offerName: string;
    minimumFollowerCount: number;
    isPrivate: boolean;
    isInfluencerOffer: boolean;
    isCustomerOffer: boolean;
    exampleUgcFile: any;
  };
  onInputChange: (
    fieldName: keyof TypeOfContentProps["inputValues"],
    value: any
  ) => void;
  data: any;
}

const ContentType: React.FC<TypeOfContentProps> = ({
  inputValues,
  onInputChange,
  data: additionalData,
}) => {
  const mapDefaultValue = (values: any): string => {
    return values.contentType;
  };
  const { isEditing, offerDetail } = additionalData;
  const [defaultSelectRadio, setDefaultSelectedRadio] = useState<string>(
    mapDefaultValue(inputValues)
  );

  const handleOptionChange = (contentType: string) => {
    onInputChange("contentType", contentType);
    if (contentType === 'ugcVideo' || contentType === 'ugcPicture') {
      onInputChange("minimumFollowerCount", 0);
    } else {
      if (inputValues.isCustomerOffer) {
        onInputChange('minimumFollowerCount', isEditing ? offerDetail?.minimumFollowerCount : 100);
      }
    }
    // set to empty when content type change
    onInputChange("exampleUGC", "");
    onInputChange("exampleUgcFile", "");
    removeData(`${inputValues?.offerName}-image`);
    setDefaultSelectedRadio(contentType);
  };

  // set to empty when content type change

  useEffect(() => {
    if (isEditing) {
      onInputChange("exampleUGC", inputValues?.exampleUGC);
    } else {
      // onInputChange("exampleUGC", "");
    }
  }, []);

  return (
    <Box py="60px">
      <RadioGroup onChange={handleOptionChange} value={defaultSelectRadio}>
        <Stack spacing={"60px"} direction="column">
          <Box>
            <Radio
              variant="chakra_radio_light"
              size="radio_lg"
              value="instagramStory"
            >
              <Flex flexFlow="column" ml="14px">
                <Text fontSize="chakra_lg" fontWeight="500" color="#101828">
                  Instagram Story
                </Text>
                <Text fontSize="chakra_sm" fontWeight="400" color="#667085">
                  24 hours instagram story, photo or video format
                </Text>
              </Flex>
            </Radio>
          </Box>
          <Box>
            <Radio
              variant="chakra_radio_light"
              size="radio_lg"
              value="instagramPost"
            >
              <Flex flexFlow="column" ml="14px">
                <Text fontSize="chakra_lg" fontWeight="500" color="#101828">
                  Instagram Post
                </Text>
                <Text fontSize="chakra_sm" fontWeight="400" color="#667085">
                  instagram post, picture format
                </Text>
              </Flex>
            </Radio>
          </Box>
          <Box>
            <Radio
              variant="chakra_radio_light"
              size="radio_lg"
              value="instagramReel"
            >
              <Flex flexFlow="column" ml="14px">
                <Text fontSize="chakra_lg" fontWeight="500" color="#101828">
                  Instagram Reel
                </Text>
                <Text fontSize="chakra_sm" fontWeight="400" color="#667085">
                  Instagram reel, video format
                </Text>
              </Flex>
            </Radio>
          </Box>
          <Box>
            <Radio
              variant="chakra_radio_light"
              size="radio_lg"
              value="tiktokVideo"
            >
              <Flex flexFlow="column" ml="14px">
                <Text fontSize="chakra_lg" fontWeight="500" color="#101828">
                  Tiktok Video
                </Text>
                <Text fontSize="chakra_sm" fontWeight="400" color="#667085">
                  Tiktok video, video format
                </Text>
              </Flex>
            </Radio>
          </Box>
          <Box>
            <Radio
              variant="chakra_radio_light"
              size="radio_lg"
              value="ugcPicture"
            >
              <Flex flexFlow="column" ml="14px">
                <Text fontSize="chakra_lg" fontWeight="500" color="#101828">
                  UGC Picture
                </Text>
                <Text fontSize="chakra_sm" fontWeight="400" color="#667085">
                  User generated content sent directly to you, picture format
                </Text>
              </Flex>
            </Radio>
          </Box>
          <Radio variant="chakra_radio_light" size="radio_lg" value="ugcVideo">
            <Flex flexFlow="column" ml="14px">
              <Text fontSize="chakra_lg" fontWeight="500" color="#101828">
                UGC Video
              </Text>
              <Text fontSize="chakra_sm" fontWeight="400" color="#667085">
                User generated content sent directly to you, video format
              </Text>
            </Flex>
          </Radio>
        </Stack>
      </RadioGroup>
    </Box>
  );
};

export default ContentType;
