import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
    Box,
    Flex,
    Text,
    SimpleGrid,
    useColorModeValue,
    Icon,
    Center,
    Spinner,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { MdCheck, MdDiscount, MdImage, MdLocationPin, MdOutlineBarChart, MdSend } from "react-icons/md";
import StatsRow from "components/stats/StatsRow";
import ComingSoonChart from "components/charts/ComingSoonChart";
import { OffersDataContext } from "contexts/offersDataContext";
import Card from "components/card/Card";
import UgcPreviewCard from "views/admin/overview/components/UgcPreviewCard";
import LocationCard from "../../accountOverview/components/LocationCard";
import { LocationsDataContext } from "contexts/locationsDataContext";
import BackButton from "components/buttons/BackButton";
import { AccountsDataContext } from "contexts/accountsDataContext";
import MetricChartCard from "components/charts/MetricChartCard";
import useOffersData from "services/offerDataService";

export default function OfferOverview() {
    const textColor = useColorModeValue("black", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const history = useHistory();

    /**
  * @info changes made by nirmal on date :- 16/05/2024
  *  Removed provider here as we don't need it because we are using custom hook below.
  */
    // const offersData = useContext(OffersDataContext);
    const locationsData = useContext(LocationsDataContext);
    const clientData = useContext(AccountsDataContext);
    const [isBackButtonHovered, setIsBackButtonHovered] = useState(false);

    const offerId = new URLSearchParams(window.location.search).get("offerId");

    /**
   * @info changes made by nirmal on date :- 16/05/2024
   * Added custome hook to get offerData
   */
    const { isLoading: offerLoading = false, offersData: offer } = useOffersData({ offerId });

    const offerData = offer[offerId] || {}

    const timestampedData = offerData?.stats?.timestamps ?? [];
    const chartData = [
        {
            name: "Estimated Reach",
            // Map the keys to data points
            data:
                timestampedData == null || timestampedData.length > 0 ? [] :
                    Object.keys(timestampedData)
                        .map((timestamp) => ({
                            // Convert timestamp to a number and create a Date object
                            x: new Date(parseInt(timestamp)).getTime(),
                            y: Math.ceil(timestampedData[timestamp].reach / 3) || 0 // Use 0 if reach is undefined
                        }))
                        // Sort the data points by the 'x' property, which is the timestamp in milliseconds
                        .sort((a, b) => a.x - b.x)
        }
    ];

    // get clientId from offerData
    const clientId = offerData?.clientID;
    // include only locations in offerData.locations
    const filteredLocationsData = Object.values(locationsData?.data).filter(
        (location: any) => offerData?.locations?.includes(location?.locationID)
    );

    // Update statsList to display information related to the selected offer
    const statsList = [
        {
            title: "Offers Accepted",
            value: String(offerData?.stats?.acceptances ?? 0),
            icon: MdCheck,
        },
        {
            title: "Number of Posts",
            value: String(offerData?.stats?.posts ?? 0),
            icon: MdImage,
        },
        {
            title: "Estimated Reach",
            value: String(Math.floor((offerData?.stats?.reach || 0) / 3)),
            icon: MdOutlineBarChart,
        },
        {
            title: "Offers Redeemed",
            value: String(offerData?.stats?.redemptions ?? 0),
            icon: MdDiscount,
        },
    ];

    /**
  * @info changes made by nirmal on date :- 16/05/2024
  * Added loader here if data is not available.
  */
    if (offerLoading) {
        return (
            <Center height="90vh">
                <Spinner size="xl" />
            </Center>
        );
    }

    return (
        <Box >
            <Flex alignItems="start" flexDirection={"column"} mb="15px">
                <Flex alignItems="center" pb="25px">
                    <BackButton isHovered={isBackButtonHovered} setIsHovered={setIsBackButtonHovered} />
                    <Text ml="10px" fontWeight="bold" fontSize="30px">
                        Offer Overview
                    </Text>
                </Flex>
                <Flex alignItems="center">
                    <Text ml="10px" fontSize="25px">
                        "{offerData?.offerName}"
                    </Text>
                </Flex>
            </Flex>
            <StatsRow stats={statsList} />
            <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mb="20px">
                <Flex
                    gridColumn={{ md: "span 2" }}
                    flexDirection="column"
                    w="100%"
                    mb="0px"
                    justifyContent={"space-around"}
                >
                    <MetricChartCard chartData={chartData} />
                    <UgcPreviewCard
                        clientIDs={[clientId]}
                        filteredClientId={clientId}
                        filteredOfferId={offerId}
                        maxRows={1}
                        mt="20px"
                    />
                </Flex>
                {
                    (clientData[clientId]?.locationless) ||
                    < Card
                        justifyContent="flex-start"
                        alignItems="center"
                        flexDirection="column"
                        w="100%"
                        h="100%"
                        mb="0px"
                        mt={"20px"}
                    >
                        <Flex justifyContent="space-between" w="100%" pe="20px" pt="5px">
                            <Text
                                color={textColor}
                                fontSize="24px"
                                textAlign="start"
                                fontWeight="700"
                                lineHeight="100%"
                            >
                                Active Locations
                            </Text>
                        </Flex>
                        <Flex
                            flexDirection={"column"}
                            justifyItems="start"
                            py="25px"
                            px="10px"
                            width="100%"
                        >
                            {Object.values(filteredLocationsData)
                                .sort((a: any, b: any) => b.date - a.date)
                                .slice(0, 3)
                                .map((location: any) => (
                                    < LocationCard key={location?.locationID} location={location} clientID={clientId} />
                                ))}
                        </Flex>
                    </Card>
                }
            </SimpleGrid>
        </Box >
    );
}
