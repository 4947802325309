import React from 'react';
import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';

interface ProtectedRouteProps extends RouteProps {
    // TypeScript way to say children is a React component, but could be more specific if needed
    children: React.ReactNode;
    user: any;
    isSuperUser: boolean;
}

const SuperProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, user, isSuperUser, ...rest }) => {

    const location = useLocation();

    return (
        <Route
            {...rest}
            render={() =>
                user && isSuperUser ? children : (
                    <Redirect to={{
                        pathname: "/admin/overview/",
                        state: { from: location }
                    }} />
                )
            }
        />
    );
}

export default SuperProtectedRoute;
