import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Center,
  Link,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";

import { CloseCircleIcon } from "components/icons/Icons";

interface MannualAddCodesProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddCodes?: (codes: string[]) => void;
  modelTitle: string;
  batchCodes: string[];
  existingCodes: string[];
}

const MannualAddCodesPopup: React.FC<MannualAddCodesProps> = ({
  isOpen,
  onClose,
  handleAddCodes,
  modelTitle,
  batchCodes,
  existingCodes
}) => {
  const [allCodes, setAllCodes] = useState([]);
  const [batchCode, setBatchCode] = useState("");
  const [codeError, setCodeError] = useState("");

  const handleClose = () => {
    onClose();
  };

  const handleCodeChange = (event: any) => {
    const value = event?.target?.value?.trim();
    setBatchCode(value);
    setCodeError("");
  };

  const addDiscountCode = () => {
    if (!batchCode) {
      setCodeError("Please enter code.");
      return;
    }
    // Check if the element contains only numbers and characters
    // const regex = /^[a-zA-Z0-9]+$/;
    // if (!regex?.test(batchCode)) {
    //   setCodeError("Invalid code, only character and numbers are allowed.");
    //   return;
    // }

    const emojiRegex =
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
    // const inputPassword = e.target.value?.trim();
    if (emojiRegex?.test(batchCode)) {
      setCodeError("Invalid code, please try with different one.");
      return;
    }

    if (batchCode?.length < 3) {
      setCodeError("Minimum 3 charater is required for code.");
      return;
    }

    if (
      batchCode &&
      (allCodes?.indexOf(batchCode) > -1 || batchCodes?.indexOf(batchCode) > -1)
    ) {
      setCodeError("Code is already exists.");
      return;
    }

    // check with existing codes in edit flow
    if (existingCodes?.length && existingCodes?.indexOf(batchCode) > -1) {
      setCodeError("Code is already exists.");
      return;
    }


    setAllCodes([...allCodes, batchCode]);
    setBatchCode("");
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent p={"28px 40px 16px 44px"} borderRadius="20px" maxW="874px">
        <ModalHeader p={"0px"}>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Text fontSize={"chakra_2xl"} fontWeight={"500"}>
              {modelTitle}
            </Text>
            <Link
              fontSize="chakra_sm"
              color="#000"
              fontWeight="400"
              onClick={handleClose}
            >
              <CloseCircleIcon h="36px" w="36px" />
            </Link>
          </Flex>
        </ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody maxWidth={"100%"} p={"0"}>
          <Box py="60px">
            <Flex gap={"43px"} alignItems="start" mb={"34px"}>
              <FormControl display="flex" alignItems="start" gap="28px">
                <FormLabel
                  minWidth="auto"
                  m="0"
                  py="10px"
                  variant={"chakra_label_default"}
                >
                  Code
                </FormLabel>
                <Box w={"100%"}>
                  <Input
                    width="100%"
                    variant="chakra_input_default"
                    size="chakra_md_medium"
                    fontSize={"chakra_sm"}
                    fontWeight={"500"}
                    type="text"
                    value={batchCode}
                    onChange={handleCodeChange}
                    maxLength={35}
                  />
                  {codeError && codeError?.length > 0 && (
                    <Text textColor={"red"} mt="3px" fontSize={"12px"}>
                      {codeError}
                    </Text>
                  )}
                </Box>
              </FormControl>
              <Button
                width={"171px"}
                variant="chakra_btn_secondary"
                size="chakra_xs"
                disabled={!batchCode?.length || codeError?.length > 0}
                my="2px"
                cursor={
                  !batchCode?.length || codeError?.length > 0 ? "pointer" : ""
                }
                _hover={{
                  transform: "scale(1.05)",
                  // transform: "none",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
                _focus={{
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
                _active={{
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
                onClick={addDiscountCode}
              >
                +Add
              </Button>
            </Flex>
            {allCodes?.length > 0 && (
              <Box mx="-12px">
                <Box maxHeight="140px" overflowY={"auto"} px="12px">
                  <SimpleGrid columns={5} spacing={15}>
                    {allCodes.map((code: string, index: number) => (
                      <Box
                        key={`code-${index}`}
                        // fontSize={"chakra_xs"}
                        fontWeight="500"
                        lineHeight={"24px"}
                        color={"#1A1D1F"}
                        ml="0"
                        bg={"#F4F4F4"}
                        borderRadius="5px"
                        px="23px"
                        py="3px"
                        fontSize="14px"
                      >
                        {code}
                      </Box>
                    ))}
                  </SimpleGrid>
                </Box>
              </Box>
            )}
          </Box>
        </ModalBody>
        <ModalFooter pt={"0px"} px={"0"} pb={"0"}>
          <Flex w={"100%"} justifyContent={"space-around"} gap="24px">
            <Box textAlign={"center"}>
              <Button
                width={"315px"}
                variant="chakra_btn_secondary"
                size="chakra_xs"
                // disabled={!selectedCustomer.length}
                // cursor={!selectedCustomer.length ? "pointer" : ""}
                _hover={{
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
                _focus={{
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
                _active={{
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
                onClick={() => {
                  handleAddCodes(allCodes);
                }}
              >
                Confirm
              </Button>
            </Box>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MannualAddCodesPopup;
