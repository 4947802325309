import React, { useContext, useEffect, useState } from "react";
import { Text, Flex, IconButton, Box, Button, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import OfferTitlePage from "../pages/OfferTitlePage";
import OfferIntegration from "../pages/OfferIntegration";
import OfferType from "../pages/OfferType";
import OfferSelectLocation from "../pages/OfferSelectLocation";
import CustomerGroups from "../pages/CustomerGroups";
import OfferCompensationType from "../pages/OfferCompensationType";
import ContentType from "../pages/ContentType";
import NumberOfOffers from "../pages/NumberOfOffers";
import InfluencersGroup from "../pages/InfluencersGroup";
import CashAmount from "../pages/CashAmount";
import CashbackAmount from "../pages/CashbackAmount";
import PrerequisiteOffer from "../pages/PrerequisiteOffer";
import IsFreeReward from "../pages/IsFreeReward";
import ItemDiscount from "../pages/ItemDiscount";
import OfferRequirementsLimits from "../pages/OfferRequirementsLimits";
import ExampleUGC from "../pages/ExampleUGC";
import CrossConceptRewardsOffering from "../pages/CrossConceptRewardsOffering";
import { BackIcon } from "components/icons/Icons";
import { AccountsDataContext } from "contexts/accountsDataContext";
import { AdminDataContext } from "contexts/adminDataContext";
import PayAndCheckout from "../pages/PayAndCheckout";
import OfferSummary from "../pages/OfferSummary";
import { getOfferDetail } from "services/flaskService";
import { checkForDisable, strToFloat } from "utils";
import { removeData } from "utils/localStorage.service";
import UploadDiscountCodes from "../pages/UploadDiscountCodes";

interface InputValues {
  offerName: string;
  date?: string;
  integration?: string;
  integrations?: any;
  isPrivate: boolean;
  isInfluencerOffer: boolean;
  isCustomerOffer: boolean;
  privateOfferPhoneList?: any[];
  chosenCustomerGroups?: any[];
  typeOfOffer: string;
  isCash: boolean;
  isCashBack: boolean;
  locations?: any[];
  existingLocations?: any[];
  influencerPhoneNumbers?: any[];
  contentType: string;
  quantity: number;
  exampleUGC: any;
  OfferImage?: any;
  specificProduct?: string;
  compensation?: any;
  percentOrCashOff?: string;
  isPrerequisite?: boolean;
  prerequisiteName?: string;
  prerequisiteQuantity?: number;
  prerequisiteCollectionID?: string;
  isFreeReward?: boolean;
  discountType: string;
  discountItem?: string;
  discountItemID?: string;
  discountCollectionID?: string;
  minimumFollowerCount?: number;
  minPurchaseAmount?: number;
  expirationLimit?: number;
  rewardClientIDs?: any[];
  rewardClientNames?: any[];
  offerImage?: any;
  offerID?: string;
  instagramStory?: any;
  // added this for upload discount codes
  isUploadCodes?: boolean;
  batchCodes?: string[];
  hashtag?: string;
  ecommerceDiscountCode?: string;
  isEvergreenCode?: boolean;
  productID?: string;
  collectionID?: string;
  exampleUgcFile?: string;
}

const OfferLayout: React.FC = () => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState<string>("offerName"); // Initial step
  const [isOfferLoading, setIsOfferLoading] = useState<boolean>(false); // Example state for user authentication
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isEditing = queryParams?.get("isEditing") === "true";
  const offerID = queryParams?.get("offerID") ?? "";
  const clientID = queryParams?.get("accountId") ?? "";

  // manage initial offer state
  const [inputValues, setInputValues] = useState<InputValues>({
    offerName: "",
    offerID: "",
    integration: "",
    integrations: {},
    date: "",
    isPrivate: false,
    isInfluencerOffer: false,
    isCustomerOffer: false,
    privateOfferPhoneList: [],
    chosenCustomerGroups: [],
    locations: [],
    existingLocations: [],
    influencerPhoneNumbers: [],
    typeOfOffer: "",
    isCash: false,
    isCashBack: false,
    contentType: "instagramStory",
    quantity: 0,
    exampleUGC: "",
    OfferImage: "",
    specificProduct: "",
    compensation: 0,
    percentOrCashOff: "",
    isPrerequisite: false,
    prerequisiteName: "",
    prerequisiteQuantity: 0,
    prerequisiteCollectionID: "",
    isFreeReward: true,
    discountType: "Item Discount",
    discountItem: "",
    discountItemID: "",
    discountCollectionID: "",
    minPurchaseAmount: 0,
    minimumFollowerCount: 100,
    expirationLimit: 30,
    rewardClientIDs: [],
    rewardClientNames: [],
    instagramStory: "",
    hashtag: "",
    // Initialize other input fields here
    isUploadCodes: false,
    batchCodes: [],
    ecommerceDiscountCode: "",
    isEvergreenCode: false,
    productID: "",
    collectionID: "",
    exampleUgcFile: ""
  });

  const [offerDetail, setOfferDetail] = useState<any>({});
  const allAccounts: any = useContext(AccountsDataContext);
  const adminData: any = useContext(AdminDataContext);
  const accountData = allAccounts?.[clientID];
  const clientIntegrations = Object?.keys(accountData?.["integrations"] ?? []);

  useEffect(() => {
    const fetchOfferData = async () => {
      //fetch offer data based on offerID
      try {
        const response = await getOfferDetail(offerID);
        setIsOfferLoading(false);
        if (response?.offer_details) {
          const editingOfferData = response?.offer_details;
          console.log("editing offer detail---", editingOfferData)
          setOfferDetail(editingOfferData);
          const {
            offerID,
            compensation,
            contentType,
            customerGroup,
            date,
            discountCollectionID,
            discountItem,
            discountItemID,
            exampleUGC,
            influencerPhoneNumbers,
            integrations,
            isCash,
            isCustomerOffer,
            isInfluencerOffer,
            isPrivate,
            locations,
            minPurchaseAmount,
            minimumFollowerCount,
            offerImage,
            offerName,
            percentOrCashOff,
            prerequisiteCollectionID,
            prerequisiteName,
            prerequisiteQuantity,
            privateOfferPhoneList,
            quantity,
            rewardClientIDs,
            rewardClientNames,
            specificProduct,
            typeOfOffer,
            discountType,
            expirationLimit,
            isFreeReward,
            rewardProductId,
            rewardItem,
            rewardCollectionId,
            isPrerequisite,
            hashtag,
            batchCodes,
            isUploadCodes,
            isEvergreenCode,
            ecommerceDiscountCode,
            productID = "",
            collectionID = ""
          } = editingOfferData;

          if (integrations && Object.keys(integrations)?.length) {
            const [integrationVal] = Object.keys(integrations);
            handleInputChange("integration", integrationVal);
          }

          // newly added keys.
          handleInputChange("isUploadCodes", isUploadCodes);
          handleInputChange("collectionID", collectionID);
          handleInputChange("productID", productID);
          handleInputChange("isEvergreenCode", isEvergreenCode);
          handleInputChange("ecommerceDiscountCode", ecommerceDiscountCode);


          handleInputChange("offerName", offerID);
          handleInputChange("integrations", integrations);
          handleInputChange("rewardClientNames", rewardClientNames || []);
          handleInputChange("rewardClientIDs", rewardClientIDs || []);
          handleInputChange(
            "minimumFollowerCount",
            Number(minimumFollowerCount)
          );
          handleInputChange("discountCollectionID", discountCollectionID);
          handleInputChange("minPurchaseAmount", strToFloat(minPurchaseAmount));
          handleInputChange("discountItemID", discountItemID);
          handleInputChange("discountItem", discountItem?.trim());
          handleInputChange("isFreeReward", isFreeReward);
          handleInputChange(
            "prerequisiteCollectionID",
            prerequisiteCollectionID
          );
          handleInputChange("prerequisiteQuantity", prerequisiteQuantity);
          handleInputChange("prerequisiteName", prerequisiteName?.trim());
          handleInputChange("isPrerequisite", isPrerequisite);
          handleInputChange("percentOrCashOff", percentOrCashOff);
          handleInputChange("compensation", compensation);
          handleInputChange("specificProduct", specificProduct);
          handleInputChange("offerImage", offerImage);
          handleInputChange("exampleUGC", exampleUGC);
          handleInputChange("quantity", quantity || 0);
          handleInputChange("contentType", contentType);
          handleInputChange(
            "discountType",
            discountType ? discountType : "Item Discount"
          );
          handleInputChange("instagramStory", "");
          handleInputChange("isCash", isCash);
          handleInputChange("typeOfOffer", typeOfOffer);
          handleInputChange("influencerPhoneNumbers", influencerPhoneNumbers);
          handleInputChange("locations", locations);
          // set this because we need to check which location is removed while updating offer.
          handleInputChange("existingLocations", locations);
          handleInputChange("chosenCustomerGroups", customerGroup);
          handleInputChange("privateOfferPhoneList", privateOfferPhoneList);
          handleInputChange("isCustomerOffer", isCustomerOffer);
          handleInputChange("isInfluencerOffer", isInfluencerOffer);
          handleInputChange("isPrivate", isPrivate);
          handleInputChange("date", date);
          handleInputChange("offerID", offerName);
          handleInputChange("offerName", offerName);
          handleInputChange("expirationLimit", expirationLimit);
          handleInputChange("hashtag", hashtag);
        }
      } catch (error) {
        console.error("Error fetching offer data:", error);
        setIsOfferLoading(false);
      }
    };

    if (isEditing && offerID) {
      setIsOfferLoading(true);
      fetchOfferData();
    }
  }, [isEditing, offerID]);

  const steps: {
    id: string;
    component: React.ComponentType<any>;
    data: any;
  }[] = [
      {
        id: "offerName",
        component: OfferTitlePage,
        data: {
          title: "Offer Name",
          description: "Name this offer, it is not shown to the end user",
        },
      },

      {
        id: "integration",
        component: OfferIntegration,
        data: {
          title: "Integration",
          description:
            "Choose the integration you would like to connect this offer to",
          clientIntegrations,
          isEditing,
        },
      },
      {
        id: "offerType",
        component: OfferType,
        data: {
          title: "Offer Type",
          description:
            "Choose the audience of the offer you would like to offer this to",
          clientID,
          isEditing,
        },
      },
      {
        id: "influencerGroups",
        component: InfluencersGroup,
        data: {
          title: "Select Influencers",
          description:
            "Pick and Choose the influencers you want to receive this offer",
          clientID,
          isEditing,
          offerDetail
        },
      },
      {
        id: "customerGroups",
        component: CustomerGroups,
        data: {
          title: "Select Private Customer Groups/Customers",
          description:
            "Select customer groups and/or add customers you want to send this offer to",
          clientID,
          isEditing, offerDetail
        },
      },
      {
        id: "uploadDiscountCodes",
        component: UploadDiscountCodes,
        data: {
          title: "Upload Discount Codes",
          description:
            "Upload discount code to be redeemed at your store(be sure these codes are registered in your system)",
          clientID,
          accountData,
          isEditing,
          editedOfferId: offerID,
          offerDetail
        },
      },
      {
        id: "locations",
        component: OfferSelectLocation,
        data: {
          title: "Select Location",
          description: "Choose which one of your locations support this offer",
          clientID,
        },
      },
      {
        id: "typeOfOffer",
        component: OfferCompensationType,
        data: {
          title: "Offer Compensation Type",
          description:
            "Choose what type of reward you would like this offer to consist of",
          clientID,
          isEditing,
        },
      },
      {
        id: "contentType",
        component: ContentType,
        data: {
          title: "Content Type",
          description:
            "Choose the type of media that is tied with the reward. All of the social media content require the customer to tag your brand",
          clientID,
          isEditing,
          offerDetail
        },
      },
      {
        id: "quantity",
        component: NumberOfOffers,
        data: {
          title: "Number of Offers",
          description:
            "How many offers are available to your customer base for acceptance",
          clientID,
          isEditing,
          offerDetail
        },
      },
      {
        id: "exampleUGC",
        component: ExampleUGC,
        data: {
          title: "Example UGC",
          description:
            "Upload an example of what an ideal submission should be and mention what should be included in the submission",
          clientID,
          isEditing,
        },
      },
      {
        id: "cashAmount",
        component: CashAmount,
        data: {
          title: "Cash Reward Amount",
          description: "How much is the cash amount for completing the offer",
          clientID,
          accountData,
          isEditing,
          offerDetail
        },
      },
      {
        id: "cashBackAmount",
        component: CashbackAmount,
        data: {
          title: "Cashback Reward Amount",
          description:
            "What percentage of the customer’s order are they getting back for completing the offer",
          clientID,
          accountData,
          isEditing,
          editedOfferId: offerID,
          offerDetail
        },
      },
      {
        id: "payAndCheckout",
        component: PayAndCheckout,
        data: {
          title: "Checkout & Pay",
          description: "See your offer summary and pay",
          clientID,
          accountData,
          adminData,
          isEditing,
          editedOfferId: offerID,
          offerDetail
        },
      },
      {
        id: "prerequisiteOffer",
        component: PrerequisiteOffer,
        data: {
          title: "Prerequisite Offer",
          description: `An offer type that allows a “prerequisite”. Ex. Post an Instagram Story to get a free shirt with a purchase of 3 shirts.`,
          clientID,
          accountData,
        },
      },
      {
        id: "freeReward",
        component: IsFreeReward,
        data: {
          title: "Free Reward",
          description: "Are you offering free reward with your offer",
          clientID,
          accountData,
          clientIntegrations,
          isEditing,
          offerDetail,
        },
      },
      {
        id: "discountSpecifics",
        component: ItemDiscount,
        data: {
          title: "Discount Specifics",
          description: "Specify the parameters of the discount you are offering",
          clientID,
          accountData,
          clientIntegrations,
          isEditing,
          offerDetail,
        },
      },
      {
        id: "OfferRequirementLimit",
        component: OfferRequirementsLimits,
        data: {
          title: "Offer Requirements Limits",
          description:
            "Constrain the limits of the offer, tailor who can accept this offer",
          clientID,
          accountData,
          isEditing,
        },
      },
      {
        id: "CrossConceptRewarding",
        component: CrossConceptRewardsOffering,
        data: {
          title: "Cross Concept Rewarding",
          description:
            "Choose where out of your concepts this reward can be redeemed",
          clientID,
          accountData,
          allAccounts,
          isEditing,
        },
      },
      {
        id: "offerSummary",
        component: OfferSummary,
        data: {
          title: "Offer Summary",
          description:
            "View the overall summary of your offer and edit things accordingly",
          clientID,
          accountData,
          isEditing,
          editedOfferId: offerID,
          offerDetail,
        },
      },
    ];

  /**
   * @info check which step is enable currently
   * @param currentStepId
   * @returns
   */
  const checkForCurrentStep = (currentStepId: string): boolean => {
    // Ensure that the function returns a boolean.
    // The !! operator will convert the value to a boolean ensuring that undefined is converted to false.
    switch (currentStepId) {
      case "offerName":
        // if current step is offername then clear localstorage
        if (inputValues.offerName?.trim()) {
          removeData(`${inputValues.offerName}-offer-image`);
          removeData(`${inputValues.offerName}-image`);
        }
        return !inputValues.offerName?.trim()?.length;
      case "offerType":
        if (
          inputValues.isPrivate ||
          inputValues.isCustomerOffer ||
          inputValues.isInfluencerOffer
        ) {
          return false;
        } else {
          return true;
        }
      case "customerGroups":
        return !inputValues.chosenCustomerGroups?.length;
      case "influencerGroups":
        return !inputValues.influencerPhoneNumbers?.length;
      case "uploadDiscountCodes":
        if (inputValues.isEvergreenCode) {
          return !inputValues.ecommerceDiscountCode?.length;
        }
        if (inputValues?.isUploadCodes) {
          if (isEditing && offerDetail?.batchCodes?.length) {
            if (inputValues?.isCustomerOffer) {
              return false;
            } else if (inputValues?.isPrivate) {
              if ([...inputValues?.batchCodes, ...(offerDetail?.batchCodes || [])].length >= inputValues?.privateOfferPhoneList?.length) {
                return false;
              }
              return true
            } else if (inputValues?.isInfluencerOffer) {
              if ([...inputValues?.batchCodes, ...(offerDetail?.batchCodes || [])].length >= inputValues?.influencerPhoneNumbers?.length) {
                return false;
              }
              return true
            }
            return true;
          } else {

            if (inputValues?.isCustomerOffer) {
              return !inputValues?.batchCodes?.length;
            } else if (inputValues?.isPrivate) {
              if (inputValues?.batchCodes?.length >= inputValues?.privateOfferPhoneList?.length) {
                return false;
              }
              return true
            } else if (inputValues?.isInfluencerOffer) {
              if (inputValues?.batchCodes?.length >= inputValues?.influencerPhoneNumbers?.length) {
                return false;
              }
              return true
            }
            return !inputValues?.batchCodes?.length;
          }
        }
        return inputValues?.isUploadCodes;

      case "locations":
        return !inputValues?.locations?.length;
      case "typeOfOffer":
        return !inputValues?.typeOfOffer;
      case "contentType":
        return !inputValues?.contentType;
      case "quantity":
        return !inputValues?.quantity;
      case "exampleUGC":
        if (inputValues?.exampleUGC) {
          if (!inputValues?.specificProduct?.length) {
            return false;
          } else {
            if (inputValues?.specificProduct?.trim().length) {
              return false;
            }
          }
        }
        return true;
      case "cashAmount":
        if (inputValues.isCustomerOffer && ["ugcPicture", "ugcVideo"]?.indexOf(inputValues?.contentType) === -1) {
          console.log("cashAmount===> if condition---", inputValues?.contentType)
          return !inputValues.minimumFollowerCount || !inputValues.compensation;
        } else {
          console.log("cashAmount===> else condition---", inputValues?.contentType)

          return !inputValues.compensation;
        }
      case "prerequisiteOffer":
        if (inputValues.isPrerequisite) {
          return (
            !inputValues.prerequisiteName?.trim().length ||
            !inputValues.prerequisiteCollectionID ||
            !inputValues.prerequisiteQuantity
          );
        } else {
          return false;
        }

      case "freeReward":
        if (inputValues.isFreeReward) {
          if (clientIntegrations?.length) {
            return (
              !inputValues.discountItem?.trim().length ||
              !inputValues.discountItemID
            );
          } else {
            return !inputValues.discountItem?.trim().length;
          }
        } else {
          return false;
        }
      case "discountSpecifics":
        if (inputValues.discountType === "Item Discount") {
          if (clientIntegrations?.length) {
            return (
              !inputValues.discountItem?.trim() ||
              !inputValues.discountItemID ||
              !inputValues.discountCollectionID ||
              !inputValues.compensation
            );
          } else {
            return (
              !inputValues.discountItem?.trim() || !inputValues.compensation
            );
          }
        }
        return !inputValues.compensation;
      case "OfferRequirementLimit":
        // if (inputValues.isCustomerOffer) {
        //   return (
        //     // !inputValues.minPurchaseAmount ||
        //     !inputValues.minimumFollowerCount ||
        //     !inputValues.expirationLimit
        //   );
        // } else {
        return !inputValues?.expirationLimit;
      // }
      case "CrossConceptRewarding":
        return false;
      default:
        return !!!inputValues[currentStepId as keyof InputValues];
    }
  };

  // Filter steps based on conditions
  const filteredSteps = steps.filter((step) => {
    switch (step.id) {
      case "offerType":
        return !isEditing;
      case "integration":

        // hide integration screen while editing offer if integration available also hide is no integration while adding offer

        if (isEditing) {
          return false;
        } else {
          return clientIntegrations?.length
        }
      case "influencerGroups":
        return !inputValues?.isInfluencerOffer ? false : true;
      case "customerGroups":
        return !inputValues.isPrivate ? false : true;
      case "locations":
        return clientID && accountData?.locations?.length;
      case "uploadDiscountCodes":
        /**
         * skip this screen if client have any integration
         * skip this screen in edit flow for cash and cashback both
         */
        if (clientIntegrations.length) {
          return false;
        }
        if (isEditing) {
          return inputValues?.typeOfOffer == 'credit' ? true : false
        } else {
          return true;
        }
      // return !clientIntegrations.length || (!isEditing && !(["cash", "cashback"].indexOf(inputValues.compensation) > -1)) ? true : false;

      case "typeOfOffer":
        if (isEditing) {
          return false;
        } else {
          return !(inputValues?.isEvergreenCode || inputValues?.isUploadCodes)
        }
      case "quantity":
        if (inputValues?.isUploadCodes) {
          return false;
        }
        return inputValues?.isCustomerOffer;
      case "cashBackAmount":
        return inputValues.typeOfOffer === "cashback";
      case "cashAmount":
        return inputValues.typeOfOffer === "cash";
      case "prerequisiteOffer":
        // handle condition for bigcommerce
        if (inputValues?.integration === "bigCommerce") {
          return false;
        } else {
          return inputValues.typeOfOffer === "credit" &&
            clientIntegrations.length
            ? true
            : false;
        }
      case "freeReward":
        // handle condition for bigcommerce
        if (isEditing && checkForDisable(isEditing, inputValues, offerDetail)) {
          return false;
        }
        return !(inputValues?.integration === "bigCommerce");
      case "payAndCheckout":
        return inputValues.typeOfOffer === "cash" && inputValues.compensation;
      case "creditAmount":
        return inputValues.typeOfOffer === "credit";
      case "discountSpecifics":
        return !inputValues.isFreeReward;
      // case "payAndCheckout":
      //   return
      case "CrossConceptRewarding":
        // include this step when a client have not select any integration and more then one account
        return (
          !inputValues.integration &&
          allAccounts &&
          Object.keys(allAccounts)?.length > 1
        );
      default:
        // include all other steps
        return true;
    }
  });

  const currentStepIndex = filteredSteps.findIndex(
    (step) => step.id === currentStep
  );

  const currentStepId = filteredSteps[currentStepIndex]?.id;
  const currentStepData = filteredSteps[currentStepIndex]?.data;
  const CurrentComponent = filteredSteps[currentStepIndex]?.component;

  const handleNext = () => {
    const nextStepIndex = currentStepIndex + 1;
    if (nextStepIndex < filteredSteps.length) {
      setCurrentStep(filteredSteps[nextStepIndex]?.id);
    }
  };

  const handlePrev = () => {
    const prevStepIndex = currentStepIndex - 1;
    if (prevStepIndex >= 0) {
      setCurrentStep(filteredSteps[prevStepIndex]?.id);
    }
  };

  const handleInputChange = (fieldName: keyof InputValues, value: any) => {
    setInputValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  if (isEditing && offerID && isOfferLoading) {
    console.log("Getting offer details.==>");
    return (
      <Box>
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      </Box>
    );
  }

  // check is account exist or not
  if ((allAccounts && !allAccounts[clientID]) || adminData?.isLoading) {
    return (
      <Box>
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      </Box>
    );
  }

  return (
    <Flex
      flexDirection={"column"}
      padding={"22px 40px 40px"}
      height={currentStepId === "locations" ? "calc(100vh - 135px)" : "auto"}
      minH={currentStepId !== "locations" ? "calc(100vh - 135px)" : undefined}
      overflow={"hidden"}
      backgroundColor="#FCFCFC"
      borderRadius="10px"
      position="relative"
    >
      <Flex
        borderBottom={"1px solid #EAECF0"}
        alignItems="center"
        margin={"0px -18px"}
        gap={"13px"}
        paddingBottom={"22px"}
      >
        <IconButton
          aria-label="Go Back"
          icon={<BackIcon h="32px" w="32px" />}
          onClick={() => {
            currentStep === "offerName" ? history.goBack() : handlePrev();
          }}
          style={{
            background: "transparent",
            border: "none",
            marginLeft: -14,
            paddingRight: 0,
          }}
        />
        <Flex flexDirection="column" gap="5px">
          <Text fontWeight="500" fontSize="chakra_2xl" color={"#101828"}>
            {currentStepData.title}
          </Text>
          <Text fontWeight="400" color="#667085" fontSize="chakra_lg">
            {currentStepData.description}
          </Text>
        </Flex>
      </Flex>
      <Box
        margin={"0px -15px"}
        padding={"0px 15px"}
        overflow={currentStepId === "locations" ? "hidden" : "initial"}
        flex={"1 1 auto"}
        display={"flex"}
        flexDirection={"column"}
      >
        <CurrentComponent
          data={currentStepData}
          inputValues={inputValues}
          onInputChange={handleInputChange}
        />
      </Box>
      {/* Footer */}

      {!(currentStepId === "cashBackAmount") &&
        !(currentStepId === "payAndCheckout") &&
        !(currentStepId === "offerSummary") && (
          <Flex
            justifyContent={"flex-end"}
            borderTop={"1px solid #EAECF0"}
            paddingTop={"24px"}
            mt={"auto"}
          >
            <Button
              onClick={handleNext}
              type="submit"
              variant="chakra_btn_secondary"
              size="chakra_xs"
              _hover={{
                transform: "scale(1.05)",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _focus={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _active={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              disabled={
                currentStepIndex === filteredSteps?.length - 1 ||
                checkForCurrentStep(currentStepId)
              }
            >
              Next
            </Button>
          </Flex>
        )}
    </Flex>
  );
};

export default OfferLayout;
