import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { Box, Input, Flex, Text, useToast } from "@chakra-ui/react";
import { UploadIcon } from "components/icons/Icons";

interface CsvUploaderProps {
  inputValues: {
    isUploadCodes: boolean;
    batchCodes?: string[];
    privateOfferPhoneList?: any[];
    influencerPhoneNumbers?: any[];
    isCustomerOffer: boolean;
  };
  setLoading?: Dispatch<SetStateAction<boolean>>;
  existingCodes: string[];
  checkCodesForPrivateAndInfluencer?: any
  onInputChange: (
    fieldName: keyof CsvUploaderProps["inputValues"],
    value: any
  ) => void;
  data?: any;
  isEdit: boolean;
}

const CSVUploader: React.FC<CsvUploaderProps> = ({
  inputValues,
  onInputChange,
  data: additionalDetails,
  existingCodes,
  setLoading,
  isEdit,
  checkCodesForPrivateAndInfluencer
}) => {
  const toast = useToast();
  const [inputId, setInputId] = useState<string>("");
  const [isDragging, setIsDragging] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null); // Reference to the Box element
  useEffect(() => {
    console.log("input id-- in effer", inputId)
    if (!inputId) {
      setInputId(`file-input-${Math?.random()?.toString(36).substr(2, 9)}`);
    }
  }, [inputId, setInputId])


  useEffect(() => {
    // Generate a unique ID for the input element
    // Add event listeners to handle dropping outside the box
    const handleDocumentDragEnter = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const handleDocumentDragOver = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const handleDocumentDrop = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragging(false);
    };

    document.addEventListener("dragenter", handleDocumentDragEnter);
    document.addEventListener("dragover", handleDocumentDragOver);
    document.addEventListener("drop", handleDocumentDrop);

    return () => {
      document.removeEventListener("dragenter", handleDocumentDragEnter);
      document.removeEventListener("dragover", handleDocumentDragOver);
      document.removeEventListener("drop", handleDocumentDrop);
    };
  }, []);
  const handleFileUpload = (event) => {
    const file = event?.target?.files[0];
    if (!file) {
      return; // Handle no file selected
    }

    if (file?.type !== "text/csv") {
      // setErrorMessage("Please upload a CSV file (.csv).");
      toast({
        title: "Validation Errors",
        description: "Please upload a CSV file (.csv).",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      resetInput();
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const csvData = event?.target?.result;
      setLoading(true);
      try {
        const parsedData = csvFileToArray(csvData);
        setTimeout(() => {
          const { duplicateCount, invalidCount, validCount, validElements } =
            getElementStats(parsedData);
          console.log(
            "{ duplicateCount, invalidCount, validCount, validElements }",
            { duplicateCount, invalidCount, validCount, validElements }
          );
          const canCodeAdd = checkCodesForPrivateAndInfluencer([...validElements, ...(existingCodes || [])]);
          setLoading(false);
          // if (!canCodeAdd) {
          //     const privateOfferPhoneListLength = inputValues.privateOfferPhoneList?.length || 0;
          //     const influencerPhoneNumbersLength = inputValues.influencerPhoneNumbers?.length || 0;
          //     const totalCodes = existingCodes?.length + validCount || 0;
          //     const ToastTitle = inputValues.isCustomerOffer ? `${validCount ?? 0} code(s) uploaded.` : `${validCount ?? 0} code(s) uploaded.
          // ${privateOfferPhoneListLength ? "Private customer: " + privateOfferPhoneListLength + "\nTotal code(s): " + totalCodes : ""}
          // ${influencerPhoneNumbersLength ? "Influencer's customer: " + influencerPhoneNumbersLength + "\nTotal code(s): " + totalCodes : ""}`;

          toast({
            // title: `CSV uploaded successfully. \n Newely added codes :${validCount}, \n Invalid codes :${invalidCount}, \n Duplicate codes :${duplicateCount}.`,
            title: `${validCount ?? 0} code(s) uploaded.`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onInputChange("batchCodes", [
            ...new Set([...inputValues?.batchCodes, ...validElements]),
          ]);

          // } else {
          //   toast({
          //     title: "Validation Errors",
          //     description: "Number of total codes should be greater then number of total customers/influencers.",
          //     status: "error",
          //     duration: 3000,
          //     isClosable: true,
          //   });
          // }
        }, 1000);
        resetInput();
      } catch (error: any) {
        // setErrorMessage(error?.message || error);
        setLoading(false);
        resetInput();
        toast({
          title: "Validation Errors",
          description: error?.message || error,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
    reader.readAsText(file);
  };

  const csvFileToArray = (string) => {
    const lines = string?.split(/\r?\n/); // Handle both \n and \r\n

    // Extract header (first line)
    const csvHeader = lines[0]?.trim()?.split(",");

    // Validate column count (should only be one)
    if (csvHeader?.length !== 1) {
      throw new Error(
        "Invalid CSV, please upload a valid CSV file (single column)."
      );
    }

    // Validate column name (should be "discountCode")
    const colName = csvHeader[0];
    if (colName !== "discountCode") {
      throw new Error(
        "Invalid CSV, please upload a valid CSV file (column name: discountCode)."
      );
    }

    // Extract data rows (skip header)
    const csvRows = lines?.slice(1)?.filter((row) => row?.trim()); // Filter empty rows
    if (!csvRows?.length) {
      throw new Error("Invalid CSV data, please upload a valid CSV file");
    }
    const dataRow = csvRows?.map((row) => {
      const value = row?.trim(); // Trim data values as well
      return value;
    });
    if (dataRow?.length > 1000) {
      throw new Error("1000 codes can be uploaded at once.");
    }
    return dataRow;
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    // Check if the cursor is outside the box
    if (!boxRef.current) return;

    const { clientX, clientY } = event;
    const { top, bottom, left, right } = boxRef?.current?.getBoundingClientRect();

    if (
      clientX <= left ||
      clientX >= right ||
      clientY <= top ||
      clientY >= bottom
    ) {
      setIsDragging(false);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event?.dataTransfer?.files[0];
    setIsDragging(false); // Reset drag-active state after drop

    if (!file) {
      return; // Handle no file selected
    }

    if (file.type !== "text/csv") {
      // setErrorMessage("Please upload a CSV file (.csv).");
      toast({
        title: "Validation Errors",
        description: "Please upload a CSV file (.csv).",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      resetInput();
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const csvData = event?.target?.result;
      setLoading(true);
      try {
        const parsedData = csvFileToArray(csvData);
        setTimeout(() => {

          const { duplicateCount, invalidCount, validCount, validElements } =
            getElementStats(parsedData);
          // console.log(
          //   "{ duplicateCount, invalidCount, validCount, validElements }",
          //   { duplicateCount, invalidCount, validCount, validElements }
          // );
          const canCodeAdd = checkCodesForPrivateAndInfluencer([...validElements, ...(existingCodes || [])]);

          setLoading(false);
          // if (!canCodeAdd) {
          //   const privateOfferPhoneListLength = inputValues.privateOfferPhoneList?.length || 0;
          //   const influencerPhoneNumbersLength = inputValues.influencerPhoneNumbers?.length || 0;
          //   const totalCodes = existingCodes?.length + validCount || 0;
          //   const ToastTitle = inputValues.isCustomerOffer ? `${validCount ?? 0} code(s) uploaded.` : `${validCount ?? 0} code(s) uploaded.
          // ${privateOfferPhoneListLength ? "Private customer: " + privateOfferPhoneListLength + "\nTotal code(s): " + totalCodes : ""}
          // ${influencerPhoneNumbersLength ? "Influencer's customer: " + influencerPhoneNumbersLength + "\nTotal code(s): " + totalCodes : ""}`;

          toast({
            // title: `CSV uploaded successfully. \n Newely added codes :${validCount}, \n Invalid codes :${invalidCount}, \n Duplicate codes :${duplicateCount}.`,
            title: `${validCount ?? 0} code(s) uploaded.`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onInputChange("batchCodes", [
            ...new Set([...inputValues?.batchCodes, ...validElements]),
          ]);

          // } else {
          //   toast({
          //     title: "Validation Errors",
          //     description: "Number of total codes should be greater then number of total customers/influencers.",
          //     status: "error",
          //     duration: 3000,
          //     isClosable: true,
          //   });
          // }
        }, 1000);
        resetInput();
      } catch (error: any) {
        setLoading(false);
        resetInput();
        toast({
          title: "Validation Errors",
          description: error?.message || error,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
    reader.readAsText(file);
  };

  const isValidElement = (element) => {
    // Check if the element contains only numbers and characters
    const regex = /^[a-zA-Z0-9]+$/;
    return regex?.test(element);
  };

  const findDuplicates = (csvData) => {
    const data = new Set(csvData);
    return inputValues?.batchCodes?.filter((element) => data?.has(element));
  };

  const findDuplicatesFromExistingCodes = (codes) => {
    const data = new Set(codes);
    return existingCodes?.filter((element) => data?.has(element));

  }

  const getElementStats = (data) => {
    const csvData = [...new Set([...data])];
    // Step 1: Validate elements from csvData
    const invalidElements = [];
    const validCsvData = csvData?.filter((element) => {
      if (!isValidElement(element)) {
        invalidElements?.push(element);
        return false;
      }
      return true;
    });

    // Step 2: Check for duplicates and remove them
    const duplicates = findDuplicates(validCsvData);
    let uniqueValidCsvData = validCsvData?.filter(
      (element) => !duplicates?.includes(element)
    );

    // check all codes with existing codes and this is edit flow only
    if (isEdit) {
      const duplicateFromExisting = findDuplicatesFromExistingCodes(uniqueValidCsvData);
      uniqueValidCsvData = duplicateFromExisting?.length ? validCsvData?.filter(
        (element) => !duplicateFromExisting?.includes(element)
      ) : uniqueValidCsvData;
    }

    const duplicateCount = duplicates?.length;
    const invalidCount = invalidElements?.length;
    const validCount = uniqueValidCsvData?.length;

    return {
      duplicateCount,
      invalidCount,
      validCount,
      validElements: uniqueValidCsvData,
    };
  };

  const resetInput = () => {
    const input = document?.getElementById(inputId) as HTMLInputElement;
    if (input) {
      input.value = "";
    }
  };

  return (
    <Flex direction="column" align="start" justify="flex-start">
      {
        <Box
          ref={boxRef} // Assign reference to the Box element
          backgroundColor={isDragging ? "rgba(0, 0, 0, 0.1)" : "#F4F4F4"} // Dynamic background color
          width="514px"
          borderStyle="dashed"
          borderRadius="lg"
          textAlign="center"
          cursor="pointer"
          onClick={() => {
            const input = document?.getElementById(inputId);
            input?.click();
          }}
          // this we have tried to implement drag and drop functionality for this but not working properly so I have commented as of now.
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            height="100%"
            justifyContent="center"
            py="30px"
            px="24px"
          >
            <UploadIcon h="40px" w="40px" mb="12px" />
            <Text fontSize="chakra_md" fontWeight="600" mb="0px" color="black">
              {isDragging ? "Drop the file here" : "Click to Upload CSV file"}
            </Text>
            {/* Change the text based on drag-over state */}
            <Text fontSize="chakra_md" fontWeight="400" color="#667085">
              or drag an drop
            </Text>
            <Text fontSize="chakra_md" fontWeight="400" color="#667085">
              CSV
            </Text>
            {/* {errorMessage && (
              <Text fontSize="chakra_md" fontWeight="400" color="red">
                {errorMessage}
              </Text>
            )} */}
            <Input
              type="file"
              draggable={true}
              accept=".csv"
              onChange={handleFileUpload}
              id={inputId}
              display="none"
            />
          </Flex>
        </Box>
      }
    </Flex>
  );
};

export default CSVUploader;

