import { createIcon } from "@chakra-ui/react";
import React from "react";
import visaIcon from "assets/paymentIcons/visa.png";
import amexIcon from "assets/paymentIcons/amex.png";

import dinnersIcon from "assets/paymentIcons/dinners.png";
import discoverIcon from "assets/paymentIcons/discover.png";

import genericIcon from "assets/paymentIcons/generic.png";
import jcbIcon from "assets/paymentIcons/jcb.png";
import mastercardICon from "assets/paymentIcons/mastercard.png";
import unionPay from "assets/paymentIcons/unionPay.png";



export const PaymentIconLink: any = {
    "american express": amexIcon,
    "diners club": dinnersIcon,
    "diners": dinnersIcon,
    discover: discoverIcon,
    "eftpos australia": genericIcon,
    jcb: jcbIcon,
    mastercard: mastercardICon,
    visa: visaIcon,
    unionpay: unionPay,
    unknown: genericIcon,
};
