import { AccountsDataContext } from "contexts/accountsDataContext";
import { AdminDataContext } from "contexts/adminDataContext";
import OffersDataProvider from "contexts/offersDataContext";
import { useContext } from "react";
import OfferHistory from "./offerHistory";

export default function OfferHistoryDataBridge() {
    const accountsData = useContext(AccountsDataContext);
    // get offer ids from accountsData
    // iterate through each account and get offer ids
    // add offer ids to offerIds array
    // let offerIds = [];
    let clientAccounts = []
    for (let account in accountsData) {
        if (account && accountsData[account]) {
            clientAccounts.push({
                ...accountsData[account]
            })
        }
        // offerIds.push(...accountsData[account]?.offers);
    }

    /**
     * @info
     * changes made by nirmal on date 17/may/2024 as we don't need to pass offersId from here because using custom hook to get offer data
     */

    // removed this offer Data provider as we do not need we are using customer hook to fetch offer data
    return <OfferHistory clientAccounts={clientAccounts} />
    // return <OffersDataProvider offerIds={offerIds}   >
    //     <OfferHistory clientAccounts={clientAccounts} />
    // </OffersDataProvider>


}