import { Box, Text, Flex, IconButton, Button, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { AccountsDataContext } from "contexts/accountsDataContext";
import { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LocationsDataContext } from "../../../../../contexts/locationsDataContext";
import AddLocationButton from "./components/AddLocationButton";
import LocationCardBig from '../locations/components/LocationCardBig';
import BackButton from "components/buttons/BackButton";
import Card from "components/card/Card";
const AccountLocations = () => {
  const history = useHistory();
  const locationsData = useContext(LocationsDataContext);
  const accountsData = useContext(AccountsDataContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const clientID = queryParams.get("accountId");
  const clientData = accountsData[clientID];
  const [isBackButtonHovered, setIsBackButtonHovered] = useState(false);


  const filteredLocationsData = Object.values(locationsData?.data).filter((location: any) => location.clientID === clientID);

  console.log(locationsData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (locationsData?.isLoading) {
    return (<Card flexDirection="column" mt="25px">
      <Flex justifyContent="center" alignItems="center" height="200px">
        <Spinner size="xl" />
      </Flex>
    </Card>);
  }

  return (
    <Box
      backgroundColor="white"
      //   height="900px"
      mt="75px"
      borderRadius="10px"
    >
      <Flex alignItems="center" justifyContent="space-between" pr="10px">
        <Flex alignItems="center" pt="25px" pb="25px">
          <BackButton isHovered={isBackButtonHovered} setIsHovered={setIsBackButtonHovered} />


          <Text ml="10px" fontWeight="bold" fontSize="30px">
            Locations for {clientData?.accountName}
          </Text>
        </Flex>
        <AddLocationButton clientID={clientData?.clientID} mr="20px" />
      </Flex>

      <Flex alignItems="center" justifyContent="center">
        <Box borderTop="1.3px solid #E2E8F0" mb="10px" width="96%" />
      </Flex>

      <Box mt="20px" width="100%" mb="20px">
        <Flex flexDirection="column" alignItems="center">
          {filteredLocationsData.map((location: any, index: number) => (
            <LocationCardBig
              key={index}
              location={location}
              clientID={clientID}
            />
          ))}
        </Flex>
      </Box>

      {/* Content of the component goes here */}
    </Box>
  );
};

export default AccountLocations;
