import {
  Box,
  ButtonGroup,
  Flex,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Dropdown from "./components/dropDown";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import useOffersData from "services/offerDataService";
import { AdminDataContext } from "contexts/adminDataContext";

export default function OfferHistory({ clientAccounts }: any) {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [processedOffers, setProcessedOffers] = useState([]);
  const [filteredClientId, setFilteredClientId] = useState(clientAccounts?.length ? clientAccounts[0]?.clientID : null
  );

  const history = useHistory();
  /**
   * @info changes made by nirmal on date :- 16/05/2024
   * Added custome hook to get offerData
  */
  const { adminData } = useContext(AdminDataContext);
  const {
    isLoading: offerLoading = true,
    showNext,
    showPrevious,
    offersData,
    totalOffers,
    fetchedRecordCount,
  } = useOffersData({
    clientID: (filteredClientId ?? (clientAccounts[0]?.clientID || null)),
    perPage: 10,
    userId: adminData?.userID || "",
  });

  useEffect(() => {
    /**
     * @info changes made by nirmal on date :- 26/04/2024
     * Added a filter for the task below
     *  #`Adding a filter into the History tab in the dashboard to allow customers to filter offers by brand`
     */
    if (
      offersData &&
      typeof offersData === "object" &&
      Object.keys(offersData)?.length
    ) {
      // Convert the object of offers to an array and sort by date in descending order
      let sortedOffers = Object.values(offersData)
        .sort((a: any, b: any) => b.date - a.date) // Assuming 'date' is a timestamp
        .map((offer: any) => {
          // Set offer type based on its properties
          offer.offerType = `${offer.isCash ? "Cash" : "Discount"}`;
          // changes made by nirmal on 13/05/2024 for key mismatch on history
          offer.totalQty = offer.quantity;
          // Calculate additional offer properties if necessary
          if (offer.quantity != null) {
            offer.amountSpent = offer.isCash
              ? offer.quantity * offer.compensation
              : 0;
            offer.offersRedeemed = offer.quantity - offer.numberOffersLeft;
          }
          return offer;
        })
        .filter((offer) => {
          // Apply filtering if filteredOffers is present
          if (filteredClientId) {
            return offer.clientID === filteredClientId;
          }
          return true; // If no filter is applied, return true for all items
        });

      // Update the state with processed and filtered offers
      setProcessedOffers(sortedOffers);
    }
  }, [filteredClientId, offersData, offerLoading]); // Run this effect whenever offersData or filteredOffers changes

  if (offerLoading) {
    return (
      <Box>
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      </Box>
    );
  }

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${month}/${day}/${year}`;
  };
  const offersLength = processedOffers.length;
  return (
    <Box>
      <Box
        overflow="visible"
        backgroundColor="white"
        borderRadius="10px"
        width="100%"
        // height={offersLength * 75 + 196}
        position="relative"
        px="25px"
        boxSizing="border-box"
      >
        <Box pt="25px" left={0} width="100%" height="100%">
          <Flex pb="25" justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <Text
                ml="10px"
                mt="0px"
                fontWeight="bold"
                fontSize="25px"
                mb="0px"
              >
                Offer History
              </Text>
            </Flex>
            {
              // Added a dropdown here for filter offer's by brand and it's already defined just I have called it here and added filter related logic in this(dropdown) component, for more clearification please check `Dropdown` component.
            }

            {
              clientAccounts && clientAccounts?.length > 0 && (

                <Dropdown
                  filteredClientId={filteredClientId}
                  setFilteredClientId={setFilteredClientId}
                  clientAccounts={clientAccounts}
                />
              )
            }
          </Flex>
          <Box maxWidth="100%" minHeight="300px" height="calc(100vh - 332px)" overflowY="scroll">
            <Table>
              <Thead>
                <Tr>
                  <Th color="grey.600" fontSize="14px" fontWeight="bold">
                    Offer name
                  </Th>
                  <Th color="grey.600" fontSize="14px" fontWeight="bold">
                    Offer type
                  </Th>
                  <Th color="grey.600" fontSize="14px" fontWeight="bold">
                    Date
                  </Th>
                  <Th color="grey.600" fontSize="14px" fontWeight="bold">
                    Amount spent
                  </Th>
                  <Th color="grey.600" fontSize="14px" fontWeight="bold">
                    Qty Left
                  </Th>
                  <Th color="grey.600" fontSize="14px" fontWeight="bold">
                    Qty Redeemed
                  </Th>
                  <Th color="grey.600" fontSize="14px" fontWeight="bold">
                    Starting Qty
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {processedOffers.length === 0 ? (
                  <Tr
                    rounded="md"
                    backgroundColor="#FFFFFF"
                    style={{ cursor: "pointer" }}
                    fontSize="15px"
                    height="75px"
                  >
                    <Td colSpan={8} textAlign="center">
                      No offers found.
                    </Td>
                  </Tr>
                ) : (
                  processedOffers.map((offer: any, index: number) => (
                    <Tr
                      key={index}
                      rounded="md"
                      onMouseEnter={() => setHoveredRow(index)}
                      onMouseLeave={() => setHoveredRow(null)}
                      backgroundColor={
                        hoveredRow === index ? "#DDEAF5" : "#FFFFFF"
                      }
                      style={{ cursor: "pointer" }}
                      fontSize="15px"
                      height="75px"
                      onClick={() => {
                        history.push(
                          `/admin/view-offer/?offerId=${offer.offerID}`
                        );
                      }}
                    >
                      <Td>{offer.offerName}</Td>
                      <Td>{offer.offerType}</Td>
                      <Td>{formatDate(offer.date) ?? "N/A"}</Td>
                      <Td>{offer.amountSpent ?? "N/A"}</Td>
                      <Td>{offer.numberOffersLeft}</Td>
                      <Td>{offer.offersRedeemed ?? "N/A"}</Td>
                      <Td>{offer.totalQty ?? "N/A"}</Td>
                      <Td>
                        <ChevronRightIcon w="20px" h="20px" color="black" />
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </Box>

          {/**
           * @info changes made by nirmal on date :- 16/05/2024
           * Added Pagination 
           */}
          <Box maxWidth="100%" overflow="hidden">
            {processedOffers && processedOffers.length > 0 && (
              <Flex
                alignItems="center"
                padding="15px"
                justifyContent="right"
                mb="30px"
                px="40px"
              >
                {/* <Text as={'b'} mr={2}>Page : {currentPage}</Text> */}
                <ButtonGroup variant="outline" spacing="2">
                  <IconButton
                    icon={<ChevronLeftIcon />}
                    fontSize="30px"
                    aria-label="Previous Page"
                    title="Previous Page"
                    disabled={
                      !processedOffers.length ||
                      fetchedRecordCount <= processedOffers?.length
                    }
                    onClick={() => {
                      showPrevious(processedOffers[0], processedOffers?.length);
                    }}
                  />
                  <IconButton
                    icon={<ChevronRightIcon />}
                    aria-label="Next Page"
                    title="Next Page"
                    fontSize="30px"
                    // disabled={perPageLimit !== Object.keys(offersData)?.length}
                    disabled={
                      !processedOffers.length ||
                      fetchedRecordCount >= totalOffers
                    }
                    onClick={() => {
                      showNext(
                        processedOffers[processedOffers.length - 1],
                        fetchedRecordCount
                      );
                    }}
                  />
                </ButtonGroup>
                <Text as={"b"} ml={2}>
                  Total Offers : {totalOffers}
                </Text>
              </Flex>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
