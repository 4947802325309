import { ArrowForwardIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  useColorModeValue,
  Icon,
  Center,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { MdCheck, MdImage, MdLocationPin, MdOutlineBarChart, MdPeople, MdSend } from "react-icons/md";
import StatsRow from "components/stats/StatsRow";
import { LocationsDataContext } from "contexts/locationsDataContext"; // Import your LocationsDataContext
import { OffersDataContext } from "contexts/offersDataContext"; // Import your OffersDataContext
import OfferCard from "../../accountOverview/components/OfferCard";
import Card from "components/card/Card";
import UgcPreviewCard from "views/admin/overview/components/UgcPreviewCard";
import BackButton from "components/buttons/BackButton";
import MetricChartCard from "components/charts/MetricChartCard";
import useOffersData from "services/offerDataService";
import CreateOfferButton from "../../offers/components/createOfferButton";

export default function LocationOverview() {
  const textColor = useColorModeValue("black", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [isBackButtonHovered, setIsBackButtonHovered] = useState(false);
  const history = useHistory();

  const locationsData = useContext(LocationsDataContext);

  const locationId = new URLSearchParams(window.location.search).get(
    "locationId"
  );

  /**
   * @info
   * Changes made by nirmal on 20/may/2024 added account id with route because location is taking time to fetch data.
   */
  const clientId = new URLSearchParams(window.location.search).get(
    "accountId"
  );
  const locationData = locationsData?.data[locationId];

  /**
   * @info changes made by nirmal on date :- 20/05/2024
   * Added custome hook to get offerData
   */
  const {
    isLoading: offerLoading = false,
    offersData,
    totalOffers
  } = useOffersData({ perPage: 5, clientID: clientId, locationId });


  // removed this context as we are using custom hook to get data
  // const offersData = useContext(OffersDataContext);



  // get clientId from locationData 
  // comment this because we are getting client id from url.
  // const clientId = locationData?.clientID;

  if (offerLoading) {
    return (
      <Center height="90vh">
        <Spinner size="xl" />
      </Center>
    );
  }



  const locationStats = locationData?.stats;

  const timestampedData = locationStats?.timestamps ?? [];
  const chartData = [
    {
      name: "Signups",
      data:
        timestampedData == null || timestampedData.length > 0 ? [] :
          Object.keys(timestampedData)
            .map((timestamp) => ({
              // Convert timestamp to a number and create a Date object
              x: new Date(parseInt(timestamp)).getTime(),
              y: timestampedData[timestamp].signups || 0 // Use 0 if reach is undefined
            }))
            // Sort the data points by the 'x' property, which is the timestamp in milliseconds
            .sort((a, b) => a.x - b.x)
    }
  ];
  // Update statsList to display information related to the selected location
  const statsList = [
    {
      title: "Signups",
      value: String(locationData?.stats?.signups ?? 0),
      icon: MdPeople,
    },
    {
      title: "Offers Accepted",
      value: String(locationData?.stats?.acceptances ?? 0),
      icon: MdCheck,
    },
    {
      title: "Number of Posts",
      value: String(locationData?.stats?.posts ?? 0),
      icon: MdImage,
    },
    {
      title: "Estimated Reach",
      value: String(Math.floor((locationData?.stats?.reach || 0) / 3)),
      icon: MdOutlineBarChart,
    },
  ];

  return (
    <Box >
      <Flex alignItems="start" flexDirection={"column"} mb="15px">
        <Flex alignItems="center" pb="25px">
          <BackButton isHovered={isBackButtonHovered} setIsHovered={setIsBackButtonHovered} />
          <Text ml="10px" fontWeight="bold" fontSize="30px">
            Location Overview
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Icon as={MdLocationPin} boxSize="3vh" />
          <Text ml="10px" fontSize="25px">
            {locationData?.address}
          </Text>
        </Flex>
      </Flex>
      <StatsRow stats={statsList} />
      <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mb="20px">
        <Flex
          gridColumn={{ md: "span 2" }}
          flexDirection="column"
          w="100%"
          mb="0px"
          justifyContent={"space-around"}
        >
          <MetricChartCard chartData={chartData} />
          <UgcPreviewCard
            clientIDs={[clientId]}
            filteredClientId={clientId}
            filteredLocationId={locationId}
            maxRows={1}
            mt="20px"
          />
        </Flex>
        <Card
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="column"
          w="100%"
          h="100%"
          mb="0px"
        /***
         * Changes made by nirmal on 16:36 removed margin from top because inconsistant design as other pages.
         */
        // mt={"20px"}
        >
          <Flex justifyContent="space-between"
            alignItems="center"
            w="100%"
            pe="20px"
            pt="5px"
            mb="15px">
            <Text
              color={textColor}
              fontSize="24px"
              textAlign="start"
              fontWeight="700"
              lineHeight="100%"
            >
              Offers
            </Text>
            {/* <ArrowForwardIcon w='25px' h='25px' color={textColor} onClick={() => { history.push("/location/offers") }} /> */}

            <Button
              backgroundColor="black"
              borderRadius="10px"
              transition="transform 0.2s, box-shadow 0.2s"
              transform="translateY(0)"
              boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
              _hover={{
                transform: "scale(1.05)",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _focus={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _active={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              onClick={() => {
                history.push(
                  "/admin/view-all-offers/?accountId=" +
                  clientId +
                  "&isInfluencerMode=" +
                  false + "&locationId=" + locationId
                );
              }}
              rightIcon={<ChevronRightIcon color="white" fontSize="20px" />}
            >
              <Text color="white" fontSize="15px" fontWeight="500">
                All offers
              </Text>
            </Button>
          </Flex>

          {Object.keys(offersData)?.length === 0 && (
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              w="100%"
              h="100%" // or use a specific value depending on your needs
              pe="20px"
              pt="5px"
              mb="15px"
              pb="3%"
            >
              <Text pb="1%" fontWeight="500" fontSize="20px">
                Create your first offer!
              </Text>
              <CreateOfferButton clientID={clientId} />
            </Flex>
          )}

          <Flex
            flexDirection={"column"}
            justifyItems="start"
            py="25px"
            px="10px"
            width="100%"
          >
            {Object.values(offersData)
              .sort((a: any, b: any) => b.date - a.date)
              .slice(0, 3)
              .map((offer: any) => (
                <OfferCard key={offer.offerID} offer={offer} clientProfilePic={""}
                />
              ))}
          </Flex>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
