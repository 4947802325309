import React, { useState, useEffect, useContext } from "react";
import {
    Heading,
    Divider,
    Input,
    Button,
    FormErrorMessage,
    FormControl,
    FormLabel,
    useToast,
    Spinner,
    Grid,
    GridItem,
    InputGroup,
    InputRightElement,
    Icon,
    Text,
} from "@chakra-ui/react";

import { AdminDataContext } from "contexts/adminDataContext";
import { auth } from "../../../../firebase";
import { ActiveUidContext } from "contexts/activeUidContext";
import {
    EmailAuthProvider,
    reauthenticateWithCredential,
    updatePassword,
} from "firebase/auth";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";

// Add general setting props if needed
interface GeneralSettingsProps { }

// define initial state for all the veriables
const initialState: any = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    validationErrorMessages: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    },
    isOldPasswordValid: null,
    isNewPasswordValid: null,
    isConfirmPasswordValid: null,
    validationErrors: [],
    isHandleUpdatePassword: false,
    show: {
        oldPassword: false,
        confirmPassword: false,
        newPassword: false,
    },
};

const GeneralSettings: React.FC<GeneralSettingsProps> = () => {
    const textColorSecondary = "gray.500";
    const [userEmail, setUserEmail] = useState("");
    const [state, setState] = useState(initialState);
    const toast = useToast();
    const { adminData, isLoading } = useContext(AdminDataContext);
    const { email = null } = useContext(ActiveUidContext);
    const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);

    // handle effect for admin data and loggedin user email and load data if something change in adminData and if passwordUpdate
    useEffect(() => {
        if (adminData && !isLoading && email) {
            setUserEmail(email);
            setState(initialState);
        }
    }, [adminData, isLoading, isPasswordUpdated]);

    /**
     * @info update state veriables for show and hide value on icon click
     * @param iconType
     */
    const handleIconClick = (iconType: string) => {
        const iconState: any = state.show;
        setState((prevState: any) => ({
            ...prevState,
            show: {
                ...prevState.show,
                [iconType]: !iconState[iconType],
            },
        }));
    };

    /**
     * @info handle for oldPassword, newPassword , confirmPassword and accordingly added password validation
     * @param event
     * @param type
     */
    const handlePasswordChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        type: string
    ) => {
        const value = event.target.value?.trim();
        // check if input contains emoji
        const emojiRegex =
            /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
        let isPassword = true;
        let errorMessage = "";
        if (emojiRegex.test(value)) {
            isPassword = false;
            errorMessage = "Emoji's are not allowed in password";
        } else {
            isPassword = value.length > 7;
            errorMessage =
                value.length <= 7 ? "Minimum Password length required" : "";
        }
        switch (type) {
            case "old":
                setState((prevState: any) => ({
                    ...prevState,
                    oldPassword: value,
                    isOldPasswordValid: isPassword,
                    validationErrorMessages: {
                        ...prevState.validationErrorMessages,
                        oldPassword: errorMessage,
                    },
                }));
                break;
            case "new":
                setState((prevState: any) => ({
                    ...prevState,
                    newPassword: value,
                    isNewPasswordValid: isPassword,
                    validationErrorMessages: {
                        ...prevState.validationErrorMessages,
                        newPassword: errorMessage,
                    },
                }));
                break;
            case "confirm":
                setState((prevState: any) => ({
                    ...prevState,
                    confirmPassword: value,
                    isConfirmPasswordValid: isPassword,
                    validationErrorMessages: {
                        ...prevState.validationErrorMessages,
                        confirmPassword: errorMessage,
                    },
                }));
                break;
            default:
                break;
        }
    };

    /**
     * Validate password on password change
     */
    const validatePasswords = () => {
        const errors: string[] = [];

        if (state.oldPassword === state.newPassword) {
            errors.push("New password cannot be the same as old password.");
        }

        if (state.newPassword !== state.confirmPassword) {
            errors.push("New password and confirm password do not match.");
        }

        if (!state.newPassword) {
            errors.push("New password is required.");
        }

        setState((prevState: any) => ({
            ...prevState,
            validationErrors: errors,
        }));
    };

    /**
     * Handle logic on click of save button and check validation and update password accordingly.
     * @returns
     */
    const handleUpdatePassword = async () => {
        try {
            if (state.validationErrors.length > 0) {
                toast({
                    title: "Validation Errors",
                    description: state.validationErrors.join("\n"),
                    status: "error",
                });
                return;
            }
            setState((prevState: any) => ({
                ...prevState,
                isHandleUpdatePassword: true,
            }));
            // authentication for old password so first checking old password is correct or not if not the throw error
            const checkForOldPassword = await reAuthenticate(state.oldPassword);
            if (checkForOldPassword) {
                // update password password of user in firebase
                await updatePassword(auth.currentUser, state.newPassword);
                setState(initialState);
                setIsPasswordUpdated(!isPasswordUpdated);
                // setState(initialState);
                toast({
                    title: "Password updated sucessfully.",
                    status: "success",
                    duration: 1250,
                    isClosable: false,
                });
            }
        } catch (error: any) {
            const errorObj = error.toString();
            toast({
                title: "Validation Errors",
                description: errorObj?.message || errorObj,
                status: "error",
            });
            setState((prevState: any) => ({
                ...prevState,
                isHandleUpdatePassword: false,
            }));
            return;
        }
    };

    /**
     * function for check old password in firebase
     * @param oldPassword
     * @returns
     */
    const reAuthenticate = async (oldPassword: string) => {
        try {
            const credential = EmailAuthProvider.credential(
                auth.currentUser.email,
                oldPassword
            );
            await reauthenticateWithCredential(auth.currentUser, credential);
            return true;
        } catch (error) {
            console.error("Reauthentication failed:", error);
            throw new Error("Invalid Old Password, please enter correct password.");
        }
    };

    /**
     * Handle effect while changing in password fields.
     */
    useEffect(() => {
        validatePasswords();
    }, [state.oldPassword, state.newPassword, state.confirmPassword]);

    return (
        <>
            <Heading size="chakra_xl" mb={"36px"}>
                General
            </Heading>
            <FormControl>
                <FormLabel variant={'chakra_label_default'} htmlFor="userName">Email</FormLabel>
                <Input
                    variant="chakra_input_light"
                    size="chakra_md"
                    value={userEmail}
                    readOnly
                    placeholder="User name"
                />
            </FormControl>

            <Divider my={"32px"} borderColor={"#EAECF0"} />

            <Heading size="chakra_xl" mb={"36px"}>
                Change Password
            </Heading>

            <FormControl
                isInvalid={state.validationErrors.some((error: any) =>
                    error.includes("oldPassword")
                )}
            >
                <FormLabel variant={'chakra_label_default'} htmlFor="oldPassword" display={"flex"} gap={"1"}>
                    Old Password <Text color="red">*</Text>
                </FormLabel>
                <InputGroup size="md" mb="5px">
                    <Input
                        variant="chakra_input_light"
                        size="chakra_md"
                        value={state.oldPassword}
                        style={{
                            borderColor:
                                state.isOldPasswordValid === null
                                    ? ""
                                    : state.isOldPasswordValid
                                        ? "green"
                                        : "red",
                        }}
                        onChange={(event) => handlePasswordChange(event, "old")}
                        placeholder="Old Password"
                        type={state.show.oldPassword ? "text" : "password"}
                        id="oldPassword"
                        autoComplete={"new-password"}
                        maxLength={20}
                    />

                    <InputRightElement
                        width={"40px"}
                        height={"48px"}
                        display="flex"
                        alignItems="center"
                    >
                        <Icon
                            color={textColorSecondary}
                            _hover={{ cursor: "pointer" }}
                            as={
                                state.show.oldPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye
                            }
                            onClick={() => handleIconClick("oldPassword")}
                        />
                    </InputRightElement>
                </InputGroup>
                {!!state.oldPassword.length &&
                    !!state.validationErrorMessages?.["oldPassword"]?.length && (
                        <Text textColor={"red"} fontSize={"12px"}>
                            {state.validationErrorMessages?.["oldPassword"]}
                        </Text>
                    )}
            </FormControl>
            <Grid templateColumns="repeat(4, 1fr)" gap={4} my={"32px"}>
                <GridItem colSpan={2}>
                    <FormControl
                        isInvalid={state.validationErrors.some(
                            (error: any) =>
                                error.includes("newPassword") ||
                                error.includes("confirmPassword")
                        )}
                    >
                        <FormLabel variant={'chakra_label_default'} htmlFor="newPassword" display={"flex"} gap={"1"}>
                            New Password<Text color="red">*</Text>
                        </FormLabel>
                        <InputGroup size="md" mb="5px">
                            <Input
                                variant="chakra_input_light"
                                size="chakra_md"
                                style={{
                                    borderColor:
                                        state.isNewPasswordValid === null
                                            ? ""
                                            : state.isNewPasswordValid
                                                ? "green"
                                                : "red",
                                }}
                                onChange={(event) => handlePasswordChange(event, "new")}
                                placeholder="Min. 8 characters"
                                value={state.newPassword}
                                type={state.show.newPassword ? "text" : "password"}
                                id="newPassword"
                                autoComplete={"new-password"}
                                maxLength={20}
                            />
                            <InputRightElement
                                width={"40px"}
                                height={"48px"}
                                display="flex"
                                alignItems="center"
                            >
                                <Icon
                                    color={textColorSecondary}
                                    _hover={{ cursor: "pointer" }}
                                    as={
                                        state.show.newPassword
                                            ? RiEyeCloseLine
                                            : MdOutlineRemoveRedEye
                                    }
                                    onClick={() => handleIconClick("newPassword")}
                                />
                            </InputRightElement>
                        </InputGroup>

                        {!!state.newPassword.length &&
                            !!state.validationErrorMessages?.["newPassword"]?.length && (
                                <Text textColor={"red"} fontSize={"12px"}>
                                    {state.validationErrorMessages?.["newPassword"]}
                                </Text>
                            )}
                    </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                    <FormControl
                        isInvalid={state.validationErrors.some((error: any) =>
                            error.includes("confirmPassword")
                        )}
                    >
                        <FormLabel variant={'chakra_label_default'} htmlFor="confirmPassword" display={"flex"} gap={"1"}>
                            Confirm Password <Text color="red">*</Text>
                        </FormLabel>
                        <InputGroup size="md" mb="5px">
                            <Input
                                value={state.confirmPassword}
                                variant="chakra_input_light"
                                size="chakra_md"
                                style={{
                                    borderColor:
                                        state.isConfirmPasswordValid === null
                                            ? ""
                                            : state.isConfirmPasswordValid
                                                ? "green"
                                                : "red",
                                }}
                                onChange={(event) => handlePasswordChange(event, "confirm")}
                                placeholder="Min. 8 characters"
                                type={state.show.confirmPassword ? "text" : "password"}
                                id="confirmPassword"
                                autoComplete={"new-password"}
                                maxLength={20}
                            />
                            <InputRightElement
                                width={"40px"}
                                height={"48px"}
                                display="flex"
                                alignItems="center"
                            >
                                <Icon
                                    color={textColorSecondary}
                                    _hover={{ cursor: "pointer" }}
                                    as={
                                        state.show.confirmPassword
                                            ? RiEyeCloseLine
                                            : MdOutlineRemoveRedEye
                                    }
                                    onClick={() => handleIconClick("confirmPassword")}
                                />
                            </InputRightElement>
                        </InputGroup>
                        {!!state.confirmPassword.length &&
                            !!state.validationErrorMessages?.["confirmPassword"]?.length && (
                                <Text textColor={"red"} fontSize={"12px"}>
                                    {state.validationErrorMessages?.["confirmPassword"]}
                                </Text>
                            )}
                    </FormControl>
                </GridItem>
            </Grid>

            <Divider my={"32px"} borderColor={"#EAECF0"} />

            <Button
                variant={
                    !state.isOldPasswordValid ||
                        !state.isConfirmPasswordValid ||
                        !state.isNewPasswordValid
                        ? "chakra_btn_secondary"
                        : "chakra_btn_secondary"
                }
                isDisabled={
                    !state.isOldPasswordValid ||
                    !state.isConfirmPasswordValid ||
                    !state.isNewPasswordValid
                }
                size="chakra_lg"
                width={'183px'}
                onClick={handleUpdatePassword}
            >
                {state.isHandleUpdatePassword ? (
                    <Spinner color="white" mt="5px" />
                ) : (
                    "Save"
                )}
            </Button>
        </>
    );
};

export default GeneralSettings;
