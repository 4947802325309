import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import LoginScreen from "./views/auth/signIn";
import AdminLayout from "./layouts/admin";
import { Redirect } from "react-router-dom";

import { AdminDataProvider } from "contexts/adminDataContext";
import { Route, Switch } from "react-router-dom";
import WaitListScreen from "./views/auth/signIn/waitListScreen"; // Import the WaitListScreen component
import ProtectedRoute from "routeWrappers/protectedRoute";
import AuthenticationRoute from "routeWrappers/authenticationRoute";
import SuperProtectedRoute from "routeWrappers/superProtectedRoute";
import SuperUserDataProvider from "contexts/superUserDataContext";
import SuperUserLayout from "layouts/superUser";
import { useState } from "react";
import { ActiveUidProvider } from "contexts/activeUidContext";

function App() {
  const [user] = useAuthState(auth);

  const isSuperUser =
    user?.email?.includes("@storyit.us") ||
    user?.email?.includes("bransonjpackard@gmail.com") ||
    user?.email?.includes("wilgeller@gmail.com") ||
    user?.email?.includes("hardikg@evincedev.com") ||
    user?.email?.includes("nirmal@evincedev.com") || user?.email?.includes("nirmalevince@gmail.com");  // for now, give all storyit emails superuser access

  const baseRedirectPath = user
    ? isSuperUser
      ? "/superuser"
      : "/admin"
    : "/login";

  return (
    <ActiveUidProvider>
      <Switch>
        <Route exact path="/">
          <Redirect to={baseRedirectPath} />
        </Route>
        <AuthenticationRoute path="/login" user={user}>
          <LoginScreen />
        </AuthenticationRoute>
        <AuthenticationRoute path="/waitlist" user={user}>
          <WaitListScreen />
        </AuthenticationRoute>
        <ProtectedRoute path="/admin" user={user}>
          <AdminDataProvider>
            <AdminLayout />
          </AdminDataProvider>
        </ProtectedRoute>
        <SuperProtectedRoute
          path="/superuser"
          user={user}
          isSuperUser={isSuperUser}
        >
          <SuperUserDataProvider>
            <SuperUserLayout />
          </SuperUserDataProvider>
        </SuperProtectedRoute>
      </Switch>
    </ActiveUidProvider>
  );
}

export default App;
