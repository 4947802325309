import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { IoMdInformationCircle } from "react-icons/io";
import ImageUploader from "../../accounts/pages/offers/components/ImageUploader";
import VideoUploader from "../../accounts/pages/offers/components/VideoUploader";

interface ContentExampleUgcProps {
  setContentExampleUGC: Function;
  setIsContentExampleUGCValid: Function;
  contentType: String;
  exampleUgcUrl: string;
}

function ContentExampleUgc({
  setContentExampleUGC,
  contentType,
  setIsContentExampleUGCValid,
  exampleUgcUrl,
}: ContentExampleUgcProps) {
  return (
    <Flex
      justifyContent="start"
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      <Flex alignItems="center" mb="10%">
        <Text
          fontSize="18px"
          fontWeight="400"
          whiteSpace="nowrap" // Prevent text from wrapping
        >
          {contentType === "instagramStory"
            ? "Instagram Story"
            : contentType === "instagramPost"
            ? "Instagram Post"
            : contentType === "instagramReel"
            ? "Instagram Reel"
            : contentType === "ugc"
            ? "UGC"
            : "Tiktok Video"}
        </Text>
        <Box
          ml="5%"
          as="div"
          cursor="pointer"
          onClick={() => { }}
          display="inline-block" // Ensures the icon doesn't take full width
        >
          <IoMdInformationCircle color="#6F767E" size="20px" />
        </Box>

        {/* enter textfield here */}
      </Flex>

      {exampleUgcUrl !== "" && (
        <Flex direction="column" align="center" justify="center">
          <Box
            maxWidth="150px"
            maxHeight="300px"
            borderRadius="10px"
            overflow="hidden"
            boxShadow="lg"
            mb={4}
          >
            {(contentType !== "instagramReel" || contentType !== "tiktokVideo") && (
              <Image
                src={exampleUgcUrl}
                alt="Unable to load image"
                onError={(e) => {
                  (e.target as HTMLImageElement).src =
                    "https://firebasestorage.googleapis.com/v0/b/storyitapp-dev.appspot.com/o/UGC%20placeholder.jpg?alt=media&token=1908d380-979a-4671-a66d-699ea13b3622";
                }}
              />
            )}
            {(contentType === "instagramReel" || contentType === "tiktokVideo") && (
              <video width="100%" height="100%" controls>
                <source src={exampleUgcUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </Box>
        </Flex>
      )}

      {contentType !== "instagramReel" && contentType !== "tiktokVideo" && exampleUgcUrl === "" && (
        <ImageUploader
          isButton={true}
          setExampleUGC={setContentExampleUGC}
          setIsImageValid={setIsContentExampleUGCValid}
          exampleUgcUrl={exampleUgcUrl}
        />
      )}
      {(contentType === "instagramReel" || contentType === "tiktokVideo") && exampleUgcUrl === "" && (
        <VideoUploader
          isButton={true}
          setExampleUGC={setContentExampleUGC}
          setIsVideoValid={setIsContentExampleUGCValid}
        />
      )}
    </Flex>
  );
}
export default ContentExampleUgc;
