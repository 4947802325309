import React from "react";
import { Text, GridItem, Flex, Icon } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";


interface InputtedPhoneNumberTileProps {
  phoneNumber: string;
  handleNumbersInputted: Function;
}

const InputtedPhoneNumberTile: React.FC<InputtedPhoneNumberTileProps> = ({
  phoneNumber,
  handleNumbersInputted,
}) => {
  return (
    <GridItem key={phoneNumber} bg="#F4F4F4" p={4} borderRadius="md" position="relative">
      <Flex justify="flex-end" position="absolute" top="0" right="0" p="2">
        <Icon as={CloseIcon} boxSize="1.5" color="gray.500" cursor="pointer" onClick={() => handleNumbersInputted(phoneNumber, false)} />
      </Flex>
      <Text
        fontSize="14px"
        fontWeight="500"
        align="center"
      >
        {phoneNumber}
      </Text>
    </GridItem>
  );
};

export default InputtedPhoneNumberTile;
