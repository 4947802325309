import {
  Box,
  Text,
  Grid,
  FormControl,
  Input,
  FormLabel,
  Flex,
  Button,
  IconButton,
  Switch,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";


import React, { useState } from "react";
import InstagramProfilePic from "../../components/InstagramProfilePic";
interface InstagramResultsProps {
  imageData: Uint8Array;
  instagramDisplayName: string;
  instagramHandle: string;
  isVerified: boolean;
  instagramBio: string;
}

function InstagramResults({
  imageData,
  instagramHandle,
  isVerified,
  instagramBio,
  instagramDisplayName,
}: InstagramResultsProps) {
  return (
    <Box padding="10px">
      {/* <Text fontWeight="400" color="grey" fontSize="15px">
        Instagram result (your instagram information will be displayed here):
      </Text> */}

      <Flex>
        <InstagramProfilePic imageData={imageData} imageSize={"175px"}/>
        <Box ml="30px" mt="35px">
          <Text fontSize="18px" mt="10px">
            Username:{" "}
            <Text as="span" fontWeight="bold">
              {instagramHandle}
            </Text>
          </Text>
          <Text fontSize="18px" mt="10px">
            Name:{" "}
            <Text as="span" fontWeight="bold">
              {instagramDisplayName}
            </Text>
          </Text>
          <Text fontSize="18px" mt="10px">
            Verified:{" "}
            <Text as="span" fontWeight="bold">
              {isVerified ? "True" : ""}
            </Text>
          </Text>

          <Text fontSize="18px" mt="10px">
            Bio:{" "}
            <Text as="span" fontWeight="bold">
              {instagramBio}
            </Text>
          </Text>
        </Box>
      </Flex>
      <Text ml="40px" fontSize="12px" color="grey" mt="20px">
        Not your account? Enter a different handle and try again.
      </Text>
    </Box>
  );
}

export default InstagramResults;
