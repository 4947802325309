/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import {
  Text,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  useRadioGroup,
  HStack,
} from "@chakra-ui/react";
import RadioCard from "../../offers/components/Radiocard";
import { checkForDisable, checkForEmojiInString, contentTypeText } from "utils";

interface IsFreeRewardProps {
  inputValues: {
    isFreeReward: boolean;
    prerequisiteQuantity?: number;
    discountItem: string;
    discountItemID: string;
    prerequisiteName?: string;
    isPrerequisite?: boolean;
    discountCollectionID?: string;
    contentType: string;
    percentOrCashOff: string;
    compensation: number;
    specificProduct: string;
    hashtag: string;
    isEvergreenCode: boolean;
    integration: string;
    ecommerceDiscountCode: string;
  };
  onInputChange: (
    fieldName: keyof IsFreeRewardProps["inputValues"],
    value: any
  ) => void;
  data: any;
}

const IsFreeReward: React.FC<IsFreeRewardProps> = ({
  inputValues,
  onInputChange,
  data: additionalDetails,
}) => {
  const { accountData, clientIntegrations } = additionalDetails;
  const { instagramHandle, offers = [], accountName } = accountData;
  const options = ["Yes", "No"];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: "No",
    onChange: console.log,
  });

  const group = getRootProps();
  const handleChange = (value: string) => {
    // Handle change here

    onInputChange("isFreeReward", value === "Yes" ? true : false);
    if (value === "No") {
      onInputChange("discountCollectionID", "");
      onInputChange("discountItemID", "");
      onInputChange("discountItem", "");
      onInputChange("compensation", 0);
    }
  };

  useEffect(() => {
    // if (!inputValues.isFreeReward) {
    // }
    // need to test this
    if (inputValues.isFreeReward) {
      onInputChange(
        "percentOrCashOff",
        inputValues.isFreeReward ? "Percent" : ""
      );
      onInputChange("compensation", inputValues?.isFreeReward ? 100 : 0);
    }
  }, [inputValues.isFreeReward]);

  return (
    <Box py="60px">
      <Flex flexFlow={"column"} gap={"60px"}>
        <FormControl display="flex" alignItems="center" gap="20px">
          <FormLabel m="0" variant={"chakra_label_default"}>
            Free reward
          </FormLabel>
          <HStack {...group} gap={"24px"}>
            {options.map((value) => {
              const radio = getRadioProps({ value });
              return (
                <RadioCard
                  key={value}
                  onChange={() => handleChange(value)}
                  isChecked={inputValues?.isFreeReward === (value === "Yes")} // Set checked state based on inputValues.isPrerequisite
                >
                  {value}
                </RadioCard>
              );
            })}
          </HStack>
        </FormControl>
        {inputValues.isFreeReward && (
          <>
            <FormControl display="flex" alignItems="center" gap="20px">
              <FormLabel m="0" variant={"chakra_label_default"}>
                Name of free item
              </FormLabel>
              <Box w={"100%"}>
                <Input
                  width="100%"
                  variant="chakra_input_default"
                  size="chakra_md_medium"
                  fontSize={"chakra_sm"}
                  fontWeight={"500"}
                  type="text"
                  maxLength={30}
                  value={inputValues?.discountItem}
                  onChange={(e) =>
                    onInputChange("discountItem", e?.target?.value)
                  }
                />
              </Box>
            </FormControl>

            {clientIntegrations?.length > 0 && (
              <>
                <FormControl display="flex" alignItems="center" gap="20px">
                  <FormLabel m="0" variant={"chakra_label_default"}>
                    Product ID
                  </FormLabel>
                  <Box w={"100%"}>
                    <Input
                      width="100%"
                      variant="chakra_input_default"
                      size="chakra_md_medium"
                      fontSize={"chakra_sm"}
                      fontWeight={"500"}
                      type="text"
                      value={inputValues?.discountItemID}
                      onChange={(e) => {
                        let val = e?.target?.value?.trim();
                        if (checkForEmojiInString(val)) {
                          onInputChange("discountItemID", val?.trim());
                        }
                      }}
                    />
                  </Box>
                </FormControl>
                <FormControl display="flex" alignItems="center" gap="20px">
                  <FormLabel m="0" variant={"chakra_label_default"}>
                    Collection ID (Optional)
                  </FormLabel>
                  <Box w={"100%"}>
                    <Input
                      width="100%"
                      variant="chakra_input_default"
                      size="chakra_md_medium"
                      fontSize={"chakra_sm"}
                      fontWeight={"500"}
                      type="text"
                      value={inputValues.discountCollectionID}
                      onChange={(e) => {
                        let val = e?.target?.value?.trim();
                        if (checkForEmojiInString(val)) {
                          onInputChange("discountCollectionID", val?.trim());
                        }
                      }}
                    />
                  </Box>
                </FormControl>
              </>
            )}
          </>
        )}
      </Flex>

      {inputValues?.isFreeReward &&
        (inputValues?.isPrerequisite ? (
          <>
            <Box
              maxW={"514px"}
              mx="auto"
              mt="60px"
              py={"15px"}
              px={"14px"}
              bg={"#D4FEAE"}
              borderRadius={"8px"}
            >
              <Flex alignItems={"center"} textAlign={"center"}>
                <Text fontSize={"15px"} fontWeight={"600"}>
                  {`Post an ${contentTypeText(inputValues?.contentType)} of ${inputValues?.specificProduct?.trim()?.length
                    ? inputValues.specificProduct?.trim()
                    : accountName
                    } tagging @${instagramHandle} ${inputValues?.hashtag?.length > 0
                      ? "and #" + inputValues?.hashtag
                      : ""
                    } to ${inputValues?.discountItem?.trim()?.length > 0
                      ? "in order to get free " +
                      inputValues.discountItem?.trim() +
                      " with a"
                      : ""
                    } purchase of ${inputValues?.prerequisiteQuantity
                    } ${inputValues?.prerequisiteName?.trim()}`}
                </Text>
              </Flex>
            </Box>
          </>
        ) : (
          <>
            <Box
              maxW={"514px"}
              mx="auto"
              mt="60px"
              py={"15px"}
              px={"14px"}
              bg={"#D4FEAE"}
              borderRadius={"8px"}
            >
              <Flex alignItems={"center"} textAlign={"center"}>
                <Text fontSize={"15px"} fontWeight={"600"}>
                  {`Post an ${contentTypeText(inputValues?.contentType)} of ${inputValues?.specificProduct?.trim()?.length
                    ? inputValues.specificProduct?.trim()
                    : accountName
                    } tagging @${instagramHandle} ${inputValues?.hashtag?.length > 0
                      ? "and #" + inputValues?.hashtag
                      : ""
                    } in order to get free ${inputValues?.discountItem?.trim()?.length > 0
                      ? "" + inputValues?.discountItem?.trim() + ""
                      : ""
                    } on your next total order.`}

                  {inputValues?.isEvergreenCode &&
                    ` Code ${inputValues?.ecommerceDiscountCode} will be distributed to the people who complete the offer.`}
                </Text>
              </Flex>
            </Box>
          </>
        ))}
    </Box>
  );
};

export default IsFreeReward;
