import React from "react";
import { Box, Button, Text } from "@chakra-ui/react";

interface PaginationProps {
  currPage: number;
  setCurrPage: (page: number) => void;
  totalPages: number;
}

const Pagination: React.FC<PaginationProps> = ({ currPage, setCurrPage, totalPages }) => {
  const displayPageNumbers = () => {
    if (totalPages <= 5) {
      // Show all pages if there are 5 or fewer pages
      return Array.from({ length: totalPages }, (_, index) => (
        <Button
          key={index}
          onClick={() => setCurrPage(index)}
          bg={currPage === index ? 'gray.500' : 'gray.400'}
          color="white"
          mx={1}
        >
          {index + 1}
        </Button>
      ));
    }

    if (currPage < 2) {
      // Show first few pages and last page
      return (
        <>
          {Array.from({ length: 4 }, (_, index) => (
            <Button
              key={index}
              onClick={() => setCurrPage(index)}
              bg={currPage === index ? 'gray.500' : 'gray.400'}
              color="white"
              mx={1}
            >
              {index + 1}
            </Button>
          ))}
          <Text mx={1}>...</Text>
          <Button
            onClick={() => setCurrPage(totalPages - 1)}
            bg="gray.400"
            color="white"
            mx={1}
          >
            {totalPages}
          </Button>
        </>
      );
    }

    if (currPage > totalPages - 4) {
      // Show last few pages and first page
      return (
        <>
          <Button
            onClick={() => setCurrPage(0)}
            bg="gray.400"
            color="white"
            mx={1}
          >
            1
          </Button>
          <Text mx={1}>...</Text>
          {Array.from({ length: 4 }, (_, index) => (
            <Button
              key={totalPages - 4 + index}
              onClick={() => setCurrPage(totalPages - 4 + index)}
              bg={currPage === totalPages - 4 + index ? 'gray.500' : 'gray.400'}
              color="white"
              mx={1}
            >
              {totalPages - 4 + index + 1}
            </Button>
          ))}
        </>
      );
    }

    // Show middle pages with "..." for start and end
    return (
      <>
        <Button onClick={() => setCurrPage(0)} bg="gray.400" color="white" mx={1}>
          1
        </Button>
        <Text mx={1}>...</Text>
        {Array.from({ length: 3 }, (_, index) => (
          <Button
            key={currPage - 1 + index}
            onClick={() => setCurrPage(currPage - 1 + index)}
            bg={currPage === currPage - 1 + index ? 'gray.500' : 'gray.400'}
            color="white"
            mx={1}
          >
            {currPage - 1 + index + 1}
          </Button>
        ))}
        <Text mx={1}>...</Text>
        <Button
          onClick={() => setCurrPage(totalPages - 1)}
          bg="gray.400"
          color="white"
          mx={1}
        >
          {totalPages}
        </Button>
      </>
    );
  };

  return (
    <Box 
      display="flex" 
      alignItems="center" 
      justifyContent="center" 
      mt={6} 
      width="100%" 
      maxWidth="600px" 
      mx="auto"  // Center horizontally
    >
      {/* Previous Arrow */}
      <Button
        onClick={() => setCurrPage(currPage - 1)}
        disabled={currPage === 0}
        bg="gray.400"
        color="white"
        mr={2}
      >
        &lt;
      </Button>

      {/* Page Numbers */}
      {displayPageNumbers()}

      {/* Next Arrow */}
      <Button
        onClick={() => setCurrPage(currPage + 1)}
        disabled={currPage === totalPages - 1}
        bg="gray.400"
        color="white"
        ml={2}
      >
        &gt;
      </Button>
    </Box>
  );
};

export default Pagination;
