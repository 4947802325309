import { Box, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

interface AccountTileProps {
  imageURL: string;
  accountName: string;
  clientID: string;
  handleClick: Function;
  [x: string]: any;
}

function AccountTile({ imageURL, accountName, clientID, handleClick, ...rest }: AccountTileProps) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box {...rest} cursor={isHovered ? "pointer" : "auto"} onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        handleClick(clientID);
      }} >
      <Box
        textAlign="center"
        backgroundColor={isHovered ? "#E6F5FC" : "#DDEAF5"}
        borderRadius="15px"
        p="5px"
        width="90%"
        transition="transform 0.2s, box-shadow 0.2s"
        transform={isHovered ? "scale(1.05)" : "scale(1)"}
        boxShadow={isHovered ? "0px 4px 8px rgba(0, 0, 0, 0.1)" : "none"}
      >
        <Box
          mt="5%"
          mb="5%"
          display="inline-block"
          width="150px"
          height="150px"
          borderRadius="50%"
          backgroundImage={imageURL}
          backgroundSize="cover"
        />
      </Box>
      <Text mt="10px" ml="5" fontWeight="bold" fontSize="19px">
        {accountName}
      </Text>
    </Box>
  );
}

export default AccountTile;

