import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Center,
} from "@chakra-ui/react";

interface LogoutPopupModelProps {
  isOpen: boolean;
  onClose: () => void;
  handleSignout: () => void;
}

const LogoutPopupModel: React.FC<LogoutPopupModelProps> = ({
  isOpen,
  onClose,
  handleSignout,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size={"md"} isCentered>
      <ModalOverlay />
      <ModalContent py={"36px"} px={"40px"} borderRadius="8px">
        <ModalHeader p={"0px"} mb={"8px"}>
          <Center>Are you sure?</Center>
        </ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody p={"0"}>
          <Center>
            <Text
              fontSize="chakra_sm"
              color="rgba(84, 89, 94, 0.6)"
              fontWeight="normal"
            >{`This will log you out of your Facebook`}</Text>
          </Center>
        </ModalBody>

        <ModalFooter
          pt={"24px"}
          px={"0"}
          pb={"0"}
          justifyContent="center"
          gap="24px"
        >
          <Button
            variant="chakra_btn_secondary"
            size="chakra_lg"
            w="100%"
            borderRadius="10px"
            transition="transform 0.2s, box-shadow 0.2s"
            transform="translateY(0)"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _focus={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _active={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
            autoFocus={false}
            tabIndex={-1}
            onClick={onClose}
          >
            <Text color="white" fontSize="15px">
              No, cancel
            </Text>
          </Button>

          <Button
            variant="chakra_btn_secondary"
            size="chakra_lg"
            w="100%"
            borderRadius="10px"
            transition="transform 0.2s, box-shadow 0.2s"
            transform="translateY(0)"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _focus={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            _active={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
            onClick={handleSignout}
          >
            <Text color="white" fontSize="15px">
              Yes, logout
            </Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LogoutPopupModel;
