/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Box, FormControl, FormLabel, Input, Flex, Text } from "@chakra-ui/react";
import { valueToNumberConvert } from "utils";

interface NumberofOfferProps {
  inputValues: {
    quantity: number;
    typeOfOffer: string;
  };
  onInputChange: (
    fieldName: keyof NumberofOfferProps["inputValues"],
    value: any
  ) => void;
  data: any;
}

const NumberOfOffers: React.FC<NumberofOfferProps> = ({
  inputValues,
  onInputChange,
  data: additionalData,

}) => {
  const { isEditing, offerDetail } = additionalData;
  // console.log("offer details---", offerDetail)

  const [errorMessage, setErrorMessage] = useState("");
  const [currentQty, setCurrentQty] = useState(isEditing && (inputValues?.typeOfOffer === "cash" || inputValues?.typeOfOffer === "cashback") && offerDetail?.quantity >= inputValues?.quantity ? offerDetail?.quantity : inputValues?.quantity);
  const handleQuantityChange = (e) => {
    let value: any = valueToNumberConvert(e?.target?.value);
    console.log("inputValues.offerType === ", inputValues?.typeOfOffer)
    if (isEditing && (inputValues.typeOfOffer === "cash" || inputValues.typeOfOffer === "cashback")) {
      // Extract the value entered by the user
      // Check if the value is a valid number
      if (isNaN(value)) {
        setCurrentQty(0);
        onInputChange("quantity", 0);
        setErrorMessage(`Quantity can not be less then existing quantity as ${offerDetail?.quantity}.`);
        return;
      } else {
        if (offerDetail?.quantity > value) {
          setCurrentQty(value);
          onInputChange("quantity", 0);
          setErrorMessage(`Quantity can not be less then existing quantity as ${offerDetail?.quantity}.`);
          return;
        }
      }
    }
    setErrorMessage("");
    // Extract the value entered by the user
    // Check if the value is a valid number
    if (!isNaN(value)) {
      setCurrentQty(value);
      onInputChange("quantity", value);
    } else {
      setCurrentQty(0);
      onInputChange("quantity", 0);
    }
  }

  return (
    <Box py="60px">
      <Flex flexFlow={"column"} gap={"60px"}>
        <FormControl display="flex" alignItems="center" gap="20px">
          <FormLabel m="0" variant={"chakra_label_default"}>
            Number of offers
          </FormLabel>
          <Box w={"100%"}>
            <Input
              value={currentQty === 0 ? "" : currentQty}
              onChange={handleQuantityChange}
              width="100%"
              variant="chakra_input_default"
              size="chakra_md_medium"
              fontSize={"chakra_sm"}
              fontWeight={"500"}
              type="text"
            />
            {errorMessage && errorMessage?.length > 0 && (
              <Text textColor={"red"} mt="3px" fontSize={"12px"}>
                {errorMessage}
              </Text>
            )}
          </Box>
        </FormControl>
      </Flex>
    </Box>
  );
};

export default NumberOfOffers;
