/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from "react";
import { Text, Flex, Box, Checkbox, CheckboxGroup, Spinner, Button } from "@chakra-ui/react";
import { LocationsDataContext } from "contexts/locationsDataContext";


interface OfferSelectLocationProps {
    inputValues: {
        locations: any[];
    };
    onInputChange: (fieldName: keyof OfferSelectLocationProps["inputValues"], value: any[]) => void;
    data: any
}

const OfferSelectLocation: React.FC<OfferSelectLocationProps> = ({ inputValues, onInputChange, data: additionalData }) => {
    const { isLoading, data: locationsData } = useContext(LocationsDataContext);
    const { clientID } = additionalData;
    // filter out data to ensure only locations for the current client are shown
    const locationsDataCleaned = locationsData?.length ?? Object?.values(locationsData)?.filter(
        (location: any) => {
            return location?.clientID === clientID;
        }
    );


    // State to track if all locations are selected
    const [allLocationsSelected, setAllLocationsSelected] = useState(false);


    const handleLocationChange = (locationID: string, isChecked: boolean) => {
        let prevLocation = inputValues.locations;
        if (isChecked) {
            onInputChange("locations", [...prevLocation, locationID])
        } else {
            const updatedList = prevLocation?.filter(
                (item: string) => item !== locationID
            );
            onInputChange("locations", updatedList)
        }
    };


    // Effect to check if all locations are selected
    useEffect(() => {
        if (locationsDataCleaned && inputValues?.locations?.length === locationsDataCleaned?.length) {
            setAllLocationsSelected(true);
        } else {
            setAllLocationsSelected(false);
        }
    }, [locationsDataCleaned, inputValues.locations]);


    const toggleSelectAll = () => {
        if (allLocationsSelected) {
            onInputChange("locations", []);
        } else {
            const allLocationIDs = locationsDataCleaned?.map((location: any) => location?.id);
            onInputChange("locations", allLocationIDs);
        }
    };

    // check if data is loading or not
    if (isLoading) {
        return (
            <Box>
                <Flex justifyContent="center" alignItems="center" height="200px">
                    <Spinner size="xl" />
                </Flex>
            </Box>
        );
    }

    return (
        <>
            <Button
                variant="chakra_btn_white"
                size="chakra_md"
                border={'1px solid #EFEFEF'}
                position="absolute"
                top="35px"
                right="40px"
                onClick={toggleSelectAll} // Add this line
            >
                {allLocationsSelected ? "Deselect All" : "Select All"}
            </Button>
            <Box overflowX={'auto'} mx={'-15px'} py="60px">
                <Box px={'15px'}>
                    <CheckboxGroup >
                        <Flex maxW={'100%'} rowGap={'60px'} columnGap={'20px'} flexWrap={'wrap'}>
                            {
                                locationsDataCleaned?.map((location: any, index: any) => (
                                    <Box width="calc(33.33% - 15px)" key={`location-${index}`}>
                                        <Checkbox variant="chakra_checkbox_dark" size="checkbox_lg" value='' key={index}
                                            isChecked={inputValues?.locations?.includes(location.id)}
                                            onChange={() => handleLocationChange(location?.id, !(inputValues?.locations?.includes(location?.id)))}
                                        >
                                            <Text fontSize={'chakra_lg'} fontWeight='400' color={'#1A1D1F'} ml="8px">{location?.address}</Text>
                                        </Checkbox>
                                    </Box>
                                ))
                            }
                        </Flex>
                    </CheckboxGroup>
                </Box>
            </Box>
        </>
    );
}

export default OfferSelectLocation;
