import { Box, Text, Grid } from "@chakra-ui/react";
import React from "react";
import AccountTile from "./AccountTile";
import { useHistory } from "react-router-dom";

interface AccountTileProps {
  accountName: string;
  instagramProfilePicture: string;
  clientID: string;
}

interface AccountsGridProps {
  accountsData: AccountTileProps[];
}

function AccountsGrid({ accountsData }: AccountsGridProps) {
  const history = useHistory();
  function handleClick(clientID: string) {
    history.push("/admin/accounts/view-account/?accountId=" + clientID);
  }

  return (
    <Box position="absolute" mt="20px" width="100%" height="100%">
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1fr 1fr 1fr 1fr", // Define four equal columns for larger screens
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "50px", xl: "50px" }}
        columnGap={{ base: "0px", xl: "0px" }}
      >
        {accountsData.length === 0 ? (
          <Text>Add your first account!</Text>
        ) : (
          Object.values(accountsData).map((account, index) => (
            <AccountTile
              handleClick={handleClick}
              key={account.clientID}
              role="button"
              imageURL={account.instagramProfilePicture}
              accountName={account.accountName}
              clientID={account.clientID}
            />
          ))
        )}
      </Grid>
    </Box>
  );
}

export default AccountsGrid;
