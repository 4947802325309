import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  Select,
  Spinner,
  Icon
} from "@chakra-ui/react";
import { FaStar } from "react-icons/fa6";
import { AccountsDataContext } from "contexts/accountsDataContext";
import AccountsDropdown from "./components/accountsDropDown";
import { FoodFluenceDataContext } from "../../../contexts/foodfluenceDataContext";
import { FaRegBookmark, FaBookmark } from "react-icons/fa6";
import InfluencersList from './components/InfluencersList'
import Pagination from './components/Pagination';

interface Influencer {
  id: string;
  firstName: string;
  lastName: string;
  name: string
  email_address: string;
  instagramHandle: string;
  tiktokHandle: string;
  state: string;
  city: string;
  profilePic: any;
  profile_pic_url: string;
  follower_count: number;
  featured: boolean;
}

export default function FoodFluence() {
  const influencers: Record<string, Influencer> = useContext(FoodFluenceDataContext);

  const [filteredClientID, setFilteredClientID] = useState<string>("");
  const [selectedAccountName, setSelectedAccountName] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const accountsData = useContext(AccountsDataContext);
  const [filterBookmarked, setFilterBookmarked] = useState(false);
  const [filterFeatured, setFilterFeatured] = useState(false);
  const [followerRange, setFollowerRange] = useState("")
  const [visibleInfluencers, setVisibleInfluencers] = useState<Influencer[][]>([[]])
  const [currPage, setCurrPage] = useState(0)

  useEffect(() => {
      const filteredInfluencers = getFilteredInfluencers();
      const influencersByPage = groupInfluencersByPage(filteredInfluencers ?? [], 20);
      setVisibleInfluencers(influencersByPage);
      setCurrPage(0)
  }, [influencers, searchTerm, selectedState, selectedCity, filterBookmarked, filterFeatured, followerRange, filteredClientID]);

  const filteredAccountsData = accountsData[filteredClientID] ?? {};

  const getFilteredInfluencers = () => {
    const filtered = Object.keys(influencers??{})
      .map(key => influencers[key])
      .filter(influencer => {
        influencer.name = influencer.firstName + (influencer.lastName!="unknown"?" " + influencer.lastName:"")
        const matchesSearchTerm =
          influencer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          influencer.email_address.toLowerCase().includes(searchTerm.toLowerCase()) ||
          influencer.instagramHandle.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesState = selectedState ? influencer.state === selectedState : true;
        const matchesCity = selectedCity ? influencer.city === selectedCity : true;
        const matchedBookmark = filterBookmarked ? filteredAccountsData.bookmarkedInfluencers && filteredAccountsData.bookmarkedInfluencers.includes(influencer.id) : true
        const matchedFeatured = filterFeatured ? influencer.featured == filterFeatured : true
        const matchedFollowerRange = followerRanges[followerRange][0] <= influencer.follower_count && influencer.follower_count <= followerRanges[followerRange][1]
        return matchesSearchTerm && matchesState && matchesCity && matchedBookmark && matchedFeatured && matchedFollowerRange;
      })
      return filtered.sort((a, b) => {
        return b.featured === a.featured ? 0 : b.featured ? 1 : -1;
      });
  }
  
  let clientAccounts = [];
  for (let account in accountsData) {
    if (account && accountsData[account]) {
      clientAccounts.push({
        ...accountsData[account],
      });
    }
  }

  const groupInfluencersByPage = (influencers, groupSize) => {
    let groups = []
    for (let i = 0; i < influencers.length; i += groupSize) {
      groups.push(influencers.slice(i, i + groupSize));
    }
    if(groups.length == 0){
      groups = [[]]
    }
    return groups;

  }

  const handleAccountNameChange = (name: string) => {
    setSelectedAccountName(name);
  };

  const uniqueStates = new Set<string>();
  const uniqueCities = new Set<string>();
  Object.values(influencers??{}).forEach((influencer) => {
    uniqueStates.add(influencer.state);
    if (selectedState === influencer.state) {
      if(influencer.city != ""){
        const cities = influencer.city.split(", ")
        cities.forEach((city)=>{
          uniqueCities.add(city);
        })
      }
    }
  });

  const followerRanges = 
  {
    "": [0, Infinity],
    "0 - 25k": [0, 25000],
    "25k - 100k": [25000, 100000],
    "100k - 500k": [100000, 500000],
    "500k+": [500000, Infinity]
}
  
  return (
    <Box>
      <Box
        backgroundColor="white"
        borderRadius="10px"
        height="calc(100vh - 135px)"
        position="relative"
        display="flex"
        flexDirection="column"
      >
        <Box pt="25px" left={0} width="100%">
          <Flex m="20px"  justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <Text fontWeight="bold" fontSize="25px" >
                FoodFluence (Beta)
              </Text>
            </Flex>
            <Box>
              <Flex gap="5px">
                {accountsData && Object.keys(accountsData)?.length > 0 && (
                  <AccountsDropdown
                    filteredClientId={filteredClientID}
                    clientAccounts={clientAccounts}
                    setFilteredClientId={setFilteredClientID}
                    setSelectedAccountName={handleAccountNameChange}
                    selectedAccountName={selectedAccountName}
                  />
                )}
              </Flex>
            </Box>
          </Flex>
  
          <Box p="20px">
            <Text mb="16px" fontWeight="bold" fontSize="22px">Find Influencers</Text>
            <Input
              placeholder="Search by name, email, or Instagram handle"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              mb="20px"
              bg="gray.200"
              _placeholder={{ color: 'gray.400' }}
            />
  
            <Flex gap="10px" mb="20px">
              <Select
                placeholder="State"
                borderWidth={"thin"}
                borderColor="black"
                borderRadius={10}
                value={selectedState}
                onChange={(e) => {
                  setSelectedState(e.target.value);
                  setSelectedCity("");
                }}
                width={200}
              >
                {[...uniqueStates].sort().map((state) => (
                  <option key={state} value={state}>{state}</option>
                ))}
              </Select>
              <Select
                placeholder="City"
                borderWidth={"thin"}
                borderColor="black"
                borderRadius={10}
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                isDisabled={!selectedState}
                width={200}
              >
                {[...uniqueCities].sort().map((city) => (
                  <option key={city} value={city}>{city}</option>
                ))}
              </Select>
              <Select
                placeholder="Follower Count"
                borderWidth={"thin"}
                borderColor="black"
                borderRadius={10}
                value={followerRange}
                onChange={(e) => setFollowerRange(e.target.value)}
                width={200}
              >
                {Object.keys(followerRanges).map((text, index) => (
                  text!=""?
                  (<option key={index} value={text}>{text}</option>):(<></>)
                ))}
              </Select>
              <Button
                rightIcon={<Icon as={FaStar} color={filterFeatured ? "white" : "green"} />}
                borderWidth={"thin"}
                borderColor={filterFeatured ? "green" : "black"}
                backgroundColor={filterFeatured ? "green" : "white"}
                color={filterFeatured ? "white" : "black"}
                onClick={() => setFilterFeatured(!filterFeatured)}
                fontWeight={"normal"}
                borderRadius={10}
                variant="ghost"
                _hover={{ borderColor: "grey.400" }}
              >
                Featured
              </Button>
              <Button onClick={() => setFilterBookmarked(!filterBookmarked)} variant="ghost">
                {filterBookmarked ?
                  (<FaBookmark color="green" />) : (<FaRegBookmark />)}
              </Button>
            </Flex>
          </Box>
        </Box>
  
        <Box overflow="auto" flex="1" p="20px">
        <InfluencersList key={currPage} influencers={visibleInfluencers[currPage]} bookmarkedInfluencers={filteredAccountsData.bookmarkedInfluencers} clientID={filteredClientID}/>
        </Box>
        <Pagination
        currPage={currPage}
        setCurrPage={setCurrPage}
        totalPages={visibleInfluencers.length}
      />
    </Box>
    </Box>
  );
}
