import "initEnv";
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { AuthProvider } from './contexts/authContext';


ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<AuthProvider>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</AuthProvider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);