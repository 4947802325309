import React, { useState } from "react";
import DMTemplateEditor from "./DmTemplateEditor";
import DMLogs from "./DmLogs";
import { Box, Flex, Switch, Text, Heading, Button } from "@chakra-ui/react";
import { updateDmStatus } from "services/firebaseService";
import LogoutPopupModel from "./LogoutModel";

interface AutoEngagementProps {
  userId: string;
  metaData: any;
  handleLogout: any;
}

const AutoEngagementPreview: React.FC<AutoEngagementProps> = ({
  userId,
  metaData,
  handleLogout,
}) => {
  const [isActive, setIsActive] = useState(metaData?.dmEnable || false);
  const [showLogoutModel, setShowLogoutModel] = useState(false);

  const handleOnLogoutClick = () => {
    setShowLogoutModel(true);
  };

  // on cancle logout logout popup will close
  const onLogoutCancel = () => {
    setShowLogoutModel(false);
  };

  // // on click of logoutbutton signout from the dashboard but first it will ask for confirmation
  // const onLogout = () => {
  //   setShowLogoutModel(false);
  //   auth.signOut();
  // };

  const toggleSwitch = async () => {
    try {
      await updateDmStatus(userId, !isActive);
      setIsActive(!isActive);
    } catch (error) {
      console.log("Unabled to update status dm at the moment");
    }
  };
  return (
    <Flex
      overflow="hidden"
      backgroundColor="white"
      borderRadius="10px"
      width="100%"
      position="relative"
      height="calc(100vh - 135px)"
      padding={"20px 30px 30px"}
      flexDirection={"column"}
    >
      <Text
        fontWeight="bold"
        fontSize="25px"
        borderBottom="1.3px solid #E2E8F0"
        paddingBottom={"15px"}
      >
        AutoEngagement
      </Text>
      <Box
        width="100%"
        flex={"1 1 auto"}
        padding={"30px 50px 0px"}
        overflow={"hidden"}
      >
        <Box
          border="0px solid #000"
          width="100%"
          height={"100%"}
          borderRadius={"10px"}
          padding={"30px 50px"}
          overflow={"auto"}
          position={"relative"}
        >
          <Flex
            align="center"
            justifyContent={"space-between"}
            marginBottom={"30px"}
            gap={"30px"}
          >
            <Heading as="h2" size="lg" fontWeight={"600"}>
              Welcome to AutoEngagement, {metaData?.meta?.userName || ""}!
            </Heading>
            <Button
              variant="chakra_btn_secondary"
              size="chakra_lg"
              borderRadius="50rem"
              transition="transform 0.2s, box-shadow 0.2s"
              transform="translateY(0)"
              boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
              minWidth={"140px"}
              onClick={handleOnLogoutClick}
            >
              Logout
            </Button>
          </Flex>
          <Flex align="center" marginBottom={"30px"}>
            <Switch
              color="#2A85FF"
              size="10px"
              defaultChecked={isActive}
              onChange={() => {
                toggleSwitch();
              }}
            />
            <Text
              ml={2}
              mr={5}
              fontSize="15px"
              fontWeight="500"
              color={isActive ? "#2A85FF" : "grey"}
            >
              Enable DM
            </Text>
          </Flex>
          <DMTemplateEditor userId={userId} metaData={metaData} />
          <DMLogs userId={userId} metaData={metaData} />
        </Box>
      </Box>

      {
        // open logout Model
        <LogoutPopupModel
          isOpen={showLogoutModel}
          handleSignout={handleLogout}
          onClose={onLogoutCancel}
        />
      }
    </Flex>
  );
};

export default AutoEngagementPreview;
