import React from "react";
import {
  Box,
  Avatar,
  Text,
  Flex,
  Checkbox,
  Spacer,
  Icon,
} from "@chakra-ui/react";
import { FaPhone } from "react-icons/fa"; // Import the phone icon from react-icons/fa

import CustomerStatTiles from "./CustomerStatTiles";

interface Customer {
  firstName: string;
  instagramHandle: string;
  profilePicURL: string;
  phoneNumber: string;
  isInfluencer: boolean;
}

interface CustomerCheckTileProps {
  customer: Customer;
  handleCheckboxChange: Function;
  isChecked: boolean;
}

const CustomerCheckTile: React.FC<CustomerCheckTileProps> = ({
  customer,
  handleCheckboxChange,
  isChecked,
}) => {
  return (
    <Box
      width="97.5%"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      m=".5%"
      pt="2%"
      pb="2%"
      pl="2.5%"
    >
      <Flex align="center">
        <Avatar
          size="xl"
          name={customer.firstName}
          src={customer.profilePicURL}
        />

        <Box pl="2.5%">
          <Flex justify="start" align="center">
            <Flex justify="start" alignItems="center">
              <Text fontSize="22.5px" fontWeight="semibold">
                {customer.firstName === ""
                  ? customer.phoneNumber
                  : customer.firstName}
              </Text>
              {customer.isInfluencer && (
                <Box
                  ml="10px"
                  backgroundColor="#D3FFD2"
                  borderRadius="10px"
                  width="75px"
                  
                  p="1"
                >
                  <Flex
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontSize="11px" color="#117C00">
                      Influencer
                    </Text>
                  </Flex>
                </Box>
              )}
            </Flex>
          </Flex>
          <Text color="gray.600" fontSize="15px">
            @{customer.instagramHandle}
          </Text>
        </Box>
        <Spacer />
        <Checkbox
          mr="7.5%"
          size="lg"
          isChecked={isChecked}
          onChange={() => {
            handleCheckboxChange(customer.phoneNumber, !isChecked!);
          }}
        />
      </Flex>
    </Box>
  );
};

export default CustomerCheckTile;
