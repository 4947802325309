import React from "react";
import { background } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Input,
  Text,
  Button,
  Grid,
  GridItem,
  Box,
  Spacer,
} from "@chakra-ui/react";

import InputtedPhoneNumberTile from './InputtedPhoneNumberTile';


interface AddCustomersByPhoneNumberProps {
  handleNumbersInputted: Function;
  customerNumbersInputted: string[];
  isOpen: boolean;
  onClose: () => void;
  phoneNumber: string;
  setPhoneNumber: Function;
  formatPhoneNumber: Function;
  setFormattedPhoneNumber: Function;
  formattedPhoneNumber: string;
  unformatPhoneNumber: Function;
  setIsOpen: Function;
}

const AddCustomersByPhoneNumber: React.FC<AddCustomersByPhoneNumberProps> = ({
  handleNumbersInputted,
  customerNumbersInputted,
  isOpen,
  onClose,
  phoneNumber,
  setPhoneNumber,
  formatPhoneNumber,
  setFormattedPhoneNumber,
  formattedPhoneNumber,
  unformatPhoneNumber,
  setIsOpen
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="xl" minWidth="60%" minHeight="80%">
        <ModalHeader fontSize="25px" fontWeight="500">
          Add customers by phone number
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            mb="5%" // Add this line to limit maximum height
			mt="2%"
          >
            <Text display="flex" fontSize="20px" fontWeight="500" color="black">
              Phone Number
            </Text>
            <Input
              isRequired={true}
              variant="auth"
              value={formattedPhoneNumber} // This is where the preset value is set
              fontSize="lg"
              ml="1%"
              width="60%"
              type="tel"
              borderRadius="13px"
              placeholder="+1 ..."
              fontWeight="500"
              size="lg"
              onChange={(e) => {
                setPhoneNumber("+1" + e.target.value);
                setFormattedPhoneNumber(formatPhoneNumber(e.target.value));
              }}
            />
            <Button
              ml="1%"
              backgroundColor="black"
              borderRadius="10px"
              transition="transform 0.2s, box-shadow 0.2s"
              transform="translateY(0)"
              boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
              _hover={{
                transform: "scale(1.05)",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _focus={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _active={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              onClick={() => {
                // handle inputted phoneNumber --> use the setCustomerNumbersInputted() function to add this number to the end of the list
                handleNumbersInputted(unformatPhoneNumber(phoneNumber), true);
                setPhoneNumber(""); // resets the phone number to
                setFormattedPhoneNumber(""); // resets the phone number to
              }}
            >
              <Text
                fontWeight="500"
                fontSize="15px"
                textAlign="center"
                color="white"
              >
                + Add number
              </Text>
            </Button>
          </Flex>

          <Box height="380px" overflow="auto">
            <Grid
              templateColumns="repeat(6, 1fr)"
              gap="2.5%"
              mb="2.5%"
              rowGap="15%"
            >
              {customerNumbersInputted.map((number, index) => (
				<InputtedPhoneNumberTile phoneNumber={number} handleNumbersInputted={handleNumbersInputted} />
                // <GridItem key={index} bg="#F4F4F4" p={4} borderRadius="md">
                //   <Text fontSize="14px" fontWeight="500" align="center">
                //     {number}
                //   </Text>
                // </GridItem>
              ))}
            </Grid>
          </Box>
          <Flex alignItems="center" justifyContent="center">
            <Box borderTop="1.3px solid #E2E8F0" mt="15px" width="96%" />
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            <Button
              mt="3%"
              width="300px"
              backgroundColor="black"
              borderRadius="10px"
              transition="transform 0.2s, box-shadow 0.2s"
              transform="translateY(0)"
              boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
              _hover={{
                transform: "scale(1.05)",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _focus={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              _active={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              onClick={() => {setIsOpen(false)}}
            >
              <Text
                fontWeight="500"
                fontSize="15px"
                textAlign="center"
                color="white"
              >
                Confirm
              </Text>
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddCustomersByPhoneNumber;
// import React, { useState } from "react";
// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalCloseButton,
//   ModalBody,
//   Flex,
//   Input,
//   Text,
//   Button,
//   Grid,
//   Box,
// } from "@chakra-ui/react";
// import InputtedPhoneNumberTile from './InputtedPhoneNumberTile';

// interface AddCustomersByPhoneNumberProps {
//   handleNumbersInputted: Function
//   customerNumbersInputted: string[];
//   isOpen: boolean;
//   onClose: () => void;
//   setIsOpen: Function;
// }

// const AddCustomersByPhoneNumber: React.FC<AddCustomersByPhoneNumberProps> = ({
//   handleNumbersInputted,
//   customerNumbersInputted,
//   isOpen,
//   onClose,
//   setIsOpen
// }) => {
//   const [phoneNumberInput, setPhoneNumberInput] = useState("");

//   const formatPhoneNumber = (input: any) => {
//     // Remove all non-digits characters
//     const digitsOnly = input.replace(/\D/g, '');

//     // Format the number if it has 10 digits
//     if (digitsOnly.length === 10) {
//       return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
//     }
//     // If the input is not a complete number, return it unformatted
//     return input;
//   };

//   const unformatPhoneNumber = (formattedNumber: string) => {
//     return formattedNumber.replace(/[()-\s+]/g, "");
//   };

//   const handleInputChange = (input: string) => {

//     // Only add new characters if they are digits or commas.
//     if (/[^0-9,]/.test(input.slice(-1)) && input.slice(-1) !== ',') {
//       return;
//     }

//     // Split the input by commas to handle each number individually
//     // const phoneNumbers = input.split(',').map((num: string) => num.trim());

//     // Format each phone number and join them back with commas
//     const formattedInput = phoneNumbers
//       .map((number: any) => formatPhoneNumber(number))
//       .join(', ');

//     // Update the state with the new formatted input
//     setPhoneNumberInput(formattedInput);
//   };

//   // const handleAddNumbers = () => {
//   //   const numbers = phoneNumberInput
//   //     .split(',')
//   //     .filter(Boolean)
//   //     .map(unformatPhoneNumber);
//   //   handleNumbersInputted(numbers);
//   //   setPhoneNumberInput("");
//   // };

//   return (
//     <Modal isOpen={isOpen} onClose={onClose}>
//       <ModalOverlay />
//       <ModalContent borderRadius="xl" minWidth="60%" minHeight="80%">
//         <ModalHeader fontSize="25px" fontWeight="500">
//           Add customers by phone number
//         </ModalHeader>
//         <ModalCloseButton />
//         <ModalBody>
//           <Flex
//             direction="row"
//             justifyContent="center"
//             alignItems="center"
//             mb="5%"
//             mt="2%"
//           >
//             <Text display="flex" fontSize="20px" fontWeight="500" color="black">
//               Phone Numbers
//             </Text>
//             <Input
//               isRequired={true}
//               variant="auth"
//               value={phoneNumberInput}
//               fontSize="lg"
//               ml="1%"
//               width="60%"
//               type="tel"
//               borderRadius="13px"
//               placeholder="Enter numbers separated by commas"
//               fontWeight="500"
//               size="lg"
//               onChange={(e) => handleInputChange(e.target.value)}
//             />
//             <Button
//               ml="1%"
//               backgroundColor="black"
//               borderRadius="10px"
//               transition="transform 0.2s, box-shadow 0.2s"
//               transform="translateY(0)"
//               boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
//               _hover={{
//                 transform: "scale(1.05)",
//                 boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
//               }}
//               _focus={{
//                 boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
//               }}
//               _active={{
//                 boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
//               }}
//               onClick={handleAddNumbers}
//             >
//               <Text
//                 fontWeight="500"
//                 fontSize="15px"
//                 textAlign="center"
//                 color="white"
//               >
//                 + Add numbers
//               </Text>
//             </Button>
//           </Flex>

//           <Box height="380px" overflow="auto">
//             <Grid
//               templateColumns="repeat(6, 1fr)"
//               gap="2.5%"
//               mb="2.5%"
//               rowGap="15%"
//             >
//               {customerNumbersInputted.map((number, index) => (
//                 <InputtedPhoneNumberTile key={index} phoneNumber={number} handleNumbersInputted={handleNumbersInputted} />
//               ))}
//             </Grid>
//           </Box>
//           <Flex alignItems="center" justifyContent="center">
//             <Box borderTop="1.3px solid #E2E8F0" mt="15px" width="96%" />
//           </Flex>
//           <Flex alignItems="center" justifyContent="center">
//             <Button
//               mt="3%"
//               width="300px"
//               backgroundColor="black"
//               borderRadius="10px"
//               fontWeight="bold"
//               fontSize="20px"
//               color="white"
//               _hover={{
//                 backgroundColor: "#111",
//               }}
//               onClick={() => setIsOpen(false)}
//             >
//               Done
//             </Button>
//           </Flex>
//         </ModalBody>
//       </ModalContent>
//     </Modal>
//   );
// };

// export default AddCustomersByPhoneNumber;
