import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { AccountsDataContext } from "contexts/accountsDataContext";
import { useContext, useState } from "react";
import { IoMdInformationCircle } from "react-icons/io";
import { useHistory } from "react-router-dom";
import {
  createAutopilotDoc,
  createNewOfferDoc,
  createNewOfferFlowDoc,
  generateID,
  getCurrentUTCTimestamp,
  updateAutopilotDoc,
  updateClientOfferIds,
} from "../../../../services/firebaseService";
import ContentSummary from "../components/ContentSummary";
import TitleAndDescription from "../components/TitleAndDescription";

interface SummaryProps {
  setStep: Function;
  setAutopilotDataMap: Function;
  autopilotDataMap: Map<string, any>;
  isEditing: boolean;
}

function Summary({
  setStep,
  setAutopilotDataMap,
  autopilotDataMap,
  isEditing,
}: SummaryProps) {
  const contentTypes = autopilotDataMap.get("contentTypes");
  const contentTypesPreSelected = autopilotDataMap.get(
    "contentTypesPreSelected"
  );
  const editingContentType = autopilotDataMap.get("editingContentType");

  let newContentTypesSelected = contentTypes.filter(
    (contentType: string) => !contentTypesPreSelected.includes(contentType) // add editingContentType if its not ""
  );

  if (
    editingContentType &&
    !newContentTypesSelected.includes(editingContentType)
  ) {
    newContentTypesSelected = [...newContentTypesSelected, editingContentType];
  }

  const clientID = autopilotDataMap.get("clientID");
  const [isUploading, setIsUploading] = useState(false);
  const history = useHistory();
  const accountsData = useContext(AccountsDataContext);
  let clientOfferList = accountsData[clientID]["offers"] ?? [];

  async function handleClick() {
    setIsUploading(true);
    // we are assumming that a doc has not been created  (when editing, its gonna use a different function and page)

    for (let i = 0; i < newContentTypesSelected.length; i++) {
      // loop throught newly selected content types

      let contentTypeData = autopilotDataMap.get(newContentTypesSelected[i]); // access content types data

      if (newContentTypesSelected[i] !== editingContentType) {
        let date = getCurrentUTCTimestamp(); // date stamp

        // creating offer with min $
        let offerIDCash = generateID();

        const cashOfferData = new Map<string, any>([
          [
            "offerName",
            "Cash Autopilot: " + contentTypeData.get("contentType"),
          ],
          ["locations", [] as string[]],
          ["minCompensation", contentTypeData.get("min$")],
          ["maxCompensation", contentTypeData.get("max$")],
          ["compensation", 0],
          ["typeOfOffer", "credit"],
          ["isCash", false],
          ["percentOrCashOff", "Cash"],
          ["quantity", 99999],
          ["numToPost", 1],
          ["totalCost", 0],
          ["customerGroup", ""],
          ["ecommerceDiscountCode", ""],
          ["isActive", true],
          ["isPrivate", false],
          ["isCustomerOffer", true],
          ["isInfluencerOffer", false],
          ["numberOffersLeft", 99999],
          ["privateOfferPhoneList", [] as string[]],
          ["influencerPhoneNumbers", [] as string[]],
          ["contentType", contentTypeData.get("contentType")],
          [
            "integrations",
            contentTypeData.get("integration")
              ? { [contentTypeData.get("integration")]: {} }
              : {},
          ],
          ["discountItem", ""],
          ["discountItemID", ""],
          ["minPurchaseAmount", 0],
          ["discountCollectionID", ""],
          ["isAutopilotOffer", true],
          ["isCashBack", false],
          ["Hashtag", ""],
          ["specificProduct", ""],
          ["isFreeReward", false],
          ["isPrerequisite", false],
          ["discountType", "Store Discount"],
          ["isUploadCodes", false],
          ["isEvergreenCode", false],
          ["prerequisiteName", ""],
          ["prerequisiteQuantity", 0],
          ["prerequisiteCollectionID", ""],
          ["chosenCustomerGroups", []],
          ["expirationLimit", 30]
        ]);


        const cashOffer = await createNewOfferFlowDoc(
          offerIDCash,
          clientID,
          "",
          contentTypeData.get("exampleUGC"),
          cashOfferData,
          date,
          "",
          "",
          0, // minimum follower count
          [], //
          [],
          [],
          []
        );

        // creating offer with min %

        let offerIDPercent = generateID();

        const percentOfferData = new Map<string, any>([
          [
            "offerName",
            "Percent Autopilot: " + contentTypeData.get("contentType"),
          ],
          ["locations", [] as string[]],
          ["minCompensation", contentTypeData.get("min%")],
          ["maxCompensation", contentTypeData.get("max%")],
          ["compensation", 0],
          ["typeOfOffer", "credit"],
          ["isCash", false],
          ["percentOrCashOff", "Percent"],
          ["quantity", 99999],
          ["numToPost", 1],
          ["totalCost", 0],
          ["customerGroup", ""],
          ["ecommerceDiscountCode", ""],
          ["isActive", true],
          ["isPrivate", false],
          ["isCustomerOffer", true],
          ["isInfluencerOffer", false],
          ["numberOffersLeft", 99999],
          ["privateOfferPhoneList", [] as string[]],
          ["contentType", contentTypeData.get("contentType")],
          [
            "integrations",
            contentTypeData.get("integration")
              ? { [contentTypeData.get("integration")]: {} }
              : {},
          ],
          ["influencerPhoneNumbers", [] as string[]],
          ["discountItem", ""],
          ["discountItemID", ""],
          ["minPurchaseAmount", 0],
          ["discountCollectionID", ""],
          ["isAutopilotOffer", true],
          ["isCashBack", false],
          ["Hashtag", ""],
          ["specificProduct", ""],
          ["isFreeReward", false],
          ["isPrerequisite", false],
          ["discountType", "Store Discount"],
          ["isUploadCodes", false],
          ["isEvergreenCode", false],
          ["prerequisiteName", ""],
          ["prerequisiteQuantity", 0],
          ["prerequisiteCollectionID", ""],
          ["chosenCustomerGroups", []],
          ["expirationLimit", 30]
        ]);

        const percentOfferCreated = await createNewOfferFlowDoc(
          offerIDPercent,
          clientID,
          "",
          contentTypeData.get("exampleUGC"),
          percentOfferData,
          date,
          "",
          "",
          0, // minimum follower count
          [], //
          [],
          [],
          []
        );
        console.log("percent offer created---", percentOfferCreated);

        // await createNewOfferDoc(
        //   offerIDPercent,
        //   clientID,
        //   contentTypeData.get("exampleUGC"),
        //   percentOfferData,
        //   date,
        //   "",
        //   0,
        //   [],
        //   [],
        //   [],
        //   []
        // );

        clientOfferList.push(offerIDCash, offerIDPercent);

        contentTypeData.set("offerID$", offerIDCash);
        contentTypeData.set("offerID%", offerIDPercent);
        contentTypeData.set("isActive", true);

        // updating in the autopilot data
        setAutopilotDataMap(() => {
          const tempMap = new Map(autopilotDataMap);
          tempMap.set(newContentTypesSelected[i], contentTypeData);
          return tempMap;
        });
      }
    }

    await updateClientOfferIds(clientID, clientOfferList); //updating client file with the new offer list

    if (isEditing || editingContentType !== "") {
      // update autopilot doc with new autopilotdatamap
      await updateAutopilotDoc(autopilotDataMap);
    } else {
      // creating the autopilot document for the respective client with all the information
      await createAutopilotDoc(autopilotDataMap);
    }

    setIsUploading(false);
    history.goBack();
  }

  function backFunction() {
    setStep(3);
  }

  return (
    <Flex flexDirection="column" alignItems="start">
      <Flex justifyContent="start" alignItems="center" width="100%">
        <TitleAndDescription
          hideBackButton={false}
          title={"Content Priority"}
          description={"Select the priority of each content type"}
          backFunction={backFunction}
        />
        <Box
          marginLeft="auto"
          color="white"
          display="inline-block"
          width="50px"
          height="50px"
          borderRadius="50px"
          backgroundImage={autopilotDataMap.get("clientProfilePic")}
          backgroundSize="cover"
        />
      </Flex>
      <Box borderTop="1.3px solid #E2E8F0" width="100%" mt="1.5%" />
      <Box
        height="650px"
        borderRadius="lg"
        boxShadow="lg"
        overflowY="auto"
        width="100%"
        mt="2.5%"
        p={7}
      >
        <Flex justifyContent="start" alignItems="center">
          <Flex width="25%" justifyContent="start" alignItems="center">
            <Text fontSize="20px" fontWeight="400">
              Content Type
            </Text>
            <Box
              ml="2%"
              as="div"
              cursor="pointer"
              onClick={() => { }}
              display="inline-block" // Ensures the icon doesn't take full width
            >
              <IoMdInformationCircle color="#6F767E" size="20px" />
            </Box>
          </Flex>
          <Flex width="25%" justifyContent="start" alignItems="center">
            <Text fontSize="20px" fontWeight="400">
              Summary
            </Text>
            <Box
              ml="2%"
              as="div"
              cursor="pointer"
              onClick={() => { }}
              display="inline-block" // Ensures the icon doesn't take full width
            >
              <IoMdInformationCircle color="#6F767E" size="20px" />
            </Box>
          </Flex>
        </Flex>
        <Box borderTop="1.3px solid #E2E8F0" width="100%" mt="1.5%" />
        {((contentTypes.includes("instagramStory") &&
          !contentTypesPreSelected.includes("instagramStory")) ||
          editingContentType === "instagramStory") && (
            <ContentSummary
              clientInstagramHandle={autopilotDataMap?.get(
                "clientInstagramHandle"
              )}
              contentData={autopilotDataMap?.get("instagramStory") ?? {}}
            />
          )}
        {((contentTypes.includes("instagramPost") &&
          !contentTypesPreSelected.includes("instagramPost")) ||
          editingContentType === "instagramPost") && (
            <ContentSummary
              clientInstagramHandle={autopilotDataMap?.get(
                "clientInstagramHandle"
              )}
              contentData={autopilotDataMap?.get("instagramPost") ?? {}}
            />
          )}
        {((contentTypes.includes("instagramReel") &&
          !contentTypesPreSelected.includes("instagramReel")) ||
          editingContentType === "instagramReel") && (
            <ContentSummary
              clientInstagramHandle={autopilotDataMap?.get(
                "clientInstagramHandle"
              )}
              contentData={autopilotDataMap?.get("instagramReel") ?? {}}
            />
          )}
        {((contentTypes.includes("tiktokVideo") &&
          !contentTypesPreSelected.includes("tiktokVideo")) ||
          editingContentType === "tiktokVideo") && (
            <ContentSummary
              clientInstagramHandle={autopilotDataMap?.get(
                "clientInstagramHandle"
              )}
              contentData={autopilotDataMap?.get("tiktokVideo") ?? {}}
            />
          )}

        {((contentTypes.includes("ugc") &&
          !contentTypesPreSelected.includes("ugc")) ||
          editingContentType === "ugc") && (
            <ContentSummary
              clientInstagramHandle={autopilotDataMap?.get(
                "clientInstagramHandle"
              )}
              contentData={autopilotDataMap?.get("ugc") ?? {}}
            />
          )}
      </Box>
      <Box borderTop="1.3px solid #E2E8F0" width="100%" mt="7.5%" />

      <Flex justifyContent="flex-end" width="100%" p={4} mt="1.5%">
        <Button
          onClick={handleClick}
          backgroundColor="black"
          width="150px"
          p="10px 15px"
          borderRadius="10px"
          transition="transform 0.2s, box-shadow 0.2s"
          transform="translateY(0)"
          _hover={{
            transform: "scale(1.05)",
          }}
          _active={{}}
        >
          {!isUploading ? (
            <Text
              fontWeight="500"
              fontSize="15px"
              textAlign="center"
              color="white"
            >
              Publish
            </Text>
          ) : (
            <Spinner color="white" size="md" />
          )}
        </Button>
      </Flex>
    </Flex>
  );
}

export default Summary;
